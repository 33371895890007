const identityCard = function(IDCard){
    //基础格式校验
    if(!IDCard || !/^(1[1-5]|2[1-3]|3[1-7]|4[1-6]|5[0-4]|6[1-5]|71|8[1-3]|91)\d{15}(\d|X|x)$/.test(IDCard)){
        return false;
    }
    //出生日期校验
    let year = IDCard.substr(6,4),
        month = parseInt(IDCard.substr(10,2)),
        day = parseInt(IDCard.substr(12,2)),
        birthday = `${year}/${month}/${day}`;
    let d = new Date(birthday),
        dYear = d.getFullYear(),
        dMonth = d.getMonth() + 1,
        dDay = d.getDate(),
        dBirthday = `${dYear}/${dMonth}/${dDay}`;
    if(d == "Invalid Date" || birthday != dBirthday){
        return false;
    }
    //校验位
    let sum = 0,
        weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2],
        codes = "10X98765432";
    for(var i = 0; i < IDCard.length - 1; i++){
        sum += IDCard[i] * weights[i];
    }
    let last = codes[sum % 11];
    if(IDCard.substr(17,1).toLocaleUpperCase() != last){
        return false;
    }
    //信息读取
    let cDate = new Date(),
        cYear = cDate.getFullYear();
        // cMonth = cDate.getMonth() + 1,
        // cDay = cDate.getDate();
    let info = {};
    info.sex = (parseInt(IDCard.substr(16,1)) % 2) == 1 ? 1 : 2;//1男 2女
    info.dateBirth = birthday;
    info.age = cYear - year;
    return info;
}
export default identityCard;