<template>
    <div class="contest view">
        <div class="head_desc clearfix">
            <div class="iconBox fl">
                <i class="icon"></i>
                <p>赛事</p>
            </div>
            <div class="addMatch">
                <router-link to="/match/contest/editContest/0" class="btn">+创建主赛事</router-link>
                <div @click="hideMask">什么是赛事<i>?</i></div>
            </div>
            <div class="detail" hidden>
                <i class="selectTop homeHeader-bit-top"></i>
                <p>例如: </p>
                <p>主赛事: 中国职工沙滩运动会</p>
                <p>子赛事: 沙滩足球</p>
                <p>子赛事: 沙滩排球</p>
                <p>子赛事: 沙滩田径</p>
                <p>您可创建赛事后关联主赛事, 或在主赛事下创建子赛事.</p>
            </div>
        </div>
        <StatusBar
            :tit="'状态'"
            :currStatus="currStatus"
            :json_status="json_status"
            @statusHandle="statusHandle($event)"
            />
        <div class="tab">
            <div class="tab_th clearfix">
                <div class="fl td_1">序号</div>
                <div class="fl td_2">赛事名称</div>
                <div class="fl td_1">赛事项目</div>
                <div class="fl td_1">状态</div>
                <div class="fl td_1">赛事等级</div>
                <div class="fl td_3">操作</div>
            </div>
            <div class="box_tr" v-if="dataAll.length == 0">
                <p class="noData" v-text="listLoadTip"></p>
            </div>
            <div class="box_tr" v-else>
                <div class="tab_tr clearfix" v-for="(item, i) in dataAll" :key="i">
                    <div class="fl td_1">
                        <span v-text="item.rownum"></span>
                    </div>
                    <div class="fl td_2">
                        <span v-text="item.league_full_name || '-'"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="item.events_name || '-'"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="item.league_status || '-'"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="item.league_level || '-'"></span>
                    </div>
                    <div class="fl td_3">
                        <span
                            v-for="(handle, j) in handleList(item.league_status_number, item.league_id)"
                            :key="j">
                            <router-link 
                                v-if="handle.to"
                                :to="handle.to"
                                v-text="handle.txt"></router-link>
                            <a 
                                v-else
                                href="javascript:;"
                                @click="changeStatus(item.league_id)"
                                v-text="handle.txt"
                                ></a>
                        </span>
                        
                    </div>
                </div>
            </div>
            <PageHandle 
                :page="page"
                :totalPage="totalPage"
                :totalCount="totalCount"
                @pageTo="pageTo($event)" 
                />
        </div>
        <TipHandle 
            :txt="tip"
            :btnTxt="btnTxt"
            @maskHandle="maskHandle($event)" 
            />
    </div>
</template>

<script>
import StatusBar from '@/components/StatusBar.vue'
import PageHandle from '@/components/PageHandle.vue'
import TipHandle from '@/components/TipHandle.vue'
import { toQueryString } from '@/assets/js/util'

export default {
    name: 'Contest',
    props: {
    },
    components: {
        StatusBar,
        PageHandle,
        TipHandle
    },
    data() {
        return {
            token: this.$cookies.get('token'),
            page: 1,
            count: 20,
            totalPage: 1,
            totalCount: 1,
            currStatus: 0,
            json_status: {
                0: '全部',
                1: '进行',
                2: '结束'
            },
            status:{
                1: '未发布',
                2: '待审核',
                3: '审核未通过',
                4: '正在进行中',
                5: '已结束'
            },
            dataAll: [],
            getListFlag: true,
            tip: '',
            btnTxt: [],
            issueId: null,
            issueFlag: true,
            listLoadTip: '',
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        handleTips(txt, btnTxt){
            this.tip = txt
            this.btnTxt = btnTxt
        },
        maskHandle(btn){
            if(btn == 0){
                // 确认发布
                this.issueLeague()
            }
            this.tip = ''
        },
        statusHandle(status) {
            if(!this.getListFlag) {
                return
            }
            this.currStatus = status
            this.getList()
        },
        pageTo(page) {
            if(!this.getListFlag) {
                return
            }
            if(page == '-' && this.page > 1) {
                this.page --
                this.getList()
            } else if(page == '+' && this.page < this.totalPage){
                this.page ++
                this.getList()
            } else if(page != this.page && typeof page == 'number') {
                if(page > this.totalPage || page < 1) {
                    this.handleTips('请输入1~' + this.totalPage + '的数', ['关闭'])
                    return
                }
                this.page = page
                this.getList()
            }
            
        },
        getList() {
            if(this.getListFlag) {
                this.getListFlag = false
            } else {
                return
            }
            this.dataAll = []
            this.listLoadTip = '数据加载中...'
            this.$axios.post('Match/Entrance',toQueryString({
                action: 'GetMyleagueList',
                user_token:this.token,
                league_status: this.currStatus,
                page: this.page,
                count: this.count,
                type: 3,
                league_id: 0
            })).then((res) => {
                this.getListFlag = true
                let data = res.data;
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                if(data != -1){
                    if(data.leagueList.length == 0){
                        this.listLoadTip = '无数据'
                    }
                    this.dataAll = data.leagueList
                    this.totalCount = data.totalCount
                    this.totalPage = Math.ceil(data.totalCount/this.count)
                } else {
                    this.listLoadTip = data.message
                }
            }).catch(() => {
                this.listLoadTip = '网络异常'
                this.getListFlag = true
            })
        },
        handleList(status, matchId) {
            let handleConfig = {
                //未发布
                1: [
                    {txt:'编辑',to: '/match/contest/editContest/' + matchId},
                    {txt:'发布',to: ''}
                ],
                //待审核
                2: [
                    {txt:'赛事信息',to: '/match/contest/editContest/' + matchId}
                ],
                //审核未通过
                3: [
                    {txt:'编辑',to: '/match/contest/editContest/' + matchId}
                ],
                //审核通过
                4: [
                    {txt:'赛事信息',to: '/match/contest/editContest/' + matchId},
                    {txt:'赛事结果',to: '/match/contest/editContest/' + matchId}
                ],
                //已结束
                5: [
                    {txt:'赛事信息',to: '/match/contest/editContest/' + matchId},
                    {txt:'赛事结果',to: '/match/contest/editContest/' + matchId}
                ],
                //进行中
                6: [
                    {txt:'赛事信息',to: '/match/contest/editContest/' + matchId},
                    {txt:'赛事结果',to: '/match/contest/editContest/' + matchId}
                ],
            }
            return handleConfig[status]
        },
        changeStatus(id){
            this.issueId = id
            this.handleTips('发布之后不可修改，确认发布？', ['确认', '关闭'])
        },
        issueLeague(){
            if(this.issueFlag){
                this.issueFlag = false
            } else {
                return
            }
            this.$axios.post('Match/Entrance',toQueryString({
                action: 'Upt_league_status',
                user_token:this.token,
                enroll_type: 0,
                type: 1,
                league_id: this.issueId
            })).then((res) => {
                let data = res.data
                this.issueFlag = true
                if(data.code == 1){
                    this.handleTips('发布成功', ['关闭'])
                    this.page = 1
                    this.getList()
                } else {
                    this.handleTips(data.message, ['关闭'])
                }
            }).catch(() => {
                this.handleTips('网络异常', ['关闭'])
                this.issueFlag = true
            })
        },
        hideMask(){

        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    @import '~@/assets/css/common.css';
    .head_desc{
        height: 8rem;
        margin: -0.6rem -1rem 1rem -1rem;
        background: #e9e9e9;
        padding: 1rem 1rem 1rem 0;
    }
    .head_desc .iconBox{
        width: 5.6rem;
        height: 6rem;
        border: 2px solid #BDBDBD;
        text-align: center;
        margin-right: 1rem;
    }
    .head_desc .iconBox .icon{
        display: inline-block;
        width: 3.2rem;
        height: 3.2rem;
        background: url('~@/assets/imgs/icons.png') no-repeat -8rem 0;
        background-size: auto 120%;
    }
    .head_desc .iconBox p{
        font-size: 1.2rem;
    }
    .tab{
        width: 100%;
        height: calc(100% - 11rem);
    }
    .tab .td_2{width: 43%; min-width: 27rem;}
</style>
