<template>
    <ul>
        <li v-for="(item, i) in subnav" :key="i">
            <a class="unClick" href="javascript:;" v-if="item.src == ''" v-text="item.txt"></a>
            <router-link 
                v-else
                :to="item.src" 
                v-text="item.txt">
                <!-- @click="subNavHandle(i)" -->
            </router-link>
            <div class="aniBox fr" :style="'top:'+ (i*4)+ 'rem'"></div>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'LeftSide',
    props: {
        subnav: Array
    },
    data() {
        return {}
    },
    methods: {
        // subNavHandle(idx) {
        //     this.$emit('updateIdx', idx)
        // }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .leftSide ul{
        position: relative;
    }
    .leftSide .aniBox{
        width: 1.2rem;
        height: 5.4rem;
        position: absolute;
        background: #e9e9e9;
        margin: -1.2rem 0 0 0;
        right: 0;
        top: 0;
        overflow: hidden;
        display: none;
    }
    .leftSide .aniBox::before,
    .leftSide .aniBox::after{
        content: '';
        position: absolute;
        width: 2rem;
        height: 2rem;
        background: #ffffff;
        border-radius: 0 0 50% 0;
        margin: -0.8rem 0 0 -1.4rem;
    }
    .leftSide .aniBox::after{
        border-radius: 0 50% 0 0;
        margin: 4.2rem 0 0 -1.4rem;
    }
    .leftSide ul li{
        margin: 0 auto 1rem;
        width: 80%;
        height: 3rem;
        line-height: 3rem;
    }
    .leftSide ul li a{
        display: block;
        color: #000;
        background: #f0f0f0;
        border-radius: .3rem;
    }
    .leftSide ul li .router-link-exact-active{
        color: #fff;
        background: #960202;
    }
    .leftSide ul li .router-link-exact-active+.aniBox{
        display: block;
    }
    .leftSide ul li .unClick{
        color: #fff;
        background: #9a9a9a;
    }
</style>
