<template>
    <div class="editContest view">
        <router-link to="/infos/APPHomeList" class="backBtn"> &lt; 返回</router-link>
        <span>推荐新闻到首页</span>
        <div class="infosBox">
            <div class="group">
                <label>新闻链接：</label>
                <input type="text" v-model="form.newsLink" placeholder="新闻链接"  class="input" @blur="bulletinLinkBlur">
            </div>
            <div class="group">
                <label>标题：</label>
                <input type="text" v-model="form.newsTitle" placeholder="新闻标题"  class="input">
            </div>
            <div class="group">
                <label>缩略图：</label>
                <div class="img_box">
                    <ul>
                        <li v-for="item,index in imgs" :key="index" 
                        :class="{
                            'active': index == activeIndex
                        }" 
                        :style="{
                            'background-image': `url(${item})`
                        }" @click="lookImg(index)">
                            <i :class="{'el-icon-success':index == activeIndex}"  @click.stop="selectImg(index, item)"></i>
                        </li>
                    </ul>
                    <div class="uploadPic clearfix">
                        <div class="pic fl">
                            <img class="img-change" :src="defaultImg" alt="">
                            <input class="file" type="file" accept="" id="img1" @change="fileChange($event)">
                        </div>
                        <div class="picText fl">
                            <div>
                                <p>只能上传jpg、png格式，建议分辨率：290*163，大小不超过200KB</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="handleBox">
            <button @click="save" v-text="submitTxt"></button>
        </div>
        <div class="html_box" style="display:none"></div>
        <TipHandle 
            :txt="tip"
            :btnTxt="btnTxt"
            @maskHandle="maskHandle($event)" 
            />
                    
        <el-image ref="imagePreview" class="image_preview"
            src="~@/assets/images/logo.png" 
            :preview-src-list="imgs" :initial-index="initialIndex">
        </el-image>
    </div>
</template>

<script>
const DOMParser = require('xmldom').DOMParser;
import { nanoid } from "nanoid"
import OSS from 'ali-oss'
import TipHandle from '@/components/TipHandle'
export default {
    name: 'EditNews',
    components: {
        TipHandle
    },
    props: {
    },
    data() {
        return {
            form:{
                newsLink: '',
                newsTitle: '',
                activeImg: ''
            },
            getImgs: [],
            uploadImgs: [],
            activeIndex: -1,
            initialIndex: 0,
            defaultImg: require("@/assets/imgs/joinImg2.png"),
            tip: '',
            btnTxt: [],
            clickFlag: true,
            submitTxt: '保存',
            options:{}
        }
    },
    created(){
        this.$api.GetMainListThumbnailUploadOption().then(data=>{
            if(data.Code == 0){
                this.options = data.Options;
            }
        })
    },
    computed: {
        imgs(){
            return [...this.getImgs,...this.uploadImgs];
        }
    },
    methods: {
        handleTips(txt, btnTxt) {
            this.tip = txt
            this.btnTxt = btnTxt
        },
        maskHandle(btn) {
            if(btn == 1) {
                this.$router.back();
            }
            this.tip = ''
        },
        bulletinLinkBlur(){
            var value = this.form.newsLink;
            if((/^http|https/.test(value))){
                this.getHtml(value)
            }
        },
        getHtml(val){
            let _this = this;
            try{
                var xhr = new XMLHttpRequest();
                xhr.open('get', val, true);
                xhr.send();
                xhr.onreadystatechange = function(){
                    if(xhr.readyState == 4 && xhr.status == 200){
                        let doc = new DOMParser().parseFromString(xhr.response, 'text/html');
                        let imgs = [],
                            title = "";
                            console.log(doc.getElementsByTagName('title')[0]);
                        if(doc.getElementsByTagName('html')[0].getAttribute('YD')){
                            imgs = doc.getElementsByClassName('news_content')[0].getElementsByTagName('img');
                            title = doc.getElementsByTagName('title')[0].textContent;
                        }else{
                            imgs = doc.getElementsByTagName('img');
                        }
                        let src = [];
                        for(var i=0;i<imgs.length;i++){
                            src.push(imgs[i].getAttribute('src'))
                        }
                        _this.getImgs = src;
                        _this.form.newsTitle = title;
                    }
                }
            }catch{
                return;
            }
        },
        fileChange(event){
            let files = event.target.files, file;
            if (files && files.length > 0) {
                file = files[0];
                if(!file) return; 
                var acceptArr = [],
                    // acceptArr1 = ['image/jpeg','image/png','image/bmp'],
                    acceptArr2 = ['image/jpeg','image/png','image/bmp','pdf','word',
                        'excel','zip','application/pdf','application/msword',
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                        'application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'application/x-zip-compressed'];
                acceptArr = acceptArr2;
                if(acceptArr.indexOf(file.type) == -1){
                    alert('不支持此文件类型');
                    return;
                }
                if (file.size > 200*1024) {
                    alert('文件大小不能超过 200kb!');
                    return;
                }
                this.ossUpload(file)
            }
        },
        ossUpload(fileData){
            const _this = this;
            const options = this.options;
            const client = new OSS({
                // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
                region: options.Region,
                // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                accessKeyId: options.AccessKeyId,
                accessKeySecret: options.AccessKeySecret,
                // 从STS服务获取的安全令牌（SecurityToken）。
                stsToken: options.SecurityToken,
                // 填写Bucket名称。
                bucket: options.Bucket
            });
            const headers = {
                'Cache-Control': 'public',
                'Content-Disposition': encodeURIComponent(fileData.name),
            }
            let nameID = options.ObjectNamePrefix + nanoid();
            // console.log(client)
            client.put(nameID, fileData, {headers}).then(function (r1) {
                let imgs = [..._this.uploadImgs];
                imgs.push(r1.url);
                _this.$set(_this,'uploadImgs',imgs);
            }).catch(function () {
                alert('图片上传失败');
            });
        },
        lookImg(index){
            this.initialIndex = index;
            this.$refs['imagePreview'].showViewer= true;
        },
        selectImg(index, src){
            if(this.activeIndex == index){
                this.activeIndex = -1;
                this.form.activeImg = '';
            }else{
                this.activeIndex = index;
                this.form.activeImg = src;
            }
        },
        save(){
            if(this.submitTxt != '保存') return;
            let form = this.form;
            if(!form.newsLink){
                this.handleTips('请输入新闻链接',['确定']);
            }else if(!form.newsTitle){
                this.handleTips('请输入新闻标题',['确定']);
            }else if(!form.activeImg){
                let text =  this.imgs.length == 0 ? '请上传缩略图' : '请选择缩略图';
                this.handleTips(text,['确定']);
            }else{
                this.submitTxt = '保存中';
                this.$api.AddNewsToMainListEntry({
                    "NewsInfo": {
                        "Title": form.newsTitle,
                        "ThumbnailUrl": form.activeImg,
                        "EntryUrl": form.newsLink
                    }
                }).then(data=>{
                    this.submitTxt = "保存";
                    if(data.Code == 0){
                        this.handleTips('保存成功',['','返回列表']);
                    }else{
                        this.handleTips(data.Message, ['关闭']);
                    }
                }).catch(()=>{
                    this.submitTxt = "保存";
                    this.handleTips('保存失败', ['关闭']);
                })
            }

        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    @import '~@/assets/css/form.css';
    .img_box{
        display: inline-block;
        vertical-align: text-top;
    }
    .img_box ul{
        display: flex;
        padding-top: 20px;
    }
    .img_box ul li{
        height: 120px;
        width: 120px;
        margin-right: 10px;
        margin-bottom: 10px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        border-radius: 10px;
        overflow: hidden;
    }
    .img_box ul li i{
        position: absolute;
        width: 25px;
        height: 25px;
        display: block;
        top: 5px;
        right: 5px;
        font-size: 25px;
        border: 2px #fff solid;
        border-radius: 50%;
    }
    .img_box ul li.active::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        background: rgba(0,0,0,0.5);
    }
    .img_box ul li.active i{
        border: none;
        color: #00ff08;
    }
    .el-image{
        position: absolute;
        top: -500%;
    }
    .el-image ::v-deep .el-image__error{
        display: none;
    }
</style>
