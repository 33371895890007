<template>
    <div class="view">
        <div class="srchBox clearfix">
            <div class="fl">
                <label for="">竞赛项目：</label>
                <select name="" id="" v-model="events_id">
                    <option value="0">全部</option>
                    <option 
                        v-for="(item, i) in itemGroup" 
                        :key="i"
                        :value="item.events_id"
                        v-text="item.events_name"
                        ></option>
                </select>
            </div>
            <div class="fl ml_10">
                <label for="">状态：</label>
                <select name="" id="" v-model="point_status">
                    <option value="0">全部</option>
                    <option 
                        v-for="(item, i) in statusTxt" 
                        :key="i"
                        :value="i"
                        v-text="item"
                        ></option>
                </select>
            </div>
            <div class="fl ml_10">
                <button class="btn" @click="srchHandle">查询</button>
            </div>
        </div>
        <div class="tab">
            <div class="tab_th clearfix">
                <div class="fl td_0">序号</div>
                <div class="fl td_3">竞赛项目</div>
                <div class="fl td_2">赛事名称</div>
                <div class="fl td_1">状态</div>
                <div class="fl td_3">操作</div>
            </div>
            <div class="box_tr" v-if="dataAll.length == 0">
                <p class="noData" v-text="listLoadTip"></p>
            </div>
            <div class="box_tr" v-else>
                <div class="tab_tr clearfix" v-for="(item, i) in dataAll" :key="i">
                    <div class="fl td_0">
                        <span v-text="item.rownum"></span>
                    </div>
                    <div class="fl td_3">
                        <span v-text="item.events_name || '-'"></span>
                    </div>
                    <div class="fl td_2">
                        <span v-text="item.league_name || '-'"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="statusTxt[item.point_status]"></span>
                    </div>
                    <div class="fl td_3">
                        <span 
                            v-for="(handle, j) in handleList(item.point_status)"
                            :key="j">
                            <router-link 
                                v-if="handle.to == 4"
                                :to="'/vipPoint/count/detail/'+ item.league_id +'/'+ item.events_id"
                                v-text="handle.txt"></router-link>
                            <a
                                v-else
                                href="javascript:;"
                                @click="showMask(handle.to, item.league_id, item.events_id)"
                                v-text="handle.txt"></a>
                        </span>
                        
                    </div>
                </div>
            </div>
            <PageHandle 
                :page="page"
                :totalPage="totalPage"
                :totalCount="totalCount"
                @pageTo="pageTo($event)" 
                />
        </div>
        <div class="maskBox" v-show="maskFlag > 0">
            <div class="maskCont" v-show="maskFlag == 1">
                <h3>配置积分参数</h3><br>
                <div class="">
                    <label for="">积分年度：</label>
                    <date-picker v-model="point_year" type="year" value-type="format" format="YYYY" placeholder="选择年份"></date-picker>
                </div>
                <div class="configBox configHead clearfix">
                    <div class="fl w0">赛事分组</div>
                    <div class="fl w0">积分分组</div>
                    <div class="fl">赛事等级</div>
                    <div class="fl">积分比例</div>
                </div>
                <div class="configList">
                    <div class="configBox clearfix" v-for="(item, i) in maskLeagueGroup" :key="i">
                        <div class="fl w0" v-text="item.group_name"></div>
                        <div class="fl w0">
                            <select v-model="configArr[i].point_group_id">
                                <option 
                                    v-for="(pointItem,j) in maskPointGroup"
                                    :key="j"
                                    :value="pointItem.point_group_id"
                                    v-text="pointItem.point_group_name"
                                    ></option>
                            </select>
                        </div>
                        <div class="fl">
                            <select v-model="configArr[i].league_level">
                                <option 
                                    v-for="(lvl,k) in maskLvlArr"
                                    :key="k"
                                    :value="lvl.Value"
                                    v-text="lvl.Value"
                                    ></option>
                            </select>
                        </div>
                        <div class="fl">
                            <input type="tel" placeholder="大于0的数" v-model="configArr[i].group_parameter">%
                        </div>
                    </div>
                </div>
                <div class="maskBtnBox">
                    <div class="maskHint fl" v-text="maskHint"></div>
                    <button @click="hideMask">取消</button>
                    <button @click="configParam">确定</button>
                </div>
            </div>
            <div class="maskCont" v-show="maskFlag == 2">
                <h3>确认提交计算？</h3><br>
                <div class="maskBtnBox">
                    <div class="maskHint fl" v-text="maskHint"></div>
                    <button @click="hideMask">取消</button>
                    <button @click="submitCalc">确定</button>
                </div>
            </div>
            <div class="maskCont" v-show="maskFlag == 3">
                <h3>积分参数</h3><br>
                <div class="">
                    <label for="">积分年度：</label>
                    <span v-text="point_year"></span>
                </div>
                <div class="configBox configHead clearfix">
                    <div class="fl w0">赛事分组</div>
                    <div class="fl w0">积分分组</div>
                    <div class="fl">赛事等级</div>
                    <div class="fl">积分比例</div>
                </div>
                <div class="configList">
                    <div class="configBox clearfix" v-for="(item, i) in configArr" :key="i">
                        <div class="fl w0" v-text="item.group_name"></div>
                        <div class="fl w0">
                            <input type="text" v-model="configArr[i].point_group_name" disabled>
                        </div>
                        <div class="fl">
                            <input type="text" v-model="configArr[i].league_level" disabled>
                        </div>
                        <div class="fl">
                            <input type="tel" placeholder="大于0的数" v-model="configArr[i].group_parameter" disabled>%
                        </div>
                    </div>
                </div>
                <div class="maskBtnBox">
                    <button @click="hideMask">关闭</button>
                </div>
            </div>
        </div>
        <TipHandle 
            :txt="tip"
            :btnTxt="btnTxt"
            @maskHandle="maskHandle($event)" 
            />
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/zh-cn'
import PageHandle from '@/components/PageHandle.vue'
import TipHandle from '@/components/TipHandle.vue'
import { toQueryString } from '@/assets/js/util'

export default {
    name: 'Count',
    components: {
        DatePicker,
        PageHandle,
        TipHandle
    },
    props: {
    },
    data() {
        return {
            token: this.$cookies.get('token'),
            events_id: 0,
            point_status: 0,
            page: 1,
            count: 20,
            totalPage: 1,
            totalCount: 1,
            tip: '',
            btnTxt: [],
            statusTxt: {
                1: '未积分',
                2: '已积分'
            },
            dataAll:[],
            itemGroup: [],
            maskLeagueGroup: [],
            maskPointGroup: [],
            maskLvlArr: [],
            maskFlag: 0,
            maskLeagueId: 0,
            maskEventId: 0,
            point_year: '',
            configArr: [],
            maskHint: '',
            listLoadTip: '',
            getListFlag: true,
            calcFlag: true,
            paramFlag: true
        }
    },
    mounted() {
        this.getList()
        this.getItemGroup(0)
        this.getLvl()
    },
    methods: {
        handleTips(txt, btnTxt){
            this.tip = txt
            this.btnTxt = btnTxt
        },
        maskHandle(btn){
            if(btn == 1) {
                this.$router.push('/vipPoint/count/detail/' + this.maskLeagueId + '/' + this.maskEventId)
            }
            this.tip = ''
        },
        pageTo(page) {
            if(!this.getListFlag) {
                return
            }
            if(page == '-' && this.page > 1) {
                this.page --
                this.getList()
            } else if(page == '+' && this.page < this.totalPage){
                this.page ++
                this.getList()
            } else if(page != this.page && typeof page == 'number') {
                if(page > this.totalPage || page < 1) {
                    this.handleTips('请输入1~' + this.totalPage + '的数', ['关闭'])
                    return
                }
                this.page = page
                this.getList()
            }
        },
        handleList(status){
            let arr = []
            if(status==1){
                arr = [
                    {to: 1, txt: '配置积分参数'},
                    {to: 2, txt: '开始计算'},
                ]
            } else if(status==2){
                arr = [
                    {to: 3, txt: '查看积分参数'},
                    {to: 4, txt: '积分详情'},
                ]
            }
            return arr
        },
        srchHandle() {
            if(!this.getListFlag) {
                return
            }
            this.page = 1
            this.getList()
        },
        showMask(type, id1, id2){
            this.maskFlag = type
            this.maskLeagueId = id1
            this.maskEventId = id2
            if(type == 1){
                this.getPointGroup()
            } else if(type == 3) {
                this.getPointRecord(3)
            }
            // console.log(type)
        },
        hideMask(){
            this.maskFlag = -1
            this.maskHint = ''
        },
        // 积分计算列表
        getList(){
            if(this.getListFlag) {
                this.getListFlag = false
            } else {
                return
            }
            this.dataAll = []
            this.listLoadTip = '数据加载中...'
            this.$axios.post('Match/Entrance',toQueryString({
                action: 'Get_point_events_id',
                user_token:this.token,
                events_id: this.events_id,
                status: this.point_status,
                page: this.page,
                count: this.count
            })).then((res) => {
                this.getListFlag = true
                let data = res.data;
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                if(data.code == 1){
                    if(data.NewList.length == 0){
                        this.listLoadTip = '无数据'
                    }
                    this.dataAll = data.NewList;
                    this.totalCount = data.totalCount
                    this.totalPage = Math.ceil(data.totalCount/this.count)
                } else {
                    this.listLoadTip = data.message
                }
            }).catch(() => {
                this.listLoadTip = '网络异常'
                this.getListFlag = true
            })
        },
        // 提交配置积分参数
        configParam(){
            if(!this.point_year){
                this.maskHint = '请选择积分年度'
                return
            }
            for(let i in this.configArr) {
                if(!this.configArr[i].group_parameter || this.configArr[i].group_parameter < 0) {
                    this.maskHint = '请输入大于0的积分比例'
                    return
                }
            }
            if(this.paramFlag) {
                this.paramFlag = false
            } else {
                return
            }
            this.maskHint = '参数提交中...'
            this.$axios.post('Match/Entrance',toQueryString({
                action: 'Upt_league_group',
                user_token:this.token,
                league_id: this.maskLeagueId,
                events_id: this.maskEventId,
                point_year: this.point_year,
                Str: JSON.stringify(this.configArr)
            })).then((res) => {
                this.paramFlag = true
                this.maskHint = ''
                let data = res.data;
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                if(data.code == 1){
                    this.handleTips('配置成功', ['关闭'])
                    this.maskFlag = -1
                } else {
                    this.maskHint = data.message
                }
            }).catch(() => {
                this.maskHint = '网络异常'
                this.paramFlag = true
            })
        },
        // 计算积分 
        submitCalc(){
            if(this.calcFlag){
                this.calcFlag = false
            } else {
                return
            }
            this.maskHint = '计算中...'
            this.$axios.post('Match/Entrance',toQueryString({
                action: 'Calculate_integral',
                user_token:this.token,
                league_id: this.maskLeagueId,
                events_id: this.maskEventId,
            })).then((res) => {
                this.calcFlag = true
                this.maskHint = ''
                let data = res.data;
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                if(data.code == 1){
                    this.maskFlag = -1
                    this.handleTips('积分计算完成', ['关闭', '查看积分详情'])
                } else {
                    this.maskHint = data.message
                }
            }).catch(() => {
                this.calcFlag = true
                this.maskHint = '网络异常'
            })
        },
        // 获取配置积分 
        getPointRecord(type){
            this.$axios.post('Match/Entrance',toQueryString({
                action: 'Get_league_group',
                user_token:this.token,
                league_id: this.maskLeagueId,
                events_id: this.maskEventId
            })).then((res) => {
                let data = res.data;
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                if(data.code == 1){
                    if(type) {
                        this.maskFlag = type
                    }
                    if(data.point_year) {
                        this.point_year = data.point_year
                        this.configArr = data.NewList
                    }
                } else {
                    this.handleTips(data.message, ['关闭'])
                }
            }).catch(() => {
                this.handleTips('网络异常', ['关闭'])
            })
        },
        // 获取竞赛项目
        getItemGroup(id){
            this.$axios.post('Match/Entrance',toQueryString({
                action: 'Get_point_league_events_group',
                user_token:this.token,
                events_id: id
            })).then((res) => {
                let data = res.data;
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                if(data.code == 1){
                    if(id){
                        this.maskPointGroup = data.NewList[0].epgList
                        this.configArr.forEach((v,i) => {
                            this.$set(this.configArr[i], 'point_group_id', this.maskPointGroup[0].point_group_id)
                        })
                        this.getPointRecord()
                    } else {
                        this.itemGroup = data.NewList
                    }
                } else {
                    this.handleTips(data.message, ['关闭'])
                }
            }).catch(() => {
                this.handleTips('网络异常', ['关闭'])
            })
        },
        // 配置积分分组列表
        getPointGroup(){
            this.$axios.post('Match/Entrance',toQueryString({
                action: 'Get_point_group_id',
                user_token:this.token,
                league_id: this.maskLeagueId,
                events_id: this.maskEventId
            })).then((res) => {
                let data = res.data;
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                if(data.code == 1){
                    let _arr = []
                    data.NewList.forEach((v) => {
                        _arr.push({
                            group_id: v.group_id,
                            point_group_id: 0,
                            league_level: v.league_level?v.league_level: this.maskLvlArr[0].Value,
                            group_parameter: 100
                        })
                    })
                    this.configArr = _arr
                    this.maskLeagueGroup = data.NewList
                    this.getItemGroup(this.maskEventId)
                } else {
                    this.handleTips(data.message, ['关闭'])
                }
            }).catch(() => {
                this.handleTips('网络异常', ['关闭'])
            })
        },
        // 赛事等级 
        getLvl(){
            this.$axios.post('Match/Entrance',toQueryString({
                action: 'Get_dicts_KeyValue',
                user_token:this.token,
                dict_key: 'league_level',
            })).then((res) => {
                let data = res.data;
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                if(data.code == 1){
                    this.maskLvlArr = data.list
                } else {
                    this.handleTips(data.message, ['关闭'])
                }
            }).catch(() => {
                this.handleTips('网络异常', ['关闭'])
            })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .srchBox{
        height: 3rem;
        line-height: 3rem;
    }
    .srchBox input, .srchBox select{
        height: 2rem;
    }
    .tab{
        width: 100%;
        height: calc(100% - 2.6rem);
    }
    .maskBox{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, .8);
    }
    .maskBox .maskCont{
        position: absolute;
        width: 50%;
        min-width: 35rem;
        top: 20%;
        border: 1px solid #888;
        left: 50%;
        transform: translateX(-50%);
        background: #fff;
        padding: 1rem 2rem;
    }
    .configBox{
        line-height: 2rem;
        padding: .3rem 0;
    }
    .configBox:nth-child(odd){
        background: #eaeaea;
    }
    .configHead div{
        font-weight: 600;
    }
    .configBox div{
        width: 18%;
    }
    .configBox div.w0{
        width: 32%;
    }
    .configBox div select{
        width: 94%;
        height: 2rem;
    }
    .configBox div input{
        width: 70%;
        padding-left: .2rem;
        height: 2rem;
    }
    .configList{
        max-height: 15rem;
        overflow: auto;
    }
    .maskBtnBox{
        text-align: right;
        margin: 1rem 0 0;
    }
    .maskBtnBox .maskHint{
        color: red;
    }
    .maskBtnBox button{
        margin: 0 1rem;
    }
    .tab .td_2{width: 43%; min-width: 27rem;}
</style>
