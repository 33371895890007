<template>
    <div class="view">
        <div class="srchBox clearfix">
            <div class="fl projectType">
                <label for="">运功项目：</label>
                <button v-for="(item,index) in eventsList" :key="index" :class="{'active':item.Id == eventsId}" @click="selectEvents(item.Id)">{{item.Name}}</button>
            </div>
        </div>
        <div class="srchBox clearfix">
            <div class="fl state">
                <label for="">计算状态：</label>
                <button v-for="(item,index) in statusArr" :key="index" :class="{'active':item.status == status}" @click="selectStatus(item.status)">{{item.text}}</button>
            </div>
        </div>
        <div class="tab">
            <div class="tab_th clearfix">
                <div class="td_1">序号</div>
                <div class="td_2">运动项目</div>
                <div class="td_3">赛事名称</div>
                <div class="td_2">大师分类型</div>
                <div class="td_2">大师分年度</div>
                <div class="td_2">当前状态</div>
                <div class="td_2">操作</div>
            </div>
            <div class="box_tr" v-if="list.length == 0">
                <p class="noData" v-text="listLoadTip"></p>
            </div>
            <div class="box_tr" v-else>
                <div  class="tab_tr clearfix" v-for="(item, i) in list" :key="i">
                    <div class="td_1"><span>{{(page - 1) * count + i + 1}}</span></div>
                    <div class="td_2"><span>{{item.EventsName}}</span></div>
                    <div class="td_3"><span>{{item.LeagueName}}</span></div>
                    <div class="td_2"><span>{{['','金分','银分','红分'][item.MastersScoreType]}}</span></div>
                    <div class="td_2"><span>{{item.MastersYear}}</span></div>
                    <div class="td_2"><span>{{['未计算','已计算'][item.MastersStatus]}}</span></div>
                    <div class="td_2">
                        <span v-if="item.PointStatus == 2">
                            <a href="javascript:;" v-if="item.MastersStatus == 0" @click="calculate(i, item.LeagueId, item.EventsId)">开始计算</a><!--计算-->
                            <router-link :to="`/masterScore/numericalIntegration/integrationDetail/${item.LeagueId}?name=${item.LeagueName}&type=${item.MastersScoreType}&year=${item.MastersYear}`" v-else-if="item.MastersStatus == 1">大师分详情</router-link><!--大师分详情-->
                        </span>
                        <span v-else>请先进行积分计算</span>
                    </div>
                </div>
            </div>
        </div>
        <PageHandle 
            :page="page"
            :totalPage="totalPage"
            :totalCount="totalCount"
            @pageTo="pageTo($event)" 
            />
        <TipHandle 
            :txt="tip"
            :btnTxt="btnTxt"
            @maskHandle="maskHandle($event)" 
            />
    </div>
</template>

<script>
import PageHandle from '@/components/PageHandle.vue'
import TipHandle from '@/components/TipHandle.vue'
import {
    GetMastersCountPageList,
    CountMastersScore
} from "@/newApi"

export default {
    name: 'Rating',
    components: {
        PageHandle,
        TipHandle
    },
    props: {
    },
    data() {
        return {
            page: 1,
            count: 20,
            totalPage: 0,
            totalCount: 0,
            tip: '',
            btnTxt: [],
            eventsId:0,
            eventsList:[{
                Id: 0,
                Name: '全部'
            },{
                Id: 13,
                Name: '掼蛋'
            }],
            status: -1,
            statusArr:[{
                status: -1,
                text: '全部'
            },{
                status: 0,
                text: '未计算'
            },{
                status: 1,
                text: '已计算'
            }],
            list:[],
            listLoadTip: '',
            getListFlag: true,
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        handleTips(txt, btnTxt){
            this.tip = txt
            this.btnTxt = btnTxt
        },
        maskHandle(){
            this.tip = ''
        },
        pageTo(page){
            if(!this.getListFlag) {
                return
            }
            if(page == '-' && this.page > 1) {
                this.page --
                this.getList()
            } else if(page == '+' && this.page < this.totalPage){
                this.page ++
                this.getList()
            } else if(page != this.page && typeof page == 'number') {
                if(page > this.totalPage || page < 1) {
                    this.handleTips('请输入1~' + this.totalPage + '的数', ['关闭'])
                    return
                }
                this.page = page
                this.getList()
            }
            
        },
        selectEvents(eventsId){
            if(!this.getListFlag) {
                return
            }
            this.eventsId = eventsId;
            this.totalPage = 0;
            this.totalCount = 0;
            this.page = 1;
            this.getList();
        },
        selectStatus(status){
            if(!this.getListFlag) {
                return
            }
            this.status = status;
            this.totalPage = 0;
            this.totalCount = 0;
            this.page = 1;
            this.getList();
        },
        // 列表
        getList(){
            if(this.getListFlag) {
                this.getListFlag = false
            } else {
                return
            }
            this.list = [];
            this.listLoadTip = '数据加载中...'
            GetMastersCountPageList({
                "EventsId": this.eventsId,
                "MastersStatus": this.status == -1 ? null : this.status,
                "PageSize": this.count,
                "PageNum": this.page
            }).then(data=>{
                this.getListFlag = true;
                if(data.Code == 0){
                    if(data.ContinueList && data.ContinueList.length > 0){
                        this.totalPage = data.PageCount;
                        this.totalCount = data.TotalCount;
                        this.page = data.PageNum;
                        this.list = data.ContinueList;
                        this.listLoadTip = '';
                    }else{
                        this.totalPage = 0;
                        this.totalCount = 0;
                        this.page = 1;
                        this.list = [];
                        this.listLoadTip = '无数据';
                    }
                }else{
                    this.listLoadTip = data.Message;
                }
            }).catch(()=>{
                this.getListFlag = true;
                this.listLoadTip = '数据获取失败';
            })
        },
        //计算
        calculate(index, LeagueId, EventsId){
            var loading = this.$loading({
                text:"正在计算...",
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            CountMastersScore({
                LeagueId,
                EventsId
            }).then((data)=>{
                loading.close();
                if(data.Code == 0){
                    this.handleTips('计算完成', ['关闭']);
                    this.$set(this.list[index], 'MastersStatus', 1);
                }else{
                    this.handleTips(data.Message,['关闭']);
                }
            }).catch(()=>{
                loading.close();
                this.handleTips('网络异常',['关闭']);
            })
        }
    }
}
</script>

<style scoped>
@import '~@/assets/css/common_z.css';
    .srchBox{
        height: 3rem;
        line-height: 3rem;
    }
    .srchBox input, .srchBox select{
        height: 2rem;
    }
    .state,
    .projectType{
        white-space: nowrap;
    }
    .state button,
    .projectType button{
        height: 2rem;
        padding: 0 1rem;
        margin-right: 1rem;
        border: none;
        cursor: pointer;
        background: #DFDFDF;
    }
    .state button.active,
    .projectType button.active{
        background: #C91E25;
        color: #ffffff;
    }
    .tab{
        width: 100%;
        height: calc(100% - 11rem);
        overflow-x: auto;
    }
    .tab .tab_th{
        min-width: 75rem;
    }
    .box_tr{
        height: calc(100% - 3rem);
        width: 100%;
        min-width: 75rem;
        overflow-x: hidden;
    }
    .tab .tab_tr{
        width: 100%;
    }
</style>
