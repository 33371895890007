<template>
    <div class="view">
        <router-link to="/infos/news" class="backBtn"> &lt; 返回</router-link>
        <span v-text="'当前：' + ((if_audit == 0 || newsId == 0)?'审核新闻': '查看新闻')"></span>
        <div class="infosBox">
            <div class="group">
                <label>编辑者：</label>
                <span v-text="oneInfos.author"></span>
            </div>
            <div class="group">
                <label>新闻类型：</label>
                <span v-text="newsType[oneInfos.type]"></span>
            </div>
            <div class="group">
                <label>关联赛事：</label>
                <span v-text="oneInfos.league_name"></span>
            </div>
            <div class="group">
                <label>新闻标题：</label>
                <span v-text="oneInfos.tit || '-'"></span>
            </div>
            <div class="group">
                <label>副标题：</label>
                <span v-text="oneInfos.minTit || '-'"></span>
            </div>
            <div class="group">
                <label>关键字：</label>
                <span v-text="oneInfos.keywords || '-'"></span>
            </div>
            <div class="group" v-show="oneInfos.type == 3 || oneInfos.type == 4">
                <label>新闻来源：</label>
                <select disabled="disabled" v-model="oneInfos.datum_from">
                    <option value="1">管理端</option>
                    <option value="2">移动端</option>
                </select>
            </div>
            <div class="group" v-show="oneInfos.type == 4">
                <label>视频id：</label>
                <input type="text" v-model="oneInfos.video_id" placeholder="视频id"  class="input" maxlength="32">
            </div>
            <div class="group">
                <div>正文：</div>
                <div class="htmlBox">
                    <div v-html="content"></div>
                </div>
            </div>
            <div class="group" v-show="oneInfos.type != 3">
                <div class="">
                    <div>已上传的新闻主图：</div>
                    <div class="showImg" style="width: 192px;">
                        <img :src="picImg0 || defaultImg" style="width: 100%;">
                    </div>
                </div>
            </div>
            <div class="group" v-show="if_audit == 2 || if_audit == 0">
                <div class="">
                    <div>不通过理由：</div>
                    <div>
                        <div v-text="reason" v-if="if_audit == 2"></div>
                        <textarea v-else cols="60" rows="10" maxlength="300" v-model.trim="reason"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="handleBox" v-show="if_audit == 0 || newsId == 0">
            <button @click="submitMe(1)" v-text="submitTxt1"></button>
            <button @click="submitMe(2)" v-text="submitTxt2"></button>
        </div>
        <TipHandle 
            :txt="tip"
            :btnTxt="btnTxt"
            @maskHandle="maskHandle($event)" 
            />
    </div>
</template>

<script>
import TipHandle from '@/components/TipHandle'
import { toQueryString } from '@/assets/js/util'
export default {
    name: 'EditNews',
    components: {
        TipHandle
    },
    props: {
    },
    data() {
        return {
            token: this.$cookies.get('token'),
            begin: null,
            end: null,
            tip: '',
            btnTxt: [],
            newsType: ['全部类型', '活动新闻', '资讯新闻', '图片新闻', '视频新闻'],
            newsId: 0,
            oneInfos: {
                'newsId': '',
                'tit': '',
                'minTit': '',
                'league_name': '',
                'type': 1,
                'keywords': '',
                'desc': '',
                'author': '',
                'list': [],
                'datum_from': 1,
                'video_id': ''
            },
            if_audit: 1,
            content: '',
            leagueIdList: [],
            picImg0: '',
            defaultImg: require('@/assets/imgs/joinImg2.png'),
            clickFlag: true,
            submitTxt1: '通过审核',
            submitTxt2: '不通过审核',
            reason: ''
        }
    },
    mounted() {
        this.newsId = this.$route.params.newsId
        if(this.newsId > 0) {
            this.getNewsDetail()
        }
    },
    watch: {},
    methods: {
        handleTips(txt, btnTxt) {
            this.tip = txt
            this.btnTxt = btnTxt
        },
        maskHandle(btn) {
            if(btn == 0) {
                //通过审核
                this.sureCheck(this.checkType)
            } else if(btn == 1) {
                this.$router.push('/infos/news')
            }
            this.tip = ''
        },
        // 获取新闻详情
        getNewsDetail() {
            this.$axios.post('CESAManage/Entrance', toQueryString({
                action: 'GetDatumByID',
                user_token: this.token,
                datumId: this.newsId
            })).then((res) => {
                let data = res.data;
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                this.oneInfos.newsId = data.datum_id
                this.oneInfos.tit = data.title || ''
                this.oneInfos.minTit = data.sub_title || ''
                this.oneInfos.type = data.datum_type
                this.oneInfos.league_name = data.league_name
                this.oneInfos.keywords = data.datum_keyword || ''
                this.oneInfos.author = data.author || ''
                this.oneInfos.authorId = data.user_id

                this.if_audit = data.if_audit;
                this.oneInfos.datum_from = data.datum_from;
                this.oneInfos.video_id = data.video_id || ''
                this.oneInfos.list = data.list || ''
                this.picImg0 = data.img_url + data.title_img_path.split('/uploadfile')[1]
                this.reason = data.audit_reason

                this.getDetailHtml(data.datum_path)
            }).catch(() => {
                this.clickFlag = true
                this.handleTips('网络异常', ['关闭'])
            })
        },
        // 提交
        submitMe(type) {
            if(!this.clickFlag) {
                return
            }
            this.checkType = type
            if(type == 1) {
                this.handleTips('确认通过审核？', ['确认', '取消'])
            } else if(type == 2) {
                if(!this.reason) {
                    this.handleTips('请输入不通过理由', ['关闭'])
                    return
                }
                this.handleTips('确认不通过审核？', ['确认', '取消'])
            }
            
        },
        sureCheck(type) {
            if(this.clickFlag) {
                this.clickFlag = false
            } else {
                return
            }
            if(type == 1) {
                this.reason = ''
                this.submitTxt1 = '提交中...'
            } else if(type == 2) {
                this.submitTxt2 = '提交中...'
            }
            this.$axios.post('CESAManage/Entrance', toQueryString({
                action: 'upt_league_datum',
                type,
                datum_id: this.newsId,
                user_token: this.token,
                audit_reason: this.reason
            })).then((res) => {
                let data = res.data
                this.submitTxt1 = '通过审核'
                this.submitTxt2 = '不通过审核'
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                if(data.code == 1){
                    this.handleTips('审核完毕', ['', '返回列表'])
                } else {
                    this.clickFlag = true
                    this.handleTips(data.message, ['关闭'])
                }
            }).catch(() => {
                this.submitTxt1 = '通过审核'
                this.submitTxt2 = '不通过审核'
                this.clickFlag = true
                this.handleTips('网络异常', ['关闭'])
            })
        },
        // 获取富文本框内容
        getDetailHtml(param) {
            this.$axios.post('CESAManage/Entrance', toQueryString({
                action: 'QueryHtmlDesc',
                user_token: this.token,
                html_path: param,
                datum_from: this.oneInfos.datum_from,
                datum_type: this.oneInfos.type,
                datum_id: this.oneInfos.newsId,
                list: JSON.stringify(this.oneInfos.list),
                video_id: this.oneInfos.video_id
            })).then((res) => {
                let data = res.data
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                this.content = data
            }).catch(() => {
                this.handleTips('网络异常', ['关闭'])
            })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .htmlBox {
        min-height: 200px;
        max-height: 600px;
        padding: 1rem .4rem;
        border: 1px solid #999;
        overflow: auto;
    }
</style>
