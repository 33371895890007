<template>
    <div class="view">
        <div class="viewBtn"><span class="on">个人会员</span><span @click="goUrl">机构会员</span></div>
        <div class="srchBox clearfix">
            <div class="fl ml_10">
                <label for="">会员号：</label>
                <input type="text" name="" v-model="member_no">
            </div>
            <div class="fl ml_10">
                <label for="">姓<i style="opacity: 0;">朱靖</i>名：</label>
                <input type="text" name=""  v-model="user_name">
            </div>
            <div class="fl ml_10">
                <label for=""><i style="opacity: 0;">靖</i>证件号码：</label>
                <input type="text" name="" v-model="car_no">
            </div>
        </div>
        <div class="srchBox clearfix">
            <div class="fl ml_10">
                <label for="">手机号：</label>
                <input type="text" name="" v-model="tel_no">
            </div>
            <div class="fl ml_10">
                <label for="">机构名称：</label>
                <input type="text" name="" v-model="company_name">
            </div>
            <div class="fl ml_10">
                <label for="">机构会员号：</label>
                <input type="text" name="" v-model="company_no">
            </div>
            <div class="fl ml_10">
                <button class="btn" @click="srchHandle">查询</button>
            </div>
        </div>
        <div class="tab">
            <div class="tab_th clearfix">
                <div class="fl td_0">会员号</div>
                <div class="fl td_3">姓名</div>
                <div class="fl td_1">证件类型</div>
                <div class="fl td_3">证件号码</div>
                <div class="fl td_1">手机号</div>
                <div class="fl td_3">所属机构</div>
                <div class="fl td_1">机构会员号</div>
            </div>
            <div class="box_tr" v-if="dataAll.length == 0">
                <p class="noData" v-text="listLoadTip"></p>
            </div>
            <div class="box_tr" v-else>
                <div class="tab_tr clearfix" v-for="(item, i) in dataAll" :key="i">
                    <div class="fl td_0">
                        <span v-text="item.member_no"></span>
                    </div>
                    <div class="fl td_3">
                        <span v-text="item.NAME"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="item.identity_type || '-'"></span>
                    </div>
                    <div class="fl td_3">
                        <span v-text="item.identity_no || '-'"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="item.phone || '-'"></span>
                    </div>
                    <div class="fl td_3">
                        <span v-text="item.company_name || '-'"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="item.company_member_no || '-'"></span>
                    </div>
                </div>
            </div>
            <PageHandle
                    :page="page"
                    :totalPage="totalPage"
                    :totalCount="totalCount"
                    @pageTo="pageTo($event)"
            />
        </div>
        <TipHandle
                :txt="tip"
                :btnTxt="btnTxt"
                @maskHandle="maskHandle($event)"
        />
    </div>
</template>

<script>
    import PageHandle from '@/components/PageHandle.vue'
    import TipHandle from '@/components/TipHandle.vue'
    import { toQueryString } from '@/assets/js/util'

    export default {
        name: 'VipApplyLists',
        components: {
            PageHandle,
            TipHandle
        },
        props: {
        },
        data() {
            return {
                token: this.$cookies.get('token'),
                page: 1,
                count: 20,
                totalPage: 1,
                totalCount: 1,
                tip: '',
                btnTxt: [],
                dataAll:[],
                getListFlag: true,
                company_name: '',
                member_no: '',
                user_name: '',
                car_no: '',
                tel_no: '',
                company_no: '',
                listLoadTip: ''
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            goUrl () {
              this.$router.push('OrMemberQuery')
            },
            handleTips(txt, btnTxt) {
                this.tip = txt
                this.btnTxt = btnTxt
            },
            maskHandle(btn) {
                if(btn == 0) {
                    // 确认发布
                }
                this.tip = ''
            },
            pageTo(page) {
                if(!this.getListFlag) {
                    return
                }
                if(page == '-' && this.page > 1) {
                    this.page --
                    this.getList()
                } else if(page == '+' && this.page < this.totalPage){
                    this.page ++
                    this.getList()
                } else if(page != this.page && typeof page == 'number') {
                    if(page > this.totalPage || page < 1) {
                        this.handleTips('请输入1~' + this.totalPage + '的数', ['关闭'])
                        return
                    }
                    this.page = page
                    this.getList()
                }

            },
            srchHandle() {
                if(!this.getListFlag) {
                    return
                }
                this.page = 1
                this.getList()
            },
            // 运动员等级评定列表
            getList(){
                if(this.getListFlag) {
                    this.getListFlag = false
                } else {
                    return
                }
                this.dataAll = []
                this.listLoadTip = '数据加载中...'
                // setTimeout(() =>{
                //     this.dataAll = [
                //         {rownum:1,apply_id: 13,apply_time:'2021-02-01 10:02:56',apply_name: '机构1',apply_result: 0},
                //         {rownum:2,apply_id: 2,apply_time:'2021-02-01 10:02:56',apply_name: '机构1222',apply_result: 1},
                //         {rownum:3,apply_id: 3,apply_time:'2021-02-01 10:02:56',apply_name: '机构13333',apply_result: 2}
                //     ]
                // }, 2000)
                this.$axios.post('API/Entrance',toQueryString({
                    action: 'GetVipList',
                    user_token: this.token,
                    member_no:this.member_no,
                    name: this.user_name,
                    identity_no: this.car_no,
                    phone: this.tel_no,
                    company_name: this.company_name,
                    company_member_no: this.company_no,
                    page: this.page,
                    count: this.count
                })).then((res) => {
                    this.getListFlag = true
                    let data = res.data
                    if(data.code == -200) {
                        this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                        return
                    }
                    if(data.code == 1) {
                        if(data.dataList.length == 0){
                            this.listLoadTip = '无数据'
                        }
                        this.dataAll = data.dataList
                        this.totalCount = data.totalCount
                        this.totalPage = Math.ceil(data.totalCount/this.count)
                    } else {
                        this.listLoadTip = data.message
                    }
                }).catch(() => {
                    this.listLoadTip = '网络异常';
                    this.getListFlag = true
                })
            },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .viewBtn{
        height: 2rem;
        border-bottom: 1px solid #000;
    }
    .viewBtn span{
        display: inline-block;
        height: 100%;
        width: 100px;
        text-align: center;
        cursor: pointer;
    }
    .viewBtn span.on{
        color: #960202;
        border-bottom: 3px solid #960202;
    }
    .srchBox{
        height: 3rem;
        line-height: 3rem;
    }
    .srchBox input, .srchBox select{
        height: 2rem;
    }
    .tab{
        width: 100%;
        height: calc(100% - 8rem);
    }
</style>
