<template>
    <div class="box_status clearfix">
        <div class="desc fl" v-text="tit"></div>
        <div class="btn fl" 
            v-for="(item, i) in json_status" 
            :key="i" v-text="item" 
            @click="statusHandle(i)" 
            :class="currStatus == i ? 'active': ''"
            >
        </div>
    </div>
</template>

<script>
export default {
    name: 'StatusBar',
    components: {
    },
    props:['tit', 'currStatus', 'json_status'],
    created() {},
    mounted() {},
    updated() {},
    data(){
        return {}
    },
    methods: {
        statusHandle(key){
            this.$emit('statusHandle', key)
        },
    }
}
</script>
<style scoped>
</style>