const utils = {
    dateFormat: function (value,fmt){
        if(!value) return "";
        var date = new Date(value);
        if(date == 'Invalid Date') return "Invalid Date";
        var o = {
            "M+": date.getMonth() + 1,
            "d+": date.getDate(),
            "H+": date.getHours(),
            "m+": date.getMinutes(),
            "s+": date.getSeconds(),
            "q+": Math.floor((date.getMonth() + 3) / 3),
            "S": date.getMilliseconds()
        };
        fmt = fmt || "yyyy-MM-dd";
        if (/(y+)/.test(fmt))
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
            for (var k in o)
            if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmt;
    },
    abnormalDate:{
        get: function(value, fmt = 'yyyy年MM月dd日'){
            if(!['yyyy年MM月dd日', 'yyyy-MM-dd'].includes(fmt))return;
            let dateText = '';
            if(typeof value == 'string'){
                if(value.length < 4){
                    dateText = "";
                }else{
                    let arr = value.match(/[0-9]+/g);
                    dateText = utils.dateFormat(arr.join('-'), fmt);
                }
            }else if(typeof value == 'number'){
                let dateStr = value.toString();
                let year = dateStr.substr(0,4),
                    month = dateStr.substr(4,2),
                    day = dateStr.substr(6,2);
                let dates = [];
                if(+year){ dates.push(year); }
                if(+month){ dates.push(month); }
                if(+day){ dates.push(day); }
                if(fmt == 'yyyy年MM月dd日'){
                    let b = '年月日';
                    dateText = dates.map((item,index)=>item + b[index]).join('');
                }else{
                    dateText = dates.join('-');
                }
            }
            return dateText;
        },
        set: function(value, isNumber = true){
            let date = 0;
            let dateStr = utils.dateFormat(value);
            if(!isNumber && dateStr != 'Invalid Date' && /[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}/.test(value)){
                date = dateStr;
            }else if(value){
                let arr = value.match(/[0-9]+/g);
                let year = arr[0] || '0000',
                    month = arr[1] || '00',
                    day = arr[2] || '00';
                year = ('0000' + year).substr(year.length);
                month = ('00' + month).substr(month.length);
                day = ('00' + day).substr(day.length);
                let num = +(year + month + day);
                date = isNaN(num) ? 0 : num;
            }
            return date;
        }
    },
    formatFileSize: function(size, decimals = 2){
        if (size < 1024) {
            return size + 'B';
        } else if (size < (1024*1024)) {
            let temp = size / 1024;
            temp = decimals == 0 ? parseInt(temp) : temp.toFixed(decimals);
            return temp + 'KB';
        } else if (size < (1024*1024*1024)) {
            let temp = parseInt(size / (1024*1024));
            temp = decimals == 0 ? parseInt(temp) : temp.toFixed(decimals);
            return temp + 'MB';
        } else {
            let temp = parseInt(size / (1024*1024*1024));
            temp = decimals == 0 ? parseInt(temp) : temp.toFixed(decimals);
            return temp + 'GB';
        }
    },
    formatAmount: function(amount){
        if(!amount)return '0';
        return amount.toString().replace(/(\d)(?=(?:\d{3})+$)g/, '$1,');
    },
    toNumber: function(value, defaultValue = 0){
        value = +value;
        return isNaN(value) ? defaultValue : value;
    }
}
export default utils;