<template>
    <div class="view">
        <div class="srchBox clearfix">
            <div class="fl">
                <label>运动项目：</label>
                <select v-model="eventsId" style="width:120px">
                    <option :value="0">全部</option>
                    <option 
                        v-for="(item, i) in eventsList" 
                        :key="i"
                        :value="item.Id"
                        v-text="item.Name"
                        ></option>
                </select>
            </div>
            <div class="fl ml_10">
                <label>大师分类型：</label>
                <select v-model="mastersScoreType" style="width:80px">
                    <option :value="0">全部</option>
                    <option :value="1">金分</option>
                    <option :value="2">银分</option>
                    <option :value="3">红分</option>
                </select>
            </div>
            <div class="fl ml_10">
                <label>大师分年度：</label>
                <input type="text" v-model.number="mastersScoreYear" style="width:120px">
            </div>
            <br>
            <div class="fl">
                <label>获得日期：</label>
                <el-date-picker
                    v-model="date"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                    size="mini"
                >
                </el-date-picker>
            </div>
            <div class="fl ml_10">
                <label>姓名或会员号：</label>
                <input type="text" v-model="nameOrMemberNo">
            </div>
            <div class="fl ml_10">
                <button class="btn" @click="search">搜索</button>
            </div>
        </div>
        <div class="tab">
            <div class="tab_th clearfix">
                <div class="td_1">序号</div>
                <div class="td_3">获取时间</div>
                <div class="td_2">运动项目</div>
                <div class="td_3">赛事名称</div>
                <div class="td_2">大师分年度</div>
                <div class="td_2">爱好者姓名</div>
                <div class="td_2">会员号</div>
                <div class="td_2">获得金分数量</div>
                <div class="td_2">获得银分数量</div>
                <div class="td_2">获得红分数量</div>
            </div>
            <div class="box_tr" v-if="list.length == 0">
                <p class="noData" v-text="listLoadTip"></p>
            </div>
            <div class="box_tr" v-else>
                <div  class="tab_tr clearfix" v-for="(item, i) in list" :key="i">
                    <div class="td_1"><span>{{(page - 1) * count + i + 1}}</span></div>
                    <div class="td_3"><span>{{item.GainDate && item.GainDate.replace('T',' ').replace('Z','')}}</span></div>
                    <div class="td_2"><span>{{item.EventsName}}</span></div>
                    <div class="td_3"><span>{{item.LeagueName}}</span></div>
                    <div class="td_2"><span>{{item.LeagueYear}}</span></div>
                    <div class="td_2"><span>{{item.MemberName}}</span></div>
                    <div class="td_2"><span>{{item.MemberNo}}</span></div>
                    <div class="td_2"><span>{{item.ScoreGold > 0 ? item.ScoreGold : ''}}</span></div>
                    <div class="td_2"><span>{{item.ScoreSilver > 0 ? item.ScoreSilver : ''}}</span></div>
                    <div class="td_2"><span>{{item.ScoreRed > 0 ? item.ScoreRed : ''}}</span></div>
                </div>
            </div>
        </div>
        <PageHandle 
            :page="page"
            :totalPage="totalPage"
            :totalCount="totalCount"
            @pageTo="pageTo($event)" 
            />
        <TipHandle 
            :txt="tip"
            :btnTxt="btnTxt"
            @maskHandle="maskHandle($event)" 
            />
    </div>
</template>

<script>
import PageHandle from '@/components/PageHandle.vue'
import TipHandle from '@/components/TipHandle.vue'
import {
    GetMastersScoreRecordPageList
} from "@/newApi"

export default {
    name: 'Rating',
    components: {
        PageHandle,
        TipHandle
    },
    props: {
    },
    data() {
        return {
            page: 1,
            count: 20,
            totalPage: 0,
            totalCount: 0,
            tip: '',
            btnTxt: [],
            list:[],
            eventsId:0,
            eventsList:[{
                Id: 13,
                Name: '掼蛋'
            }],
            mastersScoreType:0,
            mastersScoreYear: null,
            date:[],
            nameOrMemberNo: "",
            listLoadTip: '',
            getListFlag: true,
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        handleTips(txt, btnTxt){
            this.tip = txt
            this.btnTxt = btnTxt
        },
        maskHandle(){
            this.tip = ''
        },
        search(){
            this.totalPage = 0;
            this.totalCount = 0;
            this.page = 1;
            this.getList();
        },
        pageTo(page){
            if(!this.getListFlag) {
                return
            }
            if(page == '-' && this.page > 1) {
                this.page --
                this.getList()
            } else if(page == '+' && this.page < this.totalPage){
                this.page ++
                this.getList()
            } else if(page != this.page && typeof page == 'number') {
                if(page > this.totalPage || page < 1) {
                    this.handleTips('请输入1~' + this.totalPage + '的数', ['关闭'])
                    return
                }
                this.page = page
                this.getList()
            }
            
        },
        // 列表
        getList(){
            if(this.getListFlag) {
                this.getListFlag = false
            } else {
                return
            }
            this.list = [];
            this.listLoadTip = '数据加载中...'
            GetMastersScoreRecordPageList({
                "EventsId": this.eventsId,
                "MastersScoreType": this.mastersScoreType,
                "MasterYear": this.mastersScoreYear || 0,
                "BeginTime": this.date[0],
                "EndTime": this.date[1],
                "NameOrMemberNo": this.nameOrMemberNo,
                "PageSize": this.count,
                "PageNum": this.page
            }).then(data=>{
                this.getListFlag = true;
                if(data.Code == 0){
                    if(data.ContinueList && data.ContinueList.length > 0){
                        this.totalPage = data.PageCount;
                        this.totalCount = data.TotalCount;
                        this.page = data.PageNum;
                        this.list = data.ContinueList;
                        this.listLoadTip = '';
                    }else{
                        this.totalPage = 0;
                        this.totalCount = 0;
                        this.page = 1;
                        this.list = [];
                        this.listLoadTip = '无数据';
                    }
                }else{
                    this.listLoadTip = data.Message;
                }
            }).catch(()=>{
                this.getListFlag = true;
                this.listLoadTip = '数据获取失败';
            })
        },
    }
}
</script>

<style scoped>
@import '~@/assets/css/common_z.css';
    .srchBox{
        height: 6rem;
        line-height: 3rem;
    }
    .srchBox input, .srchBox select{
        height: 2rem;
    }
    .state,
    .projectType{
        white-space: nowrap;
    }
    .state button,
    .projectType button{
        height: 2rem;
        padding: 0 1rem;
        margin-right: 1rem;
        border: none;
        cursor: pointer;
        background: #DFDFDF;
    }
    .state button.active,
    .projectType button.active{
        background: #C91E25;
        color: #ffffff;
    }
    .tab{
        width: 100%;
        height: calc(100% - 10rem);
        overflow-x: auto;
    }
    .tab .tab_th{
        min-width: 75rem;
    }
    .box_tr{
        height: calc(100% - 3rem);
        width: 100%;
        min-width: 75rem;
        overflow-x: hidden;
    }
    .tab .tab_tr{
        width: 100%;
    }
</style>
