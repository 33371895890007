<template>
    <div class="view">
        <div>
            <router-link :to="`/infos/createappslideshow/${appCode}`" class="btn" style="float: right;">+添加轮播图</router-link>  
            <StatusBar
                :tit="'状态'"
                :currStatus="currType"
                :json_status="typeStatus"
                @statusHandle="typeHandle($event)"
                />
        </div>
        <div class="tab">
            <div class="tab_th clearfix">
                <div class="fl td_3">名称</div>
                <div class="fl td_2" v-if="appCode == 89">竖版图片</div>
                <div class="fl td_3">横版图片</div>
                <div class="fl td_4">跳转URL</div>
                <div class="fl td_2">播放序号</div>
                <div class="fl td_2">当前状态</div>
                <div class="fl td_4">操作</div>
            </div>
            <div class="box_tr" v-if="dataAll==null">
                <p class="noData" v-text="listLoadTip"></p>
            </div>
            <div class="box_tr" v-else>
                <div class="tab_tr clearfix" v-for="(item, i) in dataAll" :key="i" style="height:110px;">
                    <div class="fl td_3">
                        <span v-text="item.Name"></span>
                    </div>
                    <div class="fl td_2" v-if="appCode == 89">
                        <img class="img-change" :src="item.PicUrl">
                    </div>
                    <div class="fl td_3">
                        <img class="img-change" :src="item.MiniappPicUrl">
                    </div>
                    <div class="fl td_4" style="word-break: break-word;">
                        <span v-if="item.JumpUrl">{{item.JumpUrl}}{{['','（需要个人用户登录）','（需要机构用户登录）'][item.NeedLogin]}}</span>
                        <span v-else>无</span>
                    </div>
                    <div class="fl td_2">
                        <span v-text="item.OrderNo"></span>
                    </div>
                    <div class="fl td_2">
                        <span>{{typeStatus.key}}</span>
                        <span v-if="Date.parse(item.PublishBegintime) > time">
                            将在{{item.PublishBegintime| dateFormat('yyyy-MM-dd HH:mm')}}上架
                        </span>
                        <span v-else-if="Date.parse(item.PublishEndtime)<time">已下架</span>
                        <span v-else-if="Date.parse(item.PublishBegintime)<=time<=Date.parse(item.PublishEndtime)">
                            将在{{item.PublishEndtime| dateFormat('yyyy-MM-dd HH:mm')}}下架
                        </span>
                        <span v-else>判断失败</span>
                    </div>
                    <div class="fl td_4">
                        <!-- <span>{{Date.parse(item.PublishBegintime)+" "+Date.parse(time)+"  "+Date.parse(item.PublishEndtime)}}</span> -->
                        <span class="sp" v-if="Date.parse(item.PublishBegintime) > time">
                            <router-link :to="{path:`/infos/createappslideshow/${appCode}?id=${item.Id}`}">编辑</router-link>
                            <a href="javascript:;" @click="shan(item.Id,item.Name)" >删除</a>
                            <a href="javascript:;" @click="sort(dataAll[i])" >修改播放序号</a>
                            <a href="javascript:;" @click="send(item.Id,item.Name)" >强制下架</a>
                        </span>
                        <span class="sp" v-else-if="Date.parse(item.PublishEndtime)<time">
                            <router-link :to="{path:`/infos/createappslideshow/${appCode}?id=${item.Id}`}">编辑</router-link>
                            <a href="javascript:;" @click="shan(item.Id,item.Name)" >删除</a>
                        </span>
                        <span class="sp" v-else>
                            <a href="javascript:;" @click="sort(dataAll[i])" >修改播放序号</a>
                            <a href="javascript:;" @click="send(item.Id,item.Name)" >强制下架</a>
                            <a href="javascript:;" @click="shan(item.Id,item.Name)" >删除</a>
                        </span>
                    </div>
                </div>
            </div>
            <PageHandle 
                :page="page"
                :totalPage="totalPage"
                :totalCount="totalCount"
                @pageTo="pageTo($event)" 
                />
        </div>
        <div class="model" v-show="modelData.maskTag > 0">
            <div class="model_content">
                <div class="mask1" v-show="modelData.maskTag == 1">
                    <div>
                        <label>播放序号：</label>
                        <input v-model="number1" type="number" placeholder="请输入播放序号"><div>请输入任意数值，越小越靠前。</div>
                    </div>
                    <div class="maskBtnBox">
                        <button @click="submit(1)">提交</button>
                        <button @click="setMask()">取消</button>
                    </div>
                </div>
                <div v-show="modelData.maskTag == 2">
                    <div>{{modelData.maskHint}}<br>确定删除该轮播图？</div>
                    <div class="maskBtnBox">
                        <button @click="submit(2)">提交</button>
                        <button @click="setMask()">取消</button>
                    </div>
                </div>
                <div v-show="modelData.maskTag == 3">
                    <div>{{modelData.maskHint}}<br>确定强制下架该轮播图？</div>
                    <div class="maskBtnBox">
                        <button @click="submit(3)">提交</button>
                        <button @click="setMask()">取消</button>
                    </div>
                </div>
            </div>
        </div>
        <TipHandle 
            :txt="tip"
            :btnTxt="btnTxt"
            @maskHandle="maskHandle($event)" 
            />
    </div>
</template>

<script>
import StatusBar from '@/components/StatusBar.vue'
import PageHandle from '@/components/PageHandle.vue'
import TipHandle from '@/components/TipHandle.vue'
import {
    QueryAppCarouselPic,
    DelAppCarouselPic,
    DownAppCarouselPic,
    UpdateAppCarouselPic
}from '@/newApi'
//import { toQueryString, splitStr } from '@/assets/js/util'

export default {
    name: 'APPSlideShow',
    props: {
    },
    components: {
        StatusBar,
        PageHandle,
        TipHandle
    },
    data() {
        return {
            appCode: +this.$route.params.appCode,
            page: 1,
            count: 20,
            totalPage: 1,
            totalCount: 1,
            getListFlag: true,
            tip: '',
            btnTxt: [],
            currType: 0,
            typeStatus: {
                0: '上架中',
                4: '待上架',
                5: '已下架'
            },
            dataAll:[],
            listLoadTip:'',
            time:'',
            modelData:{
                maskTag:-1,
                id:0,
                maskHint:"",
                dataAl:[]
            },
            number1:"",
        }
    },
    mounted(){
        this.getList()
        this.time = new Date().getTime();
    },
    watch: {
        '$route.params.appCode'(appCode){
            this.appCode = +appCode;
            this.getList()
            this.time = new Date().getTime();
        }
    },
    filters:{
            dateFormat:function(value,fmt){
            if(!value) return "";
            var date = new Date(value);
            var o = {
                "M+": date.getMonth() + 1,
                "d+": date.getDate(),
                "H+": date.getHours(),
                "m+": date.getMinutes(),
                "s+": date.getSeconds(),
                "q+": Math.floor((date.getMonth() + 3) / 3),
                "S": date.getMilliseconds()
            };
            fmt = fmt || "yyyy-MM-dd";
            if (/(y+)/.test(fmt))
                fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
             for (var k in o)
                if (new RegExp("(" + k + ")").test(fmt))
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
            return fmt;
        }
    },
    methods: {
        handleTips(txt, btnTxt){
            this.tip = txt
            this.btnTxt = btnTxt
        },
        maskHandle(btn){
            if(btn == 0){
                this.getList()
                this.setMask()
            }
            this.tip = ''
        },
        typeHandle(type) {
            if(!this.getListFlag) {
                return
            }
            this.page = 1
            this.currType = type
            this.getList()
        },
        pageTo(page) {
            if(!this.getListFlag) {
                return
            }
            if(page == '-' && this.page > 1) {
                this.page --
                this.getList()
            } else if(page == '+' && this.page < this.totalPage){
                this.page ++
                this.getList()
            } else if(page != this.page && typeof page == 'number') {
                if(page > this.totalPage || page < 1) {
                    this.handleTips('请输入1~' + this.totalPage + '的数', ['关闭'])
                    return
                }
                this.page = page
                this.getList()
            }
        },
        getList(){
            let type=0;
            if(this.currType==0){
                type=1
            }else if(this.currType==4){
                type=0
            }else{
                type=2
            }
            QueryAppCarouselPic({
                "AppCode": this.appCode,
                "PublishState": type,
                "Name": ""
            }).then(data=>{
                if(data.Code == 0){
                    if(data.AppCarouselPicInfoList==null){
                        this.listLoadTip = '无数据'
                    }
                    this.dataAll=data.AppCarouselPicInfoList
                } else {
                    this.listLoadTip = data.Message
                }
            }).catch(() => {
                this.listLoadTip = '网络异常'
                this.getListFlag = true
            })
        },
        setMask() {
            this.modelData.maskTag=-1
        },
        sort(dataAl){//修改循序
            this.modelData.maskTag=1
            this.modelData.maskHint=dataAl.Name
            this.modelData.id=dataAl.Id
            this.modelData.dataAl=dataAl
            this.number1=dataAl.OrderNo
        },
        send(id,name){//强制下架
            this.modelData.maskTag=3
            this.modelData.maskHint=name
            this.modelData.id=id
        },
        shan(id,name){//删除
            this.modelData.maskTag=2
            this.modelData.maskHint=name
            this.modelData.id=id
        },
        submit(type){
            if(type==1){
                UpdateAppCarouselPic({
                    "Id": this.modelData.id,
                    "Name": this.modelData.dataAl.Name,
                    "PicUrl": this.modelData.dataAl.PicUrl,
                    "MiniappPicUrl": this.modelData.dataAl.MiniappPicUrl,
                    "JumpUrl": this.modelData.dataAl.JumpUrl,
                    "NeedLogin": this.modelData.dataAl.NeedLogin,
                    "PublishBegintime": this.modelData.dataAl.PublishBegintime,
                    "PublishEndtime": this.modelData.dataAl.PublishEndtime,
                    "OrderNo": this.number1
                }).then(data=>{
                    if(data.Code == 0){
                        this.handleTips('修改成功',['确定'])
                    }else{
                        this.handleTips(data.Message, ['关闭'])
                    }
                }).catch(()=>{
                    this.handleTips('提交失败', ['关闭'])
                })
            }else if(type==2){//删除
                DelAppCarouselPic({
                    "Id":this.modelData.id
                }).then(data=>{
                    if(data.Code == 0){
                        this.handleTips('删除成功',['确定'])
                    }else{
                        this.handleTips(data.Message, ['关闭'])
                    }
                }).catch(()=>{
                    this.handleTips('提交失败', ['关闭'])
                })
            }else{
                DownAppCarouselPic({//强制下架
                "Id":this.modelData.id
                }).then(data=>{
                    if(data.Code == 0){
                        this.handleTips('下架成功',['确定'])
                    }else{
                        this.handleTips(data.Message, ['关闭'])
                    }
                }).catch(()=>{
                    this.handleTips('提交失败', ['关闭'])
                })
            }
        }
    }
}
</script>

<style scoped>
    .img-change{
        height:100px;
        width:auto;
        margin-top: 5px;
    }
    .tab{
        width: 100%;
        height: calc(100% - 3rem);
    }
    .tab .td_3{min-width: 16rem;};
    a .btn{
        /* float: right; */
        display: inline-block;
        background: #F5A100;
        color: #fff;
        padding: 0 .5rem;
        text-align: center;
        height: 2rem;
        line-height: 2rem;
        cursor: pointer;
        margin-bottom: .4rem;
    }
    .sp span{
        padding-left:10px;
    }
    .model{
        width:100%;
        height:100%;
        position:fixed;
        top:0;
        left:0;
        background:rgba(0,0,0,0.6);
        display:flex;
        align-items:center;
        justify-content:center;
    }
    .model_content{
        width:300px;
        height:auto;
        background:#fff;
    }
    .model_content>div{
        margin: 10px 30px;
    }
    .model_content div{
        padding-top: 10px;
    }
    .maskBtnBox{
        text-align: right;
        margin: 1rem 0 0;
    }
    .maskBtnBox button{
        margin: 10px;
    }
    /* .tab_tr div span{
        word-wrap:break-word;  
        word-break:break-all;  
        overflow: hidden; 
    } */
</style>
