<template>
    <!-- 运动员等级评定 -->
    <div class="view">
        <div class="srchBox clearfix">
            <div class="fl ml_10">
                <label for="">项目：</label>
                <select v-model="event_code">
                    <option value="0">请选择项目</option>
                    <option 
                        v-for="(item, i) in eventList" 
                        :key="i"
                        :value="item.Key"
                        v-text="item.Value"
                        ></option>
                </select>
            </div>
            <!-- <div class="fl ml_10">
                <label for="">组别：</label>
                <select v-model="group_code">
                    <option value="0">请选择组别</option>
                    <option 
                        v-for="(item, i) in groupList" 
                        :key="i"
                        :value="item.group_code"
                        v-text="item.group_name"
                        ></option>
                </select>
            </div> -->
            <div class="fl ml_10">
                <label for="">赛事名称：</label>
                <input type="text" name="" id="" v-model="league_name">
            </div>
            <div class="fl ml_10">
                <label for="">状态：</label>
                <select v-model="apply_result">
                    <option value="-1">全部</option>
                    <option 
                        v-for="(item, i) in statusJson" 
                        :key="i"
                        :value="i"
                        v-text="item"
                        ></option>
                </select>
            </div>
            <div class="fl ml_10">
                <button class="btn" @click="srchHandle">查询</button>
            </div>
        </div>
        <div class="tab">
            <div class="tab_th clearfix">
                <div class="fl td_0"><span>序号</span></div>
                <div class="fl td_1"><span>申请时间</span></div>
                <div class="fl td_0"><span>项目</span></div>
                <div class="fl td_1"><span>组别</span></div>
                <div class="fl td_2"><span>赛事名称</span></div>
                <div class="fl td_0"><span>状态</span></div>
                <div class="fl td_1"><span>操作</span></div>
            </div>
            <div class="box_tr" v-if="dataAll.length == 0">
                <p class="noData" v-text="listLoadTip"></p>
            </div>
            <div class="box_tr" v-else>
                <div class="tab_tr clearfix" v-for="(item, i) in dataAll" :key="i">
                    <div class="fl td_0">
                        <span v-text="item.rownum"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="item.apply_time"></span>
                    </div>
                    <div class="fl td_0">
                        <span v-text="item.event_name|| '-'"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="item.group_name"></span>
                    </div>
                    <div class="fl td_2">
                        <span v-text="item.league_name"></span>
                    </div>
                    <div class="fl td_0">
                        <span v-text="item.apply_result_Name"></span>
                    </div>
                    <div class="fl td_1">
                        <span>
                            <router-link
                                :to="'/grade/athleteRating/verify/' + item.apply_id"
                                v-text="item.apply_result == 0? '去审核': '查看详情'"
                                ></router-link>
                        </span>
                    </div>
                </div>
            </div>
            <PageHandle 
                :page="page"
                :totalPage="totalPage"
                :totalCount="totalCount"
                @pageTo="pageTo($event)" 
                />
        </div>
        <TipHandle 
            :txt="tip"
            :btnTxt="btnTxt"
            @maskHandle="maskHandle($event)" 
            />
    </div>
</template>

<script>
import PageHandle from '@/components/PageHandle.vue'
import TipHandle from '@/components/TipHandle.vue'
import { toQueryString } from '@/assets/js/util'

export default {
    name: 'AthleteRating',
    components: {
        TipHandle,
        PageHandle
    },
    props: {},
    data() {
        return {
            token: this.$cookies.get('token'),
            league_name:'',
            tip: '',
            btnTxt: [],
            page: 1,
            count: 20,
            totalPage: 1,
            totalCount: 1,
            dataAll:[],
            getListFlag: true,
            event_code: 0,
            eventList: [],
            group_code: 0,
            groupList: [
                // {group_code: 1, point_group_name: "111"},
                // {group_code: 2, point_group_name: "222"},
            ],
            apply_result: -1,
            statusJson: {
                0: "待审核",
                1: "通过",
                2: "未通过"
            },
            listLoadTip: ''
        }
    },
    mounted() {
        this.getList()
        this.getItemGroup()
    },
    methods: {
        handleTips(txt, btnTxt) {
            this.tip = txt
            this.btnTxt = btnTxt
        },
        maskHandle(btn) {
            if(btn == 0) {
                // 确认发布
            }
            this.tip = ''
        },
        pageTo(page) {
            if(!this.getListFlag) {
                return
            }
            if(page == '-' && this.page > 1) {
                this.page --
                this.getList()
            } else if(page == '+' && this.page < this.totalPage){
                this.page ++
                this.getList()
            } else if(page != this.page && typeof page == 'number') {
                if(page > this.totalPage || page < 1) {
                    this.handleTips('请输入1~' + this.totalPage + '的数', ['关闭'])
                    return
                }
                this.page = page
                this.getList()
            }
            
        },
        srchHandle() {
            if(!this.getListFlag) {
                return
            }
            this.page = 1
            this.getList()
        },
        // 运动员等级评定列表
        getList(){
            if(this.getListFlag) {
                this.getListFlag = false
            } else {
                return
            }
            this.dataAll = []
            this.listLoadTip = '数据加载中...'
            this.$axios.post('CESA/Entrance',toQueryString({
                action: 'Get_level_apply_record',
                user_token: this.token,
                event_code: this.event_code==0?'':this.event_code,
                // group_code: this.group_code==0?'':this.group_code,
                group_code: '',
                league_name: this.league_name,
                apply_result: this.apply_result == -1? '' : this.apply_result,
                page: this.page,
                count: this.count
            })).then((res) => {
                this.getListFlag = true
                let data = res.data
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                if(data.code == 1) {
                    if(data.newList.length == 0){
                        this.listLoadTip = '无数据'
                    }
                    this.dataAll = data.newList
                    this.totalCount = data.totalCount
                    this.totalPage = Math.ceil(data.totalCount/this.count)
                } else {
                    this.listLoadTip = data.message
                }
            }).catch(() => {
                this.listLoadTip = '网络异常'
                this.getListFlag = true
            })
        },
        // 获取项目及组别
        getItemGroup(){
            this.$axios.post('CESA/Entrance',toQueryString({
                action: 'Get_dicts_KeyValue',
                dict_key: 'events_id',
                // event_code: this.event_code==0?'':this.event_code,
                user_token:this.token
            })).then((res) => {
                let data = res.data;
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                if(data.code == 1) {
                    this.eventList = data.list
                    // this.group_code = 0
                    // if(this.event_code == 0) {
                    //     this.eventList = data.eventList
                    //     this.groupList = []
                    // } else {
                    //     this.groupList = data.groupList
                    // }
                } else {
                    this.handleTips(data.message, ['关闭'])
                }
            }).catch(() => {
                this.handleTips('网络异常', ['关闭'])
            })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .srchBox{
        height: 3rem;
        line-height: 3rem;
    }
    .srchBox input, .srchBox select{
        height: 2rem;
    }
    .tab{
        width: 100%;
        height: calc(100% - 3rem);
    }
    .tab .tab_th div{
        display: table;
        height: 2.5rem;
    }
    .tab .tab_th span{
        line-height: 1rem;
        display: table-cell;
        vertical-align: middle;
    }
    .tab .td_2{width: 43%; min-width: 27rem;}
</style>
