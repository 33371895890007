<template>
    <div class="container">
        <el-form :model="form" label-width="80px" size="mini" class="form">
            <el-row type="flex" :gutter="10">
                <el-col :span="6" :lg="5">
                    <el-form-item label="会员号：">
                        <el-input type="text" v-model="form.memberNo"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6" :lg="5">
                    <el-form-item label="手机号：">
                        <el-input type="text" v-model="form.phone"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6" :lg="5">
                    <el-form-item label="姓名：">
                        <el-input type="text" v-model="form.name"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6" :lg="9">
                    <el-row type="flex" justify="space-between">
                        <el-button size="mini" @click="search">搜索</el-button>
                        <el-button type="warning" size="mini" v-href="'/account/CreateUser'">+添加个人用户</el-button>
                    </el-row>
                </el-col>
            </el-row>
        </el-form>
        <el-row style="height:calc(100% - 110px)">
            <el-table
                stripe
                style="width: 100%"
                height="100%"
                v-loading="loading"
                element-loading-text="数据加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :data="list"
                size="mini"
            >
                <el-table-column label="头像" align="center" width="70px">
                    <template slot-scope="scope">
                        <img :src="scope.row.AvatarUrl || defaultImg"/>
                    </template>
                </el-table-column>
                <el-table-column prop="Nickname" label="昵称" align="center">
                </el-table-column>
                <el-table-column prop="Name" label="姓名" align="center" width="60px">
                </el-table-column>
                <el-table-column prop="UserId" label="用户ID" align="center" width="100px">
                </el-table-column>
                <el-table-column prop="MemberNo" label="会员号" align="center" width="100px">
                </el-table-column>
                <el-table-column prop="Phone" label="手机号" align="center" width="120px">
                </el-table-column>
                <el-table-column label="证件号码（证件类型）" align="center">
                    <template slot-scope="scope" v-if="scope.row.IdentityNo">
                        <span v-if="scope.row.IdentityType == 1">{{scope.row.IdentityNo}}</span>
                        <span v-else>{{scope.row.IdentityNo}}（{{getInfo(scope.row.IdentityType, 'name')}}）</span>
                    </template>
                </el-table-column>
                <el-table-column label="性别" align="center" width="50px">
                    <template slot-scope="scope">
                        {{['','男','女'][scope.row.Gender]}}
                    </template>
                </el-table-column>
                <el-table-column label="出生日期" align="center">
                    <template slot-scope="scope">
                        {{abnormalDate.get(scope.row.Birthday2)}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="100px">
                    <template slot-scope="scope">
                        <el-button type="text"  v-href="`/account/UserDetail/${scope.row.UserId}`">查看/修改</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>
         <pagination
            @page-change="pageChange"
            :current-page.sync="pageInfo.page"
            :page-size="pageInfo.pageSize"
            :total="pageInfo.total">
        </pagination>
    </div>
</template>
<script>
import identityType from '@/utils/identityType.js';
import utils from '@/utils';
import pagination from '@/components/pagination.vue';

export default {
    name: 'UserList',
    components:{
        pagination
    },
    data() {
        return {
            defaultImg: require('@/assets/imgs/个人@3x.png'),
            form: {
                memberNo: "",
                phone: "",
                name: ""
            },
            list: [],
            loading: false,
            pageInfo:{
                page: 1,
                pageSize: 10,
                total: 0
            },
            abnormalDate: utils.abnormalDate
        };
    },

    created() {
       this.getDataList(); 
       this.$router.replace();
    },

    methods: {
        getInfo: identityType.getInfo,
        getDataList(){
            let pageInfo = this.pageInfo,
                form = this.form;
            console.log(pageInfo);
            this.loading = true;
            this.$api.GetUserAccountPageList({
                "PageParm": {
                    "PageSize": pageInfo.pageSize,
                    "PageNum": pageInfo.page,
                },
                "Phone": form.phone,
                "Name": form.name,
                "MemberNo": form.memberNo
            }).then(data=>{
                this.loading = false;
                if(data.Code == 0){
                    pageInfo.page = data.PageParm.PageNum;
                    pageInfo.total = data.PageParm.TotalCount;
                    this.list = data.UserList;
                }
            })
        },
        search(){
            this.pageInfo.page = 1;
            this.getDataList()
        },
        pageChange(){
            this.getDataList();
        }
    },
};
</script>
<style scoped>
@import "~@/assets/css/clear-el.css";
.el-table img{
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 10px;
}
</style>