<template>
    <div class="view">
        <div class="viewBtn"><span class="on">赛事账号</span></div>
        <div class="srchBox clearfix">
            <div class="fl ml_10">
                <label for="">用户名：</label>
                <input type="text" name="" v-model="user_name" maxlength="20" :disabled="$route.params.id >= 0">
            </div>
            <div class="fl ml_10">
                <label for="">登录密码：</label>
                <input type="text" name="" v-model="password" maxlength="100" :disabled="$route.params.id == 0">
                <span style="margin-left: 0.5rem;text-decoration: underline;color: #960202;cursor: pointer" v-if="$route.params.id != 0" @click="getPassword">重置密码</span>
            </div>
            <div class="fl ml_10" v-if="$route.params.id == 0">
                <label for="">状态：</label>
                <span>{{confirm_status == 1 ? '启用' : '停用'}}</span>
            </div>
            <div class="fl ml_10" v-if="$route.params.id > 0">
                <label for="">状态：</label>
                <select v-model="confirm_status">
                    <option
                            v-for="(item, i) in statusJson"
                            :key="i"
                            :value="i"
                            v-text="item"
                    ></option>
                </select>
            </div>
        </div>
        <div class="accountH"><span>关联赛事</span></div>
        <div class="srchBox clearfix" v-if="$route.params.id != 0"><button @click="selectLeagueFlg = true" class="addAccountBtn" style="height: 2.5rem;margin-top: .5rem">选择赛事</button></div>
<!--        <div class="srchBox clearfix" v-if="$route.params.id != 0">-->
<!--            <div class="fl ml_10">-->
<!--                <select style="vertical-align: middle" v-model="events_status">-->
<!--                    <option value="0">全部</option>-->
<!--                    <option-->
<!--                            v-for="(item, i) in eventsJson"-->
<!--                            :key="i"-->
<!--                            :value="item.Key"-->
<!--                            v-text="item.Value"-->
<!--                    ></option>-->
<!--                </select>-->
<!--                <input class="longInput" type="text" name="" maxLength="100" placeholder="请输入主赛事名称" v-model="league_name"><span class="search" @click="getList"></span>-->
<!--            </div>-->
<!--        </div>-->
        <div class="tab" :style="$route.params.id != 0 ? 'height:calc(100% - 13rem)' : 'height:calc(100% - 10.5rem)'">
            <div class="tab_th clearfix">
<!--                <div class="fl td_0" v-if="$route.params.id != 0"><span @click="selectMess(0)" :class="{selectTab:true,selectOn:selectAll}" style="margin-top: 0.5rem"></span></div>-->
                <div class="fl td_6">赛事</div>
                <div class="fl td_3">赛事项目</div>
                <div class="fl td_1">状态</div>
            </div>
            <div class="box_tr" v-if="dataAll2.length == 0" style="height: calc(100% - 3rem);border-bottom: 1px solid #000;">
                <p class="noData" v-text="listLoadTip"></p>
            </div>
            <div class="box_tr" v-else style="height: calc(100% - 3rem);border-bottom: 1px solid #000;">
                <div class="tab_tr clearfix" v-for="(item, i) in dataAll2" :key="i">
<!--                    <div class="fl td_0" v-if="$route.params.id != 0">-->
<!--                        <span @click="selectMess(item.league_id,i)" :class="{selectTab:true,selectOn:item.flg}"></span>-->
<!--                    </div>-->
                    <div class="fl td_6">
                        <span v-text="item.league_name || '-'"></span>
                    </div>
                    <div class="fl td_3">
                        <span v-text="item.eventsName || '-'"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="item.league_status || '-'"></span>
                    </div>
                    <div class="fl td_1" v-if="$route.params.id != 0">
                        <span @click="delLeague(i)" style="cursor: pointer;text-decoration: underline;color: #960202">删除</span>
                    </div>
                </div>
            </div>
<!--            <PageHandle-->
<!--                    :page="page2"-->
<!--                    :totalPage="totalPage2"-->
<!--                    :totalCount="totalCount2"-->
<!--                    @pageTo="pageTo($event)"-->
<!--            />-->
        </div>
        <TipHandle
                :txt="tip"
                :btnTxt="btnTxt"
                @maskHandle="maskHandle($event)"
        />
        <div class="addAccount" :style="'justify-content:'+($route.params.id != 0 ? 'space-between':'center') + ''">
            <button class="addAccountBtn" v-if="$route.params.id != 0" @click="addMess">{{$route.params.id > 0 ? '保存' :'创建'}}</button>
            <button class="addAccountBtn addAccountBtn2" @click="goUrl">{{$route.params.id != 0 ? '取消' : '返回'}}</button>
        </div>
        <div class="selectLeague" v-if="selectLeagueFlg">
            <div class="selectLeague2">
                <div class="srchBox clearfix">
                <div class="fl ml_10">
                    <select style="vertical-align: middle" v-model="events_status">
                        <option value="0">全部</option>
                        <option
                                v-for="(item, i) in eventsJson"
                                :key="i"
                                :value="item.Key"
                                v-text="item.Value"
                        ></option>
                    </select>
                    <input class="longInput" type="text" name="" maxLength="100" placeholder="请输入主赛事名称" v-model="league_name"><span class="search" @click="getList"></span>
                </div>
            </div>
                <div class="tab" style="height:calc(100% - 3.5rem)">
                <div class="tab_th clearfix">
                    <div class="fl td_0" v-if="$route.params.id != 0"><span @click="selectMess(0)" :class="{selectTab:true,selectOn:selectAll}" style="margin-top: 0.5rem"></span></div>
                    <div class="fl td_6">赛事</div>
                    <div class="fl td_3">赛事项目</div>
                    <div class="fl td_3">状态</div>
                </div>
                <div class="box_tr" v-if="dataAll.length == 0">
                    <p class="noData" v-text="listLoadTip"></p>
                </div>
                <div class="box_tr" v-else>
                    <div class="tab_tr clearfix" v-for="(item, i) in dataAll" :key="i">
                        <div class="fl td_0" v-if="$route.params.id != 0">
                            <span @click="selectMess(item.league_id,i,item)" :class="{selectTab:true,selectOn:item.flg}"></span>
                        </div>
                        <div class="fl td_6">
                            <span v-text="item.league_name || '-'"></span>
                        </div>
                        <div class="fl td_3">
                            <span v-text="item.eventsName || '-'"></span>
                        </div>
                        <div class="fl td_3">
                            <span v-text="item.league_status || '-'"></span>
                        </div>
                    </div>
                </div>
                <PageHandle
                        :page="page"
                        :totalPage="totalPage"
                        :totalCount="totalCount"
                        @pageTo="pageTo($event)"
                />
            </div>
                <div class="closeBox" @click="selectLeagueFlg = false"><img src="@/assets/imgs/boxClose.png"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHandle from '@/components/PageHandle.vue'
    import TipHandle from '@/components/TipHandle.vue'
    import { toQueryString } from '@/assets/js/util'

    export default {
        name: 'VipApplyLists',
        components: {
            PageHandle,
            TipHandle
        },
        props: {
        },
        data() {
            return {
                selectLeagueFlg:false,//弹窗赛事列表
                token: this.$cookies.get('token'),
                page: 1,//筛选的赛事列表
                count: 20,//筛选的赛事列表
                totalPage: 1,//筛选的赛事列表
                totalCount: 1,//筛选的赛事列表
                page2: 1, //选中的赛事列表
                totalPage2: 1, //选中的赛事列表
                totalCount2: 1, //选中的赛事列表
                dataAll2:[], //选中的赛事列表
                tip: '',
                btnTxt: [],
                dataAll:[],//筛选的赛事列表
                getListFlag: true,
                league_name: '',
                user_name:'',
                password:'',
                listLoadTip: '',
                events_status: 0,
                eventsJson: [],
                confirm_status: 1,
                statusJson: {
                    1: "启用",
                    2: "停用"
                },
                selectList:[],
                passwordFlg: true,
                getMessFlg: true,
                selectAll: false, //选中当前页所有赛事
                m_id: '',
            }
        },
        watch:{
            selectList () {
                if(this.selectList.length === 0){
                    this.selectAll = false;
                    this.listLoadTip = '无赛事数据'
                }else {
                    let num = 0;
                    this.dataAll.forEach((item) =>{
                        if(this.selectList.find(num => num == item.league_id)) {
                            num ++;
                        }
                    });
                    this.selectAll = num === this.dataAll.length;
                }
            },
            selectLeagueFlg () {
                if(!this.selectLeagueFlg){
                    this.events_status = 0;
                    this.league_name = '';
                }else {
                    this.page = 1;
                    this.getList()
                }
            }
        },
        mounted() {
            this.getKeyValue('events_id');
            let sessionList = sessionStorage.getItem('accountMess');
            if(sessionList){
                let arr = sessionList.split('|');
                this.user_name = arr[0];
                this.password = arr[1];
                this.confirm_status = arr[2] === '启用' ? 1 : 2;
                this.selectList = arr[3].split(',').map(item => item && parseInt(item));
                this.m_id = arr[4]
            }
            if(this.$route.params.id >= 0){
                this.getList()
            }else {
                this.listLoadTip = '无赛事数据'
            }
        },
        methods: {
            delLeague (i) {
                this.selectList.splice(i,1);
                this.dataAll2.splice(i,1);
            },//删除选中的赛事
            goUrl () {
                sessionStorage.removeItem('accountMess');
                this.$router.push('/account/AccountManage')
            },
            handleTips(txt, btnTxt) {
                this.tip = txt
                this.btnTxt = btnTxt
            },
            maskHandle(btn) {
                if(btn == 0) {
                    // 确认发布
                }
                this.tip = ''
            },
            pageTo(page) {
                if(!this.getListFlag) {
                    return
                }
                if(page == '-' && this.page > 1) {
                    this.page --
                    this.getList()
                } else if(page == '+' && this.page < this.totalPage){
                    this.page ++
                    this.getList()
                } else if(page != this.page && typeof page == 'number') {
                    if(page > this.totalPage || page < 1) {
                        this.handleTips('请输入1~' + this.totalPage + '的数', ['关闭'])
                        return
                    }
                    this.page = page
                    this.getList()
                }

            },
            srchHandle() {
                if(!this.getListFlag) {
                    return
                }
                this.page = 1
                this.getList()
            },
            // 运动员等级评定列表
            getList(){
                if(this.getListFlag) {
                    this.getListFlag = false
                } else {
                    return
                }
                this.dataAll = []
                this.selectAll = false;
                this.listLoadTip = '数据加载中...'
                // setTimeout(() =>{
                //     this.dataAll = [
                //         {rownum:1,apply_id: 13,apply_time:'2021-02-01 10:02:56',apply_name: '机构1',apply_result: 0},
                //         {rownum:2,apply_id: 2,apply_time:'2021-02-01 10:02:56',apply_name: '机构1222',apply_result: 1},
                //         {rownum:3,apply_id: 3,apply_time:'2021-02-01 10:02:56',apply_name: '机构13333',apply_result: 2}
                //     ]
                // }, 2000)
                this.$axios.post('Match/Entrance',toQueryString({
                    action: 'GetZleagueList',
                    user_token: this.token,
                    mid: this.$route.params.id >= 0 && !this.selectLeagueFlg ? this.m_id : '',
                    events_id: this.events_status == 0 || (this.$route.params.id >= 0 &&  !this.selectLeagueFlg)? '' :this.events_status,
                    name: this.$route.params.id >= 0 && !this.selectLeagueFlg ? '' : this.league_name,
                    page: this.page,
                    count: this.$route.params.id >= 0 && !this.selectLeagueFlg ? 1000 : this.count
                })).then((res) => {
                    this.getListFlag = true
                    let data = res.data
                    if(data.code == -200) {
                        this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                        return
                    }
                    if(data.code == 1) {
                        if(data.dataList.length == 0){
                            this.listLoadTip = '无赛事数据'
                        }
                        this.listLoadTip = '';
                        let num = 0;
                        let arr = data.dataList.map((item) =>{
                            let obj = {flg: this.selectList.find(num => num == item.league_id)};
                            if(obj.flg){num++}
                            return { ...item, ...obj}
                        });
                        if(!this.selectLeagueFlg){
                            this.dataAll2 = arr;
                            this.totalCount2 = data.totalCount;
                            this.totalPage2 = Math.ceil(data.totalCount/this.count);
                        }else {
                            this.selectAll = num === arr.length && data.dataList.length > 0;
                            this.dataAll = arr;
                            this.totalCount = data.totalCount;
                            this.totalPage = Math.ceil(data.totalCount/this.count);
                        }
                    } else {
                        this.listLoadTip = data.message
                    }
                }).catch(() => {
                    this.listLoadTip = '网络异常'
                    this.getListFlag = true
                })
            },
            // 点击选中
            selectMess (id, index, it) {
                let arr = this.selectList.map(item => item);
                let arr2 = this.dataAll2.map(item => item);
                if(id === 0) {
                    if(this.dataAll.length === 0){return}
                    this.dataAll.forEach((item, i) =>{
                        arr = arr.filter(num => num != item.league_id);
                        arr2 = arr2.filter(res => res.league_id != item.league_id);
                        if(this.selectAll) {
                            this.$set(this.dataAll[i],'flg',false);
                        }else {
                            arr.push(item.league_id);
                            arr2.push(item);
                            this.$set(this.dataAll[i],'flg',true);
                        }
                    });
                } else {
                    if(arr.find(num => num == id)) {
                        arr = arr.filter(num => num != id);
                        this.$set(this.dataAll[index],'flg',false);
                    }else {
                        arr.push(id);
                        this.$set(this.dataAll[index],'flg',true);
                    }
                    if(arr2.find(res => res.league_id == id)) {
                        arr2 = arr2.filter(res => res.league_id != id);
                    }else {
                        arr2.push(it);
                    }
                }
                this.selectList = arr;
                this.dataAll2 = arr2;
                console.log(this.selectList)
                console.log(this.dataAll2)
            },
            //添加保存
            addMess () {
                if(!this.user_name){
                    this.$message({
                        message: '请输入用户名！',
                        type: 'warning'
                    });
                    return;
                }
                if(!this.password){
                    this.$message({
                        message: '请输入登录密码！',
                        type: 'warning'
                    });
                    return;
                }
                if(this.selectList.length === 0){
                    this.$message({
                        message: '请选择关联赛事！',
                        type: 'warning'
                    });
                    return;
                }
                this.$confirm('确定'+ (this.$route.params.id < 0 ? '创建' :'保存') +'?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.addMessTo()
                }).catch(() => {
                });
            },
            addMessTo () {
                if(!this.getMessFlg) {
                    return
                }
                this.getMessFlg = false;
                this.$axios.post('API/CentControlEntrance',toQueryString({
                    action: 'Addmuser',
                    user_token: this.token,
                    manager_user_id: this.$route.params.id < 0 ? 0 :this.$route.params.id,
                    login_name: this.user_name,
                    password: this.password,
                    user_status: this.$route.params.id < 0 ? 1 : this.confirm_status,
                    user_type: 2,
                    leagueid: this.selectList.join(',')
                })).then((res) => {
                    this.getMessFlg = true;
                    let data = res.data;
                    if(data.code == -200) {
                        this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                        return
                    }
                    // eslint-disable-next-line no-empty
                    if(data.code == 1) {
                        this.$message({
                            message: ''+(this.$route.params.id < 0 ? '创建' :'保存')+'成功！',
                            type: 'success'
                        });
                        sessionStorage.removeItem('accountMess');
                        this.$router.push('/account/AccountManage')
                    } else {
                        this.$message({
                            message: data.message,
                            type: 'warning'
                        });
                    }
                }).catch(() => {
                    this.$message.error('网络异常');
                    this.getMessFlg = true
                })
            },
            //获取下拉列表数据
            getKeyValue (nameStr) {
                this.$axios.post('CESA/Entrance',toQueryString({
                    action: 'Get_dicts_KeyValue',
                    user_token: this.token,
                    dict_key: nameStr
                })).then((res) => {
                    let data = res.data;
                    // eslint-disable-next-line no-empty
                    if(data.code == 1) {
                        this.eventsJson = data.list
                    } else {
                        //this.listLoadTip = data.message
                    }
                }).catch(() => {
                    //this.listLoadTip = '网络异常';
                })
            },
            //密码生成
            getPassword () {
                if(this.passwordFlg) {
                    this.passwordFlg = false
                } else {
                    return
                }
                this.$axios.post('API/Entrance',toQueryString({
                    action: 'GetPwd',
                    user_token: this.token
                })).then((res) => {
                    this.passwordFlg = true;
                    let data = res.data;
                    this.password = data;
                }).catch(() => {
                    this.$message.error('网络异常');
                    this.passwordFlg = true
                })
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .selectLeague{
        position: fixed;
        top: 0;left: 0;
        bottom: 0;right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.6);
        z-index: 10;
        min-width: 1280px;
    }
    .selectLeague2{
        position: absolute;
        width: 80%;
        left: 10%;
        top: 10%;
        background-color: #ffffff;
        padding: 1rem;
        height: 80%;
        overflow: hidden;
    }
    .selectLeague2 .closeBox{
        position: absolute;
        top: -2rem;
        right: -2rem;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        background-color: rgba(0,0,0,.8);
        cursor: pointer;
    }
    .selectLeague2 .closeBox img{
        display: inline-block;
        width: 2rem;
        height: 2rem;
        margin: 2.1rem 0 0 .8rem;
    }
    .viewBtn{
        height: 2rem;
        border-bottom: 1px solid #000;
    }
    .viewBtn span{
        display: inline-block;
        height: 100%;
        width: 100px;
        text-align: center;
        cursor: pointer;
    }
    .viewBtn span.on{
        color: #960202;
        border-bottom: 3px solid #960202;
    }
    .srchBox{
        height: 3rem;
        line-height: 3rem;
    }
    .srchBox input, .srchBox select{
        height: 2rem;
    }
    .srchBoxAccount{
        display: flex;
        align-items: center;
    }
    .srchBoxAccount .AccountBtn{
        width: 8rem;
        height: 2.6rem;
        text-align: center;
        background-color: #960202;
        border: 1px solid #960202;
        border-radius: 0.3rem;
        cursor: pointer;
        margin-right: 2rem;
        color: #fff;
    }
    .srchBoxAccount .AccountBtn2{
        color: #960202;
        background-color: #fff;
    }
    .selectTab{
        display: inline-block !important;
        width: 1.5rem;
        height: 1.5rem;
        margin-top: 0.75rem;
        border: 0.1rem solid #000;
        cursor: pointer;
    }
    .selectOn{
        background: url("~@/assets/imgs/selectOn.png") no-repeat center;
        background-size: cover;
    }
    .tab{
        width: 100%;
        height: calc(100% - 13.5rem);
    }
    .longInput{
        width: 20rem;
        vertical-align: middle;
        border: 1px solid #000;
        border-left: none;
        text-indent: .5rem;
    }
    .search{
        display: inline-block;
        width: 3rem;
        height: 2rem;
        border: 1px solid #000;
        border-left: none;
        vertical-align: middle;
        cursor: pointer;
        background:#eee url("~@/assets/imgs/search1.png") no-repeat center;
        background-size: 1rem 1rem;
        border-bottom-right-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
    }
    .accountH{
        font-weight: 600;
    }
    .addAccount{
        width: 25rem;
        margin: .5rem auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .addAccountBtn{
        width: 10rem;
        height: 3rem;
        border-radius: 0.3rem;
        background-color: #960202;
        border: 0.1rem solid #960202;
        color: #fff;
        text-align: center;
        cursor: pointer;
    }
    .addAccount .addAccountBtn2{
        background-color: #fff;
        color: #960202;
    }
</style>
