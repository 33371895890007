import utils from '@/utils';

export default {
    //日期时间校验
    dateTimeValidator:  function(dateTime, callback){
        let dateTimeResult = utils.abnormalDate.get(dateTime, 'yyyy-MM-dd');
        if(dateTimeResult && dateTimeResult != 'Invalid Date'){
            let min = new Date('1900-01-01').getTime(),
                max = new Date().getTime();
            dateTimeResult = new Date(dateTimeResult).getTime();
            if(dateTimeResult < min || dateTimeResult > max){
                callback(new Error('不是有效日期'));
            }else{
                callback();
            }
        }else{
            callback(new Error('不是有效日期'));
        }
    },
    //校验开始时间
    beginTimeValidator: function(beginTime, endTime, callback){
        let beginTimeResult = utils.abnormalDate.get(beginTime, 'yyyy-MM-dd');
        if(beginTimeResult && beginTimeResult != 'Invalid Date'){
            let min = new Date('1900-01-01').getTime(),
                max = new Date().getTime();
            beginTimeResult = new Date(beginTimeResult).getTime();
            if(beginTimeResult < min || beginTimeResult > max){
                callback(new Error('不是有效日期'));
            }else{
                let endTimeResult = utils.abnormalDate.get(endTime, 'yyyy-MM-dd');
                if(endTimeResult && endTimeResult != 'Invalid Date'){
                    endTimeResult = new Date(endTimeResult).getTime();
                    if(beginTimeResult > endTimeResult){
                        callback(new Error('开始日期不能大于结束日期'));
                    }else{
                        callback();
                    }
                }else{
                    callback();
                }
            }
        }else{
            callback(new Error('不是有效日期'));
        }
    },
    //校验结束时间
    endTimeValidator: (beginTime, endTime, callback)=>{
        let endTimeResult = utils.abnormalDate.get(endTime, 'yyyy-MM-dd');
        if(endTimeResult && endTimeResult != 'Invalid Date'){
            let min = new Date('1900-01-01').getTime(),
                max = new Date().getTime();
            endTimeResult = new Date(endTimeResult).getTime();
            if(endTimeResult < min || endTimeResult > max){
                callback(new Error('不是有效日期'));
            }else{
                let beginTimeResult = utils.abnormalDate.get(beginTime, 'yyyy-MM-dd');
                if(beginTimeResult && beginTimeResult != 'Invalid Date'){
                    beginTimeResult = new Date(beginTimeResult).getTime();
                    if(endTimeResult < beginTimeResult){
                        callback(new Error('结束日期不能小于开始日期'));
                    }else{
                        callback();
                    }
                }else{
                    callback();
                }
            }
        }else{
            callback(new Error('不是有效日期'));
        }
    }
}
