<template>
    <div class="view">
        <div>
            <router-link to="/vipPoint/count" class="backBtn"> &lt; 返回</router-link>
            积分详情列表
        </div>
            
        <div class="tab">
            <div class="tab_th clearfix">
                <div class="fl td_0">序号</div>
                <div class="fl td_1">竞赛项目</div>
                <div class="fl td_3">赛事名称</div>
                <div class="fl td_1">年度</div>
                <div class="fl td_1">积分分组</div>
                <div class="fl td_1">会员号</div>
                <div class="fl td_3">会员名称</div>
                <div class="fl td_1">积分</div>
            </div>
            <div class="box_tr" v-if="dataAll.length == 0">
                <p class="noData" v-text="listLoadTip"></p>
            </div>
            <div class="box_tr" v-else>
                <div class="tab_tr clearfix" v-for="(item, i) in dataAll" :key="i">
                    <div class="fl td_0">
                        <span v-text="item.rownum"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="item.events_name"></span>
                    </div>
                    <div class="fl td_3">
                        <span :title="splitStr(item.league_name, 25).t"  v-text="splitStr(item.league_name, 25).s || '-'"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="item.point_year"></span>
                    </div>
                    <div class="fl td_1">
                        <span :title="splitStr(item.point_group_name, 9).t" v-text="splitStr(item.point_group_name, 9).s || '-'"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="item.member_no"></span>
                    </div>
                    <div class="fl td_3">
                        <span :title="splitStr(item.names, 25).t" v-text="splitStr(item.names, 25).s || '-'"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="item.point"></span>
                    </div>
                </div>
            </div>
            <PageHandle 
                :page="page"
                :totalPage="totalPage"
                :totalCount="totalCount"
                @pageTo="pageTo($event)" 
                />
        </div>
        <TipHandle 
            :txt="tip"
            :btnTxt="btnTxt"
            @maskHandle="maskHandle($event)" 
            />
    </div>
</template>

<script>
import PageHandle from '@/components/PageHandle.vue'
import TipHandle from '@/components/TipHandle.vue'
import { toQueryString, splitStr } from '@/assets/js/util'

export default {
    name: 'Detail',
    components: {
        PageHandle,
        TipHandle
    },
    data() {
        return {
            token: this.$cookies.get('token'),
            eventsId: 0,
            leagueId: 0,
            page: 1,
            count: 20,
            totalPage: 1,
            totalCount: 1,
            tip: '',
            btnTxt: [],
            dataAll:[],
            getListFlag: true,
            listLoadTip: '数据加载中...',
            splitStr
        }
    },
    mounted(){
        this.leagueId = this.$route.params.leagueId
        this.eventsId = this.$route.params.eventsId
        this.getList()
    },
    methods: {
        handleTips(txt, btnTxt){
            this.tip = txt
            this.btnTxt = btnTxt
        },
        maskHandle(btn){
            if(btn == 0){
                // 确认
            }
            this.tip = ''
        },
        pageTo(page) {
            if(!this.getListFlag) {
                return
            }
            if(page == '-' && this.page > 1) {
                this.page --
                this.getList()
            } else if(page == '+' && this.page < this.totalPage){
                this.page ++
                this.getList()
            } else if(page != this.page && typeof page == 'number') {
                if(page > this.totalPage || page < 1) {
                    this.handleTips('请输入1~' + this.totalPage + '的数', ['关闭'])
                    return
                }
                this.page = page
                this.getList()
            }
        },
        // 积分详情 列表
        getList(){
            if(this.getListFlag){
                this.getListFlag = false
            } else {
                return
            }
            this.dataAll = []
            this.listLoadTip = '数据加载中...'
            this.$axios.post('Match/Entrance',toQueryString({
                action: 'Get_point_record',
                user_token:this.token,
                league_id: this.leagueId,
                events_id: this.eventsId,
                page: this.page,
                count: this.count
            })).then((res) => {
                let data = res.data;
                this.getListFlag = true
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                if(data.code == 1){
                    if(data.NewList.length == 0){
                        this.listLoadTip = '无数据'
                    } else {
                        this.listLoadTip = ''
                    }
                    this.dataAll = data.NewList
                    this.totalCount = data.totalCount
                    this.totalPage = Math.ceil(data.totalCount/this.count)
                } else {
                    this.listLoadTip = data.message
                }
            }).catch(() => {
                this.getListFlag = true
                this.listLoadTip = '网络异常'
            })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
   .tab{
       height: calc(100% - 3.6rem);
   }
</style>
