import Vue from "vue"
import router from '@/router';
//跳转页面
Vue.directive('href',{
    bind: function(el,binding) {
        if(binding.value){
            el.href = binding.value;
            el.onclick = function() {
                if(binding.modifiers.blank){
                    window.open('/#' + this.href);
                }else if(binding.modifiers.replace){
                    router.replace(this.href);
                }else{
                    router.push(this.href);
                }
            }
        }
    },
    update:function(el,binding){
        el.href = binding.value;
    }
})