<template>
    <div class="container">
        <el-page-header @back="$router.back()" :content="['创建培训报名','编辑培训报名信息'][type]"></el-page-header>
        <div class="content">
            <el-form :model="form" ref="form" :rules="rules" size="mini" class="form" label-width="130px">
                <el-form-item label="培训：" prop="trainId">
                    <template v-if="type == 0">
                        <el-select v-model="form.trainId" @change="trainIdChange">
                            <el-option v-for="(item,index) in trainList" :key="index" :value="item.TrainId" :label="item.TrainDescription.TrainFullName"></el-option>
                        </el-select>
                    </template>
                    <template v-else>
                        <span>{{form.trainName}}</span>
                    </template>
                </el-form-item>
                <template v-if="form.trainId">
                    <el-form-item label="培训时间：" prop="trainTime" required>
                        <span>{{trainTime}}</span>
                    </el-form-item>
                    <el-form-item label="报名时间：" prop="enrollTime">
                        <el-date-picker
                            v-model="form.enrollTime"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-ddTHH:mm:ss"
                            :picker-options="enrollTimeOptions">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="报名须知：" prop="enrollNotice">
                        <Editor v-model="form.enrollNotice" :oss-options="ossConfig"></Editor>
                    </el-form-item>
                    <el-form-item label="报到须知：" prop="reportNotice">
                        <Editor v-model="form.reportNotice" :oss-options="ossConfig"></Editor>
                    </el-form-item>
                    <el-divider></el-divider>
                    <el-descriptions title="学员信息设置"></el-descriptions>
                    <template>
                        <el-form-item label="肖像照片：" prop="fieldsOptions.portraitPhoto" required>
                            <el-radio-group v-model="form.fieldsOptions.portraitPhoto">
                                <el-radio :label="1">必须上传</el-radio>
                                <el-radio :label="0">无需上传</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="服装尺码：" prop="fieldsOptions.clothingSizes" required>
                            <el-radio-group v-model="form.fieldsOptions.clothingSizes">
                                <el-radio :label="1">必须填写</el-radio>
                                <el-radio :label="0">无需填写</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </template>
                    <template v-for="(item,index) in classList">
                        <div :key="index">
                            <el-divider></el-divider>
                            <el-descriptions>
                                <template slot="title">报名资格设置：<span style="font-size: 0.8em;color: #444444">{{item.ClassName}}</span></template>
                            </el-descriptions>
                            <el-form-item label="说明文本：" :prop="`classLimit.${index}.desc`" :rules="classRules.desc">
                                <el-input type="textarea" :rows="5" v-model="form.classLimit[index].desc" style="max-width: 700px" ></el-input>
                            </el-form-item>
                            <el-form-item :label="item.ClassType == 1 ? '裁判员证书：' : '教练员证书：'" :prop="`classLimit.${index}.levelLimit`" :rules="classRules.levelLimit">
                                <el-select v-model="form.classLimit[index].levelLimit">
                                    <el-option label="不需要" :value="0"></el-option>
                                    <el-option v-for="(levelItem,levelIndex) in getFilterLeves(levelObj[item.EventsId + '-' + item.ClassType], item.ClassLevel)" :key="levelIndex" :value="levelItem.LevelNo" :label="levelItem.LevelName + '证书'"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item :label="item.ClassType == 1 ? '执裁经历：' : '执教经历：'" :prop="`classLimit.${index}.workLimit`" :rules="classRules.workLimit">
                                <el-radio-group v-model="form.classLimit[index].workLimit">
                                    <template v-if="form.classLimit[index].levelLimit == 0">
                                        <el-radio :label="1">需要</el-radio>
                                        <el-radio :label="0">不需要</el-radio>
                                    </template>
                                    <template v-else>
                                        <el-radio :label="1">需要（也需要证书）</el-radio>
                                        <el-radio :label="0">不需要（只需要证书）</el-radio>
                                        <el-radio :label="2">没有证书时需要，否则不需要</el-radio>
                                    </template>
                                </el-radio-group>
                            </el-form-item>
                        </div>
                    </template>

                    <el-divider></el-divider>
                    <el-descriptions title="法律文件设置"></el-descriptions>
                    <div class="files">
                        <ul>
                            <li v-for="(item,index) in files" :key="index">
                                <span>{{index + 1}}.</span>
                                <div class="button">
                                    <el-button type="primary" size="mini">
                                        <span>上传文件（pdf）</span>
                                        <input type="file" @change="fileChange($event,index)">
                                    </el-button>
                                </div>
                                <div class="state">
                                    <span class="state1" v-if="item.url">已上传{{item.state == 2 ? item.size : ''}}</span>
                                    <span class="state2" v-else-if="item.state == 1"><i class="el-icon-loading"></i>正在上传</span>
                                    <span class="state3" v-else-if="item.state == -1"><i class="el-icon-error"></i>上传失败</span>
                                </div>
                                <el-form-item label="文件名称:">
                                    <el-input v-model.trim="item.name" placeholder="文件名称最长不超过20个字符" maxlength="20"></el-input>
                                </el-form-item>
                                <div v-show="files.length > 1">
                                    <el-button type="danger" size="mini" @click="removeFile(index)">删除</el-button>
                                </div>
                            </li>
                        </ul>
                        <el-button type="primary" size="small" @click="addFile()" title="点击增加一行文件上传" style="margin-left:30px"><i class="el-icon-plus"></i> 增加文件上传</el-button>
                    </div>
                    <el-divider></el-divider>
                    <el-row type="flex">
                        <el-col :span="10" class="classFee">
                            <el-descriptions title="培训费设置"></el-descriptions>
                            <template v-for="(item,index) in classList">
                                <el-form-item :label="item.ClassName + '：'" :key="index" :rules="classFeeRule" :prop="`classLimit.${index}.fee`">
                                    <el-input v-model="form.classLimit[index].fee" style="width: 100px" ></el-input> 元/学员
                                </el-form-item>
                            </template>
                        </el-col>
                        <el-col :span="1">
                            <el-divider direction="vertical" class="vertical"></el-divider>
                        </el-col>
                        <el-col :span="10">
                            <el-descriptions title="收款账户信息"></el-descriptions>
                            <el-form-item label="开户名称：" prop="feeTransferAccount.name">
                                <el-input v-model="form.feeTransferAccount.name"></el-input>
                            </el-form-item>
                            <el-form-item label="开户银行：" prop="feeTransferAccount.bank">
                                <el-input v-model="form.feeTransferAccount.bank"></el-input>
                            </el-form-item>
                            <el-form-item label="账号：" prop="feeTransferAccount.number">
                                <el-input v-model="form.feeTransferAccount.number"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <el-row>
                        <el-button type="primary" style="margin-left: 100px" @click="save"> 保存 </el-button>
                    </el-row>
                </template>
            </el-form>
        </div>
    </div>
</template>
<script>
import { nanoid } from 'nanoid';
import utils from '@/utils';
import fileUpload from '@/utils/fileUpload.js';

import Editor from '@/components/Editor';
export default {
    name: 'trainingEnrollDetail',
    components: {
        Editor
    },
    data() {
        let id = +this.$route.params.id;
        return {
            type: id == 0 ? 0 : 1,
            trainEnrollId: id,
            form: {
                trainId: null,
                trainName: "",
                trainTime: null,
                enrollTime: [],
                enrollNotice: "",
                reportNotice: "",
                fieldsOptions: {
                    portraitPhoto: 1,
                    clothingSizes: 1
                },
                classLimit: [],
                feeTransferAccount: {
                    name: "",
                    bank: "",
                    number: ""
                }
            },
            trainList: [],
            classList: [],
            levelObj: {},
            levelMap: new Map(),
            files: [],
            ossConfig: null
        };
    },

    created() {
        this.$api.GetTrainEnrollManageInit({
            "TrainEnrollId": this.trainEnrollId
        }).then(data=>{
            if(data.Code == 0){
                if(!this.type){
                    this.trainEnrollId = data.NextTrainEnrollId;
                }
                this.ossConfig = data.UploadOptions;
            }
        })
        if(this.type){
            this.getDetailData();
        }else{
            this.$api.GetTrainListWithoutCreateEnroll().then(data=>{
                if(data.Code == 0){
                    this.trainList = data.TrainList || [];
                }
            })
        }
    },
    computed:{
        enrollTimeOptions(){
            let [BeginTime] = this.form.trainTime;
            return {
                disabledDate(time){
                    if(BeginTime){
                        return time.getTime() > new Date(BeginTime).getTime();
                    }else{
                        return true;
                    }
                }
            }
        },
        rules(){
            return {
                trainId: [{required:true,message:"请选择培训"}],
                enrollTime: [
                    {required:true,validator:(rule, value, callback)=>{
                        if(!value || value.length == 0 || !value[0] || !value[1]){
                            callback(new Error('请选择报名时间'));
                        }else{
                            callback();
                        }
                    }}
                ],
                reportNotice:[
                    {required:true, message: "请输入报道通知"}
                ],
                'feeTransferAccount.name': [{required:true,min: 5, max: 20, message:"限制5 ~ 20个字符"}],
                'feeTransferAccount.bank': [{required:true,min: 5, max: 20, message:"限制5 ~ 20个字符"}],
                'feeTransferAccount.number': [
                    {required:true,min: 5, max: 20, message:"限制10 ~ 20个字符"},
                    {validator:(rule, value, callback)=>{
                        let valueNum = Number(value);
                        if(isNaN(valueNum) || value.indexOf('.') > -1){
                            callback(new Error('格式不正确'));
                        }else{
                            callback();
                        }
                    }}
                ]
            }
        },
        classRules(){
            return {
                desc: [{required:true,message:"请填写培训要求说明"}],
                levelLimit: [{required:true,message:"请选择裁判员证书要求"}],
                workLimit: [{required:true,message:"请选择执裁经历要求"}]
            }
        },
        classFeeRule(){
            return [
                {required:true,message:"请输入培训费"},
                {validator:(rule, value, callback)=>{
                    let valueNum = Number(value);
                    if(isNaN(valueNum)){
                        callback(new Error('请输入数字'));
                    }else if(valueNum < 0 || valueNum > 99999){
                        callback(new Error('限制0 ~ 99999元'));
                    }else if(value.toString().indexOf('.') > -1){
                        callback(new Error('只支持整数'));
                    }else{
                        callback();
                    }
                }},
            ]
        },
        trainTime(){
            if(this.form.trainTime){
                let [BeginTime, EndTime] = this.form.trainTime;
                return utils.dateFormat(BeginTime, 'yyyy-MM-dd HH:mm:ss') + '~' + utils.dateFormat(EndTime, 'yyyy-MM-dd HH:mm:ss');
            }
            return '';
        }
    },
    methods: {
        getDetailData(){
            var loading = this.$loading({
                text: '数据读取中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.8)'
            })
            this.$api.GetTrainEnrollSetting({
                "TrainEnrollId": this.trainEnrollId
            }).then(data=>{
                loading.close();
                if(data.Code == 0){
                    let detail = data.TrainEnrollSetting;
                    let form = this.form;

                    form.trainId = detail.Train.TrainId;
                    form.trainName = detail.Train.TrainDescription.TrainFullName;

                    let trainTime = detail.Train.TrainTime;
                    form.trainTime = [trainTime.BeginTime, trainTime.EndTime];

                    let trainEnroll = detail.TrainEnroll;
                    let {BeginTime, EndTime} = trainEnroll.EnrollTime;
                    form.enrollTime = [BeginTime, EndTime];
                    form.enrollNotice = trainEnroll.EnrollNotice;
                    form.reportNotice = trainEnroll.ReportNotice;

                    let {
                        portrait_photo,
                        clothing_sizes
                    } = trainEnroll.FieldsOptions;
                    form.fieldsOptions = {
                        portraitPhoto: portrait_photo == 0 ? 0 :1,
                        clothingSizes: clothing_sizes == 0 ? 0 :1
                    };
                    form.feeTransferAccount = trainEnroll.FeeTransferAccount;

                    //法律文件
                    let trainEnrollFile = detail.TrainEnrollFile || [];
                    let files = [];
                    for(let i=0;i<trainEnrollFile.length;i++){
                        let obj = trainEnrollFile[i];
                        let listId = nanoid();
                        files.push({
                            listId,
                            name: obj.InstrumentName,
                            url: obj.InstrumentUrl,
                            state: 0
                        })
                    }
                    this.files = files;
                    this.addFile();
                    let trainEnrollClass = detail.TrainEnrollClass || [];
                    let levelMap = this.levelMap;
                    let classLimit = [],
                        classList = [];
                    trainEnrollClass.forEach(item => {
                        classList.push({
                            "ClassId": item.TrainClassId,
                            "ClassName": item.TrainClassName,
                            "EventsId": item.EventsId,
                            "EventsName": item.EventsName,
                            "ClassType": item.ClassType,
                            "ClassLevel": item.ClassLevel,
                            "LevelName": item.LevelName
                        })
                        classLimit.push({
                            levelLimit: item.HadCertLevelLimit || 0,
                            workLimit: item.ExperienceInput || 0,
                            desc: item.TrainClassDesc,
                            fee: item.Fee
                        })
                        let key = item.EventsId + '-' + item.ClassType;
                        if(!levelMap.get(key)){
                            levelMap.set(key, true);
                            if(item.ClassType == 1){
                                this.getRefereeLevel(item.EventsId);
                            }else if(item.ClassType == 2){
                                this.getCoachLevel(item.EventsId);
                            }
                        }
                    })
                    this.classList = classList;
                    form.classLimit = classLimit;
                    this.getLevel();
                }
            }).catch(()=>{
                loading.close();
            })
        },
        getClassList(){
            this.$api.GetTrainClasses({
                TrainId: this.form.trainId
            }).then(data=>{
                if(data.Code == 0){
                    let classList = data.TrainClasses || [];
                    let classLimit = [];
                    let levelMap = this.levelMap;
                    classList.forEach(item => {
                        classLimit.push({
                            levelLimit: 0,
                            workLimit: 0,
                            desc: "",
                            fee: ""
                        })
                        let key = item.EventsId + '-' + item.ClassType;
                        if(!levelMap.get(key)){
                            levelMap.set(key, true);
                            if(item.ClassType == 1){
                                this.getRefereeLevel(item.EventsId);
                            }else if(item.ClassType == 2){
                                this.getCoachLevel(item.EventsId);
                            }
                        }
                    });
                    this.classList = classList;
                    this.form.classLimit = classLimit;
                }
            })
        },
        getRefereeLevel(eventsId){
            this.$api.GetRefereeLevelDictionary({
                "EventsId": eventsId
            }).then(data=>{
                if(data.Code == 0){
                    let key = eventsId + '-1',
                        levels = data.Levels || [];
                    this.$set(this.levelObj, key, levels)
                }
            });
        },
        getCoachLevel(eventsId){
            this.$api.GetCoachLevelDictionary({
                "EventsId": eventsId
            }).then(data=>{
                if(data.Code == 0){
                    let key = eventsId + '-2',
                        levels = data.Levels || [];
                    this.$set(this.levelObj, key, levels)
                }
            });
        },
        getFilterLeves(levels = [], level){
            let filters = levels.filter(item=>item.LevelNo > level);
            return filters;
        },
        trainIdChange(){
            let trainId = this.form.trainId;
            let trainObj = this.trainList.filter(item=> item.TrainId == trainId)[0];
            this.$refs.form.resetFields();
            this.$nextTick(function(){
                this.form.trainId = trainId;
                this.form.trainTime = [trainObj.TrainTime.BeginTime, trainObj.TrainTime.EndTime];
                this.files = [];
                this.getClassList();
                this.addFile();
            });
        },
        addFile(){
            var files = this.files;
            let obj = files[files.length - 1];
            if(!files.length || (obj.url && obj.name)){
                let listId = nanoid();
                files.push({
                    listId,
                    name: "",
                    state: 0//1上传中 -1上传失败 2就是成功
                })
            }else{
                this.$message.error('请先上传前一个的文件和文件名称');
            }
        },
        removeFile(index){
            var files = this.files;
            files.splice(index,1);
        },
        fileChange(event, index){
            let files = event.target.files, file;
            if (files && files.length) {
                file = files[0];
                if(!file) return;
            }
            let list = this.files;
            let obj = list[index];
            obj.size = utils.formatFileSize(file.size);
            obj.state = 1;
            obj.url = "";
            if(!obj.name){
                obj.name = file.name.replace(/.pdf/g,'').substr(0,20); 
            }
            this.$set(this.files, index, obj);
            fileUpload({
                file,
                size: 100 * 1024 * 1024,
                accept: ['pdf','application/pdf'],
                ossConfig: this.ossConfig
            }).then(res=>{
                this.updateFileState(obj.listId, 2, res);
            }).catch(err=>{
                event.target.value = "";
                console.log(err);
                if(err.code == 9999){
                    this.updateFileState(obj.listId, -1);
                }else{
                    this.updateFileState(obj.listId, 0);
                    this.$message.error(err.message);
                }
            })
        },
        updateFileState(listId, state, url = ""){
            var list = this.files;
            var fileObj = null,
                fileIndex = -1;
            for(var i=0;i<list.length;i++){
                if(list[i].listId == listId){
                    fileObj = list[i];
                    fileIndex = i;
                    break;
                }
            }
            if(fileIndex > -1){
                fileObj.url = url;
                fileObj.state = state;
                this.$set(this.files, fileIndex, fileObj);
            }
        },
        save(){
            this.$refs['form'].validate((valid)=>{
                if(valid){
                    let trainEnrollId = this.trainEnrollId;
                    let form = this.form;
                    let files = this.files;
                    let trainEnrollFile = [];
                    for(let i=0;i<files.length;i++){
                        let obj = files[i];
                        if(obj.url && obj.name){
                            trainEnrollFile.push({
                                "TrainInstrumentId": 0,
                                "TrainEnrollId": trainEnrollId,
                                "InstrumentName": obj.name,
                                "InstrumentUrl": obj.url
                            })
                        }
                    }
                    let classList = this.classList,
                        classLimit = form.classLimit;
                    let trainEnrollClass = [];
                    for(let i=0;i<classList.length;i++){
                        let obj = classList[i],
                            value = classLimit[i];
                        trainEnrollClass.push({
                            "Id": 0,
                            "TrainEnrollId": trainEnrollId,
                            "TrainClassId": obj.ClassId,
                            "EventsId": obj.EventsId,
                            "TrainClassName": obj.ClassName,
                            "TrainClassDesc": value.desc,
                            "HadCertLevelLimit": value.levelLimit,
                            "ExperienceInput": value.workLimit,
                            "Fee": value.fee
                        })
                    }
                    let sendData = {
                        "Setting": {
                            "TrainEnroll": {
                                "TrainId": form.trainId,
                                "TrainEnrollId": trainEnrollId,
                                "EnrollTime": {
                                    "BeginTime": form.enrollTime[0],
                                    "EndTime": form.enrollTime[1]
                                },
                                "EnrollNotice": form.enrollNotice,
                                "ReportNotice": form.reportNotice,
                                "FieldsOptions": {
                                    "portrait_photo": form.fieldsOptions.portraitPhoto,
                                    "clothing_sizes": form.fieldsOptions.clothingSizes
                                },
                                "FeeTransferAccount": {
                                    "name": form.feeTransferAccount.name,
                                    "bank": form.feeTransferAccount.bank,
                                    "number": form.feeTransferAccount.number
                                }
                            },
                            "TrainEnrollFile": trainEnrollFile,
                            "TrainEnrollClass": trainEnrollClass
                        }
                    }
                    let loading = this.$loading({
                        text:"保存中",
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    })
                    if(this.type == 0){
                        this.$api.CreateTrainEnroll(sendData).then(data=>{
                            loading.close();
                            if(data.Code == 0){
                                this.$message.success('培训报名创建成功，2秒后返回列表');
                                setTimeout(()=>{
                                    this.$router.back();
                                },1500)
                            }
                        }).catch(()=>{
                            loading.close();
                        })
                    }else{
                        this.$api.AlterTrainEnroll(sendData).then(data=>{
                            loading.close();
                            if(data.Code == 0){
                                this.$message.success('培训报名修改成功，2秒后返回列表');
                                setTimeout(()=>{
                                    this.$router.back();
                                },1500)
                            }
                        }).catch(()=>{
                            loading.close();
                        })
                    }
                }else{
                    this.$message.error('填写信息有误，请查看！');
                }
            })
        }
    },
};
</script>
<style scoped>
@import "~@/assets/css/clear-el.css";
.tip{
    line-height: 1.8em;
    color: #9c9c9c;
}
.vertical{
    height: 100%;
}
.files{
    margin-left: 10px;
}
.files ul{
    margin-left: 0;
    margin-bottom: 20px;
}
.files li{
    display: flex;
    align-items: center;
    color: #606266;
    margin-bottom: 10px;
}
.files li>span:nth-of-type(1){
    width: 30px;
    font-size: 22px;
}
.files li .button .el-button{
    position: relative;
    display: inline-block;
    cursor: pointer;
}
.files li .button input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}
.files li .el-form-item{
    margin-bottom: 0;
    margin-right: 15px;
}
.files li .state{
    width: 140px;
    margin: 0 10px;
}
.files li .state .state1{
    color: #333333;
}
.files li .state .state2{
    color: #409eff;
}
.files li .state .state3{
    color: #f56c6c;
}
.classFee ::v-deep input{
    text-align: right;
}
</style>