<template>
    <div class="view">
        <div class="pageHeader">
            <a href="javascript:;" class="backBtn" @click="$router.back()"> &lt; 返回</a>
            <h2>{{$route.query.name}} 大师分详情</h2>
            <span>
                <template v-if="$route.query.type">大师分类型：{{['','金分','银分','红分'][$route.query.type]}}</template>
                &emsp;
                <template v-if="$route.query.year">大师分年度：{{$route.query.year}}</template>
            </span> 
        </div>
        <div class="tab">
            <div class="tab_th clearfix">
                <div class="td_1">序号</div>
                <div class="td_2">分组名称</div>
                <div class="td_1">名次</div>
                <div class="td_3">参赛者名称</div>
                <div class="td_2">爱好者名称</div>
                <div class="td_2">会员号</div>
                <div class="td_2">获得金分数量</div>
                <div class="td_2">获得银分数量</div>
                <div class="td_2">获得红分数量</div>
                <div class="td_3">获得大师分时间</div>
            </div>
            <div class="box_tr" v-if="list.length == 0">
                <p class="noData" v-text="listLoadTip"></p>
            </div>
            <div class="box_tr" v-else>
                <div class="tab_tr clearfix" v-for="(item, i) in list" :key="i">
                    <div class="td_1"><span>{{(page - 1) * count + i + 1}}</span></div>
                    <div class="td_2"><span>{{item.GroupName}}</span></div>
                    <div class="td_1"><span>{{item.Rank}}</span></div>
                    <div class="td_3"><span>{{item.TeamName}}</span></div>
                    <div class="td_2"><span>{{item.MemberName}}</span></div>
                    <div class="td_2"><span>{{item.MemberNo}}</span></div>
                    <div class="td_2"><span>{{item.ScoreGold > 0 ? item.ScoreGold : ''}}</span></div>
                    <div class="td_2"><span>{{item.ScoreSilver > 0 ? item.ScoreSilver : ''}}</span></div>
                    <div class="td_2"><span>{{item.ScoreRed > 0 ? item.ScoreRed : ''}}</span></div>
                    <div class="td_3"><span>{{item.GainDate && item.GainDate.replace('T',' ').replace('Z','')}}</span></div>
                </div>
            </div>
        </div>
        <PageHandle 
            :page="page"
            :totalPage="totalPage"
            :totalCount="totalCount"
            @pageTo="pageTo($event)" 
            />
        <TipHandle 
            :txt="tip"
            :btnTxt="btnTxt"
            @maskHandle="maskHandle($event)" 
            />
    </div>
</template>

<script>
import PageHandle from '@/components/PageHandle.vue'
import TipHandle from '@/components/TipHandle.vue'
import {
    GetMastersCountDetailPageList
} from '@/newApi';

export default {
    name: 'Detail',
    components: {
        PageHandle,
        TipHandle
    },
    data() {
        return {
            token: this.$cookies.get('token'),
            leagueId: parseInt(this.$route.params.id),
            page: 1,
            count: 20,
            totalPage: 0,
            totalCount: 0,
            tip: '',
            btnTxt: [],
            list:[],
            getListFlag: true,
            listLoadTip: '数据加载中...'
        }
    },
    mounted(){
        this.getList()
    },
    methods: {
        handleTips(txt, btnTxt){
            this.tip = txt
            this.btnTxt = btnTxt
        },
        maskHandle(){
            this.tip = ''
        },
        pageTo(page) {
            if(!this.getListFlag) {
                return
            }
            if(page == '-' && this.page > 1) {
                this.page --;
                this.getList()
            } else if(page == '+' && this.page < this.totalPage){
                this.page ++;
                this.getList()
            } else if(page != this.page && typeof page == 'number') {
                if(page > this.totalPage || page < 1) {
                    this.handleTips('请输入1~' + this.totalPage + '的数', ['关闭'])
                    return
                }
                this.page = page;
                this.getList()
            }
        },
        // 积分详情 列表
        getList(){
            if(this.getListFlag){
                this.getListFlag = false
            } else {
                return
            }
            this.list = []
            this.listLoadTip = '数据加载中...'
            GetMastersCountDetailPageList({
                "LeagueId": this.leagueId,
                "PageSize": this.count,
                "PageNum": this.page
            }).then(data=>{
                this.getListFlag = true;
                if(data.Code == 0){
                    if(data.ContinueList && data.ContinueList.length > 0){
                        this.totalPage = data.PageCount;
                        this.totalCount = data.TotalCount;
                        this.page = data.PageNum;
                        this.list = data.ContinueList;
                        this.listLoadTip = '';
                    }else{
                        this.totalPage = 0;
                        this.totalCount = 0;
                        this.page = 1;
                        this.list = [];
                        this.listLoadTip = '无数据';
                    }
                }else{
                    this.listLoadTip = data.Message;
                }
            }).catch(()=>{
                this.getListFlag = true;
                this.listLoadTip = '数据获取失败';
            })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '~@/assets/css/common_z.css';
.pageHeader{
    height: 3rem;
    display: flex;
    line-height: 2rem;
}
    .pageHeader h2{
        font-size: 18px;
        margin-right: 10px;
    }
   .tab{
       height: calc(100% - 8rem);
   }
</style>
