<template>
    <div class="view">
        <div class="srchBox clearfix">
            <div class="fl ml_10">
                <label for="">机构名称：</label>
                <input type="text" name="" id="" v-model="company_name">
            </div>
            <div class="fl ml_10">
                <label for="">状态：</label>
                <select v-model="confirm_status">
                    <option value="-1">全部</option>
                    <option 
                        v-for="(item, i) in statusJson" 
                        :key="i"
                        :value="i"
                        v-text="item"
                        ></option>
                </select>
            </div>
            <div class="fl ml_10">
                <button class="btn" @click="srchHandle">查询</button>
            </div>
        </div>
        <div class="tab">
            <div class="tab_th clearfix">
                <div class="fl td_0">序号</div>
                <div class="fl td_3">申请时间</div>
                <div class="fl td_2">机构名称</div>
                <div class="fl td_2">机构全称</div>
                <div class="fl td_1">状态</div>
                <div class="fl td_1">操作</div>
            </div>
            <div class="box_tr" v-if="dataAll.length == 0">
                <p class="noData" v-text="listLoadTip"></p>
            </div>
            <div class="box_tr" v-else>
                <div class="tab_tr clearfix" v-for="(item, i) in dataAll" :key="i">
                    <div class="fl td_0">
                        <span v-text="item.rownum"></span>
                    </div>
                    <div class="fl td_3">
                        <span v-text="item.confirm_time"></span>
                    </div>
                    <div class="fl td_2">
                        <span v-text="item.company_name || '-'"></span>
                    </div>
                    <div class="fl td_2">
                        <span v-text="item.company_full_name || '-'"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="statusJson[item.confirm_status]"></span>
                    </div>
                    <div class="fl td_1">
                        <span>
                            <router-link
                                :to="'/account/vipApplyLists/applyDetail/' + item.company_member_no"
                                v-text="item.confirm_status == 0? '去审核': '查看详情'"
                                ></router-link>
                        </span>
                    </div>
                </div>
            </div>
            <PageHandle 
                :page="page"
                :totalPage="totalPage"
                :totalCount="totalCount"
                @pageTo="pageTo($event)" 
                />
        </div>
        <TipHandle 
            :txt="tip"
            :btnTxt="btnTxt"
            @maskHandle="maskHandle($event)" 
            />
    </div>
</template>

<script>
import PageHandle from '@/components/PageHandle.vue'
import TipHandle from '@/components/TipHandle.vue'
import { toQueryString } from '@/assets/js/util'

export default {
    name: 'VipApplyLists',
    components: {
        PageHandle,
        TipHandle
    },
    props: {
    },
    data() {
        return {
            token: this.$cookies.get('token'),
            page: 1,
            count: 20,
            totalPage: 1,
            totalCount: 1,
            tip: '',
            btnTxt: [],
            dataAll:[],
            getListFlag: true,
            confirm_status: -1,
            statusJson: {
                0: "待审核",
                1: "通过",
                2: "未通过"
            },
            company_name: '',
            listLoadTip: ''
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        handleTips(txt, btnTxt) {
            this.tip = txt
            this.btnTxt = btnTxt
        },
        maskHandle(btn) {
            if(btn == 0) {
                // 确认发布
            }
            this.tip = ''
        },
        pageTo(page) {
            if(!this.getListFlag) {
                return
            }
            if(page == '-' && this.page > 1) {
                this.page --
                this.getList()
            } else if(page == '+' && this.page < this.totalPage){
                this.page ++
                this.getList()
            } else if(page != this.page && typeof page == 'number') {
                if(page > this.totalPage || page < 1) {
                    this.handleTips('请输入1~' + this.totalPage + '的数', ['关闭'])
                    return
                }
                this.page = page
                this.getList()
            }
            
        },
        srchHandle() {
            if(!this.getListFlag) {
                return
            }
            this.page = 1
            this.getList()
        },
        // 运动员等级评定列表
        getList(){
            if(this.getListFlag) {
                this.getListFlag = false
            } else {
                return
            }
            this.dataAll = []
            this.listLoadTip = '数据加载中...'
            // setTimeout(() =>{
            //     this.dataAll = [
            //         {rownum:1,apply_id: 13,apply_time:'2021-02-01 10:02:56',apply_name: '机构1',apply_result: 0},
            //         {rownum:2,apply_id: 2,apply_time:'2021-02-01 10:02:56',apply_name: '机构1222',apply_result: 1},
            //         {rownum:3,apply_id: 3,apply_time:'2021-02-01 10:02:56',apply_name: '机构13333',apply_result: 2}
            //     ]
            // }, 2000)
            this.$axios.post('CESA/CentEntrance',toQueryString({
                action: 'Get_Manage_company',
                user_token: this.token,
                company_name: this.company_name,
                confirm_status: this.confirm_status == -1? '' : this.confirm_status,
                page: this.page,
                count: this.count
            })).then((res) => {
                this.getListFlag = true
                let data = res.data
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                if(data.code == 1) {
                    if(data.dataList.length == 0){
                        this.listLoadTip = '无数据'
                    }
                    this.dataAll = data.dataList
                    this.totalCount = data.totalCount
                    this.totalPage = Math.ceil(data.totalCount/this.count)
                } else {
                    this.listLoadTip = data.message
                }
            }).catch(() => {
                this.listLoadTip = '网络异常'
                this.getListFlag = true
            })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .srchBox{
        height: 3rem;
        line-height: 3rem;
    }
    .srchBox input, .srchBox select{
        height: 2rem;
    }
    .tab{
        width: 100%;
        height: calc(100% - 3rem);
    }
</style>
