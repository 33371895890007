<template>
    <div class="status">
        <el-tag 
            v-for="(item,index) in list" :key="index" 
            :effect="item.code == statusCode ? 'dark' : 'plain'"
            type="danger" 
            @click="statusChange(item)"
        >{{item.label}}</el-tag>
    </div>
</template>
<script>
export default {
    name: "status",
    props:{
        list: {
            type: Array,
            default: ()=>[]
        },
        statusCode: [Number,String,Object]
    },
    data(){
        return {

        }
    },
    methods:{
        statusChange(obj){
            this.$emit('change', obj.code);
        }
    }
}
</script>
<style scoped>
.status .el-tag{
    cursor: pointer;
    margin-right: 15px;
    border-color: #C91E25;
    background-color: initial;
    padding: 0 20px;
}
.status .el-tag.el-tag--dark{
    background-color: #C91E25;
    border-color: #C91E25;
}
</style>