<template>
    <div class="container">
        <el-page-header @back="$router.back()" content="裁判员信息"></el-page-header>
        <div class="content" v-if="pageShow">
            <el-form :model="form" ref="form" :rules="rules" size="mini" class="form" label-width="100px">
                <div class="basic_info">
                    <div class="head_portrait">
                        <img :src="headPortrait" >
                    </div>
                    <div class="basic_info_content">
                        <el-row>
                            <el-col :span="6" :lg="5"><el-form-item label="裁判员ID：" label-width="auto">{{refereeId}}</el-form-item></el-col>
                            <el-col :span="18" :lg="19"><el-form-item label="个人用户ID：">{{userId}}</el-form-item></el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="6" :lg="5">
                                <el-form-item label="姓名：" label-width="auto">{{form.name || "未填写"}}</el-form-item>
                            </el-col>
                            <el-col :span="18" :lg="19">
                                <el-form-item label="手机号：">
                                    <span>{{form.phone || "未绑定"}}</span>
                                    <el-button 
                                        size="mini" 
                                        type="info" 
                                        v-if="form.phone || userId" 
                                        style="margin-left: 15px" 
                                        v-href="`/account/UserDetail/${userId}`">
                                        跳转个人用户信息
                                    </el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="6" :lg="5"><el-form-item label-width="0">{{form.eventsName}}裁判</el-form-item></el-col>
                            <el-col :span="18" :lg="19"><el-form-item label="通讯地址：">{{form.address || '未填写'}}</el-form-item></el-col>
                        </el-row>
                    </div>
                </div>
                <el-descriptions title="持有证书信息" style="margin-top: 15px"></el-descriptions>
                <el-row type="flex">
                    <el-col :span="6">
                        <el-form-item label="当前等级：" prop="currentLevelNo">
                            <el-select v-model="form.currentLevelNo">
                                <el-option v-for="(item,index) in refereeLevelList" :value="item.LevelNo" :label="item.LevelName" :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="证书编号：" prop="currentLevelCertNo">
                            <el-input type="text" v-model="form.currentLevelCertNo"></el-input>
                        </el-form-item>
                        <el-form-item label="发证日期：" prop="currentLevelTime">
                            <el-input v-model="form.currentLevelTime" placeholder="年月日、年月、年，-分割"></el-input>
                        </el-form-item>
                        <el-form-item label="发证机关：" prop="currentLevelCertIssuer">
                            <el-input v-model="form.currentLevelCertIssuer"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10">
                        <el-form-item label="证书图片：">
                            <image-upload
                                multiple
                                :image-urls.sync="form.currentLevelCertUrls"
                                :oss-config="ossConfig"
                                :fileSize="0.5 * 1024 * 1024"
                                :max="9"
                            >
                                <template slot="tip">
                                    <p>只能上传jpg/png格式，大小不超过 0.5MB</p>
                                </template>
                            </image-upload>
                        </el-form-item>
                    </el-col>
                    <el-col :span="1">
                        <el-divider direction="vertical" class="vertical"></el-divider>
                    </el-col>
                    <el-col :span="7">

                    </el-col>
                </el-row>
                <el-row>
                    <el-button type="primary" style="width: 100px;margin-left: 100px" @click="save"> 保存 </el-button>
                </el-row>
            </el-form>
            <referee-work 
                ref="refereeWork" 
                :list="workList" 
                :refereeId="refereeId"
                :ossConfig="ossConfig"
                @add="(...arg)=>workHandle('add', arg)"
                @update="(...arg)=>workHandle('update', arg)"
                @delete="(...arg)=>workHandle('delete', arg)"
            ></referee-work>
        </div>
    </div>
</template>
<script>
import utils from '@/utils';
import validators from './validators';

import imageUpload from '../../components/imageUpload.vue';
import refereeWork from './components/refereeWork.vue';


export default {
    name: 'RefereeDetail',
    components:{
        imageUpload,
        refereeWork
    },
    data() {
        let refereeId = +this.$route.params.id;
        return {
            pageShow: false,
            refereeId,
            userId: 0,
            defaultImg: require('@/assets/imgs/个人@3x.png'),
            defaultImg_man: require('@/assets/imgs/肖像_男@3x.png'),
            defaultImg_woman: require('@/assets/imgs/肖像_女@3x.png'),
            form:{
                portraitPhoto: "",
                phone: "",
                eventsId: "",
                eventsName: "",
                name: "",
                gender: 0,
                address: "",
                currentLevelNo: "",
                currentLevelCertNo: "",
                currentLevelTime: "",
                currentLevelCertUrls: [],
                currentLevelCertIssuer: ""
            },
            refereeLevelList: [],
            workList: [],
            trainingList: [],
            ossConfig: {}
        };
    },

    created() {
        this.getDetailData();
    },
    computed:{
        rules(){
            return {
                currentLevelNo: [{required: true, message: '请选择当前等级'}],
                currentLevelCertNo:[
                    {min: 4, max: 20, message: '限制4～20个字符'},
                    {validator: (rule, value, callback)=>{
                        let regExp = /^[A-Z0-9-]+$/;
                        if(value && !regExp.test(value)){
                            callback(new Error('证书编号格式不正确'));
                        }else{
                            callback();
                        }
                    }}
                ],
                currentLevelTime: [{
                    validator: (rule, value, callback)=>{
                        if(value){
                            validators.dateTimeValidator(value, callback);
                        }else{
                            callback()
                        }
                    }
                }],
                currentLevelCertIssuer: [{required: true, message: '请输入发证机关'}]
            } 
        },
        headPortrait(){
            return this.form.portraitPhoto || this.getDefalut(this.form.gender);
        }
    },
    methods: {
        getDefalut(type){
            switch(type){
                case 1:
                    return this.defaultImg_man;
                case 2:
                    return this.defaultImg_woman;
                default:
                    return this.defaultImg;
            }
        },
        getOSSConfig(){
            this.$api.GetPersonalUserUploadOptions({
                "QueryUserId": this.userId
            }).then(data=>{
                if(data.Code == 0){
                    this.ossConfig = data.UploadOption;
                }
            })
        },
        getDetailData(){
            var loading = this.$loading({
                text: '数据读取中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.8)'
            })
            this.$api.GetUserRefereeDetail({
                "RefereeId": this.refereeId,
            }).then(data=>{
                loading.close();
                if(data.Code == 0){
                    this.pageShow = true;
                    let userInfo = data.Referee.UserInfo,
                        user4Referee = data.Referee.User4Referee,
                        refereeExperiences = data.Referee.RefereeExperiences || [],
                        refereeTrains = data.Referee.RefereeTrains || [];
                    this.workList = refereeExperiences;
                    this.trainingList = refereeTrains;
                    let Region = userInfo.AddressInfo.Region || {},
                        address = "";
                    if(userInfo.AddressInfo.Country == "中国"){
                        address = (Region.RegionName || "") + (userInfo.AddressInfo.Address || "")
                    }else{
                        address = userInfo.AddressInfo.Country;
                    }
                    let currentLevelTime = utils.abnormalDate.get(user4Referee.CurrentLevelTime, 'yyyy-MM-dd');
                    this.userId = userInfo.UserId;
                    this.form = {
                        portraitPhoto: userInfo.PortraitPhoto,
                        phone: userInfo.Phone,
                        eventsId: user4Referee.EventsId,
                        eventsName: user4Referee.EventsName,
                        name: userInfo.Name,
                        gender: userInfo.Gender,
                        address,
                        currentLevelNo: user4Referee.CurrentLevelNo || "",
                        currentLevelCertNo: user4Referee.CurrentLevelCertNo,
                        currentLevelTime,
                        currentLevelCertUrls: user4Referee.CurrentLevelCertUrls || [],
                        currentLevelCertIssuer: user4Referee.CurrentLevelCertIssuer
                    }
                    this.getOSSConfig();
                    this.getRefereeLevel();
                }
            }).catch(()=>{
                loading.close();
            })
        },
        save(){
            this.$refs.form.validate(valid=>{
                if(valid){
                    let loading = this.$loading({
                        text: '保存中',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.8)'
                    })
                    let form = this.form;
                    let currentLevelObj = this.refereeLevelList.filter(item=>item.LevelNo == form.currentLevelNo)[0] || {};
                    let currentLevelTime = utils.abnormalDate.set(form.currentLevelTime);
                    this.$api.SaveUserRefereeBaseInfo({
                        "RefereeBaseInfo": {
                            "Id": this.refereeId,
                            "EventsId": form.eventsId,
                            "EventsName": form.eventsName,
                            "UserId": this.userId,
                            "CurrentLevelNo": currentLevelObj.LevelNo || 0,
                            "CurrentLevelName": currentLevelObj.LevelName || null,
                            "CurrentLevelTime": currentLevelTime || 0,
                            "CurrentLevelCertNo": form.currentLevelCertNo,
                            "CurrentLevelCertUrls": form.currentLevelCertUrls,
                            "CurrentLevelCertIssuer": form.currentLevelCertIssuer
                        }
                    }).then(data=>{
                        loading.close();
                        if(data.Code == 0){
                            this.$message.success('保存成功');
                            this.getDetailData();
                        }
                    }).catch(()=>{
                        loading.close();
                    })
                }else{
                    this.$message.error('填写信息有误，请查看！');
                }
            })
        },
        workHandle(type, arg){
            let workList = this.workList;
            if(type == 'add'){
                let [info] = arg;
                workList.unshift(info);
            }else if(type == 'update'){
                let [index,info] = arg;
                this.$set(workList, index, info);
            }else if(type == 'delete'){
                let [index] = arg;
                workList.splice(index, 1);
            }
        },
        getRefereeLevel(){
            this.$api.GetRefereeLevelDictionary({
                "EventsId": this.form.eventsId
            }).then(data=>{
                if(data.Code == 0){
                    this.refereeLevelList = data.Levels || [];
                }
            })
        }
    }
};
</script>
<style scoped>
@import "~@/assets/css/clear-el.css";
.vertical{
    height: 100%;
}
.basic_info{
    display: flex;
    width: 100%;
    margin-bottom: 20px;
}
.basic_info .head_portrait{
    margin-right: 15px;
}
.basic_info .head_portrait,
.basic_info .head_portrait img{
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
}
.basic_info_content{
    width: calc(100% - 95px);
}
.basic_info_content .el-form-item{
    margin: 0 !important;
}
</style>