<template>
    <div class="editContest view">
        <router-link to="/match/rating" class="backBtn"> &lt; 返回</router-link>
        <span v-show="pageTit" v-text="'当前：' + pageTit"></span>
        <div class="group projectType" style="padding:1rem;">
            <label>项目类型：</label>
            <button class="btn active">单项运动项目</button>
            <button class="btn">综合运动会</button>
        </div>
        <h2 style="font-size:2rem;padding-left:1rem">复审信息</h2>
        <div class="infosBox">
            <div class="basicInfo">
                <div class="group">
                    <label>申请机构：</label>
                    <span v-text="competitionName"></span>
                </div>
                <div class="group">
                    <label>申请时间：</label>
                    <span v-if="AuditStatus.ApplyTime">{{dateFormat(AuditStatus.ApplyTime)}}</span>
                </div>
                <div class="group">
                    <label>赛事名称：</label>
                    <span v-if="LeagueSerial.Id">{{LeagueSerial.Name}} - {{League.Name}}</span>
                    <span v-text="League.Name" v-else></span>
                </div>
                <div class="group">
                    <label>赛季名称：</label>
                    <span v-text="Season.SeasonName"></span>
                </div>
                <div class="group">
                    <label>赛季赛事名称：</label>
                    <span v-text="Season.LeagueName"></span>
                </div>
                <div class="group">
                    <label>比赛形式：</label>
                    <span v-text="League.IsOnline?'线上赛':'线下赛'"></span>
                </div>
                <div class="group">
                    <label>比赛项目：</label>
                    <span v-text="League.EventsName"></span>
                </div>
                <div class="group" v-if="!LeagueSerial.Id">
                    <label>初审认证：</label>
                    <span v-text="TentativeLevel+'级'"></span>
                </div>
                <div class="group">
                    <label>联系人：</label>
                    <span v-text="Contacts.Salutation"></span>
                </div>
                <div class="group">
                    <label>联系电话：</label>
                    <span v-text="Contacts.ContactWay"></span>
                </div>
                <div class="group" style="display:flex">
                    <label>赛事logo：</label>
                    <img style="height:7rem" :src="League.LogoUrl || require('@/assets/imgs/joinImg2.png')" alt="">
                </div>
                <div class="group">
                    <label>比赛时间：</label>
                    <span v-if="Season.BeginTime && Season.EndTime">{{dateFormat(Season.BeginTime, 'yyyy-MM-dd')}} ~ {{dateFormat(Season.EndTime, 'yyyy-MM-dd')}}</span>
                </div>
                <template v-if="!LeagueSerial.Id || IsSeasonLinkSubleague">
                    <div class="group">
                        <label>认证信息详情&emsp;</label>
                    </div>
                    <div class="group">
                        <table class="Indicators">
                            <thead>
                                <th>
                                    <td>认证标准</td>
                                    <td v-if="!LeagueSerial.Id">初审提交资料</td>
                                    <td>复审提交资料</td>
                                </th>
                            </thead>
                            <tbody>
                                <template v-for="(item,index) in Indicators">
                                    <tr :key="index" v-if="!!item.level">
                                        <td>{{item.name}}</td>
                                        <td v-if="!LeagueSerial.Id">{{TentativeIndicators ? TentativeIndicators[index].text : ''}}</td>
                                        <td :style="(!TentativeIndicators || TentativeIndicators[index].text != item.text)?'color:red':''">{{item.text}}</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </template>
                <div class="group">
                    <label>附件：</label>
                </div>
                <div class="attachment">
                    <ul>
                        <template v-for="(item,index) in Attachments">
                            <li :key="index" v-if="item.id == 2 ? false : item.id == 3 ? !!Indicators[3].level : true">
                                <div class="name_header">
                                    <div class="name">
                                        <span>{{item.name}}</span>
                                        <span style="margin-left: 5px;color: blue;text-decoration: underline;cursor: pointer;" @click="downloadFileZip(item)" v-if="item.files && item.files.length">下载zip</span>
                                    </div>
                                </div>
                                <div class="content">
                                    <ul>
                                        <li v-for="(fileItem,fileIndex) in item.files" :key="fileIndex">
                                            <a :href="fileItem.store_path" target="_blank">{{fileItem.original_name}}</a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </template>
                    </ul>
                </div>
                <template v-if="!LeagueSerial.Id || IsSeasonLinkSubleague">
                    <div class="group">
                        <label style="width: 10rem;">系统自动计算的等级：</label>
                    </div> 
                    <div class="lvlGroup clearfix">
                        <div class="box box1 clearfix">
                            <template v-for="(item, index) in Indicators">
                                <div class="oneLvl" :key="index" v-if="!!item.level">
                                    <p v-text="item.name"></p>
                                    <ul>
                                        <li 
                                            v-for="(gradeItem, gradeIndex) in grade"
                                            :key="gradeIndex"
                                            :class="(item.level == gradeItem? 'on ': ' ')"
                                            v-text="gradeItem"></li>
                                    </ul>
                                </div>
                            </template>
                        </div>
                        <div class="box box2">
                            <p>最终定级</p>
                            <ul>
                                <li 
                                    v-for="(gradeItem, gradeIndex) in grade"
                                    :key="gradeIndex"
                                    :class="(FinallyLevel == gradeItem? 'on ': ' ')"
                                    v-text="gradeItem"></li>
                            </ul>
                        </div>
                    </div>
                </template>
                <br>
                <template v-if="CardsMasters">
                    <el-descriptions title="扑克大师分" :column="1" :labelStyle="{'width':'100px','justify-content': 'end','padding-right':'12px','margin-right':'0','box-sizing':'border-box'}">
                        <el-descriptions-item label="缴费项目">{{CardsMasters.PaySubject}}</el-descriptions-item>
                        <el-descriptions-item label="缴费状态">
                            <span style="color: #C91E25" v-if="CardsMasters.MastersPayStatus == 0">未交费</span>
                            <span style="color: green" v-else-if="CardsMasters.MastersPayStatus == 1">已缴费</span>
                            <span v-else-if="CardsMasters.MastersPayStatus == 2">免费</span>
                        </el-descriptions-item>
                        <el-descriptions-item label="缴费时间">{{CardsMasters.PayTime}}</el-descriptions-item>
                        <el-descriptions-item label="缴费方式">
                            <span v-if="CardsMasters.PayMethod == 1">亿动APP</span>
                            <span v-else-if="CardsMasters.PayMethod == 2">后台</span>
                        </el-descriptions-item>
                    </el-descriptions>
                </template>
                <div class="group" style="display:flex">
                    <label>复审结果：</label>
                    <div class="radioBox">
                        <input type="radio" name="result" id="result1" v-model="audit_status" :value="1" ><label for="result1">通过</label>
                        <input type="radio" name="result" id="result2" v-model="audit_status" :value="2" ><label for="result2">不通过</label>
                    </div>
                </div>
                <div class="group" v-show="audit_status == 2">
                    <label>备注：</label>
                    <textarea class="remark" v-model.trim="RejectReason" cols="60" rows="10" maxlength="400"></textarea>
                </div>
                <div class="group" v-if="LeagueManageAccount">
                    <label>管理员账户&emsp;</label>
                </div>
                <div class="group" v-if="LeagueManageAccount">
                    <label>用户名：</label>
                    <span v-text="LeagueManageAccount.LoginName"></span>
                    <br />
                    <label>密码：</label>
                    <span v-text="LeagueManageAccount.Password"></span>
                </div>
            </div>
            <br>
            <br>
            <div class="handleBox">
                <button @click="AuditStatus.Status == 1?submitHandle():''" :disabled="!(AuditStatus.Status == 1)" v-text="btn_txt"></button>
            </div>
        </div>
        <TipHandle 
            :txt="tip"
            :btnTxt="btnTxt"
            @maskHandle="maskHandle($event)" 
            />
    </div>
</template>

<script>
import JSZip from 'jszip';
import TipHandle from '@/components/TipHandle.vue'
import {
    ReviewAudit,
    ApproveReviewAudit,
    RejectReviewAudit
} from '@/newApi'
import utils from '@/utils';

export default {
    name: 'RatingDetail',
    components: {
        TipHandle
    },
    props: {},
    data() {
        return {
            MainLeagueId:this.$route.params.mainLeagueId,
            SeasonId:this.$route.params.seasonId,
            competitionName:'',
            LeagueSerial: {},
            League:{},
            Season:{},
            Contacts:{},
            FinallyLevel:'',//复审等级
            TentativeLevel:'',//初审等级
            TentativeIndicators:[],//初审指标资料
            Indicators:[],//复审指标资料
            Attachments:[],
            AuditStatus:{},
            CardsMasters: null,
            LeagueManageAccount:{},
            IsSeasonLinkSubleague: false,
            grade:['A','B','C','D','E'],
            audit_status:0,
            RejectReason:'',
            pageTit:'查看赛事认证等级评定详情（复审）',
            tip:'',
            btnTxt:[],
            btn_txt:'提交',
            submitFlag:true,
        }
    },
    created(){
        this.getDetail()
    },
    mounted(){
    },
    watch:{
    },
    methods: {
        dateFormat(val, fmt = 'yyyy-MM-dd HH:mm:ss'){
            return utils.dateFormat(val, fmt);
        },
        handleTips(txt, btnTxt){
            this.tip = txt
            this.btnTxt = btnTxt
        },
        maskHandle(btn){
            if(btn == 0) {
                this.send()
            } else if(btn == 1){
                // 提交成功，返回列表
                this.$router.push('/match/rating')
            }
            this.tip = ''
        },
        //获取详情
        getDetail () {
            ReviewAudit({
                MainLeagueId: this.MainLeagueId,
                SeasonId: this.SeasonId
            }).then(data=>{
                console.log(data)
                if(data.Code == 0){
                    this.competitionName = data.Owner.OwnerName;
                    this.League = data.League;
                    this.Season = data.ReviewData.Season;
                    this.TentativeIndicators = data.ReviewData.TentativeIndicators;
                    this.Contacts = data.ReviewData.ApplyData.Contacts;
                    this.TentativeLevel = data.ReviewData.TentativeLevel;
                    this.FinallyLevel = data.ReviewData.ApplyData.FinallyLevel;
                    this.Indicators = data.ReviewData.ApplyData.Indicators;
                    this.Attachments = data.ReviewData.ApplyData.Attachments;
                    this.AuditStatus = data.ReviewData.AuditStatus;
                    this.CardsMasters = data.ReviewData.ApplyData.CardsMasters;
                    this.LeagueManageAccount = data.LeagueManageAccount;
                    this.LeagueSerial = {
                        Id: data.LeagueSerialId,
                        Name: data.LeagueSerialName
                    }
                    this.IsSeasonLinkSubleague = data.IsSeasonLinkSubleague;
                }
            })
        },
        //审核 this.audit_status - 1审核通过；2：审核不通过
        submitHandle() {
            if(!this.submitFlag) {
                return
            }
            if(this.audit_status == 0) {
                this.handleTips('请选择复审审核结果', ['关闭'])
                return
            } else if(this.audit_status == 1) {
                this.handleTips('确认复审通过审核？', ['确认', '关闭'])
            } else if(this.audit_status == 2) {
                if(!this.RejectReason) {
                    this.handleTips('请填写备注', ['关闭'])
                    return
                }
                this.handleTips('确认复审不通过审核？', ['确认', '关闭'])
            }
        },
        // 确认通过(0) or 不通过(1)
        send() {
            if(!this.submitFlag) {
                return ;
            }
            this.submitFlag = false
            this.btn_txt = '提交中...'
            if(this.audit_status == 1){
                ApproveReviewAudit({
                    MainLeagueId: this.MainLeagueId,
                    SeasonId: this.SeasonId
                }).then(data=>{
                    this.submitFlag = true;
                    this.btn_txt = '提交'
                    if(data.Code == 0){
                        this.handleTips('提交成功', ['', '返回列表'])
                    }else{
                        this.handleTips(data.message||'失败', ['关闭'])
                    }
                }).catch(() => {
                    this.submitFlag = true;
                    this.btn_txt = '提交'
                    this.handleTips('网络异常', ['关闭'])
                })
            }else if(this.audit_status == 2){
                RejectReviewAudit({
                    MainLeagueId: this.MainLeagueId,
                    SeasonId: this.SeasonId,
                    RejectReason: this.RejectReason
                }).then(data=>{
                    this.submitFlag = true;
                    this.btn_txt = '提交'
                    if(data.Code == 0){
                        this.handleTips('提交成功', ['', '返回列表'])
                    }else{
                        this.handleTips(data.message||'失败', ['关闭'])
                    }
                }).catch(() => {
                    this.submitFlag = true;
                    this.btn_txt = '提交'
                    this.handleTips('网络异常', ['关闭'])
                })
            }
        },
        downloadFileZip(obj){
            let loading = this.$loading({
                    lock: true,
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
            this.createZip(obj.files).then(res=>{
                loading.close();
                this.openDownloadXLSXDialog(res, this.League.Name + obj.name);
            }).catch((name)=>{
                loading.close();
                this.$message.error(name + '下载失败');
            })
        },
        createZip(list){
            const zip = new JSZip();
            return new Promise((resolve,reject)=>{
                let num = 0, length = list.length;
                list.forEach(async item => {
                    try{
                        let name = item.original_name;
                        let data = await this.fileLoad(item.store_path);
                        zip.file(name, data);
                        num++;
                        if(num == length){
                            zip.generateAsync({ type: "blob" }).then((content) => {
                                resolve(content);
                            }).catch((err)=>{
                                reject(err);
                            });
                        }
                    }catch{
                        num++;
                        if(num == length){
                            zip.generateAsync({ type: "blob" }).then((content) => {
                                resolve(content);
                            }).catch((err)=>{
                                reject(err);
                            });
                        }
                    }
                });
            })
        },
        fileLoad(url){
            return new Promise(function(resolve, reject){
                try{
                    let xhr = new XMLHttpRequest();
                        xhr.open('get', url, true);
                        xhr.responseType = 'blob';
                        xhr.onload = function () {
                            if(xhr.readyState == 4 && xhr.status == 200){
                                resolve(xhr.response);
                            }else{
                                reject();
                            }
                        }
                        xhr.send();
                }catch{
                    reject();
                }
            })
        },
        openDownloadXLSXDialog(url, saveName){
            if(typeof url == 'object' && url instanceof Blob){
                url = URL.createObjectURL(url); // 创建blob地址
            }
            var aLink = document.createElement('a');
            aLink.href = url;
            aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
            var event;
            if(window.MouseEvent) event = new MouseEvent('click');
            else{
                event = document.createEvent('MouseEvents');
                event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            }
            aLink.dispatchEvent(event);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    @import '~@/assets/css/form.css';
    @import '~@/assets/css/attachment.css';
    .projectType .btn{
        height: 2rem;
        padding: 0 2rem;
        margin-right: 3rem;
        border: none;
    }
    .projectType .btn.active{
        background: #C91E25;
        color: #ffffff;
    }
    .infosBox{
        height: calc(100% - 9rem);
    }
    .infosBox label{
        width: 8rem;
    }
    .group .radioBox label{
        width: 5rem;
        margin-right: 1.5rem;
        text-align: left;
        cursor: pointer;
    }
    .lvlGroup{
        margin: 0 0 0 5rem;
        width: calc(100% - 7rem);
        text-align: center;
        line-height: 1.6rem;
    }
    .lvlGroup .box{
        border: 1px solid #777;
        min-width: 4rem;
        width: 70%;
        float: left;
        height: 14rem;
    }
    .lvlGroup .box1{
        position: relative;
        display: flex;
    }
    .lvlGroup .box2{
        width: 15%;
        margin-left: 5%;
        /* padding-top: 2.4rem; */
    }
    .lvlGroup .box .oneLvl{
        flex: 1;
        max-width: 20%;
        min-width: 4rem;
    }
    .lvlGroup .box p{
        line-height: 2.4rem;
    }
    .lvlGroup .box ul{
        height: 10rem;
    }
    .lvlGroup .box li{
        border: 1px solid #ddd;
        border-radius: 3px;
        width: 80%;
        margin: .2rem auto;
    }
    .lvlGroup .box li.on{
        background: red;
        color: #fff;
    }
    .infosBox .group{
        line-height: 2.4rem;
    }
    .Indicators{
        width: calc(100% - 8rem);
        margin-left: 8rem;
    }
    .Indicators th,
    .Indicators tr{
        width: 100%;
        display: flex;
    }
    .Indicators th td,
    .Indicators tr td{
        flex: 10;
        text-align: center;
        border: 1px solid #ddd;
    }
</style>
