<template>
    <div class="container">
        <el-page-header @back="$router.back()" content="新建个人用户"></el-page-header>
        <div class="content">
            <el-form :model="form" ref="form" :rules="rules" size="mini" class="form" label-width="100px">
                <el-form-item label="手机号：" prop="phone" :error="phoneOrName.error">
                    <el-input v-model="form.phone"></el-input>
                </el-form-item>
                <el-form-item label="姓名：" prop="name" :error="phoneOrName.error">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="证件类型：" prop="identityType">
                    <el-select v-model="form.identityType">
                        <el-option v-for="(item,index) in identityTypeList" :value="item.type" :label="item.name" :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="证件号码：" prop="identityNo">
                    <el-input v-model="form.identityNo"></el-input>
                </el-form-item>
                <el-row>
                    <el-button type="primary" style="margin-left: 100px" @click="create"> 创建 </el-button>
                </el-row>
            </el-form>
            <template v-if="occupationList.length">
                <el-descriptions title="手机号已被此用户占用："></el-descriptions>
            </template>
            <template v-if="existUserList.length">
                <el-descriptions title="您要创建的用户可能已经存在："></el-descriptions>
            </template>
            <template  v-if="occupationList.length || existUserList.length">
                <el-table stripe style="width: 100%" :data="occupationList.length ? occupationList : existUserList" size="mini">
                    <el-table-column prop="UserId" label="用户ID" align="center"></el-table-column>
                    <el-table-column prop="Phone" label="手机号" align="center"></el-table-column>
                    <el-table-column prop="Name" label="姓名" align="center"></el-table-column>
                    <el-table-column label="证件号码（证件类型）" align="center">
                        <template slot-scope="scope" v-if="scope.row.IdentityNo">
                            <span v-if="scope.row.IdentityType == 1">{{scope.row.IdentityNo}}</span>
                            <span v-else>{{scope.row.IdentityNo}}（{{getInfo(scope.row.IdentityType, 'name')}}）</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="性别" align="center" width="50px">
                        <template slot-scope="scope">
                            {{['','男','女'][scope.row.Gender]}}
                        </template>
                    </el-table-column>
                    <el-table-column label="出生日期" align="center">
                        <template slot-scope="scope">
                            {{abnormalDate.get(scope.row.Birthday2)}}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" v-href="`/account/UserDetail/${scope.row.UserId}`">查看/修改</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
            <el-row style="margin-top: 20px" v-if="existUserList.length">
                <el-button type="primary" @click="continueCreate"> 都不是，继续创建 </el-button>
            </el-row>
        </div>
    </div>
</template>
<script>
import identityType from '@/utils/identityType.js';
import identityCard from '@/utils/identityCard.js';
import utils from '@/utils';

export default {
    name: 'CreateUser',

    data() {
        return {
            form:{
                phone: "",
                name: "",
                identityType: null,
                identityNo: ""
            },
            oldForm: null,
            phoneOrName: {
                error: ""
            },
            occupationList: [],
            existUserList: [],
            identityTypeList: identityType.list,
            abnormalDate: utils.abnormalDate
        };
    },
    created() {
        
    },
    computed:{
        rules(){
            return {
                phone: [
                    {validator: (rule, value, callback)=>{
                        if(value && (value.length != 11 || value[0] !== '1' || isNaN(Number(value)))){
                            callback(new Error('手机号格式不正确'));
                        }else{
                            callback();
                        }
                    }}
                ],
                name: [{min: 2, max: 50, message: '限制2～50个字符'}],
                identityNo: [{
                    validator: (rule, value, callback)=>{
                        if(value){
                            let type = this.form.identityType;
                            if(!type){
                                callback(new Error('请先选择证件类型'));
                            }else{
                                if(type == 1){
                                    let info = identityCard(value);
                                    if(info){
                                        let min = new Date('1900-01-01').getTime(),
                                            max = new Date().getTime(),
                                            current = new Date(info.dateBirth).getTime();
                                        if(current < min || current > max){
                                            callback(new Error('证件号出生日期不是有效日期'));
                                        }else{
                                            this.form.gender = info.sex;
                                            this.form.birthday = utils.dateFormat(new Date(info.dateBirth),'yyyy-MM-dd');
                                            callback();
                                        }
                                    }else{
                                        callback(new Error('证件号格式有误'));
                                    }
                                }else if(!value.match(this.cardInfo.RegEx)){
                                    callback(new Error('证件号格式有误'));
                                }else{
                                    callback();
                                }
                            }
                        }else{
                            callback();
                        }
                    }
                }]
            }
        }
    },
    watch: {
        'form.phone': {
            deep: true,
            handler(){
                this.phoneOrName.error = "";
            }
        },
        'form.name': {
            deep: true,
            handler(){
                this.phoneOrName.error = "";
            }
        }
    },
    methods: {
        getInfo: identityType.getInfo,
        create(){
            this.occupationList = [];
            this.existUserList = [];
            if(!this.form.phone && !this.form.name){
                this.phoneOrName.error = "手机号和姓名，必须至少输入一个！";
                return;
            }
            this.$refs.form.validate(valid=>{
                if(valid){
                    this.submit(this.form, false);
                }else{
                    this.$message.error('填写信息有误，请查看！');
                }
            })
        },
        continueCreate(){
            if(this.oldForm){
                this.submit(this.oldForm, true);
            }
        },
        submit(form, is){
            let loading = this.$loading({
                text: '创建中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.8)'
            })
            this.$api.CreateUserAccount({
                "Phone": form.phone,
                "Name": form.name,
                "IdentityType": form.identityType,
                "IdentityNo": form.identityNo,
                "IsForceCreate": is
            }).then(data=>{
                loading.close();
                if(data.Code == 0){
                    this.$message.success('创建成功');
                    this.$router.replace('/account/UserDetail/' + data.NewUserId);
                }else if(data.Code == 2212){
                    this.oldForm = {...this.form};
                    this.existUserList = data.ExistsUsers || [];
                }else if(data.Code == 2214){
                    this.occupationList = data.ExistsUsers || [];
                }
            })
        }
    },
};
</script>