<template>
    <router-view></router-view>
</template>

<script>
export default {
    name: 'Layout',
    components: {
    },
    created() {
    },
    mounted() {
    },
    updated() {
    },
    data(){
        return {
        }
    },
    methods: {
    }
}
</script>

<style scoped>
</style>