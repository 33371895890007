<template>
    <div class="view">
        <div class="srchBox clearfix">
            <div class="fl">
                <label for="">年度：</label>
                <date-picker v-model="point_year" type="year" value-type="format" format="YYYY" placeholder="选择年份"></date-picker>
            </div>
            <div class="fl ml_10">
                <label for="">竞赛项目：</label>
                <select name="" id="" v-model="events_id" @change="changeItem()">
                    <option value="0">请选择项目</option>
                    <option 
                        v-for="(item, i) in itemGroup" 
                        :key="i"
                        :value="item.events_id"
                        v-text="item.events_name"
                        ></option>
                </select>
            </div>
            <div class="fl ml_10">
                <label for="">分组：</label>
                <select v-model="point_group_id">
                    <option value="0">请选择分组</option>
                    <option 
                        v-for="(item, i) in groupList" 
                        :key="i"
                        :value="item.point_group_id"
                        v-text="item.point_group_name"
                        ></option>
                </select>
            </div>
            <div class="fl ml_10">
                <label for="">会员号：</label>
                <input type="text" name="" id="" v-model="member_no">
            </div>
            <div class="fl ml_10">
                <button class="btn" @click="srchHandle">查询</button>
            </div>
        </div>
        <div class="tab">
            <div class="tab_th clearfix">
                <div class="fl td_1">序号</div>
                <div class="fl td_1">年度</div>
                <div class="fl td_1">竞赛项目</div>
                <div class="fl td_2">分组</div>
                <div class="fl td_1">排名</div>
                <div class="fl td_3">名称</div>
                <div class="fl td_2">会员号</div>
                <div class="fl td_1">参赛数</div>
                <div class="fl td_2">积分</div>
                <div class="fl td_1">上期排名</div>
                <div class="fl td_2">操作</div>
            </div>
            <div class="box_tr" v-if="dataAll.length == 0">
                <p class="noData" v-text="listLoadTip"></p>
            </div>
            <div class="box_tr" v-else>
                <div class="tab_tr clearfix" v-for="(item, i) in dataAll" :key="i">
                    <div class="fl td_1">
                        <span v-text="item.rownum"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="item.point_year"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="item.events_name"></span>
                    </div>
                    <div class="fl td_2">
                        <span v-text="item.point_group_name"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="item.ranking"></span>
                    </div>
                    <div class="fl td_3">
                        <span v-text="item.names"></span>
                    </div>
                    <div class="fl td_2">
                        <span v-text="item.member_no.replace('/', ' /')"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="item.match_cnt || '-'"></span>
                    </div>
                    <div class="fl td_2">
                        <span v-text="item.cur_point"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="item.up_Ranking || '-'"></span>
                    </div>
                    <div class="fl td_2">
                        <span
                            v-for="(handle, j) in maskTit"
                            :key="j">
                           <a
                                href="javascript:;"
                                @click="showMask(j, i)"
                                v-text="handle"></a> 
                        </span>
                    </div>
                </div>
            </div>
            <PageHandle 
                :page="page"
                :totalPage="totalPage"
                :totalCount="totalCount"
                @pageTo="pageTo($event)" 
                />
        </div>
        <div class="maskBox" v-show="maskTag > 0">
            <div class="maskCont">
                <h3 v-text="maskTit[maskInfo.type]"></h3>
                <div class="mask1" v-show="maskTag == 1">
                    <div>
                        <label for="">积分年度：</label>
                        <p v-text="maskInfo.point_year"></p>
                    </div>
                    <div>
                        <label for="">竞赛项目：</label>
                        <p v-text="maskInfo.events_name"></p>
                    </div>
                    <div>
                        <label for="">积分分组：</label>
                        <p v-text="maskInfo.point_group_name"></p>
                    </div>
                    <div>
                        <label for="">会员号：</label>
                        <p v-text="maskInfo.member_no"></p>
                    </div>
                    <div>
                        <label for="">会员名称：</label>
                        <p v-text="maskInfo.names"></p>
                    </div>
                    <div>
                        <label for="" v-text="maskTit[maskInfo.type] + '积分：'"></label>
                        <input v-model="maskInfo.point" type="number" placeholder="请输入大于0的积分值">
                    </div>
                    <div>
                        <label for="" v-text="maskTit[maskInfo.type] + '原因：'"></label>
                        <textarea name="" id="" cols="30" rows="10" v-model="maskInfo.reason"></textarea>
                    </div>
                    <div class="maskBtnBox">
                        <div class="maskHint fl" v-text="maskHint"></div>
                        <button @click="setMask(-1)">取消</button>
                        <button @click="exSubmit()">确定</button>
                    </div>
                </div>
                <div v-show="maskTag == 2">
                    <div>确认提交？</div>
                    <div class="maskBtnBox">
                        <div class="maskHint fl" v-text="maskHint"></div>
                        <button @click="setMask(1)">取消</button>
                        <button @click="submit()">提交</button>
                    </div>
                </div>
            </div>
        </div>
        <TipHandle 
            :txt="tip"
            :btnTxt="btnTxt"
            @maskHandle="maskHandle($event)" 
            />
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/zh-cn'
import PageHandle from '@/components/PageHandle.vue'
import TipHandle from '@/components/TipHandle.vue'
import { toQueryString } from '@/assets/js/util'

export default {
    name: 'Manage',
    components: {
        DatePicker,
        PageHandle,
        TipHandle
    },
    props: {
    },
    data() {
        return {
            token: this.$cookies.get('token'),
            point_year: '',
            events_id: 0,
            point_group_id: 0,
            member_no:'',
            page: 1,
            count: 20,
            totalPage: 1,
            totalCount: 1,
            tip: '',
            btnTxt: [],
            dataAll:[],
            maskTit:{
                1: '奖励',
                2: '处罚'
            },
            maskInfo: {
                type: 1,
                item: '',
                group: '',
                vipNum: '',
                vipName: '',
                point: '',
                reason: ''
            },
            itemGroup: [],
            groupList: [],
            listLoadTip: '',
            maskTag: -1,
            maskHint: '',
            maskFlag: true,
            getListFlag: true,
        }
    },
    mounted() {
        this.getList()
        this.getItemGroup()
    },
    methods: {
        handleTips(txt, btnTxt){
            this.tip = txt
            this.btnTxt = btnTxt
        },
        maskHandle(btn){
            if(btn == 0){
                // 确认发布
            }
            this.tip = ''
        },
        pageTo(page){
            if(!this.getListFlag) {
                return
            }
            if(page == '-' && this.page > 1) {
                this.page --
                this.getList()
            } else if(page == '+' && this.page < this.totalPage){
                this.page ++
                this.getList()
            } else if(page != this.page && typeof page == 'number') {
                if(page > this.totalPage || page < 1) {
                    this.handleTips('请输入1~' + this.totalPage + '的数', ['关闭'])
                    return
                }
                this.page = page
                this.getList()
            }
            
        },
        getHandleList(status){
            let arr = []
            if(status==1){
                arr = [
                    {to: '1', txt: '奖励'},
                    {to: '2', txt: '处罚'},
                ]
            }
            return arr
        },
        srchHandle() {
            if(!this.getListFlag) {
                return
            }
            this.page = 1
            this.getList()
        },
        showMask(type, idx){
            let _infos = this.dataAll[idx]
            this.maskInfo =  {
                type,
                point_year: _infos.point_year,
                events_name: _infos.events_name,
                events_id: _infos.events_id,
                point_group_name: _infos.point_group_name,
                point_group_id: _infos.point_group_id,
                member_no: _infos.member_no,
                names: _infos.names,
                point: '',
                reason: ''
            }
            this.maskTag = 1
        },
        setMask(type) {
            this.maskTag = type
            this.maskHint = ''
        },
        exSubmit(){
            if(this.maskInfo.point == ''){
                this.maskHint = '请输入' + this.maskTit[this.maskInfo.type] + '积分'
                return
            }
            this.setMask(2)
        },
        submit(){
            if(this.maskFlag){
                this.maskFlag = false
            } else {
                return
            }
            this.maskHint = '提交中...'
            this.$axios.post('Match/Entrance',toQueryString({
                action: 'ADD_point_reward',
                user_token:this.token,
                point_year: this.maskInfo.point_year,
                events_id: this.maskInfo.events_id,
                point_group_id: this.maskInfo.point_group_id,
                type: this.maskInfo.type,
                point: this.maskInfo.point,
                member_no: this.maskInfo.member_no,
                names: this.maskInfo.names,
                reason: this.maskInfo.reason
            })).then((res) => {
                this.maskFlag = true
                let data = res.data;
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                if(data.code == 1){
                    this.setMask(-1)
                    this.page = 1
                    this.getList()
                    this.handleTips('提交成功', ['关闭'])
                } else {
                    this.maskHint = data.message
                }
            }).catch(() => {
                this.maskHint = '网络异常'
                this.maskFlag = true
            })
        },
        // 积分管理列表
        getList(){
            if(this.getListFlag) {
                this.getListFlag = false
            } else {
                return
            }
            this.dataAll = []
            this.listLoadTip = '数据加载中...'
            this.$axios.post('Match/Entrance',toQueryString({
                action: 'Get_point',
                user_token:this.token,
                point_year: this.point_year || '',
                events_id: this.events_id,
                point_group_id: this.point_group_id,
                type: 1,
                member_no: this.member_no || 0,
                page: this.page,
                count: this.count
            })).then((res) => {
                this.getListFlag = true
                let data = res.data;
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                if(data.code == 1){
                    if(data.NewList.length == 0){
                        this.listLoadTip = '无数据'
                    }
                    this.dataAll = data.NewList
                    this.totalCount = data.totalCount
                    this.totalPage = Math.ceil(data.totalCount/this.count)
                } else {
                    this.listLoadTip = data.message
                }
            }).catch(() => {
                this.listLoadTip = '网络异常'
                this.getListFlag = true
            })
        },
        // 获取竞赛项目分组
        getItemGroup(){
            this.$axios.post('Match/Entrance',toQueryString({
                action: 'Get_point_league_events_group',
                user_token:this.token
            })).then((res) => {
                let data = res.data;
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                if(data.code == 1){
                    this.itemGroup = data.NewList
                } else {
                    this.handleTips(data.message, ['关闭'])
                }
            }).catch(() => {
                this.handleTips('网络异常', ['关闭'])
            })
        },
        // 切换查询项目
        changeItem(){
            if(this.events_id > 0){
                let _epgList = this.get_epgList(this.events_id)
                if(_epgList.length > 0){
                    this.groupList = _epgList
                } else {
                    this.groupList = []
                }
            } else {
                this.groupList = []
            }
            this.point_group_id = 0
        },
        get_epgList(id) {
            let _arr = []
            for(let i in this.itemGroup) {
                if(this.itemGroup[i].events_id == id) {
                    _arr = this.itemGroup[i].epgList
                    break
                }
            }
            return _arr
        }
    }
}
</script>

<style scoped>
@import '~@/assets/css/common_z.css';
    .mx-datepicker{width: 100px;}
    .srchBox{
        height: 3rem;
        line-height: 3rem;
    }
    .srchBox input, .srchBox select{
        height: 2rem;
    }
    .tab{
        width: 100%;
        height: calc(100% - 3rem);
    }
    .maskBox{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, .5);
    }
    .maskBox .maskCont{
        position: absolute;
        width: 40%;
        min-width: 35rem;
        top: 20%;
        border: 1px solid #888;
        left: 50%;
        transform: translateX(-50%);
        background: #fff;
        padding: 1rem 2rem;
    }
    .maskBox .mask1{
        padding: 1rem 0 0 2rem;
        line-height: 2rem;
    }
    .maskBox .mask1 label{
        float: left;
        width: 5rem;
    }
    .maskBtnBox{
        text-align: right;
        margin: 1rem 0 0;
    }
    .maskBtnBox .maskHint{
        color: red;
    }
    .maskBtnBox button{
        margin: 0 1rem;
    }
</style>
