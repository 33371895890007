<template>
    <div class="container">
        <el-page-header @back="$router.back()" :content="['创建培训','编辑培训信息'][this.type]"></el-page-header>
        <div class="content">
            <el-form :model="form" ref="form" :rules="rules" size="mini" class="form" label-width="100px">
                <el-form-item label="运动项目：" prop="eventsId">
                    <el-select v-model="form.eventsId" @change="eventsChange">
                        <el-option v-for="(item,index) in eventsList" :value="item.Id" :label="item.Name" :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="培训类型：" prop="trainType">
                    <el-radio-group v-model="form.trainType" @change="trainTypeChange">
                        <el-radio :label="1">裁判员培训</el-radio>
                        <el-radio :label="2">教练员培训</el-radio>
                        <el-radio :label="3">裁判员和教练员培训</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="培训全称：" prop="trainFullName">
                    <el-input v-model="form.trainFullName" style="max-width:500px"></el-input>
                </el-form-item>
                <el-form-item label="培训简称：" prop="name">
                    <el-input v-model="form.name" style="max-width:500px"></el-input>
                </el-form-item>
                <el-form-item label="培训形式：" prop="trainForm">
                    <el-radio-group v-model="form.trainForm">
                        <el-radio :label="1">线上培训</el-radio>
                        <el-radio :label="2">线下培训</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="培训封面：">
                    <image-upload
                        :image-url.sync="form.cover"
                        :oss-config="ossConfig"
                        :fileSize="1 * 1024 * 1024"
                    >
                        <template slot="tip">
                            <p>只能上传jpg/png格式，长宽比例：375×211，大小不超过 1MB，</p>
                            <p>建议大小 0.5MB，分辨率：1080×608 或 1440×810</p>
                        </template>
                    </image-upload>
                </el-form-item>
                <el-form-item label="培训时间：" prop="trainTime">
                    <el-date-picker
                        v-model="form.trainTime"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-ddTHH:mm:ss">
                    </el-date-picker>
                </el-form-item>
                <el-card shadow="never">
                    <el-form-item label="培训地点:" prop="address" :required="this.form.trainForm == 2">
                        <el-row>
                            <el-select 
                                v-model="form.provinceCode" 
                                @change="getCity" 
                                placeholder="请选择省"
                                :loading="province.loading"
                                style="width: calc((100% - 20px) / 3);max-width: 200px;margin-right: 10px">
                                <el-option v-for="(item,index) in province.list" :key="index" :value="item.Code" :label="item.Name"></el-option>
                            </el-select>
                            <el-select 
                                v-model="form.cityCode" 
                                @change="getArea" 
                                placeholder="请选择市" 
                                :loading="city.loading"
                                style="width: calc((100% - 20px) / 3);max-width: 200px;margin-right: 10px">
                                <el-option v-for="(item,index) in city.list" :key="index" :value="item.Code" :label="item.Name"></el-option>
                            </el-select>
                            <el-select 
                                v-model="form.areaCode" 
                                placeholder="请选择区县" 
                                :loading="area.loading"
                                style="width: calc((100% - 20px) / 3);max-width: 200px">
                                <el-option v-for="(item,index) in area.list" :key="index" :value="item.Code" :label="item.Name"></el-option>
                            </el-select>
                        </el-row>
                        <el-row style="margin-top: 10px">
                            <el-input v-model="form.address" placeholder="详细地址" style="max-width: initial"></el-input>
                        </el-row>
                    </el-form-item>
                </el-card>
                <el-card shadow="never" v-for="(unitItem,unitIndex) in form.units" :key="unitIndex">
                    <el-form-item :label="`已添加${unitItem.name}:`" label-width="150px" :prop="`units.${unitIndex}.list`"
                        :inline-message="true"
                        :rules="unitItem.required ? [
                            {required:true,validator:(rule, value, callback)=>{
                                if(!value || value.length == 0){
                                    callback('请添加'+unitItem.name);
                                }else{
                                    callback();
                                }
                            }}
                        ] : null" 
                        style="margin-bottom:0"
                    >
                        <div style="width:100%;max-width:500px">
                            <el-tag
                                v-for="(item,index) in unitItem.list" :key="index"
                                :closable="type != 2"
                                disable-transitions effect="plain"  
                                @close="removeUnit(unitIndex,index)" 
                            >
                                <span>{{item}}</span>
                            </el-tag>
                        </div>
                    </el-form-item>
                    <el-form-item :required="unitItem.required" label-width="150px" style="margin-bottom:0">
                        <template slot="label">
                            {{unitItem.name}}<span style="color:#409EFF;font-weight:blod">{{unitItem.list.length + 1}}</span>:
                        </template>
                        <el-input v-model.trim="unitItem.value" :placeholder="unitItem.name + '名称'" style="width:calc(100% - 90px);max-width:400px"></el-input>
                        <el-button type="primary" style="margin-left:10px" @click="addUnit(unitIndex)"  :title="'点击添加'+unitItem.name"><i class="el-icon-plus"></i> 添加</el-button>
                    </el-form-item>
                </el-card>
                <el-form-item label="培训详情:" prop="detail">
                    <Editor v-model="form.detail" :oss-options="ossConfig"></Editor>
                </el-form-item>
                <el-divider></el-divider>
                <div style="margin-bottom:20px">培训内容</div>
                <template v-if="form.competitions.length > 0">
                    <el-card shadow="never" v-for="(competitionItem,competitionIndex) in form.competitions" :key="competitionIndex + '1'" style="width:100%;max-width:700px;box-sizing: border-box;margin-bottom:20px">
                        <el-form-item label="培训项目:" :prop="`competitions.${competitionIndex}.eventsId`" :rules="competitionRules.eventsId" label-width="100px">
                            <el-row type="flex" justify="space-between">
                                <el-select v-model="competitionItem.eventsId" placeholder="请选择">
                                    <el-option v-for="(item,index) in classForm.eventsList" :key="index" 
                                    :value="item.Id"
                                    :label="item.Name"
                                    :disabled="item.Id != competitionItem.eventsId && repetition(item.Id)"
                                    @change="itemEventsChange(competitionItem.eventsId)"></el-option>
                                </el-select>
                                <el-button type="danger" @click="removeClassEvent(competitionIndex)" v-show="form.competitions.length > 1"><i class="el-icon-minus"></i> 删除此培训项目</el-button>
                            </el-row>
                        </el-form-item>
                        <el-form-item label="已添加:" :prop="`competitions.${competitionIndex}.classList`" :rules="competitionRules.classList" label-width="100px" style="width:100%;" >
                            <el-tag
                                v-for="(item,index) in competitionItem.classList" :key="index"
                                :closable="type != 2"
                                disable-transitions effect="plain"
                                @close="removeClass(competitionIndex,index,competitionItem.eventsId)" 
                            >
                                <span>{{item.name}}</span>
                            </el-tag>
                        </el-form-item>
                        <el-form-item label="快捷添加:" style="width:100%;margin-bottom:10px" v-show="classForm.presetClass[competitionItem.eventsId]">
                            <div style="max-height:100px;overflow-y: auto;">
                                <el-checkbox-group v-model="classForm.checkList">
                                    <el-checkbox v-for="(item,index) in classForm.presetClass[competitionItem.eventsId]" :key="index"
                                        :label="competitionItem.eventsId + item.name"
                                        @change="presetClass($event,competitionIndex,item)"
                                        style="height:24px"
                                    >{{item.name}}</el-checkbox>
                                </el-checkbox-group>
                            </div>
                        </el-form-item>
                        <el-divider class="divider" content-position="left" >第 <span style="color:#409EFF;font-weight:blod">{{competitionItem.classList.length+1}}</span> 项</el-divider>
                        <div>
                            <el-form ref="classForm" :model="classForm" :disabled="type == 2" label-width="100px" size="small" :key="competitionIndex">
                                <el-form-item label="名称:" :prop="`classValues.${competitionIndex}.name`" 
                                    :rules="[...classRules.name,{validator:(rule, value, callback)=>{
                                        let filter = competitionItem.classList.filter(classItem=>classItem.name == value);
                                        if(filter.length){
                                            callback('已存在相同名称的培训内容');
                                        }else{
                                            callback();
                                        }
                                    }}]" 
                                >
                                    <el-input v-model.trim="classForm.classValues[competitionIndex].name" maxlength="20" placeholder="请输入名称" style="width:100%;max-width:400px"></el-input>
                                </el-form-item>
                                <el-form-item label="类型:" :prop="`classValues.${competitionIndex}.type`" :rules="classRules.type">
                                    <el-radio-group v-model="classForm.classValues[competitionIndex].type" :disabled="form.trainType != 3">
                                        <el-radio :label="1" v-if="[1,3].includes(form.trainType)">裁判员培训</el-radio>
                                        <el-radio :label="2" v-if="[2,3].includes(form.trainType)">教练员培训</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="级别:" :prop="`classValues.${competitionIndex}.levelNo`" :rules="classRules.levelNo">
                                    <el-select v-model="classForm.classValues[competitionIndex].levelNo">
                                        <template v-if="competitionItem.eventsId > 0 && classForm.classValues[competitionIndex].type">
                                            <el-option v-for="(item,index) in getLevel(competitionItem.eventsId, classForm.classValues[competitionIndex].type)" :value="item.LevelNo" :label="item.LevelName" :key="index"></el-option>
                                        </template>
                                        <template v-else>
                                            <el-option disabled readonly value="''">{{competitionItem.eventsId > 0 ? '请先选择类型' : '请先选择培训项目'}}</el-option>
                                        </template>
                                    </el-select>
                                </el-form-item>
                                <el-button type="primary" size="small" @click="addClass(competitionIndex)" style="margin-left:100px" title="点击添加"><i class="el-icon-plus"></i> 添加</el-button>
                            </el-form>
                        </div>
                    </el-card>
                </template>
                <el-row style="margin-top:10px" v-show="classForm.eventsList.length > form.competitions.length">
                    <el-button type="primary" size="medium" style="width:150px" @click="addClassEvents()"><i class="el-icon-plus"></i> 添加培训项目</el-button>
                </el-row>
            </el-form>
            <el-divider></el-divider>
            <el-row style="margin:30px 0 20px 100px">
                <el-button type="success" style="width:100px" @click="save()">保存</el-button>
            </el-row>
        </div>
    </div>
</template>
<script>
import imageUpload from '@/components/imageUpload.vue';
import Editor from '@/components/Editor';
export default {
    name: 'trainingManagementDetail',
    components: {
        imageUpload,
        Editor
    },
    data() {
        let trainId = +this.$route.params.id;
        return {
            trainId,
            type: trainId == 0 ? 0 : 1,
            form: {
                eventsId: null,
                trainType: 3,
                trainFullName: "",
                name: "",
                trainForm: 2,
                cover: "",
                trainTime: [],
                provinceCode: null,
                cityCode: null,
                areaCode: null,
                address: "",
                units:[{
                        name:'主办单位',
                        required:true,
                        value:'',
                        list:[]
                    },{
                        name:'支持/指导单位',
                        value:'',
                        list:[]
                    },{
                        name:'承办单位',
                        required:true,
                        value:'',
                        list:[]
                    },{
                        name:'运营单位',
                        value:'',
                        list:[]
                    },{
                        name:'协办单位',
                        value:'',
                        list:[]
                    },{
                        name:'赞助单位',
                        value:'',
                        list:[]
                    }
                ],
                detail:"",
                competitions:[]
            },
            eventsList: [],
            classForm:{
                eventsList:[],
                presetClass:{},
                checkList:[],
                classValues:[]
            },
            levelObjs: {},
            province:{
                loading: false,
                list: []
            },
            city:{
                loading: false,
                list: []
            },
            area:{
                loading: false,
                list: []
            },
            ossConfig: {}
        };
    },

    created() {
        this.$api.GetFlattedEventsList({
            'AllName': null
        }).then(data=>{
            if(data.Code == 0){
                this.eventsList = data.EventsList || [];
            }
        })
        this.$api.GetTrainManageInit({
            "TrainId": this.trainId
        }).then(data=>{
            if(data.Code == 0){
                if(!this.type){
                    this.trainId = data.NextTrainId;
                }
                this.ossConfig = data.UploadOptions;
            }
        })
        if(this.type){
            this.getDetailData();
        }else{
            this.getProvince();
        }
    },
    computed:{
        rules(){
            return {
                eventsId: [{required:true,message:"请选择培训项目"}],
                trainType: [{required:true,message:"请选择培训类型"}],
                trainFullName: [
                    {required:true,message:"请输入培训全称",},
                    {max: 50,message:"最大 50 个字符"}
                ],
                name: [{max: 32,message:"最大 32 个字符"}],
                trainForm: [{required:true,message:"请选择培训形式"}],
                trainTime: [
                    {required:true,validator:(rule, value, callback)=>{
                        if(!value || value.length == 0 || !value[0] || !value[1]){
                            callback(new Error('请选择培训时间'));
                        }else{
                            callback();
                        }
                    }}
                ],
                address: [
                    {max: 50,message:"最大 50 个字符"},
                    {validator: (rule, value, callback)=>{
                        let form = this.form;
                        if(form.trainForm == 2){
                            if(!form.provinceCode || !form.cityCode || !form.areaCode){
                                callback(new Error('请选择培训地点'));
                            }else if(!form.address){
                                callback(new Error('请输入详细地址'));
                            }else{
                                callback();
                            }
                        }else{
                            callback();
                        }
                    }
                }],
                detail: {required:true,message:"请输入培训详情"}
            }
        },
        competitionRules(){
            return {
                eventsId:[
                    {required:true,message:"请选择培训项目"}
                ],
                classList:[
                    {required:true,validator:(rule, value, callback)=>{
                        if(!value || value.length == 0){
                            callback(new Error('请添加培训内容'));
                        }else{
                            callback();
                        }
                    }}
                ]
            }
        },
        classRules(){
            return {
                name:[
                    {required:true,message:"请输入名称"},
                    {max:20,message:"名称最长20个字符"}
                ],
                type:[
                    {required:true,message:"请选择类型"}
                ],
                levelNo: [{required:true,message:"请选择级别"}]
            }
        }
    },
    methods: {
        getDetailData(){
            var loading = this.$loading({
                text: '数据读取中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.8)'
            })
            this.$api.GetTrainDetail({
                "TrainId": this.trainId
            }).then(data=>{
                loading.close();
                if(data.Code == 0){
                    let detail = data.TrainDetail
                    let form = this.form;
                    let {
                        EventsId,
                        TrainType,
                        TrainForm,
                        TrainFullName,
                        TrainCover
                    } = detail.TrainDescription;
                    form.eventsId = EventsId;
                    form.trainType = TrainType;
                    form.trainFullName = TrainFullName;
                    form.name = detail.TrainName;
                    form.trainForm = TrainForm;
                    form.cover = TrainCover;
                    let {
                        BeginTime,
                        EndTime
                    } = detail.TrainTime;
                    form.trainTime = [BeginTime, EndTime];
                    let {
                        Region,
                        Address
                    } = detail.TrainPlace;
                    form.provinceCode = Region.ProvinceCode;
                    form.cityCode = Region.CityCode;
                    form.areaCode = Region.AreaCode;
                    form.address = Address;
                    let {
                        Hosts,
                        Supporters,
                        Undertakers,
                        Operators,
                        Assists,
                        Sponsors,
                    } = detail.TrainUnits;
                    let units = form.units;
                    units[0].list = Hosts || [];
                    units[1].list = Supporters || [];
                    units[2].list = Undertakers || [];
                    units[3].list = Operators || [];
                    units[4].list = Assists || [];
                    units[5].list = Sponsors || [];
                    form.detail = detail.Descs;
                    let classList = detail.TrainClass;
                    let competitions = [];
                    let classValues = [];
                    for(var i=0;i<classList.length;i++){
                        let obj = classList[i];
                        let classObj = {
                            id: obj.ClassId,
                            name: obj.ClassName,
                            type: obj.ClassType,
                            levelNo: obj.ClassLevel,
                            levelName: obj.ClassLevelName
                        };
                        let index = -1;
                        for(var j=0;j<competitions.length;j++){
                            if(competitions[j].eventsId == obj.EventsId){
                                index = j;
                                break;
                            }
                        }
                        if(index > -1){
                            competitions[index].classList.push(classObj);
                        }else{
                            competitions.push({
                                eventsId: obj.EventsId,
                                classList: [classObj]
                            })
                            classValues.push({
                                name: "",
                                type: TrainType == 3 ? null : TrainType,
                                levelNo: null
                            })
                            this.itemEventsChange(obj.EventsId);
                        }
                    }
                    form.competitions = competitions;
                    this.$set(this.classForm,'classValues',classValues);
                    if(this.eventsList.length > 0){
                        this.setClassEvents(1, EventsId);
                    }
                    this.getProvince(true);
                    this.getCity(true);
                    this.getArea(true);
                }
            }).catch(()=>{
                loading.close();
            })
        },
        eventsChange(){
            let eventsId = this.form.eventsId;
            this.setClassEvents(2, eventsId);
        },
        trainTypeChange(){
            this.setClassEvents(2, this.form.eventsId);
            this.$nextTick(function(){
                this.$refs['classForm'][0].clearValidate();
            });
        },
        addUnit(index){
            let {value,list,required} = this.form.units[index];
            if(value){
                list.push(value);
                this.$set(this.form.units[index],'list',list);
                this.$set(this.form.units[index],'value','');
                if(required){
                    this.$refs['form'].validateField(`units.${index}.list`);
                }
            }
        },
        removeUnit(index,deleteIndex){
            let {list,required} = this.form.units[index];
            list.splice(deleteIndex,1);
            this.$set(this.form.units[index],'list',list);
            if(required){
                this.$refs['form'].validateField(`units.${index}.list`);
            }
        },

        setClassEvents(type, id){
            var eventsList = this.eventsList;
            if(id == 8 || id == 3){
                this.classForm.eventsList = eventsList.filter(item=>item.Id.toString()[0] == id);
            }else{
                let obj = eventsList.filter(item=>item.Id == id)[0];
                this.classForm.eventsList = [obj];
            }
            if(type == 2){
                this.form.competitions = [];
                this.classForm.classValues = [];
                this.addClassEvents();
            }
        },
        addClassEvents(){
            var arr = this.form.competitions;
            var eventsList = this.classForm.eventsList;
            var obj = {};
            if(arr.length == eventsList.length){
                return false;
            }
            if(arr.length > 0){
                for(var i=0;i<eventsList.length;i++){
                    if(arr.filter(n=>n.eventsId == eventsList[i].Id).length == 0){
                        obj = eventsList[i];
                        break;
                    }
                }
            }else{
                obj = eventsList[0];
            }
            arr.push({
                eventsId: obj.Id,
                classList:[]
            })
            this.$set(this.form,'competitions',arr);
            let classValues = this.classForm.classValues;
            let trainType = this.form.trainType;
            classValues.push({
                name: "",
                type: trainType == 3 ? null : trainType,
                levelNo: null
            })
            this.$set(this.classForm,'classValues',classValues);
            this.itemEventsChange(obj.Id);
        },
        removeClassEvent(index){
            this.$confirm('请确认删除此培训内容').then(() => {
                let competitions = this.form.competitions;
                competitions.splice(index,1);
                let classValues = this.classForm.classValues;
                classValues.splice(index,1);
            })
        },
        itemEventsChange(eventsId){
            let levelObjs = this.levelObjs;
            if([1,3].includes(this.form.trainType)){
                if(!levelObjs[eventsId] || !levelObjs[eventsId]['1']){
                    this.getRefereeLevel(eventsId);
                }
            }
            if([2,3].includes(this.form.trainType)){
                if(!levelObjs[eventsId] || !levelObjs[eventsId]['2']){
                    this.getCoachLevel(eventsId);
                }
            }
        }, 
        addClass(index){
            this.$refs['classForm'][index].validate((valid)=>{
                if(valid){
                    let competitionItem = this.form.competitions[index];
                    let {name, type, levelNo} = this.classForm.classValues[index];
                    let levelObj = this.levelObjs[competitionItem.eventsId] || {},
                        levels = levelObj[type] || [];
                    let classList = competitionItem.classList;
                    let levelName = levels.filter(item=>item.LevelNo == levelNo)[0].LevelName;
                    classList.push({
                        name,
                        type,
                        levelNo,
                        levelName
                    });
                    this.$set(competitionItem,'classList',classList);
                    let trainType = this.form.trainType;
                    this.$set(this.classForm.classValues, index, {
                        name: "",
                        type: trainType == 3 ? null : trainType,
                        levelNo: null
                    });
                    this.$nextTick(function(){
                        this.$refs['form'].validateField(`competitions.${index}.classList`);
                        this.$refs['classForm'][index].clearValidate();
                    })
                }
            })
        },
        removeClass(index,deleteIndex,eventsId){
            var classList = this.form.competitions[index].classList;
            var obj = classList.splice(deleteIndex,1)[0];
            this.$set(this.form.competitions[index],'classList',classList);
            this.$refs['form'].validateField(`competitions.${index}.classList`);
            let is = true;
            for(let i=0;i<classList.length;i++){
                if(classList[i].name == obj.name){
                    is = false;
                    break;
                }
            }
            if(is){
                let checkList = this.classForm.checkList;
                for(let i=0;i<checkList.length;i++){
                    if(checkList[i] == eventsId + obj.name){
                        checkList.splice(i,1);
                        i--;
                    }
                }
                this.$set(this.classForm,'checkList',checkList);
            }
        },
        presetClass(checked,index,item){
            var classList = this.form.competitions[index].classList;
            var {name,type,levelNo,levelName} = item; 
            if(checked){
                let filter = classList.filter(classItem=>classItem.name == name);
                if(filter.length){
                    this.$message.error('已存在相同名称的培训内容')
                    return ;
                }
                classList.push({
                    name,
                    type,
                    levelNo,
                    levelName
                })
            }else{
                for(var i=0;i<classList.length;i++){
                    if(classList[i].name == name){
                        classList.splice(i,1);
                        i--;
                    }
                }
            }
            this.$set(this.form.competitions[index],'classList',classList);
            this.$refs['form'].validateField(`competitions.${index}.classList`);
        },
        repetition(id){
            return this.form.competitions.filter(n=>n.eventsId == id).length > 0;
        },
        getLevel(eventsId, type){
            let levelObjs = this.levelObjs;
            let levelObj = levelObjs[eventsId] || {},
                levels = levelObj[type] || [];
            return levels;
        },
        getRefereeLevel(eventsId){
            let levelObjs = this.levelObjs;
            this.$api.GetRefereeLevelDictionary({
                "EventsId": eventsId
            }).then(data=>{
                if(data.Code == 0){
                    levelObjs[eventsId] = levelObjs[eventsId] || {};
                    levelObjs[eventsId]['1'] = data.Levels || [];
                }
            });
        },
        getCoachLevel(eventsId){
            let levelObjs = this.levelObjs;
            this.$api.GetCoachLevelDictionary({
                "EventsId": eventsId
            }).then(data=>{
                if(data.Code == 0){
                    levelObjs[eventsId] = levelObjs[eventsId] || {};
                    levelObjs[eventsId]['2'] = data.Levels || [];
                }
            });
        },
        //获取省
        getProvince(isInit) {
            this.province.loading = true;
            this.$api.GetProvinceList().then(data => {
                this.province.loading = false;
                if(data.Code == 0){
                    let list = data.ProvinceList || [];
                    this.province.list = list;
                    if(isInit != true){
                        this.city.list = [];
                        this.area.list = [];
                        this.form.provinceCode = null;
                        this.form.cityCode = null;
                        this.form.areaCode = null;
                    }
                }
            })
        },
        //获取市
        getCity(isInit) {
            if(!this.form.provinceCode)return;
            this.city.loading = true;
            this.$api.GetCityList({
                "ProvinceCode": this.form.provinceCode
            }).then(data => {
                this.city.loading = false;
                if(data.Code == 0){
                    let list = data.CityList || [];
                    this.city.list = list;
                    if(isInit != true){
                        this.area.list = [];
                        this.form.cityCode = null;
                        this.form.areaCode = null;
                    }
                }
            })
        },
        //获取区县
        getArea(isInit) {
            if(!this.form.cityCode)return;
            this.area.loading = true;
            this.$api.GetAreaList({
                "CityCode": this.form.cityCode
            }).then(data => {
                this.area.loading = false;
                if(data.Code == 0){
                    let list = data.AreaList || [];
                    this.area.list = list;
                    if(isInit != true){
                        this.form.areaCode = null;
                    }
                }
            })
        },
        save(){
            this.$refs['form'].validate((valid)=>{
                if(valid){
                    let trainId = this.trainId;
                    let form = this.form;
                    let eventsList = this.eventsList;
                    let competitions = form.competitions;
                    let trainClassList = [];
                    for(var i=0;i<competitions.length;i++){
                        let obj = competitions[i];
                        let classList = obj.classList;
                        let eventsId = obj.eventsId;
                        let eventsName = eventsList.filter(n=>n.Id == eventsId)[0].Name;
                        for(var j=0;j<classList.length;j++){
                            let classObj = classList[j];
                            trainClassList.push({
                                "ClassId": classObj.id || 0,
                                "ClassName": classObj.name,
                                "EventsId": eventsId,
                                "EventsName": eventsName,
                                "TrainId": trainId,
                                "ClassType": classObj.type,
                                "ClassLevel": classObj.levelNo,
                                "LevelName": classObj.levelName
                            })
                        }
                    }
                    let eventsId = form.eventsId;
                    let eventsName = eventsList.filter(item=>item.Id == eventsId)[0].Name;
                    let RegionName = "";
                    RegionName += (this.province.list.filter(item=>item.Code == form.provinceCode)[0] || {}).Name || '';
                    RegionName += (this.city.list.filter(item=>item.Code == form.cityCode)[0] || {}).Name || '';
                    RegionName += (this.area.list.filter(item=>item.Code == form.areaCode)[0] || {}).Name || '';
                    let sendData = {
                        "EditInfo": {
                            "TrainId": trainId,
                            "TrainDescription": {
                                "Name": form.name || form.trainFullName,
                                "EventsId": eventsId,
                                "TrainType": form.trainType,
                                "TrainForm": form.trainForm,
                                "TrainFullName": form.trainFullName,
                                "TrainCover": form.cover,
                                "TrainCoverThumbnail": "",
                                "EventsName": eventsName
                            },
                            "TrainName": form.name,
                            "TrainLogo": "",
                            "TrainTime": {
                                "BeginTime": form.trainTime[0],
                                "EndTime": form.trainTime[1]
                            },
                            "TrainPlace": {
                                "Country": "",
                                "Region": {
                                    "ProvinceCode": form.provinceCode,
                                    "CityCode": form.cityCode,
                                    "AreaCode": form.areaCode,
                                    "RegionName": RegionName
                                },
                                "Address": form.address,
                                "PostCode": ""
                            },
                            "TrainUnits": {
                                "Hosts": form.units[0].list,
                                "Supporters": form.units[1].list,
                                "Undertakers": form.units[2].list,
                                "Operators": form.units[3].list,
                                "Assists": form.units[4].list,
                                "Sponsors": form.units[5].list
                            },
                            "Descs": form.detail,
                            "TrainClass": trainClassList
                        }
                    }
                    let loading = this.$loading({
                        text:"保存中",
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    })
                    if(this.type == 0){
                        this.$api.CreateTrain(sendData).then(data=>{
                            loading.close();
                            if(data.Code == 0){
                                this.$message.success('培训创建成功，2秒后返回列表');
                                setTimeout(()=>{
                                    this.$router.back();
                                },1500)
                            }
                        }).catch(()=>{
                            loading.close();
                        })
                    }else{
                        this.$api.AlterTrain(sendData).then(data=>{
                            loading.close();
                            if(data.Code == 0){
                                this.$message.success('培训修改成功，2秒后返回列表');
                                setTimeout(()=>{
                                    this.$router.back();
                                },1500)
                            }
                        }).catch(()=>{
                            loading.close();
                        })
                    }
                }else{
                    this.$message.error('填写信息有误，请查看！');
                }
            })
        }
    },
};
</script>
<style scoped>
@import "~@/assets/css/clear-el.css";
</style>
