<template>
    <div class="container">
        <el-form label-width="90px" size="mini" class="form">
            <el-row type="flex" :gutter="10">
                <el-col :span="6" :lg="5">
                    <el-form-item label="运动项目：">
                        <el-select v-model="eventsId" @change="eventsChange" style="max-width: 200px">
                            <el-option v-for="(item,index) in eventsList" :value="item.Id" :label="item.Name" :key="index"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="18" :lg="19">
                    <el-row type="flex" justify="space-between">
                        <el-form-item label="状态：">
                            <status :list="statusList" :status-code="statusCode" @change="statusChange"></status>
                        </el-form-item>
                        <div>
                            <el-button type="warning" size="mini" v-href="'/refereeOrCoach/trainingEnroll/Detail/0'">+创建报名</el-button>
                        </div>
                    </el-row>
                </el-col>
            </el-row>
        </el-form>
        <el-row style="height:calc(100% - 110px)">
            <el-table
                stripe
                style="width: 100%"
                height="100%"
                v-loading="loading"
                element-loading-text="数据加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :data="list"
                size="mini"
            >
                <el-table-column prop="TrainDescription.EventsName" label="运动项目" align="center" width="120px"></el-table-column>
                <el-table-column  label="培训类型" align="center" width="120px">
                    <template slot-scope="scope">
                        {{['','裁判员','教练员','裁判员 教练员'][scope.row.TrainDescription.TrainType]}}
                    </template>
                </el-table-column>
                <el-table-column prop="TrainDescription.Name" label="培训名称" align="center"></el-table-column>
                <el-table-column label="报名时间" align="center">
                    <template slot-scope="scope">
                        {{dateFormat(scope.row.EnrollTime.BeginTime,'yyyy-MM-dd HH:mm:ss')}}~{{dateFormat(scope.row.EnrollTime.EndTime,'yyyy-MM-dd HH:mm:ss')}}
                    </template>
                </el-table-column>
                <el-table-column label="报名审核" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" 
                            style="color: dodgerblue !important" 
                            v-href="`/refereeOrCoach/trainingEnroll/Audit/${scope.row.TrainId}?name=${scope.row.TrainDescription.TrainFullName}`" 
                            v-if="scope.row.EnrollStatus == 2"
                        >去审核</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="培训订单" align="center">
                    <!-- <template slot-scope="scope"> -->
                        <!-- <el-button type="text" 
                            style="color: dodgerblue" 
                            v-href="`/refereeOrCoach/trainingEnroll/Order/${scope.row.TrainId}?name=${scope.row.TrainDescription.TrainFullName}`" 
                            v-if="scope.row.EnrollStatus == 2"
                        >
                            <span v-if="scope.row.WaitConfirmOrderCount">{{scope.row.WaitConfirmOrderCount}}单待确认，</span>
                            <span v-if="scope.row.PaidOrderCount">{{scope.row.PaidOrderCount}}单已支付</span>
                        </el-button> -->
                         <!-- <el-button type="text"
                            style="color: dodgerblue !important" 
                            v-href="`/refereeOrCoach/trainingEnroll/Order/${scope.row.TrainId}?name=${scope.row.TrainDescription.TrainFullName}`" 
                            v-if="scope.row.EnrollStatus == 2"
                         >查看</el-button> -->
                    <!-- </template> -->
                </el-table-column>
                <el-table-column label="状态" align="center" width="80px">
                    <template slot-scope="scope">
                        {{getStatusText(scope.row.EnrollStatus)}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <!-- <el-button type="text" v-href="`/refereeOrCoach/trainingEnroll/Detail/${scope.row.User4Coach.Id}`">查看</el-button> -->
                        <el-button type="text" 
                            v-href="`/refereeOrCoach/trainingEnroll/Detail/${scope.row.TrainEnrollId}`"
                            v-if="[1, -1].includes(scope.row.EnrollStatus)"
                        >编辑</el-button>
                        <el-button type="text" 
                            v-if="[1].includes(scope.row.EnrollStatus)"
                            @click="publish(scope.row.TrainEnrollId)"
                        >发布</el-button>
                        <!-- <el-button type="text">修改时间</el-button> -->
                        <!-- <el-button type="text"
                            v-if="[2, 3].includes(scope.row.EnrollStatus)"
                            @click="exportList(scope.row.TrainEnrollId, scope.row.TrainDescription.Name)"
                        >导出名单</el-button> -->
                        <!-- <el-button type="text">完成报名</el-button> -->
                        <el-button type="text"
                            v-if="[2].includes(scope.row.EnrollStatus)"
                            @click="cancelEnroll(scope.row.TrainEnrollId)"
                        >取消报名</el-button>
                        <!-- <el-button type="text">删除报名</el-button> -->
                        <!-- <el-button type="text">发送短信通知</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
        </el-row>
    </div>
</template>
<script>
import status from '@/components/status.vue';
import utils from '@/utils';
export default {
    name: 'trainingEnrollList',
    components:{
        status
    },
    data() {
        return {
            eventsId: 0,
            eventsList: [],
            statusCode: null,
            statusList: [
                {label: '全部', code: null},
                {label: '未发布', code: 1},
                {label: '已发布', code: 2},
                {label: '已完成', code: 3},
                {label: '已取消', code: -1}
            ],
            list: [],
            loading: false
        };
    },

    created() {
        this.$api.GetFlattedEventsList({
            'AllName': "全部"
        }).then(data=>{
            if(data.Code == 0){
                this.eventsList = data.EventsList || [];
            }
        })
        this.getDataList();
    },

    methods: {
        dateFormat: utils.dateFormat,
        getDataList(){
            this.loading = true;
            this.$api.GetTrainEnrollManageList({
                "EventsId": this.eventsId,
                "TrainEnrollStatus": this.statusCode
            }).then(data=>{
                this.loading = false;
                if(data.Code == 0){
                    this.list = data.TrainEnrolls;
                }
            })
        },
        eventsChange(){
            this.getDataList();
        },
        statusChange(code){
            if(this.statusCode != code){
                this.statusCode = code;
                this.getDataList();
            }
        },
        getStatusText(status){
            return (this.statusList.filter(item=>item.code == status)[0] || {}).label;
        },
        //培训报名发布
        publish(Id){
            this.$confirm('发布之后不可修改培训报名详情，确认发布？').then(() => {
                let loading = this.$loading({
                    lock: true,
                    text: '正在发布',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.$api.PublishTrainEnroll({
                    "TrainEnrollId": Id
                }).then((data) => {
                    loading.close();
                    if(data.Code == 0){
                        this.$message.success('发布成功');
                        this.getDataList();
                    }
                }).catch(() => {
                    loading.close();
                })
            })
        },
        exportList(){

        },
        cancelEnroll(Id){
            this.$confirm('确认取消？').then(() => {
                let loading = this.$loading({
                    lock: true,
                    text: '正在取消',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.$api.CancelTrainEnroll({
                    "TrainEnrollId": Id
                }).then((data) => {
                    loading.close();
                    if(data.Code == 0){
                        this.$message.success('取消成功');
                        this.getDataList();
                    }
                }).catch(() => {
                    loading.close();
                })
            })
        },
    },
};
</script>
<style scoped>
@import "~@/assets/css/clear-el.css";
.trainingClass{
    color: dodgerblue;
    text-decoration: underline;
}
</style>