import axios from 'axios'
import VueCookies from 'vue-cookies'
import {
  Message
} from 'element-ui';
// 创建axios实例
const service = axios.create({
  timeout: 15000, // 请求超时时间
  headers:{
    "Content-Type":"application/json; charset=utf-8",
    "Accept-Language":"zh-CN"
  },
  withCredentials:true
})
// request拦截器
service.interceptors.request.use(
  config => {
    if (config.method === 'post') {
      if (!config.data) { // 没有参数时，config.data为null，需要转下类型
        config.data = {};
      }
      config.data = JSON.stringify(config.data); 
    }
    return config
  },
  error => {
    Message.error("服务器异常");
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    if (response.status !== 200) {
      Message.error("服务器异常");
        return Promise.reject(response.data);
    } else {
      if(response.data.Code === 0){
        return response;
      } else if(response.data.Code == 9611){
        alert('登录失效，请刷新重新登录');
        VueCookies.remove('token' , '/')
        VueCookies.remove('userName' , '/')
        VueCookies.remove('userType' , '/')
        VueCookies.remove('mainLeagueId' , '/')
        window.location.reload();
        return response;
      } else{
        return response;
      }
    }
  },
  err => {
    var response = err.response;
    Message.error("服务器异常");
    return Promise.reject(response);
  }
)
//post
export function post(url,params = {}, options = {}){
  let { noErrorCode } = options;
  return new Promise((resolve, reject)=>{
      service.post(url,params).then(response=>{
        let data = response.data;
        if(data.Code === 0){
          resolve(data);
        }else{
          if(noErrorCode === true || noErrorCode && noErrorCode.length && noErrorCode.includes(data.Code)){
            resolve(data);
          }else {
            Message.error(data.Message || `接口异常(${data.Code})，请稍后重试`);
            resolve(data);
          }
        }
      }).catch(err=>{
        reject(err);
      })
    })
}

export default service;
 
