<template>
    <div class="editContest view">
        <router-link to="/match/rating" class="backBtn"> &lt; 返回</router-link>
        <span v-show="pageTit" v-text="'当前：' + pageTit"></span>
        <h2 style="font-size:2rem;padding-left:1rem">人工初审认证页面</h2>
        <div class="infosBox">
            <div class="basicInfo">
                <div class="group">
                    <label><i class="needFlag">*</i>机构名称：</label>
                    <input type="text" v-model="form.OwnerName" class="input" style="width:25rem;">
                </div>
                <div class="group">
                    <label><i class="needFlag">*</i>赛事名称：</label>
                    <input type="text" v-model="form.name" class="input" style="width:25rem;" placeholder="请填写核心赛事名，不含年份及冠名等信息">
                </div>
                <div class="group">
                    <label><i class="needFlag">*</i>赛季名称：</label>
                    <input type="text" v-model="form.seasonName" class="input" style="width:25rem;margin-right:1rem">
                </div>
                <div class="group">
                    <label><i class="needFlag">*</i>赛季赛事名称：</label>
                    <input type="text" v-model="form.leagueName" class="input" style="width:25rem;">
                </div>
                <div class="group" style="margin-bottom:1rem;display:flex">
                    <label>赛事logo：</label>
                    <div class="uploadPic clearfix">
                        <div class="pic fl" id="logoBox">
                            <img class="img-change" :src="logo.url || require('@/assets/imgs/joinImg2.png')" alt="">
                            <span class="state" v-show="logo.state == 1">上传中...</span>
                            <span class="state error" v-show="logo.state == -1">上传失败</span>
                            <input class="file" type="file" accept="image/*" id="logo" @change="logoChange($event)">
                        </div>
                        <div class="picText fl">
                            <div>
                                <p>只能上传jpg/png格式，大小不超过 200KB</p>
                                <p>建议分辨率：42×42</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="group">
                    <label><i class="needFlag">*</i>比赛项目：</label>
                    <select v-model="form.eventsIndex" class="select" style="width:20rem;">
                        <option value="-1">请选择</option>
                        <option
                            v-for="(item, i) in eventsList"
                            :key="i"
                            :value="i"
                            v-text="item.Name"
                            ></option>
                    </select>
                </div>
                <div class="group">
                    <label><i class="needFlag">*</i>比赛形式：</label>
                    <select v-model="form.isOnline" class="select" style="width:20rem;">
                        <option value="">请选择</option>
                        <option value="1" v-if="AllowOnline">线上赛</option>
                        <option value="2">线下赛</option>
                    </select>
                </div>
                <div class="group" v-if="indicatorsCheck.host">
                    <label>主办单位&emsp;</label>
                </div>
                <div class="host" v-if="indicatorsCheck.host" style="padding-left:2rem">
                    <ul>
                        <li v-for="(hostItem,hostIndex) in hostList" :key="hostIndex">
                            <div class="group">
                                <label>单位名称{{hostIndex+1}}：</label>
                                <input type="text" v-model="hostItem.name" class="input" style="width:20rem;" placeholder="请输入单位名称">
                            </div>
                            <div class="group">
                                <label>单位性质{{hostIndex+1}}：</label>
                                <select v-model="hostItem.level" class="select" style="width:20rem;">
                                    <option value="">请选择</option>
                                    <option v-for="(item,key) in hostLimit.levels" :value="key" :key="key">{{item.desc}}</option>
                                </select>
                            </div>
                            <button class="delete" @click="hostDelete(hostIndex)">删除</button>
                        </li>
                    </ul>
                    <button style="margin-top: 0.5rem;" @click="addHost()">添加主办单位</button>
                    <br>
                </div>
                <div class="group" v-if="indicatorsCheck.scope">
                    <label>覆盖范围&emsp;</label>
                </div>
                <div v-if="indicatorsCheck.scope" style="padding-left:2rem">
                    <div class="group" >
                        <label>参赛队伍范围：</label>
                        <select v-model="scope" class="select" style="width:20rem;">
                            <option value="">请选择</option>
                            <option v-for="(item,key) in scopeLimit.levels" :value="key" :key="key">{{item.desc}}</option>
                        </select>
                    </div>
                    <div class="group" >
                        <label>举办地点：</label>
                        <input type="text" v-model="address" class="input" style="width:20rem;" placeholder="多个地点用逗号隔开">
                    </div>
                </div>
                <div class="group" v-if="indicatorsCheck.scale">
                    <label>参赛规模&emsp;</label>
                </div>
                <div v-if="indicatorsCheck.scale" style="padding-left:2rem">
                    <div class="group" v-for="(item,index) in scaleLimit.items" :key="index">
                        <label>{{item.name}}：</label>
                        <input type="number" pattern="\d*" class="input" style="width:20rem;"
                            :value="scale[index]"  
                            @input="scale[index] = parseInt($event.target.value.replace(/^(0+)|[^\d]+/g,'').replace('.',''))"
                            :placeholder="item.hint || '请输入'+item.name"
                        >
                    </div>
                </div>
                <div class="group" v-if="indicatorsCheck.site">
                    <label>场地条件&emsp;</label>
                </div>
                <div v-if="indicatorsCheck.site"  style="padding-left:2rem">
                    <div class="group" v-for="(item,index) in siteLimit.items" :key="index">
                        <label>{{item.name}}：</label>
                        <input type="number" pattern="\d*" class="input" style="width:20rem;"
                            :value="site[index]"  
                            @input="site[index] = parseInt($event.target.value.replace(/^(0+)|[^\d]+/g,'').replace('.',''))"
                            :placeholder="item.hint || '请输入'+item.name"
                        >
                    </div>
                </div>
                <div class="group" v-if="indicatorsCheck.judge">
                    <label>裁判配置&emsp;</label>
                </div>
                <div v-if="indicatorsCheck.judge" style="padding-left:2rem">
                    <div class="group" v-for="(item,index) in judgeLimit.items" :key="index">
                        <label>{{item.name}}：</label>
                        <input type="number" pattern="\d*" class="input" style="width:20rem;" v-if="item.type == 'number'"
                            :value="judge[index]"  
                            @input="judge[index] = parseInt($event.target.value.replace(/^(0+)|[^\d]+/g,'').replace('.',''))"
                            :placeholder="item.hint || '请输入'+item.name"
                        >
                        <select v-model="judge[index]" class="select" style="width:20rem;" v-else-if="item.type == 'enum'">
                            <option value="">请选择</option>
                            <option v-for="(subItem,subIndex) in item.values" :key="subIndex" :value="subItem">{{subItem}}</option>
                        </select>
                    </div>
                </div>
                <div class="group" v-if="indicatorsCheck.rounds">
                    <label>比赛副数&emsp;</label>
                </div>
                <div v-if="indicatorsCheck.rounds"  style="padding-left:2rem">
                    <div class="group" v-for="(item,index) in roundsLimit.items" :key="index">
                        <label>{{item.name}}：</label>
                        <input type="number" pattern="\d*" class="input" style="width:20rem;"
                            :value="rounds[index]"  
                            @input="rounds[index] = parseInt($event.target.value.replace(/^(0+)|[^\d]+/g,'').replace('.',''))"
                            :placeholder="item.hint || '请输入'+item.name"
                        >
                    </div>
                </div>
                <br>
                <div class="group">
                    <label><i class="needFlag">*</i>赛事等级：</label>
                    <select v-model="form.finallyLevel" class="select" style="width:20rem;">
                        <option value="">请选择</option>
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="C">C</option>
                        <option value="D">D</option>
                        <option value="E">E</option>
                    </select>
                </div>
                <div class="group">
                    <label><i class="needFlag">*</i>比赛时间：</label>
                    <el-date-picker v-model="form.date" size="small" style="width: 240px" value-format="yyyy-MM-dd" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" > </el-date-picker>
                </div>
                <div class="group" v-if="attachmentLimit">
                    <label>附件：</label>
                </div>
                <div class="attachment" v-if="attachmentLimit">
                    <ul>
                        <template v-for="(item,index) in attachmentLimit">
                            <li :key="index" v-if="item.id == 2 ? false : item.id == 3 ? HasFields : true">
                                <div class="name_header" >
                                    <div class="name">
                                        <span>{{item.name}}</span>
                                    </div>
                                    <div class="upload">
                                        <button>上传</button>
                                        <input type="file" @change="fileChange($event,index)">
                                    </div>
                                </div>
                                <div class="content">
                                    <ul>
                                        <li v-for="(fileItem,fileIndex) in attachments[index].files" :key="fileIndex">
                                            <a :href="fileItem.store_path" target="_blank">{{fileItem.original_name}}</a>
                                            <button type="button" class="delete" v-if="fileItem.state == -1 || fileItem.state == 2" @click="fileDelete(index,fileIndex)">删除</button>
                                            <div class="masking" v-if="fileItem.state == 1">上传中</div>
                                            <div class="masking error" v-if="fileItem.state == -1">上传失败</div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </template>
                    </ul>
                </div>
                <div class="group">
                    <label>管理员账户&emsp;</label>
                </div>
                <div class="group">
                    <label><i class="needFlag">*</i>用户名：</label>
                    <input type="text" v-model="form.userName" class="input" style="width:15rem;">
                    <br />
                    <label><i class="needFlag">*</i>密码：</label>
                    <input type="text" v-model="form.password" class="input" style="width:15rem;">
                </div>
                <div class="group">
                    <label>申请时间：</label>
                    <el-date-picker v-model="form.ApplyTime" size="small" style="width: 240px" value-format="yyyy-MM-ddTHH:mm" type="datetime"  placeholder="申请时间"  > </el-date-picker>
                </div>
                <div class="group">
                    <label>审核时间：</label>
                    <el-date-picker v-model="form.AuditTime" size="small" style="width: 240px" value-format="yyyy-MM-ddTHH:mm" type="datetime"  placeholder="审核时间"  > </el-date-picker>
                </div>
            </div>
            <div class="handleBox">
                <button @click="send()" v-text="btn_txt"></button>
            </div>
        </div>
        <TipHandle 
            :txt="txt"
            :btnTxt="btnTxt"
            @maskHandle="maskHandle($event)" 
            />
    </div>
</template>

<script>
import TipHandle from '@/components/TipHandle.vue'
import { nanoid } from "nanoid"
import OSS from 'ali-oss'
import {
    PrepareDirectApproveInitialAudit,
    GetEventsCertificateDefine,
    DirectApproveInitialAudit
} from '@/newApi'

export default {
    name: 'RatingManualOperation',
    components: {
        TipHandle
    },
    data() {
        return {
            pageTit:'人工添加认证赛事',
            CompanyLeagueId: 0,//id
            form:{
                OwnerName:'',//机构名称
                name:"",//赛事名称
                seasonName:"",//赛季名称
                leagueName:"",//赛季赛事名称
                isOnline:'',
                eventsId:0,
                eventsName:"",
                eventsIndex:-1,
                finallyLevel:"",
                date:[],
                userName:"",
                password:"",
                ApplyTime:"",
                AuditTime:""
            },
            logo:{
                url: '',
                state: 0,
            },
            isSelect:false,
            AllowOnline:true,//是否有线上赛
            HasFields:true,//是否包含场地条件
            hostList:null,//添加的主办单位列表
            hostLimit:null,
            scope:null,//参赛队伍范围
            address:null,//举办地点
            scopeLimit:null,
            scale:null,//参赛规模
            scaleLimit:null,
            site:null,//场地条件
            siteLimit:null,
            judge:null,//裁判配置
            judgeLimit:null,
            rounds:null,//比赛副数
            roundsLimit:null,
            attachments:[],//文件
            attachmentLimit:null,
            UploaderOptions:{},//oss上传临时参数
            eventsList:[],//项目列表
            btn_txt:'保存并提交',
            submitFlag:true,
            txt: '',
            btnTxt: []
        }
    },
    created(){
        PrepareDirectApproveInitialAudit().then(data=>{
            if(data.Code == 0){
                this.CompanyLeagueId = data.CompanyLeagueId;
                this.eventsList = data.EventsList;
                this.UploaderOptions = data.UploaderOptions;
                this.form.ApplyTime = data.ApplyTime;
                this.form.AuditTime = data.AuditTime;
            }
        })
    },
    watch:{
        'form.eventsIndex':function(newVal){
            if(newVal<0){
                this.form.eventsId = 0;
                this.form.eventsName = "";
            }else{
                this.form.eventsId = this.eventsList[newVal].Id;
                this.form.eventsName = this.eventsList[newVal].Name;
            }
            this.getCriterionLimit(this.form.eventsId);
        }
    },
    computed:{
        indicatorsCheck(){
            return {
                host: this.hostLimit && this.hostLimit.form != "none",
                scope: this.scopeLimit && this.scopeLimit.form != "none",
                scale: this.scaleLimit && this.scaleLimit.form != "none",
                site: this.siteLimit && this.siteLimit.form != "none",
                judge: this.judgeLimit && this.judgeLimit.form != "none",
                rounds: this.roundsLimit && this.roundsLimit.form != "none"
            }
        }
    },
    methods: {
        handleTips(txt,btnTxt){
            this.txt = txt;
            this.btnTxt = btnTxt;
        },
        maskHandle(btn){
            if(btn == 0) {
                // 提交成功,返回上一页
                this.$router.replace('/match/rating')
            }
            this.txt='';
        },
        clearData(){//清空需要选择比赛项目的数据
            this.form.isOnline = '';
            this.AllowOnline = true;
            this.hostList = [];
            this.scope = '';
            this.address = '';
            this.scale = [];
            this.site = [];
            this.judge = [];
            this.rounds = [];
            this.hostLimit = null;
            this.scopeLimit = null;
            this.scaleLimit = null;
            this.siteLimit = null;
            this.judgeLimit = null;
            this.roundsLimit = null;
            this.attachmentLimit = null;
        },
        getCriterionLimit(EventsId){
            this.clearData();
            if(EventsId == 0)return ;
             GetEventsCertificateDefine({
                 EventsId
             }).then(data=>{
                if(data.Code == 0){
                    var Indicators = data.CertificateDefine.Indicators;
                    this.hostLimit = Indicators[0];
                    this.scopeLimit = Indicators[1];
                    this.scaleLimit = Indicators[2];
                    this.siteLimit = Indicators[3];
                    this.judgeLimit = Indicators[4];
                    this.roundsLimit = Indicators[5];
                    this.attachmentLimit = data.CertificateDefine.AttachmentKinds;
                    this.AllowOnline = data.CertificateDefine.AllowOnline;
                    this.HasFields = data.CertificateDefine.HasFields;
                    this.isSelect = true;
                    
                    if(Indicators[2] && Indicators[2].form != 'none'){
                        this.scale = Array.apply(null, Array(Indicators[2].items.length)).map(()=>'');
                    }
                    if(Indicators[3] && Indicators[3].form != 'none'){
                        this.site = Array.apply(null, Array(Indicators[3].items.length)).map(()=>'');
                    }
                    if(Indicators[4] && Indicators[4].form != 'none'){
                        this.judge = Array.apply(null, Array(Indicators[4].items.length)).map(()=>'');
                    }
                    if(Indicators[5] && Indicators[5].form != 'none'){
                        this.rounds = Array.apply(null, Array(Indicators[5].items.length)).map(()=>'');
                    }
                    //文件数据
                    var attachmentLimit = this.attachmentLimit;
                    var values = this.attachments;
                    for(var i = 0; i < attachmentLimit.length; i++){
                        if(!values[i] || (attachmentLimit[i].id == 3 && !this.HasFields)){
                            values[i] = {
                                id: attachmentLimit[i].id,
                                name:attachmentLimit[i].name,
                                files:[]
                            }
                        }
                    }
                    this.attachments = values;
                }
            })
        },
        addHost(){
            this.hostList.push({
                name:'',
                level:''
            })
        },
        hostDelete(index){
            this.hostList.splice(index,1);
        },
        logoChange(event){
            let files = event.target.files, file;
            if (files && files.length > 0) {
                file = files[0];
                if(!file) return; 
                var acceptArr = ['image/jpeg','image/png','image/bmp'];
                if(acceptArr.indexOf(file.type) == -1){
                    alert('不支持此文件类型');
                    return;
                }
                if (file.size > 200*1024) {
                    alert('图片大小不能超过 200KB，请压缩后上传');
                    return;
                }
                this.$set(this.logo,'state',1);
                this.ossUpload({
                    fileData: file,
                    fileId:7
                })
            }
        },
        fileChange(event,index){
            let files = event.target.files, file;
            let obj = this.attachments[index];
            let list = obj.files;
            if (files && files.length > 0) {
                file = files[0];
                if(!file) return; 
                var acceptArr = [],
                    // acceptArr1 = ['image/jpeg','image/png','image/bmp'],
                    acceptArr2 = ['image/jpeg','image/png','image/bmp','pdf','word',
                        'excel','zip','application/pdf','application/msword',
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                        'application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'application/x-zip-compressed'];
                acceptArr = acceptArr2;
                if(acceptArr.indexOf(file.type) == -1){
                    alert('不支持此文件类型');
                    return;
                }
                if (file.size > 100*1024*1024) {
                    alert('文件大小不能超过 100MB!');
                    return;
                }
                
                let listId = nanoid();
                list.push({
                    listId,
                    original_name: file.name,
                    size: this.formatFileSize(file.size),
                    state: 1//1上传中 -1上传失败 2或store_path参数不为空就是成功
                })
                obj.files = list;
                this.$set(this.attachments,index,obj)
                this.ossUpload({
                    listId,
                    fileData: file,
                    valuesIndex: index
                })
            }
            event.target.value = "";
        },
        ossUpload({listId, fileData, valuesIndex, fileId}){
            const _this = this;
            const options = this.UploaderOptions;
            const client = new OSS({
                // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
                region: options.Region,
                // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                accessKeyId: options.AccessKeyId,
                accessKeySecret: options.AccessKeySecret,
                // 从STS服务获取的安全令牌（SecurityToken）。
                stsToken: options.SecurityToken,
                // 填写Bucket名称。
                bucket: options.Bucket
            });
            const headers = {
                'Cache-Control': 'public',
                'Content-Disposition': encodeURIComponent(fileData.name),
            }
            let nameID = options.ObjectNamePrefix + nanoid();
            // console.log(client)
            client.put(nameID, fileData, {headers}).then(function (r1) {
                _this.fileUploadState({listId, valuesIndex, fileId, state: 2,url:r1.url});
                console.log('put success:');
            }).catch(function (err) {
                _this.fileUploadState({listId, valuesIndex, fileId, state: -1});
                console.log('error: '+JSON.stringify(err));
            });
        },
        fileUploadState({listId, valuesIndex, fileId, state,url}){
            if(fileId == 7){
                if(state == 2){
                    this.logo = {
                        url,
                        state:2
                    }
                }else {
                    this.logo = {
                        url,
                        state:-1
                    }
                }
            }else{
                var obj = this.attachments[valuesIndex];
                var list = obj.files;
                var fileObj = null,
                    fileIndex = -1;
                for(var i=0;i<list.length;i++){
                    if(list[i].listId == listId){
                        fileObj = list[i];
                        fileIndex = i;
                        break;
                    }
                }
                if(fileIndex > -1){
                    if(state == 2){
                        fileObj.store_path = url;
                        fileObj.state = 2;
                    }else {
                        fileObj.state = -1;
                    }
                    list[fileIndex] = fileObj;
                    obj.files = list;
                    this.$set(this.attachments,valuesIndex,obj);
                }
            }
        },
        formatFileSize(fileSize) {
             if (fileSize < 1024) {
                 return fileSize + 'B';
             } else if (fileSize < (1024*1024)) {
                 let temp = fileSize / 1024;
                 temp = temp.toFixed(2);
                 return temp + 'KB';
             } else if (fileSize < (1024*1024*1024)) {
                 let temp = fileSize / (1024*1024);
                 temp = temp.toFixed(2);
                 return temp + 'MB';
             } else {
                 let temp = fileSize / (1024*1024*1024);
                 temp = temp.toFixed(2);
                 return temp + 'GB';
             }
        },
        fileDelete(valuesIndex,filesIndex){//删除文件
            if(confirm('将删除您已添加的文件，请确认操作')){
                let obj = this.attachments[valuesIndex];
                let list = obj.files;
                list.splice(filesIndex, 1);
                obj.files = list;
                this.$set(this.attachments, valuesIndex, obj);
                console.log(this.attachments)
            }
        },
        send(){
            var form = this.form,
                indicatorsCheck = this.indicatorsCheck;
            var error = '';
            if(form.eventsId != 0){
                //主办单位
                let hostList = this.hostList;
                var hostError = '',
                    hostLevels = [],
                    hostValues = [];
                if(indicatorsCheck.host && hostList.length != 0){
                    for(var i=0;i<hostList.length;i++){
                        if(hostList[i].name == ""){
                            hostError = '请输入主办单位'+(i+1)+'的名称';
                            break ;
                        }else if(hostList[i].level == ""){
                            hostError = '请选择主办单位'+(i+1)+'的性质';
                            break ;
                        }
                        hostLevels.push(hostList[i].level);
                        hostValues.push(hostList[i].name);
                    }
                }
                if(indicatorsCheck.scale){
                    var scaleValues = this.scale,
                        scaleItems = this.scaleLimit.items,
                        scaleObj = this.limit(scaleValues, scaleItems);
                }
                if(indicatorsCheck.site){
                    var siteValues = this.site,
                        siteItems = this.siteLimit.items,
                        siteObj = this.limit(siteValues, siteItems);
                }
                if(indicatorsCheck.judge){
                    var judgeValues= this.judge,
                        judgeItems = this.judgeLimit.items,
                        judgeObj= this.limit(judgeValues, judgeItems);
                }
                if(indicatorsCheck.rounds){
                    var roundsValues= this.rounds,
                        roundsItems = this.roundsLimit.items,
                        roundsObj= this.limit(roundsValues, roundsItems);
                }
            }

            if(!form.OwnerName){
                error = '请填写机构名称';
            }else if(!form.name){
                error = '请填写赛事名称';
            }else if(!form.seasonName){
                error = '请填写赛季名称';
            }else if(!form.leagueName){
                error = '请填写赛季赛事名称';
            }else if(form.eventsId == 0){
                error = '请选择比赛项目';
            }else if(!form.isOnline){
                error = '请选择比赛形式';
            }else if(indicatorsCheck.host && hostError){
                error = hostError;
            }else if(indicatorsCheck.scope && !this.scope && this.address){
                error = '请选择参赛队伍范围';
            }else if(indicatorsCheck.scope && this.scope && !this.address){
                error = '请输入举办地点';
            }else if(indicatorsCheck.scale && scaleObj.error){
                error = scaleObj.error;
            }else if(indicatorsCheck.site && siteObj.error){
                error = siteObj.error;
            }else if(indicatorsCheck.judge && judgeObj.error){
                error = judgeObj.error;
            }else if(indicatorsCheck.rounds && roundsObj.error){
                error = roundsObj.error;
            }else if(!form.finallyLevel){
                error = '请选择赛事等级';
            }else if(!form.date[0]){
                error = '请选择比赛开始时间';
            }else if(!form.date[1]){
                error = '请选择比赛结束时间';
            }else if(!form.userName){
                error = '请输入管理员用户名';
            }else if(!form.password){
                error = '请输入管理员密码';
            }
            var Attachments = [];
            if(!error){
                //文件
                let attachmentLimit = this.attachmentLimit;
                let attachmentValues = this.attachments;
                destination:
                for(let i=0;i<attachmentLimit.length;i++){
                    let files = attachmentValues[i].files;
                    let list = [];
                    if(files.length > 0){
                        for(let j=0;j<files.length;j++){
                            if(!files[j].store_path ){
                                error = attachmentLimit[i].name+'有上传失败的文件';
                                break destination;
                            }
                            list.push({
                                original_name: files[j].original_name,
                                size: files[j].size,
                                store_path: files[j].store_path
                            })
                        }
                    }
                    Attachments[i] = {
                        id: attachmentLimit[i].id,
                        name: attachmentLimit[i].name,
                        files: list
                    }
                }
            }
            if(error){
                this.handleTips(error,['关闭'])
            }else{
                if(!this.submitFlag) {
                    return ;
                }
                this.submitFlag = false;
                this.btn_txt = '提交中...';
                var Indicators = [];
                //主办单位
                if(indicatorsCheck.host && hostLevels.length > 0 && hostValues.length > 0){
                    Indicators.push({
                        levels: hostLevels,
                        values: hostValues
                    })
                }else{
                    Indicators.push({})
                }
                //覆盖范围
                if(indicatorsCheck.host && this.scope && this.address){
                    Indicators.push({
                        level: this.scope,
                        value: this.address,
                    })
                }else{
                    Indicators.push({})
                }
                //参赛规模
                if(indicatorsCheck.scale && scaleObj.isNoNull){
                    Indicators.push({values: scaleValues})
                }else{
                    Indicators.push({})
                }
                //场地条件
                if(indicatorsCheck.site && siteObj.isNoNull){
                    Indicators.push({values: siteValues})
                }else{
                    Indicators.push({})
                }
                //裁判配置
                if(indicatorsCheck.judge && judgeObj.isNoNull){
                    Indicators.push({values: judgeValues})
                }else{
                    Indicators.push({})
                }
                //比赛副数
                if(indicatorsCheck.rounds && roundsObj.isNoNull){
                    Indicators.push({values: roundsValues})
                }else{
                    Indicators.push({})
                }
                DirectApproveInitialAudit({
                    "OwnerName": form.OwnerName,
                    "CompanyLeagueId": this.CompanyLeagueId,
                    "ApplyData": {
                        "Description": {
                            "Name": form.name,
                            "EventsId": form.eventsId,
                            "IsOnline": form.isOnline == 1,
                            "LogoUrl": this.logo.url
                        },
                        "SeasonCreation": {
                            "BeginTime": form.date[0],
                            "EndTime": form.date[1],
                            "SeasonName": form.seasonName,
                            "LeagueName": form.leagueName
                        },
                        "Indicators": Indicators,
                        "Attachments": Attachments,
                        "FinallyLevel": form.finallyLevel
                    },
                    "LeagueManageAccount": {
                        "LoginName": form.userName,
                        "Password": form.password
                    },
                    "ApplyTime":form.ApplyTime,
                    "AuditTime":form.AuditTime
                }).then(data=>{
                    this.submitFlag = true;
                    this.btn_txt = '保存并提交';
                    if(data.Code == 0){
                        this.handleTips('添加成功',['返回列表'])
                    }else{
                        this.handleTips(data.Message,['关闭'])
                    }
                }).catch(()=>{
                    this.submitFlag = true;
                    this.btn_txt = '保存并提交';
                    this.handleTips('网络异常',['关闭'])
                })
            }
        },
        /**
        * 不固定指标验证
        */
        limit(values,limitItems){
            var error = "",
                isNoNull = false,
                valueType = 1,
                nullIndex = -1;

            if(values.length !=0 ){
                for(var i = 0; i < values.length; i++){
                    if(values[i]){
                        isNoNull = true;
                        if(limitItems[i].type == "number"){
                            let min = limitItems[i].min, max = limitItems[i].max;
                            if(values[i] < min){
                                error = limitItems[i].name + '输入值小于最小值';
                            }else if(values[i] > max){
                                error = limitItems[i].name + '输入值大于最大值';
                            }
                        }
                    }else if(nullIndex == -1){
                        if(limitItems[i].type == "number"){
                            valueType = 1;
                        }else if(limitItems[i].type == "enum"){
                            valueType = 2;
                        }
                        nullIndex = i;
                    }
                    if(isNoNull && nullIndex > -1){
                        error = (valueType==1?'请输入':'请选择') + limitItems[nullIndex].name;
                    }
                    if(error){
                        break;
                    }
                }
            }
            return {error,isNoNull};
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    @import '~@/assets/css/form.css';
    @import '~@/assets/css/attachment.css';
    .infosBox{
        height: calc(100% - 6rem);
    }
    .infosBox label{
        width: 8rem;
    }
    .infosBox .group{
        line-height: 1.4em;
        margin: 0.8rem 0;
    }
    .handleBox button{
        padding:  0.5rem 2rem;
        border: 1px solid #555;
    }
    #logoBox{
        width: auto;
    }
    #logoBox .img-change{
        width: auto;
        position: static;
    }
    #logoBox .state{
        width: 100%;
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        position: absolute;
        top: 50%;
        margin-top: -1rem;
        left: 0;
        font-size: 1.2rem;
    }
    #logoBox .state.error{
        color: #C91E25;
    }
    .host>button{
        padding: 0.5rem 3rem;
        margin-left: 8rem;
        border-radius: 4px;
        border: 1px #555 solid;
        cursor: pointer;
    }
    .host ul li{
        display: flex;
        align-items: center;
    }
    .host ul li .delete{
        height: auto;
        background: #C91E25;
        color: #fff;
        border: none;
        padding: 0.4rem 1.5rem;
        border-radius: 4px;
        margin-left: 1rem;
        cursor: pointer;
    }
    .picText p{
        margin-bottom: 8px;
    }
</style>
