<template>
    <div class="container">
        <el-page-header @back="$router.back()" :content="title"></el-page-header>
        <div class="content">
            <!-- <el-row type="flex">
                <el-col :span="15">
                    <el-descriptions title="培训订单统计">
                        <template slot="extra">
                            <el-button size="mini" @click="getStatisticsData('order')">刷新统计</el-button>
                        </template>
                    </el-descriptions>
                    <el-table
                        style="width: 100%"
                        height="100%"
                        v-loading="order.loading"
                        element-loading-text="数据加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.8)"
                        :data="order.list"
                        size="mini"
                        show-summary
                        sum-text="合计"
                        :summary-method="$event=>getSummaries('order',...$event)"
                    >
                        <el-table-column prop="ClassName" label="培训内容名称" align="center"></el-table-column>
                        <el-table-column label="培训费" align="center">
                            <template slot-scope="scope" v-if="scope.row.Fee">{{formatAmount(scope.row.Fee)}}元</template>
                        </el-table-column>
                        <el-table-column label="已下单" align="center">
                            <template slot-scope="scope" v-if="scope.row.PeopleCount">{{scope.row.PeopleCount}}人</template>
                        </el-table-column>
                        <el-table-column label="在线支付" align="center">
                            <el-table-column label="未支付" align="center">
                                <template slot-scope="scope" v-if="scope.row.OnlineUnpaidCount">{{scope.row.OnlineUnpaidCount}}人</template>
                            </el-table-column>
                            <el-table-column label="已支付" align="center">
                                <template slot-scope="scope" v-if="scope.row.OnlinePaidCount">{{scope.row.OnlinePaidCount}}人</template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="在线支付" align="center">
                            <el-table-column label="未支付" align="center">
                                <template slot-scope="scope" v-if="scope.row.TansferUnpaidCount">{{scope.row.TansferUnpaidCount}}人</template>
                            </el-table-column>
                            <el-table-column label="待确认" align="center">
                                <template slot-scope="scope" v-if="scope.row.TansferWaitConfirmCount">{{scope.row.TansferWaitConfirmCount}}人</template>
                            </el-table-column>
                            <el-table-column label="已支付" align="center">
                                <template slot-scope="scope" v-if="scope.row.TansferPaidCount">{{scope.row.TansferPaidCount}}人</template>
                            </el-table-column>
                        </el-table-column>

                    </el-table>
                </el-col>
                <el-col :span="1">
                    <el-divider direction="vertical" class="vertical"></el-divider>
                </el-col>
                <el-col :span="8">
                    <el-descriptions title="已收款统计">
                        <template slot="extra">
                            <el-button size="mini" @click="getStatisticsData('payment')">刷新统计</el-button>
                        </template>
                    </el-descriptions>
                    <el-table
                        style="width: 100%"
                        height="100%"
                        v-loading="payment.loading"
                        element-loading-text="数据加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.8)"
                        :data="payment.list"
                        size="mini"
                        show-summary
                        sum-text="合计"
                        :summary-method="$event=>getSummaries('payment',...$event)"
                    >
                        <el-table-column label="支付方式" align="center">
                            <template slot-scope="scope">{{getPaymentMode(scope.row.PaymentMode)}}</template>
                        </el-table-column>
                        <el-table-column label="订单数" align="center">
                            <template slot-scope="scope">
                                <el-button type="text" style="color: dodgerblue" @click="query({PaymentMode: scope.row.PaymentMode})">{{scope.row.OrdersNum}}单</el-button>
                            </template>
                        </el-table-column>
                        <el-table-column label="金额数" align="center">
                            <template slot-scope="scope">{{formatAmount(scope.row.FeesTotal)}}元</template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row> -->
            <el-descriptions title="培训订单查询"></el-descriptions>
            <el-form :model="form" size="mini">
                <el-row>
                    <el-col>
                        <el-form-item label="支付方式：">
                            <el-select v-model="form.PaymentMode">
                                <el-option label="不限" :value="null"></el-option>
                                <el-option v-for="(item,index) in paymentMode" :key="index" :label="item.label" :value="item.code"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="支付状态：">
                            <el-select v-model="form.PaymentStatus">
                                <el-option label="不限" :value="null"></el-option>
                                <el-option v-for="(item,index) in status" :key="index" :label="item.label" :value="item.code"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="推荐单位：">
                            <el-input v-model="form.MerchantName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="操作人：">
                            <el-input v-model="form.OperatorUser" placeholder="姓名或手机号"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-button size="mini" @click="query()">查询</el-button>
                    </el-col>
                </el-row>
            </el-form>
            <el-table
                style="width: 100%"
                height="100%"
                v-loading="loading"
                element-loading-text="数据加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :data="list"
                size="mini"
            >
                <el-table-column label="序号" align="center" type="index" width="50px"></el-table-column>
                <el-table-column label="推荐单位" align="center">
                    <template slot-scope="scope">{{scope.row.MerchantName || '个人'}}</template>
                </el-table-column>
                <el-table-column label="操作人" align="center">
                    <template slot-scope="scope">
                        <template v-if="scope.row.OperatorUserName">{{scope.row.OperatorUserName}}（{{scope.row.OperatorUserPhone}}）</template>
                        <template v-else-if="scope.row.OperatorUserPhone">{{scope.row.OperatorUserPhone}}</template>
                    </template>
                </el-table-column>
                <el-table-column label="培训内容和人数" align="center">
                    <template slot-scope="scope">
                        <p v-for="(item,index) in scope.row.ClassPeopleCount" :key="index">{{item.ClassName}}：{{item.PeopleCount}}人 × {{formatAmount(item.Price)}}元</p>
                    </template>
                </el-table-column>
                <el-table-column label="订单金额" align="center">
                    <template slot-scope="scope"><div style="text-align: right;">{{formatAmount(scope.row.FeeTotal)}}</div></template>
                </el-table-column>
                <el-table-column label="支付方式" align="center">
                    <template slot-scope="scope">{{getPaymentMode(scope.row.PaymentMode)}}</template>
                </el-table-column>
                <el-table-column label="支付状态" align="center">
                    <template slot-scope="scope">
                        <span :style="{'color': ['#ff5630','#006644','#ff991f'][scope.row.PaymentStatus]}">{{getPaymentStatus(scope.row.PaymentStatus)}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="创建/支付时间" align="center">
                    <template slot-scope="scope">
                        <template v-if="scope.row.PaymentTime">{{dateFormat(scope.row.PaymentTime, 'yyyy-MM-dd HH:mm:ss')}}</template>
                        <template v-else>{{dateFormat(scope.row.CreateTime, 'yyyy-MM-dd HH:mm:ss')}}</template>
                    </template>
                </el-table-column>
                <el-table-column label="转账凭证/商户订单号" align="center">
                    <template slot-scope="scope">
                        <template v-if="scope.row.TradeNo">{{scope.row.TradeNo}}</template>
                        <el-button type="text" @click="lookVoucher(scope.row)" v-if="scope.row.TransferVoucherUrl">查看</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button 
                            type="text" 
                            @click="lookVoucher(scope.row)"
                            v-if="scope.row.PaymentMode == 0 && scope.row.PaymentStatus == 1"
                        >确认已支付</el-button>
                        <el-button 
                            type="text" 
                            @click="refreshStatus(scope.row)"
                            v-if="[1,2].includes(scope.row.PaymentMode) && scope.row.PaymentStatus == 0"
                        >刷新支付状态</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-dialog
            title="转账凭证"
            :visible.sync="visible"
            width="500px">
            <div slot="title">转账凭证：
                <span style="font-size: 14px;color: #777777" @click="openImg">{{voucher.url ? '（点击可放大）' : '未上传凭证'}}</span>
            </div>
            <img :src="voucher.url || defaultImage" @click="openImg" style="min-height:200px">
            <div slot="footer" class="dialog-footer" style="display:flex;justify-content: space-between;align-items: center;">
                <div>
                    <el-button type="primary" @click="confirm">确认已收款</el-button>
                    <span>
                        到账时间：
                        <el-date-picker
                            v-model="voucher.time"
                            type="datetime"
                            value-format="yyyy-MM-ddTHH:mm:ss">
                        </el-date-picker>
                    </span>
                </div>
                <el-button @click="visible = false">关闭</el-button>
            </div>
        </el-dialog>
        <el-image ref="imagePreview" class="image_preview"
            src="~@/assets/images/logo.png" 
            :preview-src-list="imagePreviews">
        </el-image>
    </div>
</template>
<script>
import utils from '@/utils';
export default {
    name: 'trainingEnrollOrder',
    data() {
        let id = +this.$route.params.id;
        return {
            trainId: id,
            order: {
                loading: false,
                list: []
            },
            payment: {
                loading: false,
                list: []
            },
            paymentMode: [
                {label: '在线支付', code: -1},
                {label: '转账', code: 0},
                {label: '微信', code: 1},
                {label: '支付宝', code: 2}
            ],
            status: [
                {label: '已支付或待确认', code: -1},
                {label: '未支付', code: 0},
                {label: '待确认', code: 1},
                {label: '已支付', code: 2},
            ],
            form: {
                PaymentMode: 0,
                PaymentStatus: -1,
                MerchantName: "",
                OperatorUser: ""
            },
            loading: false,
            list: [],
            defaultImage: require('@/assets/imgs/joinImg2.png'),
            visible: false,
            voucher: {
                url: "",
                time: "",
                id: ""
            },
            imagePreviews: []
        };
    },
    created() {
        this.getStatisticsData();
        this.getListData();
    },
    computed:{
        title(){
            return this.$route.query.name + '培训订单';
        }
    },
    methods: {
        formatAmount: utils.formatAmount,
        dateFormat: utils.dateFormat,
        getStatisticsData(type){
            if(!type || type == 'order'){
                this.order.loading = true;
            }
            if(!type || type == 'payment'){
                this.payment.loading = true;
            }
            this.$api.GetTrainOrderStatistics({
                "TrainId": this.trainId
            }).then(data=>{
                this.order.loading = false;
                this.payment.loading = false;
                if(data.Code == 0){
                    this.order.list = data.OrdersCount || [];
                    this.payment.list = data.FeesCount || [];
                }
            }).catch(()=>{
                this.order.loading = false;
                this.payment.loading = false;
            })
        },
        getListData(){
            if(this.loading)return;
            this.loading = true;
            this.$api.GetTrainOrderManageList({
                "TrainId": this.trainId,
                ...this.form
            }).then(data=>{
                this.loading = false;
                if(data.Code == 0){
                    this.list = data.TrainOrderList || [];
                }
            }).catch(()=>{
                this.loading = false;
            })
        },
        query(obj){
            let form = this.form;
            this.form = {
                ...form,
                ...obj
            }
            this.getListData();
        },
        getSummaries(){

        },
        getPaymentMode(mode){
            return (this.paymentMode.filter(item=>item.code == mode)[0] || {}).label || "";
        },
        getPaymentStatus(status){
            return (this.paymentStatus.filter(item=>item.code == status)[0] || {}).label || "";
        },
        lookVoucher(obj){
            if(obj.TransferVoucherUrl){
                this.voucher = {
                    url: obj.TransferVoucherUrl,
                    time: "",
                    id: obj.TrainOrderId
                }
                this.visible = true;
            }
        },
        confirm(){
            if(!this.voucher.time){
                this.$message.error('请选择到账时间');
            }else{
                this.$api.ConfirmTrainOrderPaid({
                    "TrainOrderId": this.voucher.id,
                    "PayTime": this.voucher.time
                }).then(data=>{
                    if(data.Code == 0){
                        this.visible = false;
                        this.getListData();
                    }
                })
            }
        },
        openImg(){
            if(!this.voucher.url)return;
            this.imagePreviews = [this.voucher.url];
            this.$refs['imagePreview'].showViewer = true;
        }
    }
};
</script>
<style scoped>
@import "~@/assets/css/clear-el.css";
    .vertical{
        height: 100%;
    }
</style>