<template>
    <div id="coachWork">
        <el-descriptions title="执裁经历" style="margin-top: 15px"></el-descriptions>
        <el-row>
            <el-table
                stripe
                style="width: 100%"
                :data="workList"
                size="mini"
            >
                <el-table-column label="开始时间" align="center" min-width="100px">
                    <template slot-scope="scope">
                        {{abnormalDate.get(scope.row.BeginTime, 'yyyy-MM-dd')}}
                    </template>
                </el-table-column>
                <el-table-column label="结束时间" align="center" min-width="100px">
                    <template slot-scope="scope">
                        <span v-if="scope.row.EndTime == -1">至今</span>
                        <span v-else>{{abnormalDate.get(scope.row.EndTime, 'yyyy-MM-dd')}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="JudgeLeague" label="执裁赛事" align="center">
                </el-table-column>
                <el-table-column prop="JudgeWork" label="执裁工作" align="center">
                </el-table-column>
                <el-table-column label="工作证明" align="center">
                    <template slot-scope="scope" v-if="scope.row.WorkVouchers && scope.row.WorkVouchers.length">
                        <el-button type="text" @click="lookImg(scope.row.WorkVouchers)">查看照片</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="WorkMatchesCount" label="执裁场次数" align="center">
                </el-table-column>
                <el-table-column prop="Remarks" label="备注" align="center">
                </el-table-column>
                <el-table-column label="操作" align="center" width="130px">
                    <template slot-scope="scope">
                        <el-button type="text" @click="lookInfo(scope.row.Id, scope.$index)">查看/修改</el-button>
                        <el-button type="text" @click="deleteRow(scope.row.Id, scope.$index)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>
        <el-row>
            <el-button type="warning" @click="add">+添加</el-button>
        </el-row>
        <el-dialog
            :title="dialogTitle"
            :visible.sync="visible"
            width="600px">
            <el-form :model="form" ref="form" :rules="rules" size="mini" label-width="100px">
                <el-row type="flex" align="center">
                    <el-col :span="10">
                        <el-form-item label="开始日期：" prop="beginTime">
                            <el-input v-model="form.beginTime" placeholder="年月日、年月、年，-分割"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10">
                        <el-form-item label="结束日期：" prop="endTime">
                            <el-input v-model="form.endTime" :disabled="dateChecked" placeholder="年月日、年月、年，-分割"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item  label-width="15px">
                            <el-checkbox v-model="dateChecked">至今</el-checkbox>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-form-item label="执裁赛事：" prop="judgeLeague">
                        <el-input v-model="form.judgeLeague" style="max-width: initial;"></el-input>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="执裁工作：" prop="judgeWork">
                        <el-input v-model="form.judgeWork" style="max-width: initial;"></el-input>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="执裁场次数：" prop="workMatchesCount">
                        <el-input v-model="form.workMatchesCount" style="max-width: initial;"></el-input>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="工作证明：">
                        <image-upload 
                            multiple
                            size="mini"
                            :image-urls.sync="form.workVouchers"
                            :ossConfig="ossConfig" 
                            :fileSize="0.5 * 1024 * 1024"
                            :max="9">
                        </image-upload>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="备注：" prop="remarks">
                        <el-input type="textarea" resize="none" :rows="4" v-model="form.remarks"></el-input>
                    </el-form-item>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="save">保存</el-button>
                <el-button @click="close">取消</el-button>
            </div>
        </el-dialog>
        <el-image ref="imagePreview" class="image_preview"
            src="~@/assets/images/logo.png" 
            :preview-src-list="imagePreviews">
        </el-image>
    </div>
</template>
<script>
import utils from '@/utils';
import validators from '../validators.js';
import imageUpload from '../../../components/imageUpload.vue';
export default {
    name: 'refereeWork',
    props:{
        list: {
            type: Array,
            default: ()=>[]
        },
        refereeId: {
            type: Number,
            default: 0
        },
        ossConfig: {
            type: Object,
            default: ()=>{return {}}
        }
    },
    components:{
        imageUpload
    },
    data() {
        return {
            visible: false,
            dialogState: 0,
            updateId: 0,
            updateIndex: -1,
            form: {},
            dateChecked: false,
            imagePreviews: [],
            abnormalDate: utils.abnormalDate
        };
    },
    computed:{
        workList(){
            return this.list || [];
        },
        dialogTitle(){
            return ['', '添加执裁经历', '查看/修改执裁经历'][this.dialogState];
        },
        rules(){
            return {
                beginTime: [
                    {required: true, message: '请输入开始日期'},
                    {validator: (rule, value, callback)=>{
                        validators.beginTimeValidator(value, this.form.endTime, callback);
                    }}
                ],
                endTime: [
                    {required: true, message: '请输入结束日期'},
                    {validator: (rule, value, callback)=>{
                        if(!this.dateChecked){
                            validators.endTimeValidator(this.form.beginTime, value, callback);
                        }else{
                            callback();
                        }
                    }}
                ],
                judgeLeague: [
                    {required: true, message: '请输入执裁赛事'},
                    {min: 5, max: 100, message: '限制5～100个字符'}
                ],
                judgeWork: [{validator: (rule, value, callback)=>{
                    if(value && !/^[\u4e00-\u9fa5]{2,3}$/.test(value)){
                        callback(new Error('限制2～3个文字'));
                    }else{
                        callback();
                    }
                }}],
                workMatchesCount:[{validator: (rule, value, callback)=>{
                    let strNum = value.toString();
                    if(/[.-]/.test(strNum)){
                        callback(new Error('请输入正整数'));
                    }else if(isNaN(Number(strNum))){
                        callback(new Error('不是一个数字'));
                    }else{
                        callback();
                    }
                }}],
                remarks: [{max: 200, message: '限制200个字符'}]
            }
        }
    },
    watch:{
        dateChecked(dateChecked){
            if(dateChecked){
                this.form.endTime = "至今";
            }else{
                this.form.endTime = "";
            }
        }
    },
    methods: {
        show(){
            this.visible = true;
            this.$nextTick(function(){
                this.$refs.form.clearValidate();
            });
        },
        close(){
            this.visible = false;
        },
        lookImg(imgs){
            if(imgs){
                this.imagePreviews = imgs;
                this.$refs['imagePreview'].showViewer = true;
            }
        },
        initInfo(info){
            if(info){
                let beginTime = utils.abnormalDate.get(info.BeginTime, 'yyyy-MM-dd'),
                    endTime = '';
                if(info.EndTime == -1){
                    endTime = '至今';
                    this.dateChecked = true;
                }else{
                    endTime = utils.abnormalDate.get(info.EndTime, 'yyyy-MM-dd');
                    this.dateChecked = false;
                }
                this.form = {
                    beginTime,
                    endTime,
                    judgeLeague: info.JudgeLeague,
                    judgeWork: info.JudgeWork,
                    workVouchers: info.WorkVouchers || [],
                    workMatchesCount: info.WorkMatchesCount,
                    remarks: info.Remarks
                }
            }else{
                this.form = {
                    beginTime: "",
                    endTime: "",
                    judgeLeague: "",
                    judgeWork: "",
                    workVouchers: [],
                    workMatchesCount: "",
                    remarks: ""
                };
                this.dateChecked = false;
            }
        },
        add(){
            this.updateId = 0;
            this.updateIndex = -1; 
            this.dialogState = 1;
            this.initInfo();
            this.show();
        },
        lookInfo(id, index){
            this.updateId = id;
            this.updateIndex = index; 
            this.dialogState = 2;
            this.initInfo(this.workList[index]);
            this.show();
        },
        save(){
            this.$refs.form.validate(valid=>{
                if(valid){
                    let loading = this.$loading({
                        text: '保存中',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.8)'
                    })
                    let form = this.form;
                    let beginTime = utils.abnormalDate.set(form.beginTime),
                        endTime = this.dateChecked ? -1 : utils.abnormalDate.set(form.endTime);
                        console.log(this.refereeId);
                    let info = {
                        "Id": this.updateId,
                        "RefereeId": this.refereeId,
                        "BeginTime": beginTime || 0,
                        "EndTime": endTime || 0,
                        "JudgeLeague": form.judgeLeague,
                        "JudgeWork": form.judgeWork,
                        "WorkVouchers": form.workVouchers,
                        "WorkMatchesCount": Number(form.workMatchesCount),
                        "Remarks": form.remarks
                    }
                    this.$api.SaveUserRefereeExperience({
                        "Experience": info
                    }).then(data=>{
                        loading.close();
                        if(data.Code == 0){
                            if(this.updateId == 0){
                                this.$message.success('添加成功');
                                info.Id = data.ExperienceId;
                                this.$emit("add", info);
                            }else{
                                this.$message.success('修改成功');
                                this.$emit('update', this.updateIndex, info);
                            }
                            this.close();
                        }else{
                            this.$message.error(data.Message);
                        }
                    }).catch(()=>{
                        loading.close();
                        this.$message.error('服务器异常');
                    })
                }else{
                    this.$message.error('填写信息有误，请查看！');
                }
            })
        },
        deleteRow(id, index){
            this.$confirm('确定删除该条执裁经历吗？删除后不可恢复', '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.DelUserRefereeExperience({
                    "ExperienceId": id
                }).then(data=>{
                    if(data.Code == 0){
                        this.$message.success('删除成功');
                        this.$emit('delete', index);
                    }else{
                        this.$message.error(data.Message);
                    }
                })
            })
        }
    }
};
</script>
<style scoped>

</style>