<template>
    <div class="container">
        <el-form :model="form" label-width="90px" size="mini" class="form">
            <el-row type="flex" :gutter="10">
                <el-col :span="4" :lg="4">
                    <el-form-item label="运动项目：">
                        <el-select v-model="form.eventsId">
                            <el-option v-for="(item,index) in eventsList" :value="item.Id" :label="item.Name" :key="index"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="5" :lg="4">
                    <el-form-item label="手机号：" label-width="75px">
                        <el-input type="text" v-model="form.phone"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="5" :lg="4">
                    <el-form-item label="姓名：" label-width="60px">
                        <el-input type="text" v-model="form.name"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="5" :lg="4">
                    <el-form-item label="证书编号：">
                        <el-input type="text" v-model="form.levelCerNo"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="5" :lg="8">
                    <el-row type="flex" justify="space-between">
                        <el-button size="mini" @click="search">搜索</el-button>
                        <el-button type="warning" size="mini" v-href="'/refereeOrCoach/CreateCoach'">+添加教练员</el-button>
                    </el-row>
                </el-col>
            </el-row>
        </el-form>
        <el-row style="height:calc(100% - 110px)">
            <el-table
                stripe
                style="width: 100%"
                height="100%"
                v-loading="loading"
                element-loading-text="数据加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :data="list"
                size="mini"
            >
                <el-table-column label="肖像照" align="center" width="70px">
                    <template slot-scope="scope">
                        <img :src="scope.row.UserInfo.PortraitPhoto || getDefalut(scope.row.UserInfo.Gender)"/>
                    </template>
                </el-table-column>
                <el-table-column prop="UserInfo.Name" label="姓名" align="center" width="70px">
                </el-table-column>
                <el-table-column prop="User4Coach.Id" label="教练员ID" align="center" width="100px"></el-table-column>
                <el-table-column prop="UserInfo.MemberNo" label="会员号" align="center" width="100px">
                </el-table-column>
                <el-table-column prop="UserInfo.Phone" label="手机号" align="center" width="120px">
                </el-table-column>
                <el-table-column prop="User4Coach.EventsName" label="运动项目" align="center" width="120px">
                </el-table-column>
                <el-table-column prop="User4Coach.CurrentLevelName" label="当前等级" align="center" width="100px">
                </el-table-column>
                <el-table-column prop="User4Coach.CurrentLevelCertNo" label="证书编号" align="center" width="100px">
                </el-table-column>
                <el-table-column label="通讯地址" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.UserInfo.AddressInfo.Country == '中国'">{{(scope.row.UserInfo.AddressInfo.Region || {}).RegionName}}</span>
                        <span v-else>{{scope.row.UserInfo.AddressInfo.Country}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="100px">
                    <template slot-scope="scope">
                        <el-button type="text" v-href="`/refereeOrCoach/CoachDetail/${scope.row.User4Coach.Id}`">查看/修改</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>
         <pagination
            @page-change="pageChange"
            :current-page.sync="pageInfo.page"
            :page-size="pageInfo.pageSize"
            :total="pageInfo.total">
        </pagination>
    </div>
</template>
<script>
import pagination from '@/components/pagination.vue';

export default {
    name: 'CoachList',
    components:{
        pagination
    },
    data() {
        return {
            defaultImg: require('@/assets/imgs/个人@3x.png'),
            defaultImg_man: require('@/assets/imgs/肖像_男@3x.png'),
            defaultImg_woman: require('@/assets/imgs/肖像_女@3x.png'),
            form: {
                eventsId: 0,
                phone: "",
                name: "",
                levelCerNo: ""
            },
            eventsList: [],
            list: [],
            loading: false,
            pageInfo:{
                page: 1,
                pageSize: 10,
                total: 200
            }
        };
    },

    created() {
        this.$api.GetCoachLevelFlattedEventsList({
            'AllName': "全部"
        }).then(data=>{
            if(data.Code == 0){
                this.eventsList = data.EventsList || [];
            }
        })
        this.getDataList();
    },

    methods: {
        getDefalut(type){
            switch(type){
                case 1:
                    return this.defaultImg_man;
                case 2:
                    return this.defaultImg_woman;
                default:
                    return this.defaultImg;
            }
        },
        getDataList(){
            let pageInfo = this.pageInfo,
                form = this.form;
            this.loading = true;
            this.$api.GetUserCoachPageList({
                "PageParm": {
                    "PageSize": pageInfo.pageSize,
                    "PageNum": pageInfo.page,
                },
                "EventsId": form.eventsId,
                "Phone": form.phone,
                "Name": form.name,
                "LevelCertNo": form.levelCerNo
            }).then(data=>{
                this.loading = false;
                if(data.Code == 0){
                    pageInfo.page = data.PageParm.PageNum;
                    pageInfo.total = data.PageParm.TotalCount;
                    this.list = data.CoachList;
                }
            })
        },
        search(){
            this.pageInfo.page = 1;
            this.getDataList()
        },
        pageChange(){
            this.getDataList();
        }
    },
};
</script>
<style scoped>
@import "~@/assets/css/clear-el.css";
.el-table img{
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 10px;
}
</style>