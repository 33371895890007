<template>
    <div class="uploadImg" :class="size">
        <div class="img_box">
            <template v-if="multiple">
                <template v-if="imageUrls.length">
                    <template v-for="(item,index) in imageUrls">
                        <div class="img" :class="{'error': !item}" :key="item + index">
                            <img :src="item" @click="openImg(index)" @error="imgError" v-if="item">
                            <i @click="removeImg(index)" v-if="!disabled">×</i>
                        </div>
                    </template>
                </template>
                <!-- <template v-else>
                    <div class="img">
                        <img :src="defaultImage">
                    </div>
                </template> -->
            </template>
            <template v-else>
                <div class="img">
                    <img :src="imageUrl || defaultImage" @click="openImg" @error="imgError">
                </div>
            </template>
            <div class="add" v-if="!disabled && (max == 0 || imageUrls.length < max)">
                <i class="el-icon-plus"></i>
                <input type="file" ref="file" accept="image/*" @change="fileChange" v-show="!loading">
                <div class="state" ref="state" v-show="loading"></div>
            </div>
        </div>
        <div class="el-upload__tip" v-if="!disabled">
            <slot name="tip"></slot>
        </div>
        <el-image ref="imagePreview" class="image_preview"
            :src="imagePreviews[initialIndex]" 
            :preview-src-list="imagePreviews">
        </el-image>
    </div>
</template>
<script>
import { nanoid } from "nanoid"
import OSS from 'ali-oss'
export default {
    name: 'ImageUpload',
    props:{
        imageUrl: String,
        disabled:{
            type: [Boolean, String],
            default: false
        },
        ossConfig: {
            type: Object,
            default: ()=>({})
        },
        multiple:{
            type: [Boolean, String],
            default: false
        },
        size: {
            type: String,
            default: 'medium'
        },
        imageUrls: {
            type: Array,
            default: ()=>[]
        },
        max:{
            type: Number,
            default: 0
        },
        lookImage: {
            type: Boolean,
            default: true
        },
        accept: {
            type: Array,
            default: ()=>['image/jpeg','image/png','image/bmp']
        },
        fileSize: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            defaultImage: require("@/assets/imgs/joinImg2.png"),
            loading: false,
            imagePreviews: [],
            initialIndex: 0
        };
    },
    methods: {
        upload(){
            if(!this.loading){
                this.$refs.file.click();
            }
        },
        fileChange(event){
            if(this.multiple && this.max > 0 && this.imageUrls.length >= this.max){
                this.$refs.file.value = "";
                this.$message.warning('图片最大上传数量 ' + this.max + ' 张');
                return;
            }
            let files = event.target.files, file;
            if (files && files.length) {
                file = files[0];
                if(!file) return; 
                var acceptArr =  this.accept;
                if(acceptArr.length && acceptArr.indexOf(file.type) == -1){
                    this.$refs.file.value = "";
                    this.$message.error('不支持此文件类型');
                    return;
                }
                if (this.fileSize && file.size > this.fileSize) {
                    this.$refs.file.value = "";
                    this.$message.error(`图片大小不能超过 ${this.formatFileSize(this.fileSize)}，请压缩后上传`);
                    return;
                }
                this.ossUpload(file)
            }
        },
        ossUpload(fileData){
            let loading = this.$loading({
                target: this.$refs.state,
                lock: true,
                text: '图片正在上传',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.8)'
            });
            this.loading = true;
            const options = this.ossConfig;
            const client = new OSS({
                // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
                region: options.Region,
                // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                accessKeyId: options.AccessKeyId,
                accessKeySecret: options.AccessKeySecret,
                // 从STS服务获取的安全令牌（SecurityToken）。
                stsToken: options.SecurityToken,
                // 填写Bucket名称。
                bucket: options.Bucket
            });
            const headers = {
                'Cache-Control': 'public',
                'Content-Disposition': encodeURIComponent(fileData.name),
            }
            let nameID = options.ObjectNamePrefix + nanoid();
            client.put(nameID, fileData, {headers}).then((r1)=>{
                loading.close();
                this.loading = false;
                if(this.multiple){
                    let imageUrls = this.imageUrls;
                    imageUrls.push(r1.url)
                    this.$emit('success', imageUrls);
                    this.$emit('update:imageUrls', imageUrls);
                }else{
                    this.$emit('success', r1.url);
                    this.$emit('update:imageUrl', r1.url);
                }
                this.$message.success('图片上传成功');
            }).catch((err)=>{
                loading.close();
                this.loading = false;
                this.$refs.file.value = "";
                this.$message.error('图片上传失败，请重试！');
                this.$emit('error', err);
            });
        },
        formatFileSize(fileSize) {
             if (fileSize < 1024) {
                 return fileSize + 'B';
             } else if (fileSize < (1024*1024)) {
                 let temp = fileSize / 1024;
                 temp = temp.toFixed(2);
                 return temp + 'KB';
             } else if (fileSize < (1024*1024*1024)) {
                 let temp = fileSize / (1024*1024);
                 temp = temp.toFixed(2);
                 return temp + 'MB';
             } else {
                 let temp = fileSize / (1024*1024*1024);
                 temp = temp.toFixed(2);
                 return temp + 'GB';
             }
        },
        openImg(index){
            if(this.lookImage && (this.imageUrl || this.imageUrls.length)){
                if(this.multiple){
                    this.imagePreviews = [...this.imageUrls];
                    this.initialIndex = index;
                    this.$refs['imagePreview'].showViewer = true;
                }else{
                    this.imagePreviews = [this.imageUrl];
                    this.initialIndex = 0;
                    this.$refs['imagePreview'].showViewer = true;
                }
            }
        },
        removeImg(index){
            let imageUrls = [...this.imageUrls];
            imageUrls.splice(index, 1);
            this.$emit('update:imageUrls', imageUrls);
        },
        imgError(event){
            console.log(event)
            event.target.parentNode.classList.add('error');
            event.target.parentNode.removeChild(event.target);
        }
    }
};
</script>
<style scoped>
.uploadImg .img_box{
    display:flex;
    flex-wrap: wrap;
}
.uploadImg .img{
    position: relative;
    --img-url: '11111111'
}
.uploadImg.medium .img{
    width: 100px;
    min-height: 100px;
    margin-right: 15px;
    margin-bottom: 15px;
}
.uploadImg.mini .img{
    width: 60px;
    min-height: 60px;
    margin-right: 10px;
    margin-bottom: 10px;
}
.uploadImg .img img{
    display: block;
    width: 100%;
    height: auto;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 6px;
    object-fit: cover;
}
.uploadImg .img i{
    display: block;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.95);
    text-align: center;
    position: absolute;
    cursor: pointer;
    box-shadow: 0 0 3px 0;
}
.uploadImg.medium .img i{
    width: 20px;
    height: 20px;
    font-size: 18px;
    line-height: 20px;
    top: -6px;
    right: -6px;
}
.uploadImg.mini .img i{
    width: 15px;
    height: 15px;
    font-size: 16px;
    line-height: 15px;
    top: -4px;
    right: -4px;
}
.uploadImg .img.error{
    border: 1px solid red;
}
.uploadImg .img.error::after{
    content: "图片显示异常";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;
    line-height: 1.3em;
    text-align: center;
    word-break: break-word;
    font-size: 12px;
}
.uploadImg .img .state,
.uploadImg .add .state{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: not-allowed;
}
.uploadImg .img input,
.uploadImg .add input{
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}
.uploadImg .add{
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
}
.uploadImg.medium .add{
    width: 100px;
    height: 100px;
}
.uploadImg.mini .add{
    width: 60px;
    height: 60px;
}
.uploadImg .add:hover {
    border-color: #409EFF;
}
.uploadImg .add i{
    width: 100%;
    height: 100%;
    font-size: 28px;
    color: #8c939d;
    display: flex;
    align-items: center;
    justify-content: center;
}
.uploadImg .add .state ::v-deep .el-loading-spinner{
    transform: translateY(-50%);
    margin-top: 0;
}
.uploadImg .add .state ::v-deep .el-loading-spinner .el-icon-loading{
    font-size: 20px;
}
.uploadImg .add .state ::v-deep .el-loading-spinner .el-loading-text{
    display: none;
}
.el-upload__tip{
    line-height: 1.8em;
    margin-bottom: 15px;
    color: #9c9c9c;
}
.image_preview{
    display: none;
}
</style>