<template>
    <div class="editContest view">
        <router-link to="/match/rating" class="backBtn"> &lt; 返回</router-link>
        <span v-show="pageTit" v-text="'当前：' + pageTit"></span>
        <h2 style="font-size:2rem;padding-left:1rem">人工复审认证页面</h2>
        <div class="infosBox">
            <div class="basicInfo">
                <div class="group">
                    <label>申请机构：</label>
                    <span v-text="Owner.OwnerName"></span>
                </div>
                <div class="group" style="margin-bottom:1rem;display:flex">
                    <label>赛事logo：</label>
                    <img style="height:7rem" :src="League.LogoUrl || require('@/assets/imgs/joinImg2.png')" alt="">
                </div>
                <div class="group">
                    <label>赛事名称：</label>
                    <span v-text="League.Name"></span>
                </div>
                <div class="group">
                    <label>比赛项目：</label>
                    <span v-text="League.EventsName"></span>
                </div>
                <div class="group">
                    <label>比赛形式：</label>
                    <span v-text="League.IsOnLine?'线上赛':'线下赛'"></span>
                </div>
                <div class="group">
                    <label>选择赛季：</label>
                    <select v-model="form.seasonIdIndex" class="select" style="width:15rem;" @change="seasonChange">
                        <option v-for="(item,index) in SeasonList" :value="index" :key="index">
                            {{item.SeasonName}}
                            （
                            <i v-if="item.AuditStatus==0">未提交</i>
                            <i v-if="item.AuditStatus==1">审核中</i>
                            <i v-if="item.AuditStatus==2">审核通过</i>
                            <i v-if="item.AuditStatus==3">审核不通过</i>
                            ）
                        </option>
                        <!-- <option value="-1">创建新赛季</option>
                        <option value="-2">新增历史赛季</option> -->
                    </select>
                </div>
                <div class="group">
                    <label><i class="needFlag" v-if="form.seasonIdIndex < 0">*</i>赛季名称：</label>
                    <input v-if="form.seasonIdIndex < 0" type="text" v-model="form.seasonName" class="input" style="width:20rem;">
                    <span v-else v-text="form.seasonName"></span>
                </div>
                <div class="group">
                    <label><i class="needFlag" v-if="form.seasonIdIndex < 0">*</i>赛事赛季名称：</label>
                    <input v-if="form.seasonIdIndex < 0"  type="text" v-model="form.leagueName" class="input" style="width:20rem;">
                    <span v-else v-text="form.leagueName"></span>
                </div>
                <div class="group">
                    <label><i class="needFlag" v-if="form.seasonIdIndex < 0">*</i>赛季时间：</label>
                    <el-date-picker :disabled="!(form.seasonIdIndex < 0)" v-model="form.date" size="small" style="width: 300px" value-format="yyyy-MM-dd" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" > </el-date-picker>
                </div>
                <div class="group">
                    <label>初审认证：</label>
                    <span v-text="form.tentativeLevel"></span>
                </div>
                <div class="group">
                    <label>填写复审信息：</label>
                    <span>如与初审资料相同无需修改</span>
                </div>
                <div class="group" v-if="indicatorsCheck.host">
                    <label>主办单位&emsp;</label>
                </div>
                <div class="host" v-if="indicatorsCheck.host" style="padding-left:2rem">
                    <ul>
                        <li v-for="(hostItem,hostIndex) in hostList" :key="hostIndex">
                            <div class="group">
                                <label>单位名称{{hostIndex+1}}：</label>
                                <input type="text" v-model="hostItem.name" class="input" style="width:20rem;" placeholder="请输入单位名称">
                            </div>
                            <div class="group">
                                <label>单位性质{{hostIndex+1}}：</label>
                                <select v-model="hostItem.level" class="select" style="width:20rem;">
                                    <option value="">请选择</option>
                                    <option v-for="(item,key) in hostLimit.levels" :value="key" :key="key">{{item.desc}}</option>
                                </select>
                            </div>
                            <button class="delete" @click="hostDelete(hostIndex)">删除</button>
                        </li>
                    </ul>
                    <button style="margin-top: 0.5rem;" @click="addHost()">添加主办单位</button>
                    <br>
                </div>
                <div class="group" v-if="indicatorsCheck.scope">
                    <label>覆盖范围&emsp;</label>
                </div>
                <div v-if="indicatorsCheck.scope" style="padding-left:2rem">
                    <div class="group" >
                        <label>参赛队伍范围：</label>
                        <select v-model="scope" class="select" style="width:20rem;">
                            <option value="">请选择</option>
                            <option v-for="(item,key) in scopeLimit.levels" :value="key" :key="key">{{item.desc}}</option>
                        </select>
                    </div>
                    <div class="group" >
                        <label>举办地点：</label>
                        <input type="text" v-model="address" class="input" style="width:20rem;" placeholder="多个地点用逗号隔开">
                    </div>
                </div>
                <div class="group" v-if="indicatorsCheck.scale">
                    <label>参赛规模&emsp;</label>
                </div>
                <div v-if="indicatorsCheck.scale" style="padding-left:2rem">
                    <div class="group" v-for="(item,index) in scaleLimit.items" :key="index">
                        <label>{{item.name}}：</label>
                        <input type="number" pattern="\d*" class="input" style="width:20rem;"
                            :value="scale[index]"  
                            @input="scale[index] = parseInt($event.target.value.replace(/^(0+)|[^\d]+/g,'').replace('.',''))"
                            :placeholder="item.hint || '请输入'+item.name"
                        >
                    </div>
                </div>
                <div class="group" v-if="indicatorsCheck.site">
                    <label>场地条件&emsp;</label>
                </div>
                <div v-if="indicatorsCheck.site"  style="padding-left:2rem">
                    <div class="group" v-for="(item,index) in siteLimit.items" :key="index"  >
                        <label>{{item.name}}：</label>
                        <input type="number" pattern="\d*" class="input" style="width:20rem;"
                            :value="site[index]"  
                            @input="site[index] = parseInt($event.target.value.replace(/^(0+)|[^\d]+/g,'').replace('.',''))"
                            :placeholder="item.hint || '请输入'+item.name"
                        >
                    </div>
                </div>
                <div class="group" v-if="indicatorsCheck.judge">
                    <label>裁判配置&emsp;</label>
                </div>
                <div v-if="indicatorsCheck.judge" style="padding-left:2rem">
                    <div class="group" v-for="(item,index) in judgeLimit.items" :key="index">
                        <label>{{item.name}}：</label>
                        <input type="number" pattern="\d*" class="input" style="width:20rem;" v-if="item.type == 'number'"
                            :value="judge[index]"  
                            @input="judge[index] = parseInt($event.target.value.replace(/^(0+)|[^\d]+/g,'').replace('.',''))"
                            :placeholder="item.hint || '请输入'+item.name"
                        >
                        <select v-model="judge[index]" class="select" style="width:20rem;" v-else-if="item.type == 'enum'">
                            <option value="">请选择</option>
                            <option v-for="(subItem,subIndex) in item.values" :key="subIndex" :value="subItem">{{subItem}}</option>
                        </select>
                    </div>
                    <br>
                </div>
                <div class="group" v-if="indicatorsCheck.rounds">
                    <label>比赛副数&emsp;</label>
                </div>
                <div v-if="indicatorsCheck.rounds" style="padding-left:2rem">
                    <div class="group" v-for="(item,index) in roundsLimit.items" :key="index">
                        <label>{{item.name}}：</label>
                        <input type="number" pattern="\d*" class="input" style="width:20rem;"
                            :value="rounds[index]"  
                            @input="rounds[index] = parseInt($event.target.value.replace(/^(0+)|[^\d]+/g,'').replace('.',''))"
                            :placeholder="item.hint || '请输入'+item.name"
                        >
                    </div>
                    <br>
                </div>
                <div class="group">
                    <label><i class="needFlag">*</i>复审赛事等级：</label>
                    <select v-model="form.finallyLevel" class="select" style="width:20rem;">
                        <option value="">请选择</option>
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="C">C</option>
                        <option value="D">D</option>
                        <option value="E">E</option>
                    </select>
                </div>
                <div class="group" v-if="attachmentLimit">
                    <label>附件：</label>
                </div>
                <div class="attachment" v-if="attachmentLimit">
                    <ul>
                        <li v-for="(item,index) in attachmentLimit" :key="index" v-show="item.id == 2 ? false : item.id == 3 ? HasFields : true">
                            <div class="name_header">
                                <div class="name">
                                    <span>{{item.name}}</span>
                                </div>
                                <div class="upload">
                                    <button>上传</button>
                                    <input type="file" @change="fileChange($event,index)">
                                </div>
                            </div>
                            <div class="content">
                                <ul>
                                    <li v-for="(fileItem,fileIndex) in attachments[index].files" :key="fileIndex">
                                        <a :href="fileItem.store_path" target="_blank">{{fileItem.original_name}}</a>
                                        <button type="button" class="delete" v-if="fileItem.state == -1 || fileItem.state == 2" @click="fileDelete(index,fileIndex)">删除</button>
                                        <div class="masking" v-if="fileItem.state == 1">上传中</div>
                                        <div class="masking error" v-if="fileItem.state == -1">上传失败</div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
                
                <div class="group">
                    <label>申请时间：</label>
                    <el-date-picker v-model="form.ApplyTime" size="small" style="width: 240px" value-format="yyyy-MM-ddTHH:mm" type="datetime"  placeholder="申请时间"  > </el-date-picker>
                </div>
                <div class="group">
                    <label>审核时间：</label>
                    <el-date-picker v-model="form.AuditTime" size="small" style="width: 240px" value-format="yyyy-MM-ddTHH:mm" type="datetime"  placeholder="审核时间"  > </el-date-picker>
                </div>
                <div class="group">
                    <label style="width:auto;margin-bottom:1rem"><i class="needFlag">*</i>赛事名称在赛事证书上的显示格式：</label>
                    <div style="padding-left:8rem">
                        <input type="text" v-model="LeagueNameByLines[0]" class="input" placeholder="第一行" maxlength="20" style="width:30rem;margin-bottom: 1rem;"><br>
                        <input type="text" v-model="LeagueNameByLines[1]" class="input" placeholder="第二行" maxlength="20" style="width:30rem;margin-bottom: 1rem;"><br>
                        <input type="text" v-model="LeagueNameByLines[2]" class="input" placeholder="第三行" maxlength="20" style="width:30rem;margin-bottom: 1rem;">
                    </div>
                </div>
            </div>
            <div class="handleBox">
                <button @click="send()" v-text="btn_txt"></button>
            </div>
        </div>
        <TipHandle 
            :txt="txt"
            :btnTxt="btnTxt"
            @maskHandle="maskHandle($event)" 
            />
    </div>
</template>

<script>
import TipHandle from '@/components/TipHandle.vue'
import { nanoid } from "nanoid"
import OSS from 'ali-oss'
import {
    PrepareDirectApproveReviewAudit,
    PrepareDirectApproveNewReviewAudit,
    PrepareDirectApproveOldReviewAudit,
    DirectApproveNewReviewAudit,
    DirectApproveOldReviewAudit
} from '@/newApi'

export default {
    name: 'RatingManualOperation',
    components: {
        TipHandle
    },
    data() {
        return {
            pageTit:'人工复审认证页面',
            CompanyLeagueId: isNaN(Number(this.$route.params.id)) ? null : Number(this.$route.params.id),//机构id
            MainLeagueId: isNaN(Number(this.$route.params.mainLeagueId)) ? null : Number(this.$route.params.mainLeagueId),//主赛事id
            SeasonId: isNaN(Number(this.$route.params.seasonId)) ? null : Number(this.$route.params.seasonId),//赛季id
            form:{
                seasonIdIndex:-1,//新赛季 -1 历史赛季 -2
                date:[],//赛季时间
                seasonName: "",//赛季名称
                leagueName: "",//赛季赛事名称
                tentativeLevel:'',//初审级别
                finallyLevel:'',//复审级别
                ApplyTime:'',
                AuditTime:''

            },
            Owner:{},//申请机构信息
            League:{},//基础信息
            SeasonList:{},//赛季列表
            AuditStatus:{},
            HasFields:true,//是否包含场地条件
            hostList:[],//添加的主办单位列表
            hostLimit:null,
            scope:'',//参赛队伍范围
            address:'',//举办地点
            scopeLimit:null,
            scale:[],//参赛规模
            scaleLimit:null,
            site:[],//场地条件
            siteLimit:null,
            judge:[],//裁判配置
            judgeLimit:null,
            rounds:[],//比赛副数
            roundsLimit:null,
            attachments:[],//文件
            attachmentLimit:null,
            UploaderOptions:{},//oss上传临时参数
            LeagueNameByLines:['','',''],//赛事名称在赛事证书上的显示格式
            btn_txt:'保存并提交',
            submitFlag:true,
            txt: '',
            btnTxt: []
        }
    },
    created(){
        PrepareDirectApproveReviewAudit({
            CompanyLeagueId: this.CompanyLeagueId,//id
            MainLeagueId: this.MainLeagueId,
            SeasonId: this.SeasonId,
        }).then(data=>{
            if(data.Code == 0){
                console.log(data)
                this.MainLeagueId = data.MainLeagueId;
                this.League = data.League;
                this.Owner = data.Owner;
                this.SeasonList = data.SeasonList;
                this.UploaderOptions = data.UploaderOptions;
                var IndicatorsLimit = data.CertificateDescription.Indicators;
                this.hostLimit = IndicatorsLimit[0];
                this.scopeLimit = IndicatorsLimit[1];
                this.scaleLimit = IndicatorsLimit[2];
                this.siteLimit = IndicatorsLimit[3];
                this.judgeLimit = IndicatorsLimit[4];
                this.roundsLimit = IndicatorsLimit[5];
                this.attachmentLimit = data.CertificateDescription.AttachmentKinds;
                this.HasFields = data.CertificateDescription.HasFields;
                this.form.ApplyTime=data.NowTime;
                this.form.AuditTime=data.NowTime;
                var IndicatorsData = {},Attachments={},LeagueNameByLines=null;
                if(data.SeasonList.length == 0){
                    let SeasonData = data.SeasonReviewCreation;
                    let SeasonCreation = SeasonData.SeasonCreation;
                    this.SeasonId = SeasonData.SeasonId;
                    this.form={
                        seasonIdIndex:-1,//新赛季 -1 历史赛季 -2
                        date:[SeasonCreation.BeginTime,SeasonCreation.EndTime],//赛季时间
                        seasonName: SeasonCreation.SeasonName,//赛季名称
                        leagueName: SeasonCreation.LeagueName,//赛季赛事名称
                        tentativeLevel: SeasonData.TentativeLevel,//初审级别
                        finallyLevel: SeasonData.InitialApplyData.FinallyLevel,//复审级别
                    }

                    IndicatorsData = SeasonData.InitialApplyData.Indicators;
                    Attachments = SeasonData.InitialApplyData.Attachments;
                    if(SeasonData.InitialApplyData.LeagueNameByLines){
                        LeagueNameByLines = SeasonData.InitialApplyData.LeagueNameByLines.split(',');
                    }
                }else{
                    let SeasonData = data.SeasonReview;
                    let Season = SeasonData.Season;
                    this.AuditStatus = SeasonData.AuditStatus;
                    let index = -1;
                    for(var i=0;i<data.SeasonList.length;i++){
                        if(data.SeasonList[i].SeasonId == Season.SeasonId){
                            index = i;
                            break;
                        }
                    }
                    this.SeasonId = Season.SeasonId;
                    this.form={
                        seasonIdIndex: index,//新赛季 -1 历史赛季 -2
                        date:[Season.BeginTime,Season.EndTime],//赛季时间
                        seasonName: Season.SeasonName,//赛季名称
                        leagueName: Season.LeagueName,//赛季赛事名称
                        tentativeLevel: SeasonData.TentativeLevel,//初审级别
                        finallyLevel: SeasonData.ApplyData.FinallyLevel,//复审级别
                    }
                    
                    IndicatorsData = SeasonData.ApplyData.Indicators;
                    Attachments = SeasonData.ApplyData.Attachments;
                    if(SeasonData.ApplyData.LeagueNameByLines){
                        LeagueNameByLines = SeasonData.ApplyData.LeagueNameByLines.split(',');
                    }
                }
                this.createdData(IndicatorsData,Attachments);
                if(LeagueNameByLines){
                    this.LeagueNameByLines.splice(0,LeagueNameByLines.length,...LeagueNameByLines);
                }else{
                    this.LeagueNameByLines = [this.League.Name,'',''];
                }
            }
        })
    },
    computed:{
        indicatorsCheck(){
            return {
                host: this.hostLimit && this.hostLimit.form != "none",
                scope: this.scopeLimit && this.scopeLimit.form != "none",
                scale: this.scaleLimit && this.scaleLimit.form != "none",
                site: this.siteLimit && this.siteLimit.form != "none",
                judge: this.judgeLimit && this.judgeLimit.form != "none",
                rounds: this.roundsLimit && this.roundsLimit.form != "none"
            }
        }
    },
    methods: {
        handleTips(txt,btnTxt){
            this.txt = txt;
            this.btnTxt = btnTxt;
        },
        maskHandle(btn){
            if(btn == 0) {
                // 提交成功,返回上一页
                this.$router.replace('/match/rating')
            }
            this.txt='';
        },
        getNewReviewAudit(){//获取创建新赛季所需审核数据
            PrepareDirectApproveNewReviewAudit({
                MainLeagueId: this.MainLeagueId,
                SeasonType: this.form.seasonIdIndex == -1?2:3
            }).then(data=>{
                console.log(data)
                if(data.Code == 0){
                    this.UploaderOptions.ObjectNamePrefix = data.ObjectNamePrefix;
                    this.SeasonId = data.SeasonId;
                    var obj = this.form;
                    obj.date = [data.SeasonCreation.BeginTime,data.SeasonCreation.EndTime];
                    obj.seasonName = data.SeasonCreation.SeasonName;
                    obj.leagueName = data.SeasonCreation.LeagueName;
                }
            })
        },
        getOldReviewAudit(){//获取已有赛季的赛季审核数据
            PrepareDirectApproveOldReviewAudit({
                MainLeagueId: this.MainLeagueId,
                SeasonId: this.SeasonId
            }).then(data=>{
                console.log(data)
                if(data.Code == 0){
                    this.UploaderOptions.ObjectNamePrefix = data.ObjectNamePrefix;
                    let SeasonData = data.SeasonReview;
                    let Season = SeasonData.Season;
                    this.AuditStatus = SeasonData.AuditStatus;
                    let index = -1;
                    let SeasonList = this.SeasonList;
                    for(var i=0;i<SeasonList.length;i++){
                        if(SeasonList[i].SeasonId == Season.SeasonId){
                            index = i;
                            break;
                        }
                    }
                    this.form={
                        seasonIdIndex: index,//新赛季 -1 历史赛季 -2
                        date:[Season.BeginTime,Season.EndTime],//赛季时间
                        seasonName: Season.SeasonName,//赛季名称
                        leagueName: Season.LeagueName,//赛季赛事名称
                        tentativeLevel: SeasonData.TentativeLevel,//初审级别
                        finallyLevel: SeasonData.ApplyData.FinallyLevel,//复审级别
                    };
                    let IndicatorsData = SeasonData.ApplyData.Indicators,
                        Attachments = SeasonData.ApplyData.Attachments;
                    this.createdData(IndicatorsData,Attachments);
                    let LeagueNameByLines = SeasonData.ApplyData.LeagueNameByLines ? SeasonData.ApplyData.LeagueNameByLines.split(',') : null;
                    if(LeagueNameByLines){
                        this.LeagueNameByLines.splice(0,LeagueNameByLines.length,...LeagueNameByLines);
                    }else{
                        this.LeagueNameByLines = [this.League.Name,'',''];
                    }
                }
            })
        },
        seasonChange(){
            var index = this.form.seasonIdIndex;
            if(index < 0){
                this.AuditStatus = {};
                this.getNewReviewAudit();
            }else{
                this.AuditStatus = this.SeasonList[index];
                this.SeasonId = this.SeasonList[index].SeasonId;
                this.getOldReviewAudit();
            }
        },
        createdData(IndicatorsData,Attachments){
            let {levels: hostLevels = [] ,values: hostValues = []} = IndicatorsData[0],
                scopeData = IndicatorsData[1],
                scaleData = IndicatorsData[2],
                siteData = IndicatorsData[3],
                judgeData = IndicatorsData[4],
                roundsData = IndicatorsData[5];
            let indicatorsCheck = this.indicatorsCheck;
            //主办单位
            if(indicatorsCheck.host){
                let hostList = [];
                for(var i=0;i<hostLevels.length;i++){
                    hostList.push({
                        level: hostLevels[i],
                        name: hostValues[i]
                    })
                }
                this.hostList = hostList;
            }
            //覆盖范围
            if(indicatorsCheck.scope){
                //参赛队伍范围
                this.scope = scopeData.level;
                //举办地点
                this.address = scopeData.value;
            }
            //参赛规模
            if(indicatorsCheck.scale){
                this.scale = scaleData.values || Array.apply(null, Array(this.scaleLimit.items.length)).map(()=>'');
            }
            //场地条件
            if(indicatorsCheck.site){
                this.site = siteData.values || Array.apply(null, Array(this.siteLimit.items.length)).map(()=>'');
            }
            //裁判配置
            if(indicatorsCheck.judge){
                this.judge = judgeData.values || Array.apply(null, Array(this.judgeLimit.items.length)).map(()=>'');
            }
            //比赛副数
            if(indicatorsCheck.rounds){
                this.rounds = roundsData.values || Array.apply(null, Array(this.roundsLimit.items.length)).map(()=>'');
            }
            //文件数据
            this.attachments = Attachments;
        },
        addHost(){
            this.hostList.push({
                name:'',
                level:''
            })
        },
        hostDelete(index){
            this.hostList.splice(index,1);
        },
        fileChange(event,index){
            let files = event.target.files, file;
            let obj = this.attachments[index];
            let list = obj.files;
            if (files && files.length > 0) {
                file = files[0];
                if(!file) return; 
                var acceptArr = [],
                    // acceptArr1 = ['image/jpeg','image/png','image/bmp'],
                    acceptArr2 = ['image/jpeg','image/png','image/bmp','pdf','word',
                        'excel','zip','application/pdf','application/msword',
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                        'application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'application/x-zip-compressed'];
                acceptArr = acceptArr2;
                if(acceptArr.indexOf(file.type) == -1){
                    alert('不支持此文件类型');
                    return;
                }
                if (file.size > 100*1024*1024) {
                    alert('文件大小不能超过 100MB!');
                    return;
                }
                
                let listId = nanoid();
                list.push({
                    listId,
                    original_name: file.name,
                    size: this.formatFileSize(file.size),
                    state: 1//1上传中 -1上传失败 2或store_path参数不为空就是成功
                })
                obj.files = list;
                this.$set(this.attachments,index,obj)
                this.ossUpload({
                    listId,
                    fileData: file,
                    valuesIndex: index
                })
            }
        },
        ossUpload({listId, fileData, valuesIndex}){
            const _this = this;
            const options = this.UploaderOptions;
            const client = new OSS({
                // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
                region: options.Region,
                // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                accessKeyId: options.AccessKeyId,
                accessKeySecret: options.AccessKeySecret,
                // 从STS服务获取的安全令牌（SecurityToken）。
                stsToken: options.SecurityToken,
                // 填写Bucket名称。
                bucket: options.Bucket
            });
            const headers = {
                'Cache-Control': 'public',
                'Content-Disposition': encodeURIComponent(fileData.name),
            }
            let nameID = options.ObjectNamePrefix + nanoid();
            // console.log(client)
            client.put(nameID, fileData, {headers}).then(function (r1) {
                _this.fileUploadState({listId, valuesIndex, state: 2,url:r1.url});
                console.log('put success:');
            }).catch(function (err) {
                _this.fileUploadState({listId, valuesIndex, state: -1});
                console.log('error: '+JSON.stringify(err));
            });
        },
        fileUploadState({listId, valuesIndex, state,url}){
            var obj = this.attachments[valuesIndex];
            var list = obj.files;
            var fileObj = null,
                fileIndex = -1;
            for(var i=0;i<list.length;i++){
                if(list[i].listId == listId){
                    fileObj = list[i];
                    fileIndex = i;
                    break;
                }
            }
            if(fileIndex > -1){
                if(state == 2){
                    fileObj.store_path = url;
                    fileObj.state = 2;
                }else {
                    fileObj.state = -1;
                }
                list[fileIndex] = fileObj;
                obj.files = list;
                this.$set(this.attachments,valuesIndex,obj);
            }
        },
        formatFileSize(fileSize) {
             if (fileSize < 1024) {
                 return fileSize + 'B';
             } else if (fileSize < (1024*1024)) {
                 let temp = fileSize / 1024;
                 temp = temp.toFixed(2);
                 return temp + 'KB';
             } else if (fileSize < (1024*1024*1024)) {
                 let temp = fileSize / (1024*1024);
                 temp = temp.toFixed(2);
                 return temp + 'MB';
             } else {
                 let temp = fileSize / (1024*1024*1024);
                 temp = temp.toFixed(2);
                 return temp + 'GB';
             }
        },
        fileDelete(valuesIndex,filesIndex){//删除文件
            if(confirm('将删除您已添加的文件，请确认操作')){
                let obj = this.attachments[valuesIndex];
                let list = obj.files;
                list.splice(filesIndex, 1);
                obj.files = list;
                this.$set(this.attachments, valuesIndex, obj);
                console.log(this.attachments)
            }
        },
        send(){
            var form = this.form,
                indicatorsCheck = this.indicatorsCheck;
            var error = '';
            if(this.AuditStatus.Status == 1){
                error = "选择的赛季为审核中赛季"
            }else if( this.AuditStatus.Status == 2){
                error = "选择的赛季为审核通过赛季"
            }else{
                //主办单位
                let hostList = this.hostList;
                var hostError = '',
                    hostLevels = [],
                    hostValues = [];
                if(indicatorsCheck.host && hostList.length != 0){
                    for(var i=0;i<hostList.length;i++){
                        if(hostList[i].name == ""){
                            hostError = '请输入主办单位'+(i+1)+'的名称';
                            break ;
                        }else if(hostList[i].level == ""){
                            hostError = '请选择主办单位'+(i+1)+'的性质';
                            break ;
                        }
                        hostLevels.push(hostList[i].level);
                        hostValues.push(hostList[i].name);
                    }
                }
                if(indicatorsCheck.scale){
                    var scaleValues = this.scale,
                        scaleItems = this.scaleLimit.items,
                        scaleObj = this.limit(scaleValues, scaleItems);
                }
                if(indicatorsCheck.site){
                    var siteValues = this.site,
                        siteItems = this.siteLimit.items,
                        siteObj = this.limit(siteValues, siteItems);
                }
                if(indicatorsCheck.judge){
                    var judgeValues= this.judge,
                        judgeItems = this.judgeLimit.items,
                        judgeObj= this.limit(judgeValues, judgeItems);
                }
                if(indicatorsCheck.rounds){
                    var roundsValues= this.rounds,
                        roundsItems = this.roundsLimit.items,
                        roundsObj= this.limit(roundsValues, roundsItems);
                }

                if(form.seasonIdIndex < 0 && !form.seasonName){
                    error = '请填写赛季名称';
                }else if(form.seasonIdIndex < 0 && !form.leagueName){
                    error = '请填写赛季赛事名称';
                }else if(form.seasonIdIndex < 0 && !form.date[0]){
                    error = '请选择比赛开始时间';
                }else if(form.seasonIdIndex < 0 && !form.date[1]){
                    error = '请选择比赛结束时间';
                }else if(indicatorsCheck.host && hostError){
                    error = hostError;
                }else if(indicatorsCheck.scope && !this.scope && this.address){
                    error = '请选择参赛队伍范围';
                }else if(indicatorsCheck.scope && this.scope && !this.address){
                    error = '请输入举办地点';
                }else if(indicatorsCheck.scale && scaleObj.error){
                    error = scaleObj.error;
                }else if(indicatorsCheck.site && siteObj.error){
                    error = siteObj.error;
                }else if(indicatorsCheck.judge && judgeObj.error){
                    error = judgeObj.error;
                }else if(indicatorsCheck.rounds && roundsObj.error){
                    error = roundsObj.error;
                }else if(!form.finallyLevel){
                    error = '请选择复审赛事等级';
                }
            }
            var Attachments = [];
            if(!error){
                //文件
                let attachmentLimit = this.attachmentLimit;
                let attachmentValues = this.attachments;
                destination:
                for(let i=0;i<attachmentLimit.length;i++){
                    let files = attachmentValues[i].files;
                    let list = [];
                    if(files.length > 0){
                        for(let j=0;j<files.length;j++){
                            if(!files[j].store_path ){
                                error = attachmentLimit[i].name+'有上传失败的文件';
                                break destination;
                            }
                            list.push({
                                original_name: files[j].original_name,
                                size: files[j].size,
                                store_path: files[j].store_path
                            })
                        }
                    }
                    Attachments[i] = {
                        id: attachmentLimit[i].id,
                        name: attachmentLimit[i].name,
                        files: list
                    }
                }
            }
            let LeagueNameByLines = this.LeagueNameByLines.filter(n=>n).join(',');
            if(!error && !LeagueNameByLines){
                error = "请设置赛事名称在赛事证书上的显示格式"
            }
            if(error){
                this.handleTips(error,['关闭'])
            }else{
                if(!this.submitFlag) {
                    return ;
                }
                this.submitFlag = false;
                this.btn_txt = '提交中...';
                var Indicators = [];
                //主办单位
                if(indicatorsCheck.host && hostLevels.length > 0 && hostValues.length > 0){
                    Indicators.push({
                        levels: hostLevels,
                        values: hostValues
                    })
                }else{
                    Indicators.push({})
                }
                //覆盖范围
                if(indicatorsCheck.host && this.scope && this.address){
                    Indicators.push({
                        level: this.scope,
                        value: this.address,
                    })
                }else{
                    Indicators.push({})
                }
                //参赛规模
                if(indicatorsCheck.scale && scaleObj.isNoNull){
                    Indicators.push({values: scaleValues})
                }else{
                    Indicators.push({})
                }
                //场地条件
                if(indicatorsCheck.site && siteObj.isNoNull){
                    Indicators.push({values: siteValues})
                }else{
                    Indicators.push({})
                }
                //裁判配置
                if(indicatorsCheck.judge && judgeObj.isNoNull){
                    Indicators.push({values: judgeValues})
                }else{
                    Indicators.push({})
                }
                //比赛副数
                if(indicatorsCheck.rounds && roundsObj.isNoNull){
                    Indicators.push({values: roundsValues})
                }else{
                    Indicators.push({})
                }
                if(form.seasonIdIndex < 0){
                    DirectApproveNewReviewAudit({
                        "MainLeagueId": this.MainLeagueId,
                        "SeasonType": 1,
                        "SeasonCreation": {
                            "SeasonId": this.SeasonId,
                            "BeginTime": form.date[0],
                            "EndTime": form.date[1],
                            "SeasonName": form.seasonName,
                            "LeagueName": form.leagueName
                        },
                        "ApplyData": {
                            "Indicators": Indicators,
                            "Attachments": Attachments,
                            "FinallyLevel": form.finallyLevel,
                            "LeagueNameByLines": LeagueNameByLines
                        },
                        "ApplyTime":form.ApplyTime,
                        "AuditTime":form.AuditTime,
                    }).then(data=>{
                        this.submitFlag = true;
                        this.btn_txt = '保存并提交';
                        if(data.Code == 0){
                            this.handleTips('提交成功',['返回列表'])
                        }else{
                            this.handleTips(data.Message,['关闭'])
                        }
                    }).catch(()=>{
                        this.submitFlag = true;
                        this.btn_txt = '保存并提交';
                        this.handleTips('网络异常',['关闭'])
                    })
                }else{
                    DirectApproveOldReviewAudit({
                        "MainLeagueId": this.MainLeagueId,
                        "SeasonId": this.SeasonId,
                        "ApplyData": {
                            "Indicators": Indicators,
                            "Attachments": Attachments,
                            "FinallyLevel": form.finallyLevel,
                            "LeagueNameByLines": LeagueNameByLines
                        },
                        "ApplyTime":form.ApplyTime,
                        "AuditTime":form.AuditTime,
                    }).then(data=>{
                        this.submitFlag = true;
                        this.btn_txt = '保存并提交';
                        if(data.Code == 0){
                            this.handleTips('提交成功',['返回列表'])
                        }else{
                            this.handleTips(data.Message,['关闭'])
                        }
                    }).catch(()=>{
                        this.submitFlag = true;
                        this.btn_txt = '保存并提交';
                        this.handleTips('网络异常',['关闭'])
                    })
                }
            }
        },
        /**
        * 不固定指标验证
        */
        limit(values,limitItems){
            var error = "",
                isNoNull = false,
                valueType = 1,
                nullIndex = -1;

            if(values.length !=0 ){
                for(var i = 0; i < values.length; i++){
                    if(values[i]){
                        isNoNull = true;
                        if(limitItems[i].type == "number"){
                            let min = limitItems[i].min, max = limitItems[i].max;
                            if(values[i] < min){
                                error = limitItems[i].name + '输入值小于最小值';
                            }else if(values[i] > max){
                                error = limitItems[i].name + '输入值大于最大值';
                            }
                        }
                    }else if(nullIndex == -1){
                        if(limitItems[i].type == "number"){
                            valueType = 1;
                        }else if(limitItems[i].type == "enum"){
                            valueType = 2;
                        }
                        nullIndex = i;
                    }
                    if(isNoNull && nullIndex > -1){
                        error = (valueType==1?'请输入':'请选择') + limitItems[nullIndex].name;
                    }
                    if(error){
                        break;
                    }
                }
            }
            return {error,isNoNull};
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    @import '~@/assets/css/form.css';
    @import '~@/assets/css/attachment.css';
    .infosBox{
        height: calc(100% - 6rem);
    }
    .infosBox label{
        width: 8rem;
    }
    
    .infosBox .group{
        line-height: 1.4em;
        margin: 0.8rem 0;
    }
    .handleBox button{
        padding:  0.5rem 2rem;
        border: 1px solid #555;
    }
    #logoBox{
        width: auto;
    }
    #logoBox .img-change{
        width: auto;
        position: static;
    }
    #logoBox .state{
        width: 100%;
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        position: absolute;
        top: 50%;
        margin-top: -1rem;
        left: 0;
        font-size: 1.2rem;
    }
    #logoBox .state.error{
        color: #C91E25;
    }
    .host>button{
        padding: 0.5rem 3rem;
        margin-left: 8rem;
        border-radius: 4px;
        border: 1px #555 solid;
        cursor: pointer;
    }
    .host ul li{
        display: flex;
        align-items: center;
    }
    .host ul li .delete{
        height: auto;
        background: #C91E25;
        color: #fff;
        border: none;
        padding: 0.4rem 1.5rem;
        border-radius: 4px;
        margin-left: 1rem;
        cursor: pointer;
    }
</style>
