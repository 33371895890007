<template>
    <div class="view">
        <div class="srchBox clearfix">
            <div class="fl">
                <label for="">时间：</label>
                <date-picker v-model="srchBegin" type="date" value-type="format" format="YYYY-MM-DD" placeholder="起始时间"></date-picker>
                -
                <date-picker v-model="srchEnd" type="date" value-type="format" format="YYYY-MM-DD" placeholder="结束时间"></date-picker>
            </div>
            <div class="fl ml_10">
                <label for="">竞赛项目：</label>
                <select v-model="events_id" @change="changeItem()">
                    <option value="0">请选择项目</option>
                    <option 
                        v-for="(item, i) in itemGroup" 
                        :key="i"
                        :value="item.events_id"
                        v-text="item.events_name"
                        ></option>
                </select>
            </div>
            <div class="fl ml_10">
                <label for="">分组：</label>
                <select v-model="point_group_id">
                    <option value="0">请选择分组</option>
                    <option 
                        v-for="(item, i) in groupList" 
                        :key="i"
                        :value="item.point_group_id"
                        v-text="item.point_group_name"
                        ></option>
                </select>
            </div>
            <div class="fl ml_10">
                <label for="">会员号：</label>
                <input type="text" name="" id="" v-model="member_no">
            </div>
            <div class="fl ml_10">
                <button class="btn" @click="srchHandle">查询</button>
            </div>
        </div>
        <div class="tab">
            <div class="tab_th clearfix">
                <div class="fl td_1"><span>序号</span></div>
                <div class="fl td_3"><span>时间</span></div>
                <div class="fl td_2"><span>竞赛项目</span></div>
                <div class="fl td_2"><span>分组</span></div>
                <div class="fl td_4"><span>名称</span></div>
                <div class="fl td_3"><span>会员号</span></div>
                <div class="fl td_1"><span>积分年度</span></div>
                <div class="fl td_2"><span>积分项</span></div>
                <div class="fl td_2"><span>积分</span></div>
                <div class="fl td_2"><span>变化后<br>年度积分</span></div>
                <div class="fl td_2"><span>变化后<br>历史积分</span></div>
            </div>
            <div class="box_tr" v-if="dataAll.length == 0">
                <p class="noData" v-text="listLoadTip"></p>
            </div>
            <div class="box_tr" v-else>
                <div class="tab_tr clearfix" v-for="(item, i) in dataAll" :key="i">
                    <div class="fl td_1">
                        <span v-text="item.rownum"></span>
                    </div>
                    <div class="fl td_3">
                        <span v-text="item.record_time"></span>
                    </div>
                    <div class="fl td_2">
                        <span v-text="item.events_name|| '-'"></span>
                    </div>
                    <div class="fl td_2">
                        <span v-text="item.point_group_name"></span>
                    </div>
                    <div class="fl td_4">
                        <span v-text="item.names"></span>
                    </div>
                    <div class="fl td_3">
                        <span v-text="item.member_no.replace('/', ' /')"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="item.point_year"></span>
                    </div>
                    <div class="fl td_2">
                        <span v-text="item.point_type_name"></span>
                    </div>
                    <div class="fl td_2">
                        <span v-text="item.point.indexOf('-') == -1? '+' + item.point : item.point"></span>
                    </div>
                    <div class="fl td_2">
                        <span v-text="item.cur_point"></span>
                    </div>
                    <div class="fl td_2">
                        <span v-text="item.toto_cur_point"></span>
                    </div>
                </div>
            </div>
            <PageHandle 
                :page="page"
                :totalPage="totalPage"
                :totalCount="totalCount"
                @pageTo="pageTo($event)" 
                />
        </div>
        <TipHandle 
            :txt="tip"
            :btnTxt="btnTxt"
            @maskHandle="maskHandle($event)" 
            />
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/zh-cn'
import PageHandle from '@/components/PageHandle.vue'
import TipHandle from '@/components/TipHandle.vue'
import { toQueryString } from '@/assets/js/util'

export default {
    name: 'Search',
    components: {
        DatePicker,
        TipHandle,
        PageHandle
    },
    props: {
    },
    data() {
        return {
            token: this.$cookies.get('token'),
            srchBegin: '',
            srchEnd: '',
            events_id: 0,
            point_group_id: 0,
            member_no:'',
            page: 1,
            count: 20,
            totalPage: 1,
            totalCount: 1,
            tip: '',
            btnTxt: [],
            dataAll:[],
            getListFlag: true,
            itemGroup: [],
            groupList: [],
            listLoadTip: ''
        }
    },
    mounted() {
        this.getList()
        this.getItemGroup()
    },
    methods: {
        handleTips(txt, btnTxt){
            this.tip = txt
            this.btnTxt = btnTxt
        },
        maskHandle(btn){
            if(btn == 0){
                // 确认发布
            }
            this.tip = ''
        },
        pageTo(page) {
            if(!this.getListFlag) {
                return
            }
            if(page == '-' && this.page > 1) {
                this.page --
                this.getList()
            } else if(page == '+' && this.page < this.totalPage){
                this.page ++
                this.getList()
            } else if(page != this.page && typeof page == 'number') {
                if(page > this.totalPage || page < 1) {
                    this.handleTips('请输入1~' + this.totalPage + '的数', ['关闭'])
                    return
                }
                this.page = page
                this.getList()
            }
            
        },
        srchHandle() {
            if(!this.getListFlag) {
                return
            }
            this.page = 1
            this.getList()
        },
        // 积分变更查询
        getList(){
            if(this.getListFlag) {
                this.getListFlag = false
            } else {
                return
            }
            this.dataAll = []
            this.listLoadTip = '数据加载中...'
            this.$axios.post('Match/Entrance',toQueryString({
                action: 'Get_point_ChangeInquiry',
                user_token:this.token,
                begin_time: this.srchBegin || '',
                end_time: this.srchEnd || '',
                events_id: this.events_id,
                point_group_id: this.point_group_id,
                member_no: this.member_no || 0,
                page: this.page,
                count: this.count
            })).then((res) => {
                this.getListFlag = true
                let data = res.data
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                if(data.code == 1) {
                    if(data.NewList.length == 0){
                        this.listLoadTip = '无数据'
                    }
                    this.dataAll = data.NewList
                    this.totalCount = data.totalCount
                    this.totalPage = Math.ceil(data.totalCount/this.count)
                } else {
                    this.listLoadTip = data.message
                }
            }).catch(() => {
                this.listLoadTip = '网络异常'
                this.getListFlag = true
            })
        },
        // 获取竞赛项目分组
        getItemGroup(){
            this.$axios.post('Match/Entrance',toQueryString({
                action: 'Get_point_league_events_group',
                user_token:this.token
            })).then((res) => {
                let data = res.data;
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                if(data.code == 1){
                    this.itemGroup = data.NewList
                } else {
                    this.handleTips(data.message, ['关闭'])
                }
            }).catch(() => {
                this.handleTips('网络异常', ['关闭'])
            })
        },
        // 切换查询项目
        changeItem(){
            if(this.events_id > 0){
                let _epgList = this.get_epgList(this.events_id)
                if(_epgList.length > 0){
                    this.groupList = _epgList
                } else {
                    this.groupList = []
                }
            } else {
                this.groupList = []
            }
            this.point_group_id = 0
        },
        get_epgList(id) {
            let _arr = []
            for(let i in this.itemGroup) {
                if(this.itemGroup[i].events_id == id) {
                    _arr = this.itemGroup[i].epgList
                    break
                }
            }
            return _arr
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '~@/assets/css/common_z.css';
    .mx-datepicker{width: 115px;}
    .srchBox{
        height: 3rem;
        line-height: 3rem;
    }
    .srchBox input, .srchBox select{
        height: 2rem;
    }
    .tab{
        width: 100%;
        height: calc(100% - 3rem);
    }
    .tab .tab_th div{
        display: table;
        height: 2.5rem;
    }
    .tab .tab_th span{
        line-height: 1rem;
        display: table-cell;
        vertical-align: middle;
    }
</style>
