<template>
    <div class="view">
        <div class="head_desc clearfix">
            <div class="iconBox fl">
                <i class="icon"></i>
            </div>
            <div class="addMatch">
                <router-link to="/match/rating/ratingManualOperation" class="btn">+人工添加认证赛事</router-link>
            </div>
        </div>
        <div class="srchBox clearfix">
            <div class="fl projectType">
                <label for="">项目类型：</label>
                <button class="btn active">单项运动项目</button>
                <button class="btn">综合运动会</button>
            </div>
        </div>
        <div class="srchBox clearfix">
            <div class="fl">
                <label for="">运动项目：</label>
                <select v-model="eventsId">
                    <option 
                        v-for="(item, i) in eventsList" 
                        :key="i"
                        :value="item.Id"
                        v-text="item.Name"
                        ></option>
                </select>
            </div>
        </div>
        <div class="srchBox clearfix">
            <div class="fl state">
                <label for="">&emsp;&emsp;状态：</label>
                <button v-for="(item,key) in stateBtnObj" :key="key" :class="{'active':key == state}" @click="setState(key)">{{item}}</button>
                <button :class="{'active':-1 == state}">搜索结果</button>
            </div>
        </div>
        <div class="srchBox clearfix">
            <div class="fl">
                <label for="">模糊搜索：</label>
                <input type="text" placeholder="机构或赛事名称" v-model="srchText">
            </div>
            <div class="fl ml_10">
                <button class="btn search" @click="srchHandle">查询</button>
            </div>
        </div>
        <div class="tab">
            <div class="tab_th clearfix">
                <div class="td_1">序号</div>
                <div class="td_2">申请时间</div>
                <div class="td_3">申请机构</div>
                <div class="td_3">主赛事名称</div>
                <div class="td_2">赛季</div>
                <div class="td_2">运动项目</div>
                <div class="td_2">状态</div>
                <div class="td_3">操作</div>
            </div>
            <div class="box_tr" v-if="list.length == 0">
                <p class="noData" v-text="listLoadTip"></p>
            </div>
            <div class="box_tr" v-else>
                <div  class="tab_tr clearfix" 
                    v-for="({
                        AuditStatus,
                        League,
                        Owner,
                        SeasonName,
                        CompanyLeagueId,
                        MainLeagueId,
                        SeasonId
                    }, i) in list" :key="i"
                >
                    <div class="td_1">
                        <span v-text="(page-1) * count + i + 1"></span>
                    </div>
                    <div class="td_2">
                        <span v-text="AuditStatus.ApplyTime && AuditStatus.ApplyTime.replace('T',' ').replace('Z','')"></span>
                    </div>
                    <div class="td_3">
                        <span v-text="Owner.OwnerName"></span>
                    </div>
                    <div class="td_3">
                        <span v-text="League.Name"></span>
                    </div>
                    <div class="td_2">
                        <span v-text="SeasonName"></span>
                    </div>
                    <div class="td_2">
                        <span v-text="League.EventsName"></span>
                    </div>
                    <div class="td_2">
                        <span v-text="showStateObj[AuditStatus.Status]"></span>
                    </div>
                    <div class="td_3">
                        <span>
                           <router-link
                                :to="'rating/ratingDetail/' + CompanyLeagueId"
                                v-if="[10,11,12].indexOf(AuditStatus.Status) > -1"
                               >查看</router-link><!--初审详情-->
                           <router-link
                                :to="'rating/ratingRecheckDetail/' + MainLeagueId + '/' + SeasonId"
                                v-if="[20,21,22,23].indexOf(AuditStatus.Status) > -1"
                               >查看</router-link><!--复审详情-->
                           <router-link
                                :to="'rating/ratingDetail/' + CompanyLeagueId"
                                v-if="[10].indexOf(AuditStatus.Status) > -1"
                                >审核</router-link><!--初审-->
                           <router-link
                                :to="'rating/ratingRecheckDetail/' + MainLeagueId + '/' + SeasonId"
                                v-if="[21].indexOf(AuditStatus.Status) > -1"
                                >审核</router-link><!--复审-->
                           <router-link
                                :to="'rating/RatingMORecheck/' + CompanyLeagueId + '/' + MainLeagueId + '/' + SeasonId"
                                v-if="[11,20,21,22,23].indexOf(AuditStatus.Status) > -1"
                                >人工复审</router-link>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <PageHandle 
            :page="page"
            :totalPage="totalPage"
            :totalCount="totalCount"
            @pageTo="pageTo($event)" 
            />
        <TipHandle 
            :txt="tip"
            :btnTxt="btnTxt"
            @maskHandle="maskHandle($event)" 
            />
    </div>
</template>

<script>
import PageHandle from '@/components/PageHandle.vue'
import TipHandle from '@/components/TipHandle.vue'
import {
    GetFlattedEventsList,
    GetAuditLeagueList
} from "@/newApi"

export default {
    name: 'Rating',
    components: {
        PageHandle,
        TipHandle
    },
    props: {
    },
    data() {
        return {
            page: 1,
            count: 20,
            totalPage: 1,
            totalCount: 1,
            tip: '',
            btnTxt: [],
            list:[],
            srchText: '',
            eventsId:0,
            eventsList:[],
            state: 0,
            stateBtnObj:{
                0:'所有审核中',
                11:'初审通过',
                12:'初审不通过',
                20:'复审未提交',
                22:'复审通过',
                23:'复审不通过'
            },
            showStateObj:{
                10:'初审审核中',
                11:'初审通过',
                12:'初审不通过',
                20:'复审未提交',
                21:'复审审核中',
                22:'复审通过',
                23:'复审不通过'
            },
            listLoadTip: '',
            getListFlag: true,
        }
    },
    created(){
        GetFlattedEventsList({
            AllName:"全部"
        }).then(data=>{
            if(data.Code == 0){
                this.eventsList = data.EventsList;
            }
        })
    },
    mounted() {
        this.getList();
    },
    watch:{
        eventsId(){
            this.page = 1;
            this.getList();
        }
    },
    methods: {
        handleTips(txt, btnTxt){
            this.tip = txt
            this.btnTxt = btnTxt
        },
        maskHandle(btn){
            if(btn == 0){
                // 确认发布
            }
            this.tip = ''
        },
        pageTo(page){
            if(!this.getListFlag) {
                return
            }
            if(page == '-' && this.page > 1) {
                this.page --
                this.getList()
            } else if(page == '+' && this.page < this.totalPage){
                this.page ++
                this.getList()
            } else if(page != this.page && typeof page == 'number') {
                if(page > this.totalPage || page < 1) {
                    this.handleTips('请输入1~' + this.totalPage + '的数', ['关闭'])
                    return
                }
                this.page = page
                this.getList()
            }
            
        },
        setState(state){
            if(!this.getListFlag) {
                return
            }
            this.state = parseInt(state);
            this.page = 1;
            this.getList()
        },
        srchHandle() {
            if(!this.getListFlag) {
                return
            }
            this.state = -1;
            this.page = 1;
            this.getList()
        },
        // 列表
        getList(){
            if(this.getListFlag) {
                this.getListFlag = false
            } else {
                return
            }
            this.list = [];
            this.listLoadTip = '数据加载中...'
            GetAuditLeagueList({
                "Paging": {
                    "RecordAmountPerPage": this.count,
                    "PageIndex": this.page
                },
                "SearchMode": this.state,
                "Keyword": this.srchText,
                "EventsId": this.eventsId
            }).then(data=>{
                this.getListFlag = true;
                if(data.Code == 0){
                    if(data.PageList){
                        this.totalPage = data.Paging.PageAmount;
                        this.totalCount = data.Paging.RecordAmount;
                        this.page = data.Paging.PageIndex;
                        this.list = data.PageList;
                        this.listLoadTip = '';
                    }else{
                        this.totalPage = 0;
                        this.totalCount = 0;
                        this.page = 1;
                        this.list = [];
                        this.listLoadTip = '无数据';
                    }
                }else{
                    this.listLoadTip = data.Message;
                }
            }).catch(()=>{
                this.getListFlag = true;
                this.listLoadTip = '数据获取失败';
            })
        },
    }
}
</script>

<style scoped>
@import '~@/assets/css/common_z.css';
.head_desc{
        height: 6rem;
        margin: -0.6rem -1rem 1rem -1rem;
        background: #e9e9e9;
        padding: 1rem 1rem 1rem 0;
    }
    .head_desc .iconBox{
        width: 5.2rem;
        height: 4rem;
        border: 2px solid #BDBDBD;
        text-align: center;
        margin-right: 1rem;
    }
    .head_desc .iconBox .icon{
        display: inline-block;
        width: 3.2rem;
        height: 3.2rem;
        background: url('~@/assets/imgs/icons.png') no-repeat -8rem 0;
        background-size: auto 120%;
    }
    .addMatch{
        margin-top: 1.1rem;
    }
    .srchBox{
        height: 3rem;
        line-height: 3rem;
    }
    .projectType .btn{
        height: 2rem;
        padding: 0 2rem;
        margin-right: 3rem;
        border: none;
        cursor: pointer;
        background: #DFDFDF;
    }
    .projectType .btn.active{
        background: #C91E25;
        color: #ffffff;
    }
    .srchBox input, .srchBox select{
        height: 2rem;
    }
    .state{
        white-space: nowrap;
    }
    .state button{
        height: 2rem;
        padding: 0 1rem;
        margin-right: 1rem;
        border: none;
        cursor: pointer;
        background: #DFDFDF;
    }
    .state button.active{
        background: #C91E25;
        color: #ffffff;
    }
    .tab{
        width: 100%;
        height: calc(100% - 22rem);
        overflow-x: auto;
    }
    .tab .tab_th{
        min-width: 75rem;
    }
    .box_tr{
        height: calc(100% - 3rem);
        width: 100%;
        min-width: 75rem;
        overflow-x: hidden;
    }
    .tab .tab_tr{
        width: 100%;
    }
</style>
