<template>
    <div class="container">
        <el-form label-width="90px" size="mini" class="form">
            <el-row type="flex" :gutter="10">
                <el-col :span="6" :lg="5">
                    <el-form-item label="运动项目：">
                        <el-select v-model="eventsId" @change="eventsChange" style="max-width: 200px">
                            <el-option v-for="(item,index) in eventsList" :value="item.Id" :label="item.Name" :key="index"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="18" :lg="19">
                    <el-row type="flex" justify="space-between">
                        <el-form-item label="状态：">
                            <status :list="statusList" :status-code="statusCode" @change="statusChange"></status>
                        </el-form-item>
                        <div>
                            <el-button type="warning" size="mini" v-href="'/refereeOrCoach/trainingManagement/Detail/0'">+创建培训</el-button>
                        </div>
                    </el-row>
                </el-col>
            </el-row>
        </el-form>
        <el-row style="height:calc(100% - 110px)">
            <el-table
                stripe
                style="width: 100%"
                height="100%"
                v-loading="loading"
                element-loading-text="数据加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :data="list"
                size="mini"
            >
                <el-table-column prop="TrainDescription.EventsName" label="运动项目" align="center" width="120px"></el-table-column>
                <el-table-column label="培训类型" align="center" width="120px">
                    <template slot-scope="scope">
                        {{['','裁判员','教练员','裁判员 教练员'][scope.row.TrainDescription.TrainType]}}
                    </template>
                </el-table-column>
                <el-table-column prop="TrainDescription.Name" label="培训名称" align="center"></el-table-column>
                <el-table-column label="培训时间" align="center">
                    <template slot-scope="scope">
                        {{dateFormat(scope.row.TrainTime.BeginTime,'yyyy-MM-dd HH:mm:ss')}}~{{dateFormat(scope.row.TrainTime.EndTime,'yyyy-MM-dd HH:mm:ss')}}
                    </template>
                </el-table-column>
                <el-table-column label="培训内容" align="center" width="100px">
                    <template slot-scope="scope">
                        {{scope.row.TainClassCount}}项
                        <!-- <span class="trainingClass" @click="lookTrainingClass(scope.row)">{{scope.row.User4Coach.EventsName}}</span> -->
                    </template>
                    <span></span>
                </el-table-column>
                <el-table-column label="状态" align="center" width="80px">
                    <template slot-scope="scope">
                        {{getStatusText(scope.row.TrainStatus)}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <!-- <el-button type="text" v-href="`/refereeOrCoach/CoachDetail/${scope.row.User4Coach.Id}`">查看</el-button> -->
                        <el-button type="text" v-href="`/refereeOrCoach/trainingManagement/Detail/${scope.row.TrainId}`" v-if="[1,4].includes(scope.row.TrainStatus)">编辑</el-button>
                        <el-button type="text" v-if="[1].includes(scope.row.TrainStatus)" @click="publish(scope.row.TrainId)">发布</el-button>
                        <!-- <el-button type="text">报名册</el-button>
                        <el-button type="text">成绩册</el-button>-->
                        <el-button 
                            type="text"
                            @click="newsChannel(scope.row.TrainId)"
                        >关联资讯栏目<template v-if="scope.row.ChannelCount > 0">（{{scope.row.ChannelCount}})</template>
                        </el-button>
                        <!--<el-button type="text">存档</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
        </el-row>
        <el-dialog title="关联资讯栏目设置" :visible.sync="channelShow" :close-on-click-modal="false" :destroy-on-close="true" width="400px" >
            <el-form ref="channelForm" :model="channelForm" size="small" >
                <el-row v-for="item,index in channelForm.data" :key="index">
                    <el-col :span="8">
                        <el-form-item :prop="`data.${index}.checked`" style="margin:0 !important">
                            <el-checkbox v-model="item.checked">{{item.label}}</el-checkbox>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="修改名称" :prop="`data.${index}.name`" :rules="channelRules.name" style="margin:0 !important">
                            <el-input v-model="item.name" style="width: 100px"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="channelShow = false">取 消</el-button>
                <el-button type="primary" @click="submitChannel">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import status from '@/components/status.vue';
import utils from '@/utils';
export default {
    name: 'trainingManagementList',
    components:{
        status
    },
    data() {
        return {
            eventsId: 0,
            eventsList: [],
            statusCode: null,
            statusList: [
                {label: '全部', code: null},
                {label: '未发布', code: 1},
                {label: '已发布', code: 4},
                {label: '已存档', code: 5}
            ],
            list: [],
            loading: false,
            channelShow: false,
            channelForm: {
                id: 0,
                data: []
            },
            channelRules:{
                name:[{
                    validator: (rule, value, callback)=>{
                        if(value){
                            if(value.length < 2 || value.length > 4){
                                callback(new Error('请输入2~4个字符！'));
                            }else{
                                callback();
                            }
                        }else{
                            callback();
                        }
                    },
                    trigger: 'blur'
                }]
            }
        };
    },

    created() {
        this.$api.GetFlattedEventsList({
            'AllName': "全部"
        }).then(data=>{
            if(data.Code == 0){
                this.eventsList = data.EventsList || [];
            }
        })
        this.getDataList();
    },

    methods: {
        dateFormat: utils.dateFormat,
        getDataList(){
            this.loading = true;
            this.$api.GetTrainManageList({
                "EventsId": this.eventsId,
                "TrainStatus": this.statusCode
            }).then(data=>{
                this.loading = false;
                if(data.Code == 0){
                    this.list = data.TrainList;
                }
            })
        },
        eventsChange(){
            this.getDataList();
        },
        statusChange(code){
            if(this.statusCode != code){
                this.statusCode = code;
                this.getDataList();
            }
        },
        getStatusText(status){
            return (this.statusList.filter(item=>item.code == status)[0] || {}).label;
        },
        lookTrainingClass(){

        },
        //培训发布
        publish(Id){
            this.$confirm('发布之后不可修改培训详情，确认发布？').then(() => {
                let loading = this.$loading({
                    lock: true,
                    text: '正在发布',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.$api.PublishTrain({
                    "TrainId": Id
                }).then((data) => {
                    loading.close();
                    if(data.Code == 0){
                        this.$message.success('发布成功');
                        this.getDataList();
                    }
                }).catch(() => {
                    loading.close();
                })
            })
        },
         //关联资讯栏目
        initChannel(){
            this.$set(this.channelForm, 'data', [{
                label:'公告资讯',
                type: "notice",
                id: 0,
                checked: false,
                isContent: false,
                name: ""
            },{
                label:'考核公示',
                type: "progress",
                id: 0,
                checked: false,
                isContent: false,
                name: ""
            },{
                label:'培训现场',
                type: "album",
                id: 0,
                checked: false,
                isContent: false,
                name: ""
            }]);
        },
        newsChannel(trainId){
            let loading = this.$loading();
            this.channelForm.id = trainId;
            this.initChannel();
            this.$api.GetTrainSscmsChannelConfig({
                TrainId: trainId
            }).then(data=>{
                loading.close();
                if(data.Code == 0){
                    this.channelShow = true;
                    let channels = this.channelForm.data,
                        arr = data.ChannelConfigs || [];
                    for(let item of channels){
                        let obj = arr.filter(n => n.ChannelType == item.type)[0];
                        if(obj){
                            item.id = obj.ChannelId;
                            item.name = item.label != obj.ChannelName ? obj.ChannelName : '';
                            item.checked = true;
                            item.isContent = obj.CountOfContents == 1;
                        }
                    }
                }
            }).catch(()=>{
                loading.close();
            })
        },
        submitChannel(){
            this.$refs['channelForm'].validate((valid)=>{
                if(valid){
                    let channels = this.channelForm.data,
                        arr = [],
                        deletes = [];
                    for(let item of channels){
                        if(!item.checked && item.isContent){
                            deletes.push(item.label);
                        }
                        if(item.checked){
                            arr.push({
                                "type": item.type,
                                "name": item.name || item.label,
                                "id": item.id
                            })
                        }
                    }
                    if(deletes.length > 0){
                        this.$confirm(`栏目${deletes.join('、')}下已发布内容，是否确认删除？`).then(() => {
                            this.saveChannel(this.channelForm.id, arr);
                        })
                    }else{
                        this.saveChannel(this.channelForm.id, arr);
                    }
                }
            })
        },
        saveChannel(TrainId, channels){
            this.$api.SaveTrainSscmsChannelConfig({
                "TrainId": TrainId,
                "ChannelConfigs": channels
            }).then(data=>{
                if(data.Code == 0){
                    this.$message.success('修改成功');
                    this.getDataList();
                    this.channelShow = false;
                }
            })
        }
    },
};
</script>
<style scoped>
@import "~@/assets/css/clear-el.css";
.trainingClass{
    color: dodgerblue;
    text-decoration: underline;
}
</style>