<template>
    <div class="pass view">
       <StatusBar
            :tit="'状态'"
            :currStatus="currStatus"
            :json_status="json_status"
            @statusHandle="statusHandle($event)"
            />
        <div class="tab">
            <div class="tab_th clearfix">
                <div class="fl td_1">序号</div>
                <div class="fl td_2">赛事名称</div>
                <div class="fl td_3">比赛形式</div>
                <div class="fl td_2">比赛时间</div>
                <div class="fl td_3">特殊状态</div>
                <div class="fl td_3">审核状态</div>
                <div class="fl td_3">操作</div>
            </div>
            <div class="box_tr" v-if="dataAll.length == 0">
                <p class="noData" v-text="listLoadTip"></p>
            </div>
            <div class="box_tr" v-else>
                <div class="tab_tr clearfix" v-for="(item, i) in dataAll" :key="i">
                    <div class="fl td_1">
                        <span v-text="item.rownum"></span>
                    </div>
                    <div class="fl td_2">
                        <span v-text="item.league_full_name || '-'"></span>
                    </div>
                    <div class="fl td_3">
                        <span v-text="league_form[item.league_form]"></span>
                    </div>
                    <div class="fl td_2">
                        <span v-if="item.begin_time && item.end_time">
                            {{new Date(item.begin_time).format('yyyy-MM-dd HH:mm')}}
                            ~
                            {{new Date(item.end_time).format('yyyy-MM-dd HH:mm')}}
                        </span>
                        <span v-else>-</span>
                    </div>
                    <div class="fl td_3">
                        <span v-text="['-','赛事取消','赛事延期','赛事中断'][item.abnormal_status || 0]"></span>
                    </div>
                    <div class="fl td_3">
                        <span v-text="item.league_status || '-'"></span>
                    </div>
                    <div class="fl td_3">
                        <span>
                            <router-link
                                :to="'/match/pass/contestDetail/' + item.league_id"
                                v-text="item.league_status_number == 2? '去审核': '查看详情'"
                                ></router-link>
                        </span>
                    </div>
                </div>
            </div>
            <PageHandle 
                :page="page"
                :totalPage="totalPage"
                :totalCount="totalCount"
                @pageTo="pageTo($event)" 
                />
        </div>
        <TipHandle 
            :txt="tip"
            :btnTxt="btnTxt"
            @maskHandle="maskHandle($event)" 
            />
    </div>
</template>

<script>
import StatusBar from '@/components/StatusBar.vue'
import PageHandle from '@/components/PageHandle.vue'
import TipHandle from '@/components/TipHandle.vue'
import { toQueryString } from '@/assets/js/util'

export default {
    name: 'Pass',
    props: {},
    components: {
        StatusBar,
        PageHandle,
        TipHandle
    },
    data() {
        return {
            token: this.$cookies.get('token'),
            page: 1,
            count: 20,
            totalPage: 1,
            totalCount: 1,
            currStatus: 0,
            json_status: {
                0: '全部',
                2: '待审核',
                3: '未通过',
                4: '已通过',
            },
            dataAll: [],
            getListFlag: true,
            tip: '',
            btnTxt: [],
            listLoadTip: '',
            league_form:  {
                1: '线下赛',
                2: '线上赛'
            },
        }
    },
    mounted() {
       this.getList()
    },
    methods: {
        handleTips(txt, btnTxt){
            this.tip = txt
            this.btnTxt = btnTxt
        },
        maskHandle(btn){
            if(btn == 0){
                // 确认发布
            }
            this.tip = ''
        },
        statusHandle(status) {
            if(!this.getListFlag) {
                return
            }
            this.page = 1
            this.currStatus = status
            this.getList()
        },
        pageTo(page) {
            if(!this.getListFlag) {
                return
            }
            if(page == '-' && this.page > 1) {
                this.page --
                this.getList()
            } else if(page == '+' && this.page < this.totalPage){
                this.page ++
                this.getList()
            } else if(page != this.page && typeof page == 'number') {
                if(page > this.totalPage || page < 1) {
                    this.handleTips('请输入1~' + this.totalPage + '的数', ['关闭'])
                    return
                }
                this.page = page
                this.getList()
            }
            
        },
        getList() {
            if(this.getListFlag) {
                this.getListFlag = false
            } else {
                return
            }
            this.dataAll = []
            this.listLoadTip = '数据加载中...'
            this.$axios.post('Match/Entrance',toQueryString({
                action: 'GetMyleagueList',
                user_token:this.token,
                league_status: this.currStatus,
                page: this.page,
                count: this.count,
                type: 5,
                league_id: 0
            })).then((res) => {
                this.getListFlag = true
                let data = res.data;
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                if(data != -1){
                    if(data.leagueList.length == 0){
                        this.listLoadTip = '无数据'
                    }
                    this.dataAll = data.leagueList;
                    this.totalCount = data.totalCount
                    this.totalPage = Math.ceil(data.totalCount/this.count)
                } else {
                    this.listLoadTip = data.message
                }
            }).catch(() => {
                this.listLoadTip = '网络异常'
                this.getListFlag = true
            })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .tab{height: calc(100% - 3rem);}
    .tab .td_2{width: 43%; min-width: 27rem;}
</style>
