<template>
    <div class="view">
        <div class="head_desc clearfix">
            <div class="addMatch">
                <div>
                    <h2>主页列表管理</h2>
                    <router-link to="/infos/APPHomeListSort" class="btn">手工排序</router-link>
                </div>
                <router-link to="/infos/AddNewsToHome" class="btn">+推荐新闻到首页</router-link>
            </div>
        </div>
        <div class="tab">
            <div class="tab_th clearfix">
                <div class="fl td_1"><span>序号</span></div>
                <div class="fl td_1"><span>类型</span></div>
                <div class="fl td_4"><span>标题</span></div>
                <div class="fl td_3"><span>发布时间</span></div>
                <div class="fl td_3"><span>操作</span></div>
            </div>
            <div class="box_tr" v-if="dataAll.length == 0">
                <p class="noData" v-text="listLoadTip"></p>
            </div>
            <div class="box_tr" v-else>
                <div class="tab_tr clearfix" v-for="(item, i) in dataAll" :key="i">
                    <div class="fl td_1">
                        <span>{{(page - 1) * count + i + 1}}</span>
                    </div>
                    <div class="fl td_1">
                        <span v-if="item.EntryType == 1">赛事</span>
                        <span v-else-if="item.EntryType == 2">培训</span>
                        <span v-else-if="item.EntryType == 90">新闻</span>
                    </div>
                    <div class="fl td_4">
                        <span>{{item.Title}}</span>
                    </div>
                    <div class="fl td_3">
                        <span>{{item.PublishTime}}</span>
                    </div>
                    <div class="fl td_3">
                        <span><a href="javascript:;" @click="cancel(item.Id)" v-if="item.EntryType == 90">取消推荐到首页</a></span>
                    </div>
                </div>
            </div>
            <PageHandle 
                :page="page"
                :totalPage="totalPage"
                :totalCount="totalCount"
                @pageTo="pageTo($event)" 
                />
        </div>
        <TipHandle 
            :txt="tip"
            :btnTxt="btnTxt"
            @maskHandle="maskHandle($event)" 
            />
    </div>
</template>

<script>
import PageHandle from '@/components/PageHandle.vue'
import TipHandle from '@/components/TipHandle.vue'
export default {
    name: 'APPHomeList',
    components: {
        TipHandle,
        PageHandle
    },
    props: {
    },
    data() {
        return {
            page: 1,
            count: 20,
            totalPage: 1,
            totalCount: 1,
            tip: '',
            btnTxt: [],
            dataAll:[],
            getListFlag: true,
            listLoadTip: ''
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        handleTips(txt, btnTxt){
            this.tip = txt
            this.btnTxt = btnTxt
        },
        maskHandle(btn){
            if(btn == 0){
                // 确认发布
            }
            this.tip = ''
        },
        pageTo(page) {
            if(!this.getListFlag) {
                return
            }
            if(page == '-' && this.page > 1) {
                this.page --
                this.getList()
            } else if(page == '+' && this.page < this.totalPage){
                this.page ++
                this.getList()
            } else if(page != this.page && typeof page == 'number') {
                if(page > this.totalPage || page < 1) {
                    this.handleTips('请输入1~' + this.totalPage + '的数', ['关闭'])
                    return
                }
                this.page = page
                this.getList()
            }
            
        },
        getList(){
            if(!this.getListFlag)return;
            this.getListFlag = false;
            this.dataAll = [];
            this.listLoadTip = '数据加载中...';
            this.$api.GetManageMainListEntryPageList({
                PageParm: {
                    PageSize: this.count,
                    PageNum: this.page
                }
            }).then(data=>{
                this.getListFlag = true;
                if(data.Code == 0){
                    this.dataAll = data.PageList;
                    this.page = data.PageParm.PageNum;
                    this.totalPage = data.PageParm.PageCount;
                    this.totalCount = data.PageParm.TotalCount;
                }
            }).catch(()=>{
                this.listLoadTip = '网络异常';
                this.getListFlag = true;
            })
        },
        cancel(id){
            this.$api.DelNewsMainListEntry({
                NewsId: id
            }).then(data=>{
                if(data.Code == 0){
                    this.handleTips('取消成功',['确定']);
                    this.getList();
                }else{
                    this.handleTips(data.Message, ['关闭']);
                }
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '~@/assets/css/common_z.css';
.head_desc {
    height: 5rem;
}
.head_desc .addMatch{
    display: flex;
    justify-content: space-between;
}
.head_desc h2{
    font-size: 28px;
    display: inline-block;
    margin-right: 2rem;
}
.tab{
    height: calc(100% - 5rem)
}
</style>
