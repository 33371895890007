<template>
    <!-- 运动员等级评定 详情/审核 -->
    <div class="view">
        <router-link to="/grade/athleteRating" class="backBtn"> &lt; 返回</router-link>
        <span v-show="pageTit" v-text="'当前：' + pageTit"></span>
        <div class="infosBox">
            <div class="basicInfo">
                <div class="group">
                    <label v-text="apply_type == 1?'申请机构：': '申请人：'"></label>
                    <span v-text="applyer"></span>
                </div>
                <div class="group">
                    <label>申请时间：</label>
                    <span v-text="apply_time"></span>
                </div>
                <br>
                <div class="group">
                    <label>申请项目：</label>
                    <span v-text="event_name"></span>
                </div>
                <div class="group">
                    <label>组别：</label>
                    <span v-text="group_name"></span>
                </div>
                <div class="group">
                    <label>赛事全称：</label>
                    <span v-text="league_name"></span>
                </div>
                <div class="group">
                    <label>比赛成绩：</label>
                    <span v-text="match_score? ('第'+match_score+'名'):'-'"></span>
                </div>
                <div class="tab applyList">
                    <div class="tab_th clearfix">
                        <div class="fl td_1"><span>申请人姓名</span></div>
                        <div class="fl td_0"><span>性别</span></div>
                        <div class="fl td_3"><span>会员号</span></div>
                        <!-- <div class="fl td_1"><span>理论测评</span></div> -->
                        <div class="fl td_3"><span>当前等级</span></div>
                        <div class="fl td_1"><span>申请等级</span></div>
                    </div>
                    <div class="box_tr" v-if="eventList.length == 0">
                        <p class="noData" v-text="listLoadTip"></p>
                    </div>
                    <div class="box_tr" v-else>
                        <div class="tab_tr clearfix" v-for="(item, i) in eventList" :key="i">
                            <div class="fl td_1">
                                <span v-text="item.name"></span>
                            </div>
                            <div class="fl td_0">
                                <span v-text="item.sex==1?'男':'女'"></span>
                            </div>
                            <div class="fl td_3">
                                <span v-text="item.member_no"></span>
                            </div>
                            <!-- <div class="fl td_1">
                                <span v-text="item.if_reach"></span>
                            </div> -->
                            <div class="fl td_3">
                                <span v-text="item.cur_level_name"></span>
                            </div>
                            <div class="fl td_1">
                                <span v-text="item.levels_name"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="group">
                    <label>附件：</label>
                    <a v-if="fileLink" :href="fileLink" target="_blank">点击下载</a>
                    <span v-else>-</span>
                </div> -->
                <div class="group">
                    <label>审核结果：</label>
                    <div class="radioBox">
                        <input type="radio" name="result" id="result1" v-model="apply_result" value="1" :disabled="apply_result_name != '待审核'"><label for="result1">通过</label>
                        <input type="radio" name="result" id="result2" v-model="apply_result" value="2" :disabled="apply_result_name != '待审核'"><label for="result2">不通过</label>
                    </div>
                </div>
                <div>

                </div>
                <div class="group">
                    <label>备注：</label>
                    <textarea class="remark" v-model.trim="memo" cols="60" rows="10" maxlength="200" :disabled="apply_result_name != '待审核'"></textarea>
                </div>
            </div>
            <br>
            <br>
        </div>
        <div class="handleBox" v-if="apply_result_name == '待审核'">
            <button @click="submitHandle()" v-text="btn_txt"></button>
        </div>
        <TipHandle 
            :txt="tip"
            :btnTxt="btnTxt"
            @maskHandle="maskHandle($event)" 
            />
    </div>
</template>

<script>
import TipHandle from '@/components/TipHandle.vue'
import { toQueryString } from '@/assets/js/util'
import{
    ApproveAthleteLevelCertificateApply,
    RejectAthleteLevelCertificateApply
}from '@/newApi'

export default {
    name: 'Verify',
    components: {
        TipHandle
    },
    props: {},
    data() {
        return {
            token: this.$cookies.get('token'),
            urlId:this.$route.params.id,
            tip: '',
            btnTxt: [],
            pageTit: '',
            apply_type: 1,
            applyer: '',
            apply_time: '',
            event_name: '',
            group_name: '',
            league_name: '',
            match_score: '',
            eventList: [],
            apply_result_name: '',
            apply_result: 0,
            // fileLink: '',
            memo: '',
            btn_txt: '提交',
            listLoadTip: '',
            submitFlag:true,
            applyId:0
        }
    },
    mounted(){
        // this.urlId = this.$route.params.id
        if(this.urlId > 0){
            this.getDetail()
        }
    },
    methods: {
        handleTips(txt, btnTxt){
            this.tip = txt
            this.btnTxt = btnTxt
        },
        maskHandle(btn){
            if(btn == 0) {
                this.changeStatus()
            } else if(btn == 1){
                // 提交成功，返回列表
                this.$router.push('/grade/athleteRating')
            }
            this.tip = ''
        },
        //获取详情
        getDetail () {
            this.listLoadTip = '数据加载中...'
            this.$axios.post('CESA/Entrance', toQueryString({
                action: 'Get_larByapply_id',
                user_token: this.token,
                apply_id: this.urlId
            })).then((res) => {
                let data = res.data
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                if(data.code == 1) {
                    this.apply_type = data.apply_type
                    this.applyer = data.applyer
                    this.apply_time = data.apply_time
                    this.league_name = data.league_name
                    this.event_name = data.event_name
                    this.group_name = data.group_name
                    this.match_score = data.match_score
                    this.eventList = data.eventList
                    this.accessory = data.accessory
                    // this.fileLink = data.imgServiceForPublic + data.accessory.replace('/uploadfile','')
                    this.memo = data.memo
                    this.apply_result = data.apply_result
                    this.apply_result_name = data.apply_result_name
                    if(this.apply_result_name == '待审核') {
                        this.pageTit = '等级评定审核'
                    } else {
                        this.pageTit = '查看等级评定详情'
                    }
                } else {
                    this.handleTips(data.message, ['关闭'])
                }
            }).catch(() => {
                this.handleTips('网络异常', ['关闭'])
            });
        },
        // 提交审核
        submitHandle() {
            if(!this.submitFlag) {
                return
            }
            if(this.apply_result == 0) {
                this.handleTips('请选择审核结果', ['关闭'])
                return
            } else if(this.apply_result == 1) {
                this.handleTips('确认通过审核？', ['确认', '关闭'])
            } else if(this.apply_result == 2) {
                if(!this.memo) {
                    this.handleTips('请填写备注', ['关闭'])
                    return
                }
                this.handleTips('确认不通过审核？', ['确认', '关闭'])
            }
        },
        // 确认通过(1) or 不通过(2)
        changeStatus() {
            if(this.submitFlag) {
                this.submitFlag = false
            } else {
                return
            }
            this.btn_txt = '提交中...'
            if(this.apply_result==1){
                ApproveAthleteLevelCertificateApply({
                    "ApplyId": this.urlId
                }).then((res) => {
                    this.btn_txt = '提交'
                    if(res.Code == -200) {
                        this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    } else if(res.Code == 0) {
                        this.handleTips('提交成功', ['', '返回列表'])
                    } else {
                        this.submitFlag = true
                        this.handleTips(res.Message, ['关闭'])
                    }
                })
            }else{
                RejectAthleteLevelCertificateApply({
                    "ApplyId": this.urlId,
                    "RejectReason": this.memo
                }).then((res) => {
                    this.btn_txt = '提交'
                    if(res.Code == -200) {
                        this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    } else if(res.Code == 0) {
                        this.handleTips('提交成功', ['', '返回列表'])
                    } else {
                        this.submitFlag = true
                        this.handleTips(res.Message, ['关闭'])
                    }
                })
            }
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .infosBox label{
        width: 7rem;
    }
    .group .radioBox{
        display: inline-block;
    }
    .group .radioBox label{
        width: 5rem;
        margin-right: 1.5rem;
        text-align: left;
        cursor: pointer;
    }
    .applyList{
        border: 1px solid #ccc;
        border-radius: .6rem;
        padding: .4rem 1rem;
        margin: 1rem;
    }
    .infosBox .group{
        line-height: 2.2rem;
    }
    input[type="radio"]{vertical-align:middle;display:none;}
    input[type='radio']+label:before{  
        content:'';display:inline-block;width:1.2rem;height:1.2rem;margin:-2px 5px 0 0;border-radius:100%;vertical-align:middle;border:1px solid #956435;}
    input[type='radio']:checked+label:before{background:#F57F00 url('~@/assets/imgs/yes.png') no-repeat center center;background-size:80%;}
</style>
