export function cleanArray(actual) {
    const newArray = [];
    for (let i = 0; i < actual.length; i++) {
      if (actual[i]) {
        newArray.push(actual[i]);
      }
    }
    return newArray;
}
export function toQueryString(obj) {
    if (!obj) return "";
    return cleanArray(
      Object.keys(obj).map(key => {
        if (obj[key] === undefined) return "";
        return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
      })
    ).join("&");
}
export function splitStr(str, len) {
    let _str = str || ''
    let _tit = ''
    if(str && str.length > len) {
        _tit = str
        _str = str.substring(0, len) + '...'
    }
    return {s: _str, t: _tit}
}
export function dateFormat(value,fmt){
  if(!value) return "";
  var date = new Date(value);
  var o = {
      "M+": date.getMonth() + 1,
      "d+": date.getDate(),
      "H+": date.getHours(),
      "m+": date.getMinutes(),
      "s+": date.getSeconds(),
      "q+": Math.floor((date.getMonth() + 3) / 3),
      "S": date.getMilliseconds()
  };
  fmt = fmt || "yyyy-MM-dd";
  if (/(y+)/.test(fmt))
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
      for (var k in o)
      if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}