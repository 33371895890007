<template>
    <!-- 证书查询 -->
    <div class="view">
        <div class="srchBox clearfix">
            <div class="fl ml_10 mr_10">
                <label for="">项目：</label>
                <select v-model="event_code">
                    <option value="0">请选择项目</option>
                    <option 
                        v-for="(item, i) in eventList" 
                        :key="i"
                        :value="item.Key"
                        v-text="item.Value"
                        ></option>
                </select>
            </div>
            <!-- <div class="fl ml_10 mr_10">
                <label for="">组别：</label>
                <select v-model="group_code">
                    <option value="0">请选择组别</option>
                    <option 
                        v-for="(item, i) in groupList" 
                        :key="i"
                        :value="item.group_code"
                        v-text="item.group_name"
                        ></option>
                </select>
            </div> -->
            <div class="fl ml_10">
                <label for="">等级：</label>
                <select v-model="lvl">
                    <option value="0">请选择等级</option>
                    <option 
                        v-for="(item, i) in lvlList" 
                        :key="i"
                        :value="item.Key"
                        v-text="item.Value"
                        ></option>
                </select>
            </div>
        </div>
        <div class="srchBox clearfix">
            <div class="fl ml_10 mr_10">
                <label for="">证书编号：</label>
                <input type="text" v-model="certificate_no">
            </div>
            <div class="fl ml_10 mr_10">
                <label for="">会员号：</label>
                <input type="text" v-model="member_no">
            </div>
            <div class="fl ml_10">
                <button class="btn" @click="srchHandle">查询</button>
            </div>
        </div>
        <div class="tab">
            <div class="tab_th clearfix">
                <div class="fl td_1"><span>序号</span></div>
                <div class="fl td_1"><span>发证日期</span></div>
                <div class="fl td_3"><span>证书编号</span></div>
                <div class="fl td_1"><span>项目</span></div>
                <div class="fl td_1"><span>组别</span></div>
                <div class="fl td_1"><span>等级</span></div>
                <div class="fl td_1"><span>姓名</span></div>
                <div class="fl td_1"><span>会员号</span></div>
            </div>
            <div class="box_tr" v-if="dataAll.length == 0">
                <p class="noData" v-text="listLoadTip"></p>
            </div>
            <div class="box_tr" v-else>
                <div class="tab_tr clearfix" v-for="(item, i) in dataAll" :key="i">
                    <div class="fl td_1">
                        <span v-text="item.rownum"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="item.pass_date"></span>
                    </div>
                    <div class="fl td_3">
                        <span v-text="item.certificate_no || '-'"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="item.event_name || '-'"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="item.group_name"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="item.cur_level || '-'"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="item.name"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="item.member_no"></span>
                    </div>
                </div>
            </div>
            <PageHandle 
                :page="page"
                :totalPage="totalPage"
                :totalCount="totalCount"
                @pageTo="pageTo($event)" 
                />
        </div>
        <TipHandle 
            :txt="tip"
            :btnTxt="btnTxt"
            @maskHandle="maskHandle($event)" 
            />
    </div>
</template>

<script>

import PageHandle from '@/components/PageHandle.vue'
import TipHandle from '@/components/TipHandle.vue'
import { toQueryString } from '@/assets/js/util'

export default {
    name: 'Certificate',
    components: {
        TipHandle,
        PageHandle
    },
    props: {},
    data() {
        return {
            token: this.$cookies.get('token'),
            tip: '',
            btnTxt: [],
            page: 1,
            count: 20,
            totalPage: 1,
            totalCount: 1,
            dataAll:[],
            getListFlag: true,
            event_code: 0,
            eventList: [],
            // group_code: 0,
            groupList: [],
            lvl: 0,
            lvlList: [],
            certificate_no: '',
            member_no:'',
            listLoadTip: ''
        }
    },
    mounted() {
        this.getList()
        this.getItemGroup()
        this.getLvl()
    },
    methods: {
        handleTips(txt, btnTxt) {
            this.tip = txt
            this.btnTxt = btnTxt
        },
        maskHandle(btn) {
            if(btn == 0) {
                // 确认发布
            }
            this.tip = ''
        },
        pageTo(page) {
            if(!this.getListFlag) {
                return
            }
            if(page == '-' && this.page > 1) {
                this.page --
                this.getList()
            } else if(page == '+' && this.page < this.totalPage){
                this.page ++
                this.getList()
            } else if(page != this.page && typeof page == 'number') {
                if(page > this.totalPage || page < 1) {
                    this.handleTips('请输入1~' + this.totalPage + '的数', ['关闭'])
                    return
                }
                this.page = page
                this.getList()
            }
            
        },
        srchHandle() {
            if(!this.getListFlag) {
                return
            }
            this.page = 1
            this.getList()
        },
        // 证书查询列表
        getList(){
            if(this.getListFlag) {
                this.getListFlag = false
            } else {
                return
            }
            this.dataAll = []
            this.listLoadTip = '数据加载中...'
            this.$axios.post('CESA/Entrance',toQueryString({
                action: 'Get_Manage_level_applyer',
                user_token:this.token,
                event_code: this.event_code==0?'':this.event_code,
                // group_code: this.group_code==0?'':this.group_code,
                group_code: '',
                cur_level: this.lvl== 0?'':this.lvl,
                certificate_no: this.certificate_no,
                member_no: this.member_no,
                page: this.page,
                count: this.count
            })).then((res) => {
                this.getListFlag = true
                let data = res.data
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                if(data.code == 1) {
                    if(data.newList.length == 0){
                        this.listLoadTip = '无数据'
                    }
                    this.dataAll = data.newList
                    this.totalCount = data.totalCount
                    this.totalPage = Math.ceil(data.totalCount/this.count)
                } else {
                    this.listLoadTip = data.message
                }
            }).catch(() => {
                this.listLoadTip = '网络异常'
                this.getListFlag = true
            })
        },
        // 获取项目及组别
        getItemGroup(){
            this.$axios.post('CESA/Entrance',toQueryString({
                action: 'Get_dicts_KeyValue',
                dict_key: 'events_id',
                user_token:this.token
            })).then((res) => {
                let data = res.data;
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                if(data.code == 1) {
                    this.eventList = data.list
                    // this.group_code = 0
                    // if(this.event_code == 0) {
                    //     this.eventList = data.eventList
                    //     this.groupList = []
                    // } else {
                    //     this.groupList = data.groupList
                    // }
                } else {
                    this.handleTips(data.message, ['关闭'])
                }
            }).catch(() => {
                this.handleTips('网络异常', ['关闭'])
            })
        },
        // 获取等级
        getLvl() {
            this.$axios.post('CESA/Entrance', toQueryString({
                action: 'Get_dicts_KeyValue',
                dict_key: 'levels',
                user_token: this.token
            })).then((res) => {
                let data = res.data;
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                if(data.code == 1) {
                    this.lvlList = data.list
                } else {
                    this.handleTips(data.message, ['关闭'])
                }
            }).catch(() => {
                this.handleTips('网络异常', ['关闭'])
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .srchBox{
        height: 3rem;
        line-height: 3rem;
    }
    .srchBox input, .srchBox select{
        height: 2rem;
        width: 9.4rem;
    }
    .srchBox input{width: 15rem;}
    .tab{
        width: 100%;
        height: calc(100% - 6rem);
    }
    .tab .tab_th div{
        display: table;
        height: 2.5rem;
    }
    .tab .tab_th span{
        line-height: 1rem;
        display: table-cell;
        vertical-align: middle;
    }
</style>
