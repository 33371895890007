<template>
  <div class="pageBtnBox clearfix">
        <button class="first" @click="pageToFirst()">首页</button>
        <button class="pre" @click="pagePre()">上一页</button>
        <div class="numpage">第<span id="currPage" v-text="page"></span>页</div>
        <button class="next" @click="pageNext()">下一页</button>
        <button class="end" @click="pageToEnd()">尾页</button>
        <div>(共<span id="totalPage" v-text="totalPage"></span>页，共<span id="ttCount" v-text="totalCount"></span>条)</div>
        <div class="pageLink">
            <span class="left">跳转/</span>
            <input class="goSize left" type="number" min="1" value=" " size="4" v-model="toLinkPage" @keyup.enter="pageTo()" placeholder="页码">
            <div class="jump" @click="pageTo()"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageHandle',
    components: {
    },
    props:['page', 'totalPage', 'totalCount'],
    created() {
    },
    mounted() {
    },
    updated() {
    },
    data(){
        return {
            toLinkPage: '',
        }
    },
    methods: {
        pageToFirst(){
            this.$emit('pageTo', 1)
        },
        pagePre(){
            this.$emit('pageTo', '-')
        },
        pageNext(){
            this.$emit('pageTo', '+')
        },
        pageToEnd(){
            this.$emit('pageTo', this.totalPage)
        },
        pageTo(){
            if(!this.toLinkPage || this.totalPage == 1) {
                return
            }
            this.$emit('pageTo', parseInt(this.toLinkPage))
        }
    }
}
</script>
<style scoped>
    .pageBtnBox{
        border-top: .1rem solid #273768;
        padding: 1rem 0 0 2%;
        text-align:center;
        height: 3rem;
    }
    .pageBtnBox button,
    .pageBtnBox a{
        float: left;
        background: #C91E25;
        color: #fff;
        margin: 0 .2rem;
        padding: .2rem 1rem;
        border: none;
        cursor: pointer;
    }
    .pageBtnBox div{
        float: left;
        margin: 0 .5rem;
    }
    .pageBtnBox .pageLink input{
        width: 3rem;
        height: 1.6rem;
        padding: 0 .3rem;
    }
    .pageBtnBox .pageLink .jump{
        cursor: pointer;
        width: 1.6rem;
        height: 1.6rem;
        background: #C91E25 url(~@/assets/imgs/icon_r.png) no-repeat center;
        background-size: .5rem;
        float: right;
        margin: 0 .4rem;
    }
</style>