<template>
  <div class="pagination">
        <el-button @click="go('first')" :disabled="prevDisabled">首页</el-button>
        <el-button @click="go('prev')" :disabled="prevDisabled">上一页</el-button>
        <span>{{pageText}}</span>
        <el-button @click="go('next')" :disabled="nextDisabled">下一页</el-button>
        <el-button @click="go('last')" :disabled="nextDisabled">尾页</el-button>
        <span>{{totalText}}</span>
        <div class="page_link">
            <span>前往</span>
            <el-input type="number" placeholder="页码" size="mini" v-model="pageValue" @keyup.enter="go()"></el-input>
            <span>页</span>
            <el-button @click="go()" :disabled="goDisabled">GO</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'pagination',
    props:{
        'current-page':{
            type: Number,
            default: 1,
            required: true
        },
        'page-size':{
            type: Number,
            default: 10
        },
        'total':{
            type: Number,
            required: true
        }
    },
    data(){
        return {
            pageValue: null
        }
    },
    computed:{
        totalPage(){
            return Math.ceil(this.total / this.pageSize);
        },
        pageText(){
            return `第 ${this.currentPage} 页`;
        },
        totalText(){
            return `（共 ${this.totalPage} 页，共 ${this.total} 条）`;
        },
        prevDisabled(){
            return this.currentPage == 1;
        },
        nextDisabled(){
            return this.currentPage == this.totalPage;
        },
        goDisabled(){
            return this.totalPage < 2;
        }
    },
    methods: {
        go(type){
            let currentPage = this.currentPage;
            let isChange = true;
            switch(type){
                case 'first':
                    currentPage = 1;
                    break;
                case 'prev':
                    currentPage--;
                    break;
                case 'next':
                    currentPage++;
                    break;
                case 'last':
                    currentPage = this.totalPage;
                    break;
                default:
                    var page = +this.pageValue;
                    if(isNaN(page) || page > this.totalPage || page < 1){
                        this.$message.warning(`不是一个有效的值，1 ~ ${this.totalPage}`);
                        isChange = false;
                    }else{
                        currentPage = Math.floor(page);
                    }
                    this.pageValue = null;
                    break;
            }
            if(isChange){
                this.$emit('update:current-page', currentPage);
                this.$nextTick(function(){
                    this.$emit('page-change', currentPage);
                })
            }
        }
    }
}
</script>
<style scoped>
    .pagination{
        width: 100%;
        height: 50px;
        border-top: 1px solid #273768;
        padding: 5px 0 5px 25px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
    }
    .pagination .el-button{
        background: #C91E25;
        color: #fff;
        margin: 0 4px;
        padding: 4px 15px;
        border: none;
        border-radius: 0;
        cursor: pointer;
    }
    .pagination .el-button.is-disabled,
    .pagination .el-button.is-disabled:hover{
        color: #fff;
        background-color: #ffaaad !important;
    }
    .pagination span{
        margin: 0 5px;
    }
    .pagination .el-input{
        width: 40px;
    }
    .pagination .page_link .el-input ::v-deep input{
        padding: 0;
        text-align: center;
    }
</style>