<template>
    <div v-if="!sessionToken" class="login">
        <div class="logo">中企运营管理平台</div>
        <div class="mainBox clearfix">
            <div class="hint" v-text="hint"></div>
            <div class="form" v-if="loginTag">
                <div class="group clearfix">
                    <div class="icon icon0"></div>
                    <div class="inputBox">
                        <input type="text" placeholder="手机号/用户名" v-model="userName">
                    </div>
                </div>
                <div class="group clearfix">
                    <div class="icon icon1"></div>
                    <div class="inputBox">
                        <input type="password" placeholder="密码" v-model="password" @keyup.enter="login">
                    </div>
                </div>
                <div class="group clearfix">
                    <div class="btn btn_login" @click="login()" v-html="loginTxt"></div>
                </div>
            </div>
            <div class="form" v-else>
                <h3>请选择要登录的账户</h3>
                <ul>
                    <li 
                        v-for="(item, i) in leagueInfos" 
                        :key="i" 
                        @click="checkLeague(item.league_id, item.league_name)" 
                        :class="item.league_id == mainLeagueId? 'on': ''"
                        class="clearfix">
                        <div class="leagueIcon">
                            <div class="iconBox">
                                <img :src="imgServerUrl + item.league_logo">
                            </div>
                        </div>
                        <div class="leagueName" v-text="item.league_name"></div>
                    </li>
                </ul>
                <div class="group clearfix">
                    <div class="btn btn_login" @click="confirmLeague()">确认</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { toQueryString } from '@/assets/js/util'
import md5 from 'js-md5'
import {
    LoginByPassword
} from '@/newApi'

export default {
    name: "Login",
    data(){
        return {
            userName: '',
            password: '',
            code: '',
            hint: '',
            time: 60,
            sendTxt: '发&nbsp;送',
            sendFlag: true,
            loginTxt: '登&nbsp;&nbsp;录',
            loginFlag: true,
            allData: null,
            loginTag: 1,
            mainLeagueId: -1,
            mainLeagueName: '',
            imgServerUrl: '',
            leagueInfos: []
        }
    },
    props:{
        sessionToken: Boolean
    },
    methods: {
        login() {
            let passwordReg = /^[A-Za-z0-9]+$/;
            if(this.userName == '') {
                this.hint = '用户名不能为空'
                return
            }
            if(this.password == '') {
                this.hint = '密码不能为空'
                return
            }
            if(!passwordReg.test(this.password) || this.password.lenrth > 20) {
                this.hint = '密码格式有误'
                return
            }
            if(this.loginFlag){
                this.loginFlag = false
            } else {
                return
            }
            this.hint = ''
            this.loginTxt = '登录中...'

            LoginByPassword({
                LoginName: this.userName,
                InputPasswordHashed: md5(this.password),
                ManagerUserType:6
            }).then(data => {
                console.log(data)
                this.loginFlag = true;
                this.loginTxt = '登&nbsp;&nbsp;录';
                if(data.Code == 0){
                    // this.getLeagueList()
                    this.$cookies.set("token", true);
                    this.$cookies.set("userName", data.ManagerUser.Username);
                    this.$cookies.set("userType", data.ManagerUser.ManagerUserType);
                    this.$emit('session', 1);
                } else {
                    this.loginTxt = '登&nbsp;&nbsp;录';
                    this.hint = data.Message;
                }
            }).catch(() => {
                this.loginFlag = true;
                this.loginTxt = '登&nbsp;&nbsp;录';
                this.hint = '网络异常';
            })
        },
        // 获取赛事列表
        getLeagueList(){
            this.$axios.post('Match/Entrance', toQueryString({
                action: 'Getleague_users',
                user_token: ''
            })).then((res) => {
                let data = res.data
                this.loginFlag = true
                this.loginTxt = '登&nbsp;&nbsp;录'
                if(data.code == 1){
                    this.imgServerUrl = data.imgUrl
                    if(data.league_users_List.length == 1) {
                        this.mainLeagueId = data.league_users_List[0].league_id
                        this.mainLeagueName = data.league_users_List[0].league_name
                        this.confirmLeague()
                        this.$cookies.set("token", this.token);
                        this.$cookies.set("userName", this.mainLeagueName);
                        this.$cookies.set("userType", this.allData.user_type);
                        this.$cookies.set("mainLeagueId", this.mainLeagueId);
                        this.$emit('session', 1);
                    } else {
                        this.loginTag = 0
                        this.leagueInfos = data.league_users_List
                    }
                } else {
                    alert(data.message)
                }
            }).catch(() => {
                alert('网络异常')
            })
        },
        // 确认赛事身份
        confirmLeague(){
            if(this.mainLeagueId == -1){
                alert('请选择赛事')
                return
            }
            this.loginTag = 1
            this.$cookies.set("token", this.token);
            this.$cookies.set("userName", this.mainLeagueName);
            this.$cookies.set("userType", this.allData.user_type);
            this.$cookies.set("mainLeagueId", this.mainLeagueId);
            this.$emit('session', 1);
        },
        checkLeague(id, name){
            this.mainLeagueId = id
            this.mainLeagueName = name
        }
    },
    mounted () {}
}
</script>

<style scoped>
@import "~@/assets/css/login.css"
</style>
