<template>
    <div class="view">
        <div class="viewBtn"><span class="on">赛事账号</span></div>
        <div class="srchBox clearfix">
            <div class="fl ml_10">
                <label for="">用户名：</label>
                <input type="text" name="" v-model="user_name">
            </div>
            <div class="fl ml_10">
                <label for="">关联赛事：</label>
                <input type="text" name="" v-model="league_name">
            </div>
            <div class="fl ml_10">
                <label for="">状态：</label>
                <select v-model="confirm_status">
                    <option value="0">全部</option>
                    <option
                            v-for="(item, i) in statusJson"
                            :key="i"
                            :value="i"
                            v-text="item"
                    ></option>
                </select>
            </div>
            <div class="fl ml_10">
                <button class="btn" @click="srchHandle">查询</button>
            </div>
        </div>
        <div class="srchBox srchBoxAccount clearfix">
            <button class="AccountBtn" @click="goUrl(-1,-1)">+添加</button>
            <button class="AccountBtn AccountBtn2" @click="UptUser_status(1)">✔启用</button>
            <button class="AccountBtn AccountBtn2" @click="UptUser_status(2)">×停用</button>
        </div>
        <div class="tab">
            <div class="tab_th clearfix">
                <div class="fl td_0"><span @click="selectMess(0)" :class="{selectTab:true,selectOn:selectList.length === dataAll.length && dataAll.length > 0}" style="margin-top: 0.5rem"></span></div>
                <div class="fl td_3">用户名</div>
                <div class="fl td_1">状态</div>
                <div class="fl td_6">关联赛事</div>
                <div class="fl td_1">操作</div>
            </div>
            <div class="box_tr" v-if="dataAll.length == 0">
                <p class="noData" v-text="listLoadTip"></p>
            </div>
            <div class="box_tr" v-else>
                <div class="tab_tr clearfix" v-for="(item, i) in dataAll" :key="i">
                    <div class="fl td_0">
                        <span @click="selectMess(item.manager_user_id,i)" :class="{selectTab:true,selectOn:item.flg}"></span>
                    </div>
                    <div class="fl td_3">
                        <span v-text="item.login_name"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="item.user_status == '注销' ? '停用' : item.user_status || '-'"></span>
                    </div>
                    <div class="fl td_6">
                        <span v-text="item.league_name || '-'"></span>
                    </div>
                    <div class="fl td_1">
                        <span>
                            <lable @click="goUrl(item.manager_user_id,i)"
                                    class="accountTabBtn"
                                    v-text="'编辑'"
                            ></lable>
                            <lable @click="goUrl(0,i)"
                                    class="accountTabBtn"
                                    v-text="'详情'"
                            ></lable>
                        </span>
                    </div>
                </div>
            </div>
            <PageHandle
                    :page="page"
                    :totalPage="totalPage"
                    :totalCount="totalCount"
                    @pageTo="pageTo($event)"
            />
        </div>
        <TipHandle
                :txt="tip"
                :btnTxt="btnTxt"
                @maskHandle="maskHandle($event)"
        />
    </div>
</template>

<script>
    import PageHandle from '@/components/PageHandle.vue'
    import TipHandle from '@/components/TipHandle.vue'
    import { toQueryString } from '@/assets/js/util'

    export default {
        name: 'VipApplyLists',
        components: {
            PageHandle,
            TipHandle
        },
        props: {
        },
        data() {
            return {
                token: this.$cookies.get('token'),
                page: 1,
                count: 20,
                totalPage: 1,
                totalCount: 1,
                tip: '',
                btnTxt: [],
                dataAll:[],
                getListFlag: true,
                getStatusFlag: true,
                listLoadTip: '',
                user_name: '',
                league_name: '',
                confirm_status: 0,
                statusJson: {
                    1: "启用",
                    2: "停用"
                },
                selectList:[], //选中的列表
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            goUrl (id, i) {
                if(id > -1) {
                    let obj = this.dataAll[i];
                    let str = obj.login_name +'|'+obj.password+'|'+obj.user_status+'|'+obj.league_id+'|'+obj.manager_user_id;
                    sessionStorage.setItem('accountMess',str)
                }else {
                    sessionStorage.removeItem('accountMess')
                }
                this.$router.push('AccountMess/'+id)
            },
            handleTips(txt, btnTxt) {
                this.tip = txt
                this.btnTxt = btnTxt
            },
            maskHandle(btn) {
                if(btn == 0) {
                    // 确认发布
                }
                this.tip = ''
            },
            pageTo(page) {
                if(!this.getListFlag) {
                    return
                }
                if(page == '-' && this.page > 1) {
                    this.page --
                    this.getList()
                } else if(page == '+' && this.page < this.totalPage){
                    this.page ++
                    this.getList()
                } else if(page != this.page && typeof page == 'number') {
                    if(page > this.totalPage || page < 1) {
                        this.handleTips('请输入1~' + this.totalPage + '的数', ['关闭'])
                        return
                    }
                    this.page = page
                    this.getList()
                }

            },
            srchHandle() {
                if(!this.getListFlag) {
                    return
                }
                this.page = 1
                this.getList()
            },
            // 运动员等级评定列表
            getList(){
                if(this.getListFlag) {
                    this.getListFlag = false
                } else {
                    return
                }
                this.dataAll = []
                this.listLoadTip = '数据加载中...'
                // setTimeout(() =>{
                //     this.dataAll = [
                //         {rownum:1,apply_id: 13,apply_time:'2021-02-01 10:02:56',apply_name: '机构1',apply_result: 0},
                //         {rownum:2,apply_id: 2,apply_time:'2021-02-01 10:02:56',apply_name: '机构1222',apply_result: 1},
                //         {rownum:3,apply_id: 3,apply_time:'2021-02-01 10:02:56',apply_name: '机构13333',apply_result: 2}
                //     ]
                // }, 2000)
                this.$axios.post('API/CentControlEntrance',toQueryString({
                    action: 'GetLusers',
                    user_token: this.token,
                    login_name: this.user_name,
                    user_status: this.confirm_status == 0 ? '' : this.confirm_status,
                    league_name: this.league_name,
                    page: this.page,
                    count: this.count
                })).then((res) => {
                    this.getListFlag = true
                    let data = res.data
                    if(data.code == -200) {
                        this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                        return
                    }
                    if(data.code == 1) {
                        if(data.dataList.length == 0){
                            this.listLoadTip = '无数据'
                        }
                        let arr = data.dataList.map((item) =>{
                            let obj = {flg: false};
                            return { ...item, ...obj}
                        });
                        this.dataAll = arr;
                        this.totalCount = data.totalCount
                        this.totalPage = Math.ceil(data.totalCount/this.count)
                    } else {
                        this.$message({
                            message: data.message,
                            type: 'warning'
                        });
                    }
                }).catch(() => {
                    this.$message.error('网络异常！');
                    this.getListFlag = true
                })
            },
            // 点击选中
            selectMess (id, index) {
                let arr = [];
               if(id === 0) {
                   if(this.dataAll.length === 0){return}
                   this.dataAll.forEach((item, i) =>{
                       if(this.selectList.length  === this.dataAll.length){
                           this.$set(this.dataAll[i],'flg',false)
                           if(i ===  (this.dataAll.length - 1)){
                               arr = []
                           }
                       } else {
                           this.$set(this.dataAll[i],'flg',true);
                           arr.push(this.dataAll[i].manager_user_id)
                       }
                   });
               } else {
                   arr = this.selectList.map(item => item);
                   if(arr.find(num => num === id)) {
                       arr = arr.filter(num => num != id);
                       this.$set(this.dataAll[index],'flg',false);
                   }else {
                       arr.push(id);
                       this.$set(this.dataAll[index],'flg',true);
                   }
               }
                this.selectList = arr;
            },
            //改吧状态
            UptUser_status (num) {
                if(this.selectList.length === 0){
                    this.$message({
                        message: '请选择账户！',
                        type: 'warning'
                    });
                    return;
                }
                this.$confirm('是否'+ (num == 1 ? '启用' :'停用') +'?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.UptUser_request(num)
                }).catch(() => {
                });
            },
            UptUser_request (num) {
                if(this.getStatusFlag) {
                    this.getStatusFlag = false
                } else {
                    return
                }
                this.$axios.post('API/Entrance',toQueryString({
                    action: 'UptUser_status',
                    user_token: this.token,
                    manager_user_id: this.selectList.join(','),
                    user_status: num
                })).then((res) => {
                    this.getStatusFlag = true;
                    let data = res.data
                    if(data.code == -200) {
                        this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                        return
                    }
                    // eslint-disable-next-line no-empty
                    if(data.code == 1) {
                        this.$message({
                            type: 'success',
                            message: ''+ (num === 1 ? '启用' :'停用') +'成功!'
                        });
                        this.selectList = [];
                        this.getList()
                    } else {
                        this.listLoadTip = data.message;
                    }
                }).catch(() => {
                    this.listLoadTip = '网络异常';
                    this.getStatusFlag = true;
                })
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .accountTabBtn{
        text-decoration: underline;
        margin: 0 0.4rem;
        color: #F57F00;
        cursor: pointer;
    }
    .viewBtn{
        height: 2rem;
        border-bottom: 1px solid #000;
    }
    .viewBtn span{
        display: inline-block;
        height: 100%;
        width: 100px;
        text-align: center;
        cursor: pointer;
    }
    .viewBtn span.on{
        color: #960202;
        border-bottom: 3px solid #960202;
    }
    .srchBox{
        height: 3rem;
        line-height: 3rem;
    }
    .srchBox input, .srchBox select{
        height: 2rem;
    }
    .srchBoxAccount{
        display: flex;
        align-items: center;
    }
    .srchBoxAccount .AccountBtn{
        width: 8rem;
        height: 2.6rem;
        text-align: center;
        background-color: #960202;
        border: 1px solid #960202;
        border-radius: 0.3rem;
        cursor: pointer;
        margin-right: 2rem;
        color: #fff;
    }
    .srchBoxAccount .AccountBtn2{
        color: #960202;
        background-color: #fff;
    }
    .selectTab{
        display: inline-block !important;
        width: 1.5rem;
        height: 1.5rem;
        margin-top: 0.75rem;
        border: 0.1rem solid #000;
        cursor: pointer;
    }
    .selectOn{
        background: url("~@/assets/imgs/selectOn.png") no-repeat center;
        background-size: cover;
    }
    .tab{
        width: 100%;
        height: calc(100% - 8rem);
    }
    .tab .box_tr .tab_tr{
        height: auto !important;
        display: flex;
        align-items: center;
    }
</style>
