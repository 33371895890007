<template>
    <div class="container">
        <el-page-header @back="$router.back()" :content="title"></el-page-header>
        <div class="content">
            <el-descriptions title="报名学员查询"></el-descriptions>
            <el-form :model="form" size="mini" label-width="100px">
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="班级：" label-width="70px">
                            <el-select v-model="form.ClassId">
                                <el-option label="全部" :value="0"></el-option>
                                <el-option v-for="(item,index) in classList" :key="index" :label="item.ClassName" :value="item.ClassId"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="推荐单位：">
                            <el-input v-model="form.MerchantName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="审核状态：">
                            <el-select v-model="form.AuditStatus">
                                <el-option v-for="(item,index) in auditStatus" :key="index" :label="item.label" :value="item.code"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="上次审核：">
                            <el-select v-model="form.LastAuditStatus">
                                <el-option v-for="(item,index) in lastAuditStatus" :key="index" :label="item.label" :value="item.code"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row type="flex" justify="space-between">
                    <el-col>
                        <el-form-item label="排序：" label-width="70px">
                            <el-select v-model="form.sort">
                                <el-option v-for="(item,index) in sorts" :key="index" :label="item.label" :value="item.code"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <div>
                        <el-button size="mini" style="width: 100px" @click="getListData(true)">查询</el-button>
                    </div>
                </el-row>
            </el-form>
            <div class="statistics">
                <p v-if="AllCount == 0">没有满足条件的记录</p>
                <p v-else>共有 {{AllCount}} 名学员符合查询条件，{{(list.length == AllCount) ? '已全部加载' : `已加载前 ${list.length} 名学员`}}。</p>
            </div>
            <el-table
                style="width: 100%"
                v-loading="loading && !isMore"
                element-loading-text="数据加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :data="list"
                size="mini"
                border
            >
                <el-table-column label="序号" align="center" type="index" width="50px"></el-table-column>
                <el-table-column label="等级" align="center" prop="ClassName"></el-table-column>
                <el-table-column label="推荐单位" align="center">
                    <template slot-scope="scope">
                        <p style="width: 100%;text-align: left">{{scope.row.MerchantName || '个人'}}</p>
                    </template>
                </el-table-column>
                <el-table-column label="订单支付状态" align="center">
                    <template slot-scope="scope">
                        <span :style="{'color': ['#ff5630', '#ff991f', '#006644'][scope.row.PaymentStatus]}">{{['未支付', '待确认', '已支付'][scope.row.PaymentStatus]}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="姓名" align="center" prop="Name"></el-table-column>
                <el-table-column label="报名时间" align="center">
                    <template slot-scope="scope">{{dateFormat(scope.row.EnrollTime, 'yyyy-MM-dd HH:mm')}}</template>
                </el-table-column>
                <el-table-column label="提交时间" align="center">
                    <template slot-scope="scope">{{dateFormat(scope.row.SubmitTime, 'yyyy-MM-dd HH:mm')}}</template>
                </el-table-column>
                <el-table-column label="审核时间" align="center">
                    <template slot-scope="scope">{{dateFormat(scope.row.AuditTime, 'yyyy-MM-dd HH:mm')}}</template>
                </el-table-column>
                <el-table-column label="审核状态" align="center">
                    <template slot-scope="scope">{{getAuditStatus(scope.row.AuditStatus)}}</template>
                </el-table-column>
                <el-table-column label="上次审核" align="center">
                    <template slot-scope="scope">{{getLastAuditStatus(scope.row)}}</template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button 
                            type="text" 
                            @click="lookDetail(scope.row.EnrollId)"
                        >查看/审核</el-button>
                    </template>
                </el-table-column>
                <div slot="append" class="load_more" v-show="isMore" @click="getListData()">{{loading ? '加载中' : '加载下一页'}}</div>
            </el-table>
        </div>
        <student-dialog ref="studentDialog" @statusUpdate="statusUpdate"></student-dialog>
    </div>
</template>
<script>
import utils from '@/utils';
import studentDialog from '../components/studentDialog.vue';
export default {
    name: 'trainingEnrollAudit',
    components: {
        studentDialog
    },
    data() {
        return {
            trainId: utils.toNumber(this.$route.params.id),
            form: {
                ClassId: 0,
                MerchantName: "",
                AuditStatus: 4,
                LastAuditStatus: null,
                sort: 1
            },
            classList: [],
            auditStatus: [
                {label: '不限', code: null},
                {label: '未提交', code: 0},
                {label: '待审核', code: 1},
                {label: '审核通过', code: 2},
                {label: '审核不通过', code: 3},
                {label: '已提交', code: 4}
            ],
            lastAuditStatus: [
                {label: '不限', code: null},
                {label: '审核通过', code: 2},
                {label: '审核不通过', code: 3},
                {label: '无', code: 4},
                {label: '通过或无', code: 5}
            ],
            sorts: [
                {label: '报名时间，从早到晚', code: 1},
                {label: '报名时间，从晚到早', code: 2},
                {label: '提交时间，从早到晚', code: 3},
                {label: '提交时间，从晚到早', code: 4},
                {label: '审核时间，从早到晚', code: 5},
                {label: '审核时间，从晚到早', code: 6},
            ],
            list: [],
            loading: false,
            AllCount: 0,
            isMore: false
        };
    },
    created() {
        this.getClassList();
        this.getListData(true);
    },
    computed:{
        title(){
            return (this.$route.query.name || '') + '在线报名';
        },
        TimeOrderType(){
            return Math.ceil(this.form.sort / 2);
        },
        TimeOrderIsAsc(){
            return this.form.sort % 2 == 1;
        },
        lastId(){
            let list = this.list,
                len = list.length;
            return len ? list[len - 1].EnrollId : 0;
        }
    },
    methods: {
        dateFormat: utils.dateFormat,
        getListData(isReload){
            if(this.loading)return;
            let lastId = this.lastId;
            if(isReload){
                lastId = 0;
                this.isMore = false;
            }
            this.loading = true;
            this.$api.GetTrainEnrollStudentAuditList({
                "TrainId": this.trainId,
                "Continuation": {
                    "LastId": lastId,
                    "MaxCount": 20
                },
                "ClassId": this.form.ClassId,
                "MerchantId": 0,
                "MerchantName": this.form.MerchantName,
                "AuditStatus": this.form.AuditStatus,
                "LastAuditStatus": this.form.LastAuditStatus,
                "TimeOrderType": this.TimeOrderType,
                "TimeOrderIsAsc": this.TimeOrderIsAsc
            }).then(data=>{
                this.loading = false;
                if(data.Code == 0){
                    let continueList = data.ContinueList || [];
                    let list = []
                    if(lastId){
                        list = [...this.list, ...continueList];
                    }else{
                        list = continueList;
                    }
                    this.$set(this, 'list', list);
                    this.AllCount = data.AllCount || 0;
                    if(list.length < this.AllCount){
                        this.isMore = true;
                    }else{
                        this.isMore = false;
                    }
                }
            }).catch(()=>{
                this.loading = false;
            })
        },
        getClassList(){
            this.$api.GetTrainClasses({
                "TrainId": this.trainId
            }).then(data=>{
                if(data.Code == 0){
                    this.classList = data.TrainClasses || [];
                }
            })
        },
        getAuditStatus(status){
            let obj = this.auditStatus.filter(item=>item.code == status)[0];
            return obj ? obj.label : '';
        },
        getLastAuditStatus(item){
            let {
                AuditStatus,
                LastAuditStatus
            } = item;
            if([2,3].includes(AuditStatus)){
                return "";
            }else if(LastAuditStatus == 2){
                return "审核通过";
            }else if(LastAuditStatus == 3){
                return "审核不通过";
            }else{
                return "";
            }
        },
        lookDetail(enrollId){
            this.$refs.studentDialog.show(enrollId);
        },
        statusUpdate(enrollId){
            let index = this.list.findIndex(item=>item.EnrollId == enrollId);
            if(index > -1){
                this.$api.GetOneTrainEnrollStudentAuditListInfo({
                    "EnrollId": enrollId
                }).then(data=>{
                    if(data.Code == 0 && data.AuditInfo){
                        this.$set(this.list, index, data.AuditInfo);
                    }
                })
            }
        }
    }
};
</script>
<style scoped>
@import "~@/assets/css/clear-el.css";
.el-table{
    border: 1px solid #EBEEF5;
}
.el-table ::v-deep th{
    border-bottom-color: #EBEEF5 !important;
}
.load_more{
    font-size: 16px;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
}
.load_more:hover{
    color: #409EFF;
}
</style>