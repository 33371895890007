<template>
    <div class="view">
        <div class="head_desc clearfix">
            <div class="addMatch">
                <div>
                    <h2>子赛事排序</h2>
                    <a class="btn" href="javascript:;" v-if="!isSort" @click="sort">手工排序</a>
                    <template v-else>
                        <button class="btn" style="background: #5f9ad3" @click="save">{{saveText}}</button>
                        <button class="btn" style="background: #a8a8a8" @click="cancel">放弃</button>
                        <button class="btn" style="background: #d07e8a" @click="recover">{{recoverText}}</button>
                    </template>
                </div>
            </div>
        </div>
        <div class="tab">
            <div class="tab_th clearfix">
                <div class="fl td_1"><span>序号</span></div>
                <div class="fl td_1"><span>运动项目</span></div>
                <div class="fl td_4"><span>赛事名称</span></div>
                <div class="fl td_3"><span>发布时间</span></div>
                <div class="fl td_3" v-if="isSort"><span>操作</span></div>
            </div>
            <div class="box_tr" v-if="dataAll.length == 0">
                <p class="noData" v-text="listLoadTip"></p>
            </div>
            <div class="box_tr" v-else>
                <div class="tab_tr clearfix" v-for="(item, i) in dataAll" :key="i">
                    <div class="fl td_1">
                        <span>{{i + 1}}</span>
                    </div>
                    <div class="fl td_1">
                        <span>{{item.EventsName}}</span>
                    </div>
                    <div class="fl td_4">
                        <span>{{item.LeagueName}}</span>
                    </div>
                    <div class="fl td_3">
                        <span>{{item.PublishTime}}</span>
                    </div>
                    <div class="fl td_3 option" v-if="isSort">
                        <span>
                            <template v-if="i == 0">
                                <button disabled>顶部</button>
                                <button disabled>上移</button>
                            </template>
                            <template v-else>
                                <button @click="move('top', i)">顶部</button>
                                <button @click="move('prev', i)">上移</button>
                            </template>
                            <template v-if="i == dataAll.length - 1">
                                <button disabled>下移</button>
                                <button disabled>底部</button>
                            </template>
                            <template v-else>
                                <button @click="move('next', i)">下移</button>
                                <button @click="move('bottom', i)">底部</button>
                            </template>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <TipHandle 
            :txt="tip"
            :btnTxt="btnTxt"
            @maskHandle="maskHandle($event)" 
            />
    </div>
</template>

<script>
import TipHandle from '@/components/TipHandle.vue'
import {
    GetManageSubleagueSortPageList,
    SaveTopsSubleagueSort,
    RestoreTopsSubleagueSort
} from '@/newApi';
export default {
    name: 'MatchSort',
    components: {
        TipHandle
    },
    data() {
        return {
            isSort: false,
            tip: '',
            btnTxt: [],
            oldData: [],
            dataAll: [],
            listLoadTip: '',
            saveText: "保存",
            recoverText: "恢复按发布时间排序"
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleTips(txt, btnTxt){
            this.tip = txt
            this.btnTxt = btnTxt
        },
        maskHandle(btn){
            if(btn == 0){
                this.$router.back();
            }
            this.tip = ''
        },
        getList(){
            this.dataAll = [];
            this.listLoadTip = '数据加载中...';
            GetManageSubleagueSortPageList({
                PageParm: {
                    PageSize: 20,
                    PageNum: 1
                }
            }).then(data=>{
                if(data.Code == 0){
                    this.dataAll = data.PageInfo.PageList;
                }
            }).catch(()=>{
                this.listLoadTip = '网络异常';
            })
        },
        sort(){
            this.isSort = true;
            this.oldData = [...this.dataAll];
        },
        move(type, index){
            var arr = [...this.dataAll];
            let obj = arr.splice(index, 1)[0];
            if(type == "top"){
                arr.unshift(obj);
            }else if(type == 'prev'){
                arr.splice(index - 1, 0, obj);
            }else if(type == 'next'){
                arr.splice(index + 1, 0, obj);
            }else{
                arr.push(obj);
            }
            this.$set(this, 'dataAll', arr);
        },
        save(){
            if(this.saveText != '保存')return;
            this.saveText = "保存中";
            let LeagueIds = this.dataAll.map(item=>item.SubleagueId);
            SaveTopsSubleagueSort({
                LeagueIds
            }).then(data=>{
                this.saveText = "保存";
                if(data.Code == 0){
                    this.isSort = false;
                    this.handleTips('保存成功',['关闭']);
                }else{
                    this.handleTips(data.Message, ['关闭']);
                }
            }).catch(()=>{
                this.saveText = "保存";
                this.handleTips('保存失败', ['关闭']);
            })
        },
        cancel(){
            this.isSort = false;
            this.dataAll = [...this.oldData];
        },
        recover(){
            if(this.recoverText != '恢复按发布时间排序')return;
            this.recoverText = "恢复中";
            RestoreTopsSubleagueSort().then(data=>{
                this.recoverText = "恢复按发布时间排序";
                if(data.Code == 0){
                    this.isSort = false;
                    this.getList();
                    this.handleTips('恢复成功',['关闭']);
                }else{
                    this.handleTips(data.Message, ['关闭']);
                }
            }).catch(()=>{
                this.recoverText = "恢复按发布时间排序";
                this.handleTips('恢复失败', ['关闭']);
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '~@/assets/css/common_z.css';
.tab{
    height: calc(100% - 4rem)
}
.head_desc{
    height: 5rem;
}
.head_desc h2{
    font-size: 28px;
    display: inline-block;
    margin-right: 2rem;
}
.head_desc button{
    display: inline-block;
    color: #fff;
    padding: 0 1rem;
    text-align: center;
    height: 2rem;
    line-height: 2rem;
    margin-right: 1rem;
    border: none;
}
.option button{
    border: none;
    background: none;
    margin: 0 4px;
    color: #dfab2e;
    text-decoration: underline;
    cursor: pointer;
}
.option button[disabled]{
    color: #848484;
}
</style>
