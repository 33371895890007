const list = [
    {
        name: '身份证（港澳台居住证）',
        forShort: '身份证',
        text:"二代身份证（港澳台居住证）正反面照片",
        type: 1,
        len: 18,
    },
    {
        name:'护照',
        text:"个人信息页",
        type:2,
        len: 9,
        RegEx:/^[a-zA-Z0-9]{9}$/,
    },
    {
        name:'台湾居民来往大陆通行证',
        text:"个人信息页",
        type:4,
        len: 8,
        RegEx:/^\d{8}$/
    },
    {
        name:'港澳居民来往内地通行证',
        text:"个人信息页",
        type:5,
        len: 9,
        RegEx:/^(H|M)\d{8}$/
    }
]
const getInfo = (type, key) =>{
    let info = list.filter(item => item.type == type)[0] || {};
    let infoValue;
    if(key == 'forShort'){
        infoValue = info.forShort || info.name;
    }else{
        infoValue = key ? info[key] : info;
    }
    return infoValue;
}
export default {
	list,
	getInfo
}