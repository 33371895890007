<template>
    <router-view></router-view>
</template>

<script>
export default {
    name: 'Match',
    components: {
    },
    created() {
    },
    mounted() {
    },
    updated() {
    },
    data(){
        return {}
    },
    methods: {
    }
}
</script>