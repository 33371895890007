<template>
    <div class="maskBody" v-show="txt">
        <div class="tipMask">
            <div class="cont">
                <p v-html="txt"></p>
            </div>
            <div class="btnBox">
                <button class="btn" v-for="(item, i) in btnTxt" :key="i" @click="btnHandle(i, item)" v-text="item" v-show="item"></button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TipHandle',
    components: {
    },
    props:{
        txt: String,
        btnTxt: Array
    },
    created() {
    },
    mounted() {
    },
    updated() {
    },
    data(){
        return {
        }
    },
    methods: {
        btnHandle(type, handle){
            if(handle == "刷新") {
                this.clearCookie()
                this.refresh()
            } else if(handle == "关闭" || handle == "取消"){
                this.$emit('maskHandle', -1)
            } else {
                this.$emit('maskHandle', type)
            }
            
        },
    }
}
</script>
<style scoped>
    .maskBody{
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, .8);
        z-index: 1500;
    }
    .maskBody .tipMask{
        position: absolute;
        width: 40%;
        min-width: 35rem;
        top: 20%;
        border: 1px solid #888;
        left: 50%;
        transform: translateX(-50%);
        background: #fff;
        padding: 1rem 2rem;
        text-align: center;
    }
    .maskBody .tipMask .cont{
        width: 80%;
        margin: 2rem auto 1rem;
    }
    .maskBody .tipMask .btnBox{
        width: 80%;
        margin: 2rem auto 1rem;
    }
    .maskBody .tipMask .btnBox .btn{
        margin: 0 1rem;
        cursor: pointer;
    }
</style>