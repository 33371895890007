import { nanoid } from "nanoid"
import OSS from 'ali-oss'
import utils from '@/utils';

const fileUpload = function(options){
    let {
        file,
        size,
        accept,
        ossConfig
    } = options;
    if(!file || !ossConfig){
        let error = `Error: ${!file ? 'file' : 'ossConfig'} is not defined`;
        console.error(error);
    }
    if(accept && accept.length && !accept.includes(file.type)){
        let result = {code: 1000, message: '不支持此文件类型'};
        return Promise.reject(result);
    }
    if (size && file.size > size) {
        let result = {code: 1001, message: `文件大小不能超过 ${utils.formatFileSize(size, 0)}`};
        return Promise.reject(result);
    }
    let {
        Region,// yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
        AccessKeyId,// 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        AccessKeySecret,
        SecurityToken,// 从STS服务获取的安全令牌（SecurityToken）。
        Bucket,// 填写Bucket名称。
        Header,//oss header
        ObjectNamePrefix
    } = ossConfig;
    const client = new OSS({
        region: Region,
        accessKeyId: AccessKeyId,
        accessKeySecret: AccessKeySecret,
        stsToken: SecurityToken,
        bucket: Bucket
    });
    const headers = {
        'Cache-Control': 'public',
        'Content-Disposition': encodeURIComponent(file.name),
        ...(Header || {})
    }
    const nameID = ObjectNamePrefix + nanoid();
    return new Promise((resolve, reject)=>{
        client.put(nameID, file, {headers}).then(res=>{
            resolve(res.url);
        }).catch(error=>{
            error = typeof error == 'string' ? {message: error} : error;
            reject({
                code: 9999,
                ...error
            })
        });
    })
}

export default fileUpload;
