<template>
    <div class="editContest view">
        <router-link to="/match/pass" class="backBtn"> &lt; 返回</router-link>
        <span v-show="pageTit" v-text="'当前：赛事审核 > ' + pageTit"></span>
        <div class="infosBox">
            <h2>赛事基本信息</h2>
            <div class="basicInfo">
                <div class="group">
                    <label>所属主赛事：</label>
                    <span v-text="editInfo.MainLeagueName || '-'"></span>
                </div>
                <div class="group">
                    <label>运动项目：</label>
                    <span v-text="editInfo.EventsName"></span>
                </div>
                <div class="group">
                    <label>赛季名称：</label>
                    <span v-text="editInfo.SeasonName"></span>
                </div>
                <div class="group">
                    <label>赛事全称：</label>
                    <span v-text="editInfo.LeagueFullName"></span>
                </div>
                <div class="group">
                    <label>赛事简称：</label>
                    <span v-text="editInfo.LeagueShortName || '-'"></span>
                </div>
                <template v-if="editInfo.MastersJoined">
                    <div class="group">
                        <label>赛事等级：</label>
                        <span v-text="editInfo.CurrentCertificateLevel + '级'"></span>
                    </div>
                    <div class="group">
                        <label>大师分类型：</label>
                        <select class="select" v-model="MastersScoreType" style="width:100px">
                            <option :value="1">金分</option>
                            <option :value="2">银分</option>
                            <option :value="3">红分</option>
                        </select>
                    </div>
                </template>
                <div class="group">
                    <label>比赛形式：</label>
                    <span v-text="editInfo.IsOnline ? '线上赛' : '线下赛'"></span>
                </div>
                <div class="group" v-if="editInfo.IsOnline">
                    <label>比赛平台url：</label>
                    <span v-text="editInfo.LeagueSite || '-'"></span>
                </div>
                <div class="group">
                    <label>赛事LOGO：</label>
                    <div class="uploadPic clearfix">
                        <div class="pic fl">
                            <img class="img-change" :src="editInfo.LogoUrl || require('@/assets/imgs/joinImg2.png')">
                        </div>
                    </div>
                </div>
                <div class="group">
                    <label>封面图片：</label>
                    <div class="uploadPic clearfix">
                        <div class="pic fl">
                            <img class="img-change" :src="editInfo.CoverUrl">
                        </div>
                    </div>
                </div>
                <div class="group">
                    <label>特殊状态：</label>
                    <span :style="editInfo.AbnormalStatus > 0 ? 'color:red' : ''">{{['无（正常）','赛事取消','赛事延期','赛事中断'][editInfo.AbnormalStatus]}}</span>
                </div>
                <div class="group" v-if="editInfo.AbnormalStatus > 0 && editInfo.BulletinUrl">
                    <label>公告标题：</label>
                    <span>
                        {{editInfo.BulletinTitle}}
                        <a :href="editInfo.BulletinUrl" target="_blank">点击查看</a>
                    </span>
                </div>
                <div class="group">
                    <label>比赛时间：</label>
                    <span v-if="editInfo.GameTime">
                        {{new Date(editInfo.GameTime.BeginTime).format('yyyy-MM-dd HH:mm')}}
                         ~ 
                        {{new Date(editInfo.GameTime.EndTime).format('yyyy-MM-dd HH:mm')}}
                    </span>
                    <span v-else>未定</span>
                </div>
                <div class="group" v-if="editInfo.IsOnline">
                    <label>比赛平台：</label>
                    <span v-text="'-'"></span>
                </div>
                <div class="group" v-else>
                    <label>比赛地点：</label>
                    <template v-if="editInfo.GamePlace && editInfo.GamePlace.length > 0">
                        <span v-for="(item,index) in editInfo.GamePlace" :key="index">
                            {{item.RegionName + (editInfo.GamePlace.length-1 > index ?' / ':'')}}
                        </span>
                    </template>
                    <span v-else>-</span>
                </div>
                <div class="group" v-for="(unitItem,unitIndex) in units" :key="unitIndex">
                    <label>{{unitItem.name}}：</label>
                    <template v-if="unitItem.list.length > 0">
                        <span v-for="(item,index) in unitItem.list" :key="index">
                            {{item + ((unitItem.list.length-1 > index) ? ' / ' :'')}}
                        </span>
                    </template>
                    <span v-else>-</span>
                </div>
                <div class="group clearfix">
                    <label class="fl">规程：</label>
                    <div class="richText fl" v-html="editInfo.Comment"></div>
                </div>
            </div>
            <h2>赛事分组信息</h2>
            <div class="mainInfo">
                <div class="boxGroup clearfix" v-for="(item,index) in competitions" :key="index">
                    <div class="group">
                        <label>比赛项目：</label>
                        <span>{{item.eventsName}}</span>
                    </div>
                    <div class="group">
                        <label>分组：</label>
                        <div class="newGroup">
                            <ul>
                                <li 
                                    v-for="(groupItem,groupIndex) in item.groups" :key="groupIndex"
                                    v-text="groupItem.name + '（' + (getGroupTypeText(groupItem.type).text|| ' - ') + (groupItem.isVip == 1?'，嘉宾':'') + '）' "
                                    class="isChecked"
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <br>
            </div>

            <div class="group" v-if="editInfo.LeagueNowStatus == 5 || editInfo.LeagueNowStatus == 6">
                <label>不通过理由：</label>
                <textarea cols="60" rows="10" v-model.trim="reason" placeholder="不通过审核时必填" maxlength="300" class="remark" :disabled="editInfo.LeagueNowStatus == 6"></textarea>
            </div>
            <br>
            <br>
        </div>
        <div class="handleBox" v-if="editInfo.LeagueNowStatus == 5">
            <button @click="passLeague(1)" v-text="btn_txt1"></button>
            <button @click="passLeague(2)" v-text="btn_txt2"></button>
        </div>
        <TipHandle 
            :txt="tip"
            :btnTxt="btnTxt"
            @maskHandle="maskHandle($event)" 
            />
    </div>
</template>

<script>

import TipHandle from '@/components/TipHandle.vue'
import { toQueryString } from '@/assets/js/util'
import {
    GetSubleagueDetail
} from '@/newApi';
export default {
    name: 'ContestDetail',
    components: {
        TipHandle
    },
    props: {},
    data() {
        return {
            token: this.$cookies.get('token'),
            leagueId: this.$route.params.leagueId,
            editInfo:{},
            MastersScoreType: '',
            gradingType: null,
            units:[],
            groupTypeList:[{
                type:1,
                text:'个人'
            },{
                type:2,
                text:'团体'
            },{
                type:3,
                text:'双人组合'
            }],
            competitions:[],
            tip: '',
            btnTxt: [],
            pageTit: '',
            reason: '',
            passFlag:true,
            btn_txt1: '通过审核',
            btn_txt2: '不通过审核'
        }
    },
    created(){
        this.getLeagueInfos()
    },
    watch:{},
    methods: {
        handleTips(txt, btnTxt){
            this.tip = txt
            this.btnTxt = btnTxt
        },
        maskHandle(btn){
            if(btn == 0 || btn == 1) {
                this.changeStatus(btn)
            } else if(btn == 2){
                // 提交成功，返回列表
                this.$router.push('/match/pass')
            }
            this.tip = ''
        },
        getGroupTypeText(type){
            return this.groupTypeList.filter(n=>n.type==type)[0] || {};
        },
        //获取赛事详情
        getLeagueInfos () {
            GetSubleagueDetail({
                SubLeagueId: this.leagueId
            }).then(data=>{
                if(data.Code == 0){
                    var editInfo = data.EditInfo;
                    this.editInfo = editInfo;
                    if(editInfo.MastersJoined){
                        if(editInfo.MastersScoreType){
                            this.MastersScoreType = editInfo.MastersScoreType;
                        }else{
                            if(['A','B'].includes(editInfo.CurrentCertificateLevel)){
                                this.MastersScoreType = 1;
                            }else if(['C','D'].includes(editInfo.CurrentCertificateLevel)){
                                this.MastersScoreType = 2;
                            }else{
                                this.MastersScoreType = 3;
                            }
                        }
                    }
                    let {
                        Assist,
                        Host,
                        GuideUnit,
                        Operator,
                        Sponsor,
                        Supporter,
                        Undertaker
                    } = editInfo.Units;
                    let units = [];
                    units.push({name:"主办单位",list: Host ? Host.split(' ') : []});
                    units.push({name:"指导单位",list: GuideUnit ? GuideUnit.split(' ') : []});
                    units.push({name:"支持单位",list: Supporter ? Supporter.split(' ') : []});
                    units.push({name:"承办单位",list: Undertaker ? Undertaker.split(' ') : []});
                    units.push({name:"运营单位",list: Operator ? Operator.split(' ') : []});
                    units.push({name:"协办单位",list: Assist ? Assist.split(' ') : []});
                    units.push({name:"赞助单位",list: Sponsor ? Sponsor.split(' ') : []});
                    this.units = units;
                    let groupList = editInfo.GroupList;
                    let competitions = [];
                    for(var i=0;i<groupList.length;i++){
                        let obj = groupList[i];
                        let groupObj = {
                            name: obj.GroupName,
                            type: obj.GroupType,
                            isVip: obj.IsHonored
                        };
                        let index = -1;
                        for(var j=0;j<competitions.length;j++){
                            if(competitions[j].eventsName== obj.EventsName){
                                index = j;
                                break;
                            }
                        }
                        if(index > -1){
                            competitions[index].groups.push(groupObj);
                        }else{
                            competitions.push({
                                eventsName: obj.EventsName,
                                groups: [groupObj]
                            })
                        }
                    }
                    this.competitions = competitions;
                    this.reason = editInfo.AuditReason;
                }
            })
        },
        //审核 type - 1审核通过；2：审核不通过
        passLeague(type) {
            if(!this.passFlag) {
                return
            }
            if(type == 1) {
                if(this.editInfo.MastersJoined && !this.MastersScoreType){
                    this.$message.success('请选择大师分类型');
                }
                this.handleTips('确认通过审核？', ['确认', '关闭'])
            } else if(type == 2) {
                if(!this.reason) {
                    this.handleTips('请输入不通过理由', ['关闭'])
                } else {
                    this.handleTips('确认不通过审核？', ['', '确认', '关闭'])
                }
            }
        },
        // 确认通过(0) or 不通过(1)
        changeStatus(type) {
            if(this.passFlag) {
                this.passFlag = false
            } else {
                return
            }
            if(type == 0) {
                this.reason = ''
                this.btn_txt1 = '提交中...'
            } else {
                this.btn_txt2 = '提交中...'
            }
            this.$axios.post('Match/Entrance', toQueryString({
                action: 'Upt_league_status',
                type: 3,
                league_id: this.leagueId,
                enroll_type: 0,
                user_token: this.token,
                audit_reason: this.reason,
                masters_score_type: this.MastersScoreType
            })).then((res) => {
                let data = res.data
                this.btn_txt1 = '通过审核'
                this.btn_txt2 = '不通过审核'
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                } else if(data.code == 1) {
                    this.handleTips('提交成功', ['', '', '返回列表'])
                } else {
                    this.passFlag = true
                    this.handleTips(data.message, ['关闭'])
                }
            }).catch(() => {
                this.btn_txt1 = '通过审核'
                this.btn_txt2 = '不通过审核'
                this.passFlag = true
                this.handleTips('网络异常', ['关闭'])
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    @import '~@/assets/css/form.css';
    .editContest .newGroup ul li.isChecked{
        background: #e02616;
        color: #fff;
        border: 1px solid #e02616;
    }
    .richText{
        width: calc(100% - 11rem);
        border: 1px solid #999;
        padding: .3rem 1rem;
        min-height: 10rem;
        max-height: 30rem;
        overflow: auto;
    }
</style>
