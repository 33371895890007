<template>
    <header class="clearfix">
        <div class="logo">中企运营管理平台</div>
        <div class="nav">
            <ul>
                <li 
                    v-for="(item, i) in navData"
                    :key="i"
                    @click="isNavClick(i)"
                    :class="(currIdx == i)? 'on': ''"
                >
                    <router-link
                        :class="'icon'+ i"
                        :to="item.src" 
                        v-text="item.txt"
                    ></router-link>
                </li>
            </ul>
        </div>
         <div class="loginInfo">
            <div class="userName fl" :title="userName" v-text="userName"></div>
            <div class="loginOut" @click="loginOut">登出</div>
        </div>
    </header>
</template>

<script>
import { toQueryString } from '@/assets/js/util'
export default {
    name: 'Header',
    props: ['initNav'],
    data () {
        return {
            token: this.$cookies.get('token'),
            currIdx: 0,
            navData: [
                {txt: '信息发布系统', src: '/infos'},
                {txt: '竞赛系统', src: '/match'},
                {txt: '积分系统', src: '/vipPoint'},
                {txt: '考级系统', src: '/grade'},
                {txt: '扑克大师分', src: '/masterScore'},
                {txt: '裁判教练', src: '/refereeOrCoach'},
                {txt: '账户系统', src: '/account'}
            ],
            userName: this.$cookies.get('userName') || '-',
            loginFlag: true
        }
    },
    created() {
        this.currIdx = this.initNav
    },
    methods: {
        isNavClick(idx) {
            this.currIdx = idx
            this.$emit('isNavIdx', idx)
        },
        loginOut () {
            if(!this.loginFlag){
                return
            } else {
                this.loginFlag = false
            }
            this.$axios.post('Login/Log_out', toQueryString({
                "user_token": this.token,
            })).then((res) => {
                let data = res.data
                this.loginFlag = true
                if(data.code == 1 || data.code == -200){
                    this.clearCookie()
                    this.$emit('session', 2)
                } else {
                    this.hint = data.message
                }
            }).catch(() => {
               alert('网络异常')
            })
        },
    }
}
</script>
<style scoped>
header{
    border-bottom: .2rem solid #960202;
    background: linear-gradient(-91deg, #4A0000 0%, #650808 100%);
}
header .logo{
    float: left;
    width: 15rem;
    height: 3rem;
    line-height: 3rem;
    font-size: 1.4rem;
    color: #eceded;
    padding: 0 0 0 3rem;
    background: url(~@/assets/imgs/logo.png) no-repeat 0 center;
    background-size: 2.4rem 2.4rem;
    margin: 1rem 1% .2rem 2%;
}
header .nav{
    float: left;
    height: 3rem;
    margin: 1.2rem 0 0 0;
    line-height: 3rem;
    text-align: center;
}
header .nav li{
    float: left;
    margin: 0 0 0 .5rem;
    background: #770000;
}
header .nav li.on a{
    background: #960202;
    color: #fff;
}
header .nav li a{
    font-size: 1.2rem;
    display: block;
    padding: 0 1rem;
    color: #F5A100;
}
header .nav li.on .icon0::before{
    background-position: -0rem center;
}
header .nav li .icon1::before{
    background-position: -6rem center;
}
header .nav li.on .icon1::before{
    background-position: -4rem center;
}
header .nav li .icon2::before{
    background-position: -10rem center;
}
header .nav li.on .icon2::before{
    background-position: -8rem center;
}
header .nav li .icon3::before{
    background-position: -14rem center;
}
header .nav li.on .icon3::before{
    background-position: -12rem center;
}
header .nav li .icon4::before{
    background-position: -18rem center;
}
header .nav li.on .icon4::before{
    background-position: -16rem center;
}
header .loginInfo{
    float: right;
    margin: 1.2rem .3rem 0 0;
    line-height: 3rem;
}
header .loginInfo .userName{
    color: #fff;
    max-width: 9rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
header .loginInfo .loginOut{
    cursor: pointer;
    color: #f5a100;
    display: inline-block;
    padding: 0 .4rem;
}
</style>