import Vue from 'vue'
import Router from 'vue-router'

import Layout from '@/components/Layout';
import Infos from '@/components/Infos'
import VipPoint from '@/components/VipPoint'
import Match from '@/components/Match'
import Grade from '@/components/Grade'
import Account from '@/components/Account'

import APPSlideShow from '@/view/infos/APPSlideShow'
import CreateAPPSlideShow from '@/view/infos/CreateAPPSlideShow'
import News from '@/view/infos/News'
import EditNews from '@/view/infos/EditNews'
import APPHomeList from '@/view/infos/APPHomeList'
import APPHomeListSort from '@/view/infos/APPHomeListSort'
import AddNewsToHome from '@/view/infos/AddNewsToHome'

import Contest from '@/view/match/Contest'
import EditContest from '@/view/match/EditContest'
import Pass from '@/view/match/Pass'
import ContestDetail from '@/view/match/ContestDetail'
import Rating from '@/view/match/Rating'
import RatingDetail from '@/view/match/RatingDetail'
import RatingRecheckDetail from '@/view/match/RatingRecheckDetail'
import RatingManualOperation from '@/view/match/RatingManualOperation'
import RatingMORecheck from '@/view/match/RatingMORecheck'
import MatchSort from '@/view/match/MatchSort'

import Search from '@/view/vipPoint/Search'
import Count from '@/view/vipPoint/Count'
import Manage from '@/view/vipPoint/Manage'
import Detail from '@/view/vipPoint/Detail'

import AthleteRating from '@/view/grade/AthleteRating'
import Verify from '@/view/grade/Verify'
import Certificate from '@/view/grade/Certificate'
import LvlBookInfos from '@/view/grade/LvlBookInfos'

import VipApplyLists from '@/view/account/VipApplyLists'
import ApplyDetail from '@/view/account/ApplyDetail'
import MemberQuery from '@/view/account/MemberQuery'
import OrMemberQuery from '@/view/account/OrMemberQuery'
import AccountManage from '@/view/account/AccountManage'
import AccountMess from '@/view/account/AccountMess'
import UserList from '@/view/account/UserList'
import CreateUser from '@/view/account/CreateUser'
import UserDetail from '@/view/account/UserDetail'

import RefereeList from '@/view/refereeOrCoach/RefereeList'
import CreateReferee from '@/view/refereeOrCoach/CreateReferee'
import RefereeDetail from '@/view/refereeOrCoach/RefereeDetail'
import CoachList from '@/view/refereeOrCoach/CoachList'
import CreateCoach from '@/view/refereeOrCoach/CreateCoach'
import CoachDetail from '@/view/refereeOrCoach/CoachDetail'
import trainingManagementList from '@/view/refereeOrCoach/trainingManagement/List';
import trainingManagementDetail from '@/view/refereeOrCoach/trainingManagement/Detail';
import trainingEnrollList from '@/view/refereeOrCoach/trainingEnroll/List';
import trainingEnrollDetail from '@/view/refereeOrCoach/trainingEnroll/Detail';
import trainingEnrollOrder from '@/view/refereeOrCoach/trainingEnroll/Order';
import trainingEnrollAudit from '@/view/refereeOrCoach/trainingEnroll/Audit';
//大师分
import ChangeQuery from '@/view/masterScore/ChangeQuery';//大师分变更查询
import NumericalIntegration from '@/view/masterScore/NumericalIntegration';//大师分计算
import IntegrationDetail from '@/view/masterScore/IntegrationDetail';//积分详情

Vue.use(Router)
const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error=> error);
}
export default new Router({
    mode: 'hash',
    linkActiveClass: 'active',
    routes: [
        {
            path: '/',
            redirect: '/infos'
        }, {
            path: '/infos',
            component: Infos,
            children: [
                {
                    path: '/',
                    redirect: 'news'
                },{
                    path: 'news',
                    name: 'News',
                    component: News
                },{
                    path: 'APPslideshow/:appCode',
                    name: 'APPSlideShow',
                    component: APPSlideShow
                }
                ,{
                    path: 'createappslideshow/:appCode',
                    name: 'CreateAPPSlideShow',
                    component: CreateAPPSlideShow
                },{
                    path: 'news/editNews/:newsId',
                    name: 'EditNews',
                    component: EditNews
                },{
                    path: 'APPHomeList',
                    name: 'APPHomeList',
                    component: APPHomeList
                },{
                    path: 'APPHomeListSort',
                    name: 'APPHomeListSort',
                    component: APPHomeListSort
                },{
                    path: 'AddNewsToHome',
                    name: 'AddNewsToHome',
                    component: AddNewsToHome
                }
            ]
        }, {
            path: '/match',
            component: Match,
            children: [
                {
                    path: '/',
                    redirect: 'contest'
                },{
                    path: 'contest',
                    name: 'Contest',
                    component: Contest
                }, {
                    path: 'contest/editContest/:leagueId',
                    name: 'EditContest',
                    component: EditContest
                }, {
                    path: 'pass',
                    name: 'Pass',
                    component: Pass
                }, {
                    path: 'pass/contestDetail/:leagueId',
                    name: 'ContestDetail',
                    component: ContestDetail
                }, {
                    path: 'rating',
                    name: 'Rating',
                    component: Rating
                }, {
                    path: 'rating/ratingDetail/:id',
                    name: 'RatingDetail',
                    component: RatingDetail
                }, {
                    path: 'rating/ratingRecheckDetail/:mainLeagueId/:seasonId',
                    name: 'RatingRecheckDetail',
                    component: RatingRecheckDetail
                }, {
                    path: 'rating/ratingManualOperation',
                    name: 'RatingManualOperation',
                    component: RatingManualOperation
                }, {
                    path: 'rating/ratingMORecheck/:id/:mainLeagueId/:seasonId',
                    name: 'RatingMORecheck',
                    component: RatingMORecheck
                }, {
                    path: 'MatchSort',
                    name: 'MatchSort',
                    component: MatchSort
                }
            ]
        }, {
            path: '/vipPoint',
            component: VipPoint,
            children: [
                {
                    path: '/',
                    redirect: 'manage'
                }, {
                    path: 'search',
                    name: 'Search',
                    component: Search
                }, {
                    path: 'manage',
                    name: 'Manage',
                    component: Manage
                }, {
                    path: 'count',
                    name: 'Count',
                    component: Count
                }, {
                    path: 'count/detail/:leagueId/:eventsId',
                    name: 'Detail',
                    component: Detail
                }
            ]
        }, {
            path: '/grade',
            component: Grade,
            children: [
                {
                    path: '/',
                    redirect: 'athleteRating'
                }, {
                    path: 'athleteRating',
                    name: 'AthleteRating',
                    component: AthleteRating
                }, {
                    path: 'athleteRating/verify/:id',
                    name: 'Verify',
                    component: Verify
                }, {
                    path: 'certificate',
                    name: 'Certificate',
                    component: Certificate
                }, {
                    path: 'certificate/LvlBookInfos/:id',
                    name: 'LvlBookInfos',
                    component: LvlBookInfos
                }
            ]
        }, {
            path: '/account',
            component: Account,
            children: [
                {
                    path: '/',
                    redirect: 'vipApplyLists'
                }, {
                    path: 'vipApplyLists',
                    name: 'VipApplyLists',
                    component: VipApplyLists
                }, {
                    path: 'vipApplyLists/applyDetail/:id',
                    name: 'ApplyDetail',
                    component: ApplyDetail
                }, {
                    path: 'MemberQuery',
                    name: 'MemberQuery',
                    component: MemberQuery
                }, {
                    path: 'OrMemberQuery',
                    name: 'OrMemberQuery',
                    component: OrMemberQuery
                }, {
                    path: 'AccountManage',
                    name: 'AccountManage',
                    component: AccountManage
                }, {
                    path: 'AccountMess/:id',
                    name: 'AccountMess',
                    component: AccountMess
                }, {
                    path: 'UserList',
                    name: 'UserList',
                    component: UserList
                }, {
                    path: 'CreateUser',
                    name: 'CreateUser',
                    component: CreateUser
                }, {
                    path: 'UserDetail/:id',
                    name: 'UserDetail',
                    component: UserDetail
                }
            ]
        }, {
            path: '/masterScore',
            component: Layout,
            children: [
                {
                    path: '/',
                    redirect: 'numericalIntegration'
                }, {
                    path: 'changeQuery',
                    name: 'ChangeQuery',
                    component: ChangeQuery
                }, {
                    path: 'numericalIntegration',
                    name: 'NumericalIntegration',
                    component: NumericalIntegration
                }, {
                    path: 'numericalIntegration/integrationDetail/:id',
                    name: 'IntegrationDetail',
                    component: IntegrationDetail
                }
            ]
        }, {
            path: '/refereeOrCoach',
            component: Layout,
            children: [
                {
                    path: '/',
                    redirect: 'RefereeList'
                }, {
                    path: 'RefereeList',
                    name: 'RefereeList',
                    component: RefereeList
                }, {
                    path: 'CreateReferee',
                    name: 'CreateReferee',
                    component: CreateReferee
                }, {
                    path: 'RefereeDetail/:id',
                    name: 'RefereeDetail',
                    component: RefereeDetail
                }, {
                    path: 'CoachList',
                    name: 'CoachList',
                    component: CoachList
                }, {
                    path: 'CreateCoach',
                    name: 'CreateCoach',
                    component: CreateCoach
                }, {
                    path: 'CoachDetail/:id',
                    name: 'CoachDetail',
                    component: CoachDetail
                }, {
                    path: 'trainingManagement/List',
                    name: 'trainingManagementList',
                    component: trainingManagementList
                }, {
                    path: 'trainingManagement/Detail/:id',
                    name: 'trainingManagementDetail',
                    component: trainingManagementDetail
                }, {
                    path: 'trainingEnroll/List',
                    name: 'trainingEnrollList',
                    component: trainingEnrollList
                }, {
                    path: 'trainingEnroll/Detail/:id',
                    name: 'trainingEnrollDetail',
                    component: trainingEnrollDetail
                }, {
                    path: 'trainingEnroll/Order/:id',
                    name: 'trainingEnrollOrder',
                    component: trainingEnrollOrder
                }, {
                    path: 'trainingEnroll/Audit/:id',
                    name: 'trainingEnrollAudit',
                    component: trainingEnrollAudit
                }
            ]
        }
    ]
})
