<template>
    <div class="editContest view">
        <router-link to="/match/contest" class="backBtn"> &lt; 返回</router-link>
        <span v-show="pageTit" v-text="'当前：赛事管理 > ' + pageTit"></span>
        <div class="infosBox">
            <h2>赛事基本信息</h2>
            <div class="basicInfo">
                <div class="group">
                    <label><i class="needFlag">*</i>赛事全称：</label>
                    <input type="text" placeholder="请输入赛事名称" maxlength="50" v-model="leagueName" class="input" style="width:52rem;">
                </div>
                <div class="group">
                    <label>赛事简称：</label>
                    <input type="text" placeholder="请输入赛事简称" maxlength="32" v-model="leagueName2" class="input" style="width:32rem;">
                </div>
                <div class="group">
                    <label><i class="needFlag">*</i>赛事LOGO：</label>
                    <div class="uploadPic clearfix">
                        <div class="pic fl">
                            <img class="img-change" :src="picImg1" alt="">
                            <input class="file" type="file" accept="image/*" id="img1" @change="chooseFile($event,1)">
                        </div>
                        <div class="picText fl">
                            <div>
                                <p>只能上传jpg/png格式，大小不超过 200KB</p>
                                <p>建议分辨率：42×42</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="group">
                    <label>封面图片：</label>
                    <div class="uploadPic clearfix">
                        <div class="pic fl">
                            <img class="img-change" :src="picImg2">
                            <input class="file" type="file" accept="image/*" id="img2" @change="chooseFile($event,2)">
                        </div>
                        <div class="picText fl">
                            <div>
                                <p>只能上传jpg/png格式，长宽比例：375×211，大小不超过 1MB，</p>
                                <p>建议大小 0.5MB，分辨率：1080×608 或 1440×810</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="group">
                    <label><i class="needFlag">*</i>赛事项目：</label>
                    <select class="select" v-model="events_id">
                        <option value="-1">请选择</option>
                        <option
                            v-for="(item, i) in itemGroup"
                            :key="i"
                            :value="item.events_id"
                            v-text="item.events_name"
                            ></option>
                    </select>
                </div>
                <div class="group">
                    <label><i class="needFlag">*</i>赛事等级：</label>
                    <select class="select" v-model="league_level">
                        <option value="-1">请选择</option>
                        <option
                            v-for="(lvl,k) in lvlArr"
                            :key="k"
                            :value="lvl.Value"
                            v-text="lvl.Value"
                            ></option>
                    </select>
                </div>
                <div class="group">
                    <label><i class="needFlag">*</i>赛事时间：</label>
                    <date-picker v-model="begin" type="datetime" value-type="format" format="YYYY-MM-DD HH:mm:ss" placeholder="赛事开始时间"></date-picker>
                    -
                    <date-picker v-model="end" type="datetime" value-type="format" format="YYYY-MM-DD HH:mm:ss" placeholder="赛事结束时间"></date-picker>
                </div>

            </div>
        </div>
        <!-- 创建赛事or 查看赛事详情并且是未发布or审核未通过状态可以显示以下按钮 -->
        <div class="handleBox" v-show="(leagueId>0 &&(league_status_number == 8 || league_status_number == 10)) || leagueId == 0">
            <button @click="save(1)">保存</button>
            <!-- <button @click="save(2)">保存并发布</button> -->
        </div>
        <TipHandle
            :txt="tip"
            :btnTxt="btnTxt"
            @maskHandle="maskHandle($event)"
            />
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/zh-cn'
import TipHandle from '@/components/TipHandle.vue'
import { toQueryString } from '@/assets/js/util'

export default {
    name: 'EditContest',
    components: {
        DatePicker,
        TipHandle
    },
    props: {
    },
    data() {
        return {
            token: this.$cookies.get('token'),
            begin: null,
            end: null,
            leagueName: '',
            leagueName2: '',
            picImg1:require("@/assets/imgs/joinImg2.png"),
            picImg1Data:'',
            picImg2:require("@/assets/imgs/joinImg2.png"),
            picImg2Data:'',
            events_id: -1,
            league_level: -1,
            saveFlag:true,
            NewList:[],
            eventsList: [],
            newGroupName: [],
            leagueId: 0,
            league_status_number: 0,
            tip: '',
            btnTxt: [],
            remark: '',
            lvlArr: [],
            itemGroup: [],
            pageTit: '',
        }
    },
    mounted(){
        this.leagueId = this.$route.params.leagueId
        this.getLvl()
        this.getItemGroup()
        if(this.leagueId > 0){
            this.getLeagueInfos()
        } else {
            this.pageTit = '创建赛事'
        }
    },
    watch:{
    },
    methods: {
        handleTips(txt, btnTxt){
            this.tip = txt
            this.btnTxt = btnTxt
        },
        maskHandle(btn){
            if(btn == 0) {
                // 提交成功,返回上一页
                this.$router.push('/match/contest')
            }
            this.tip = ''
        },
        chooseFile (ev, type) {
            let files = ev.target.files, file;
            if (files && files.length > 0) {
                file = files[0];
                if(!file) return; 
                var acceptArr =  ['image/jpeg','image/png','image/bmp'];
                if(acceptArr.indexOf(file.type) == -1){
                    alert('不支持此文件类型');
                    return;
                }
                if (type == 1 && file.size > 200*1024) {
                    alert('图片大小不能超过 200KB，请压缩后上传');
                    return;
                }else if (type == 2 && file.size > 1*1024*1024) {
                    alert('图片大小不能超过 1MB，请压缩后上传');
                    return;
                }
                this.getFileName(file, type)
            }
        },
        getFileName(file,type){
            var formData = new FormData();
            formData.append("file", file, file.name);
            // formData.append('Action', 'AddUploadFiles');
            this.$axios.post('Upload/UploadFiles', formData).then((res) => {
                let data = res.data;
                let urlStr = data.split('|');
                if(type==1){
                    this.picImg1Data = urlStr[0]
                    this.picImg1 = urlStr[1]+urlStr[0].split('/uploadfile')[1]
                }else {
                    this.picImg2Data = urlStr[0]
                    this.picImg2 = urlStr[1]+urlStr[0].split('/uploadfile')[1]
                }
                }).catch(() => {
                });
        },
        //获取赛事项目
        getItemGroup(){
            this.$axios.post('Match/Entrance',toQueryString({
                action: 'Get_point_league_events_group',
                user_token: this.token
            })).then((res) => {
                let data = res.data;
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                if(data.code == 1){
                    this.itemGroup = data.NewList
                } else {
                    this.handleTips(data.message, ['关闭'])
                }
            }).catch(() => {
                this.handleTips('网络异常', ['关闭'])
            })
        },
        // 赛事等级
        getLvl(){
            this.$axios.post('Match/Entrance',toQueryString({
                action: 'Get_dicts_KeyValue',
                user_token:this.token,
                dict_key: 'league_level',
            })).then((res) => {
                let data = res.data;
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                if(data.code == 1){
                    this.lvlArr = data.list
                } else {
                    this.handleTips(data.message, ['关闭'])
                }
            }).catch(() => {
                this.handleTips('网络异常', ['关闭'])
            })
        },
        //获取赛事详情
        getLeagueInfos () {
            this.$axios.post('Match/Entrance', toQueryString({
                action:'GetleagueByLeague_id',
                user_token: this.token,
                league_id: this.leagueId
            })).then((res) => {
                let data = res.data
                if(data!=-1){
                    this.leagueName = data.league_full_name
                    this.leagueName2 = data.league_name
                    this.picImg1 = data.imgUrl+data.league_logo.split('/uploadfile')[1]
                    this.picImg1Data = data.league_logo
                    this.picImg2 = data.league_cover?data.imgUrl+data.league_cover.split('/uploadfile')[1]:require("@/assets/imgs/joinImg2.png")
                    this.picImg2Data = data.league_cover?data.league_cover:''
                    this.league_status_number = data.league_status_number
                    this.begin = data.begin_time
                    this.end = data.end_time
                    this.events_id = data.events_id
                    this.league_level = data.league_level
                    if(this.league_status_number == 8 || this.league_status_number == 10) {
                        this.pageTit = '编辑赛事'
                    } else {
                        this.pageTit = '查看赛事'
                    }
                }
            }).catch(() => {
            });
        },
        //保存结果 type - 保存or 保存并发布
        save (type) {
            if(!this.leagueName){
                this.handleTips('请填写赛事全称!', ['关闭'])
                return
            }
            if(this.picImg1Data.indexOf('uploadfile') < 0){
                this.handleTips('请上传赛事logo!', ['关闭'])
                return
            }
            if(this.events_id == -1){
                this.handleTips('请选择赛事项目', ['关闭'])
                return
            }
            if(this.league_level == -1){
                this.handleTips('请选择赛事等级', ['关闭'])
                return
            }
            if(!this.begin){
                this.handleTips('请填写赛事起始时间', ['关闭'])
                return;
            }
            if(!this.end){
                this.handleTips('请填写赛事结束时间', ['关闭'])
                return;
            }
            if(new Date(this.end) <= new Date(this.begin)){
                this.handleTips('起始时间应不大于截止时间', ['关闭'])
                return;
            }

            if(this.leagueId == 0){
                this.submitLeague(1, type);
            }else {
                this.submitLeague(2, type);
            }
        },
        //提交赛事信息，type：1添加 2修改
        submitLeague (type, saveType) {
            if(this.saveFlag){
                this.saveFlag = false
            } else {
                return
            }
            this.$axios.post('Match/Entrance', toQueryString({
                action: 'Addleague',
                user_token: this.token,
                league_full_name:this.leagueName,
                league_name: this.leagueName2,
                league_logo: this.picImg1Data,
                league_cover: this.picImg2Data,
                begin_time: this.begin,
                end_time: this.end,
                province_id: 0,
                city_id: 0,
                area_id: 0,
                host_unit: '',
                support_unit: '',
                assist_unit: '',
                sponsor_unit: '',
                desc: '',
                league_type:1,
                if_deleted: 1,
                if_show: 2,
                main_league_id: 0,
                league_id: this.leagueId,
                events_id: this.events_id,
                league_level: this.league_level,
                enroll_info: '',
                enroll_begin_time: '',
                enroll_end_time: '',
                statement: '',
                if_issue: 1,
                league_form: 0,
                league_site: '',
                type,
                league_events_groupStr: '',
                league_status: saveType
            })).then((res) => {
                let data = res.data;
                if(data.code==1){
                    this.handleTips('提交成功', ['返回列表'])
                } else if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                } else {
                    this.handleTips(data.message, ['关闭'])
                    this.saveFlag = true
                }
            }).catch(() => {
                this.handleTips('网络异常', ['关闭'])
                this.saveFlag = true
            })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    @import '~@/assets/css/form.css';
    .isChecked{
        background: brown;
        color: cornsilk;
    }
    .picText p{
        margin-bottom: 8px;
    }
</style>
