<template>
    <div class="student_dialog">
        <el-dialog :visible.sync="visible" :destroy-on-close="true" ref="dialog">
            <div slot="title" class="el-dialog__title" style="text-align: center">{{TrainClass.ClassName}} 学员报考资格审核</div>
            <template>
                <el-row>
                    <el-col :span="8">
                        <el-descriptions title="培训内容信息" :column="1" :labelStyle="labelStyle">
                            <el-descriptions-item label="培训项目">{{TrainClass.EventsName}}</el-descriptions-item>
                            <el-descriptions-item label="培训类型">{{pageText.trainType}}</el-descriptions-item>
                            <el-descriptions-item label="培训级别">{{TrainClass.LevelName}}</el-descriptions-item>
                            <el-descriptions-item label="报名时间">{{enrollTimeText}}</el-descriptions-item>
                        </el-descriptions>
                    </el-col>
                    <el-col :span="8">
                        <el-descriptions title="培训订单" :column="1" :labelStyle="labelStyle">
                            <el-descriptions-item label="订单号">{{TrainOrder.TrainOrderId}}</el-descriptions-item>
                            <el-descriptions-item label="下单时间">{{createTimeText}}</el-descriptions-item>
                            <el-descriptions-item label="支付方式">{{TrainOrder.PaymentMode == 0 ? '转账' : '微信支付'}}</el-descriptions-item>
                            <el-descriptions-item label="支付状态">
                                <span :style="{'color': ['#ff5630', '#ff991f', '#36b37e'][TrainOrder.PaymentStatus]}">{{['未支付', '待确认', '已支付'][TrainOrder.PaymentStatus]}} </span>
                                <span style="color: dodgerblue;text-decoration: underline;cursor: pointer;" @click="lookImg([TrainOrder.TransferVoucherUrl])" v-if="TrainOrder.PaymentMode == 0 && TrainOrder.PaymentStatus == 1">查看支付凭证</span>
                            </el-descriptions-item>
                        </el-descriptions>
                    </el-col>
                    <el-col :span="8">
                        <el-descriptions :column="1" :labelStyle="labelStyle">
                            <el-descriptions-item label="推荐单位">{{TrainOrder.MerchantName || '无'}}</el-descriptions-item>
                            <el-descriptions-item label="盖章文件">
                                <template v-if="TrainOrder.MerchantStampFiles">
                                    <span>{{TrainOrder.MerchantStampFiles.length}}张图片 </span>
                                    <span style="color: dodgerblue;text-decoration: underline;cursor: pointer;" @click="lookImg(TrainOrder.MerchantStampFiles)">点击查看</span>
                                </template>
                                <template v-else>无</template>
                            </el-descriptions-item>
                            <el-descriptions-item label="操作员">
                                <p v-if="EnrollOperateUser.Name">{{EnrollOperateUser.Name}} ({{EnrollOperateUser.Phone}})</p>
                                <p v-else>{{EnrollOperateUser.Phone}}</p>
                            </el-descriptions-item>
                        </el-descriptions>
                        <el-descriptions :column="1" :labelStyle="labelStyle" :colon="false">
                            <el-descriptions-item>
                                <img class="operate_img" :src="EnrollOperateUser.PortraitPhoto" v-if="EnrollOperateUser.PortraitPhoto" @click="lookImg([EnrollOperateUser.PortraitPhoto])">
                            </el-descriptions-item>
                        </el-descriptions>
                    </el-col>
                </el-row>
                <el-divider></el-divider>
                <el-descriptions>
                    <span slot="title" class="el-descriptions__title">学员信息</span>
                    <div slot="extra">
                        <el-button type="primary" size="mini" v-href.blank="'/account/UserDetail/' + EnrollStudent.UserId">跳转个人用户信息</el-button>
                        <template v-if="EnrollStudent.CoachOrRefereeId">
                            <el-button type="primary" size="mini" v-if="TrainClass.ClassType" v-href.blank="'/refereeOrCoach/RefereeDetail/' + EnrollStudent.CoachOrRefereeId">跳转裁判员信息</el-button>
                            <el-button type="primary" size="mini" v-else v-href.blank="'/refereeOrCoach/CoachDetail/' + EnrollStudent.CoachOrRefereeId">跳转教练信息</el-button>
                        </template>
                    </div>
                </el-descriptions>
                <el-row type="flex">
                    <el-col :span="12">
                        <div class="student_img">
                            <img :src="portrait_photo" @click="lookImg(EnrollStudentUser.PortraitPhoto ? [EnrollStudentUser.PortraitPhoto] : [])">
                            <el-checkbox v-model="imgChecked" 
                                style="margin-top: 10px;display: block;text-align: center;" 
                                v-if="EnrollStudentUser.PortraitPhoto && [1, 3].includes(EnrollStudentUser.PortraitAuditStatus)"
                            >证件照审核通过</el-checkbox>
                        </div>
                        <el-descriptions :column="1" :labelStyle="labelStyle">
                            <el-descriptions-item label="姓名">{{EnrollStudentUser.Name}}</el-descriptions-item>
                            <el-descriptions-item label="手机号码">{{EnrollStudentUser.Phone}}</el-descriptions-item>
                            <el-descriptions-item label="生日">{{birthdayText}}</el-descriptions-item>
                            <el-descriptions-item label="性别">{{['未知', '男', '女'][EnrollStudentUser.Gender]}}</el-descriptions-item>
                            <el-descriptions-item label="证件类型">{{identityText}}</el-descriptions-item>
                            <el-descriptions-item label="证件号码">{{EnrollStudentUser.IdentityNo}}</el-descriptions-item>
                            <el-descriptions-item label="工作单位">{{EnrollStudentUser.JobUnit || EnrollStudentUser.CompanyName || '无'}}</el-descriptions-item>
                            <el-descriptions-item label="通讯地址"><span v-html="addressText"></span></el-descriptions-item>
                            <el-descriptions-item label="服装尺码" v-if="EnrollFieldsOption.clothing_sizes"><span v-html="clothingSizesText"></span></el-descriptions-item>
                        </el-descriptions>
                    </el-col>
                    <template v-if="ClassSetting.HadCertLevelLimit > 0">
                        <el-col :span="2">
                            <el-divider direction="vertical" class="vertical"></el-divider>
                        </el-col>
                        <el-col :span="10">
                            <el-descriptions :column="1" :labelStyle="labelStyle" :colon="!!EnrollStudent.CoachOrRefereeId || !!EnrollStudent.UploadCertUrls">
                                <div slot="title" class="el-descriptions__title">
                                    {{pageText.levelName}}：<span class="levelCert_auditTime" v-if="EnrollStudent.CoachOrRefereeId && occupationInfo.AuditTime">{{levelCertAuditTimeText}}</span>
                                </div>
                                <template v-if="EnrollStudent.CoachOrRefereeId">
                                    <el-descriptions-item label="证书等级">{{occupationInfo.CurrentLevelName}}</el-descriptions-item>
                                    <el-descriptions-item label="证书编号">{{occupationInfo.CurrentLevelCertNo}}</el-descriptions-item>
                                    <el-descriptions-item label="发证日期">{{currentLevelTimeText}}</el-descriptions-item>
                                    <el-descriptions-item label="发证机关">{{occupationInfo.CurrentLevelCertIssuer}}</el-descriptions-item>
                                    <el-descriptions-item label="证书照片">
                                        <template v-if="occupationInfo.CurrentLevelCertUrls">
                                            <span>{{occupationInfo.CurrentLevelCertUrls.length}}张图片 </span>
                                            <span style="color: dodgerblue;text-decoration: underline;cursor: pointer;" @click="lookImg(occupationInfo.CurrentLevelCertUrls)">点击查看</span>
                                        </template>
                                        <template v-else>无</template>
                                    </el-descriptions-item>
                                    <el-descriptions-item label="证书来源">{{occupationInfo.ScoreSource || '未知'}}</el-descriptions-item>
                                </template>
                                <template v-else-if="EnrollStudent.UploadCertUrls">
                                    <el-descriptions-item label="证书照片">
                                        <span>{{EnrollStudent.UploadCertUrls.length}}张图片 </span>
                                        <span style="color: dodgerblue;text-decoration: underline;cursor: pointer;" @click="lookImg(EnrollStudent.UploadCertUrls)">点击查看</span>
                                    </el-descriptions-item>
                                </template>
                                <template v-else>
                                    <el-descriptions-item>学员未提供{{pageText.levelName}}</el-descriptions-item>
                                </template>
                            </el-descriptions>
                        </el-col>
                    </template>
                </el-row>
                <template v-if="ExtraDataDefines.length">
                    <el-divider></el-divider>
                    <el-descriptions>
                        <span slot="title" class="el-descriptions__title">其他信息</span>
                    </el-descriptions>
                    <el-row type="flex">
                    <el-col :span="12">
                        <el-descriptions :column="1" :labelStyle="{...labelStyle, width: '120px'}">
                            <el-descriptions-item :label="item.Name" v-for="(item,index) in ExtraDataDefines" :key="index">
                                <template v-if="item.DataType == 'DateTime' && EnrollStudent.Extras[item.Field]">
                                    {{dateFormat(EnrollStudent.Extras[item.Field])}}
                                </template>
                                <template v-else>
                                    {{EnrollStudent.Extras[item.Field] || '(无)'}}
                                </template>
                            </el-descriptions-item>
                        </el-descriptions>
                    </el-col>
                    </el-row>
                </template>
                <el-divider></el-divider>
                <template v-if="ClassSetting.ExperienceInput > 0">
                    <el-descriptions>
                        <p slot="title" class="el-descriptions__title" v-html="pageText.workTitle"></p>
                    </el-descriptions>
                    <el-table 
                        style="width: 100%" 
                        :data="workList" 
                        size="mini" 
                        :empty-text="'学员未提交' + pageText.workName" 
                        @selection-change="handleSelectionChange"
                        border
                    >
                        <el-table-column type="selection" width="50"></el-table-column>
                        <el-table-column label="开始时间" align="center">
                            <template slot-scope="scope">{{abnormalDate.get(scope.row.BeginTime, 'yyyy-MM-dd')}}</template>
                        </el-table-column>
                        <el-table-column label="结束时间" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.EndTime == -1">至今</span>
                                <span v-else>{{abnormalDate.get(scope.row.EndTime, 'yyyy-MM-dd')}}</span>
                            </template>
                        </el-table-column>
                        <template v-if="TrainClass.ClassType == 1">
                            <el-table-column label="执裁赛事" prop="JudgeLeague" align="center"></el-table-column>
                            <el-table-column label="执裁工作" prop="JudgeWork" align="center"></el-table-column>
                            <el-table-column label="工作证明" align="center">
                                <template slot-scope="scope">
                                    <el-button type="text" style="color: dodgerblue !important;" @click="lookImg(scope.row.WorkVouchers)" v-if="scope.row.WorkVouchers">查看图片</el-button>
                                    <span v-else>（无）</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="执裁场次数" align="center">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.WorkMatchesCount > 0">{{scope.row.WorkMatchesCount}}</span>
                                </template>
                            </el-table-column>
                        </template>
                        <template v-else>
                            <el-table-column label="执教队伍" prop="TeachingTeam" align="center"></el-table-column>
                            <el-table-column label="执教工作" prop="TeachingWork" align="center"></el-table-column>
                            <el-table-column label="工作证明" align="center">
                                <template slot-scope="scope">
                                    <el-button type="text" style="color: dodgerblue !important;" @click="lookImg(scope.row.WorkVouchers)" v-if="scope.row.WorkVouchers">查看图片</el-button>
                                    <span v-else>（无）</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="取得成绩" prop="WorkPerformance" align="center"></el-table-column>
                        </template>
                        <el-table-column label="审核状态" align="center">
                            <template slot-scope="scope">
                                <span :style="{'color': ['','#409EFF', '#36b37e', '#ff5630'][scope.row.AuditStatus]}">{{['', '待审核', '审核通过', '审核不通过'][scope.row.AuditStatus]}}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-divider></el-divider>
                </template>
            </template>
            <div class="dialog-footer">
                <el-descriptions :labelStyle="labelStyle">
                    <el-descriptions-item label="审核状态">
                        <span :style="{'color': ['','', '#36b37e', '#ff5630'][EnrollStudent.AuditStatus]}">{{['未提交', '待审核', '审核通过', '审核不通过'][EnrollStudent.AuditStatus]}}</span>
                    </el-descriptions-item>
                    <template v-if="[2, 3].includes(EnrollStudent.AuditStatus)">
                        <el-descriptions-item label="审核时间">{{auditTimeText}}</el-descriptions-item>
                    </template>
                    <template v-if="[1, 2, 3].includes(EnrollStudent.AuditStatus)">
                        <el-descriptions-item label="提交审核时间" :labelStyle="{...labelStyle, 'width': '120px'}">{{submitAuditTimeText}}</el-descriptions-item>
                    </template>
                </el-descriptions>
                <el-descriptions :labelStyle="labelStyle" v-if="[0, 1].includes(EnrollStudent.AuditStatus) && EnrollStudent.LastAuditStatus != null">
                    <el-descriptions-item label="上次审核">
                        <span :style="{'color': ['','', '#36b37e', '#ff5630'][EnrollStudent.LastAuditStatus]}">{{['','', '审核通过', '审核不通过'][EnrollStudent.LastAuditStatus]}}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="上次审核时间" :labelStyle="{...labelStyle, 'width': '120px'}">{{auditTimeText}}</el-descriptions-item>
                </el-descriptions>
                <el-descriptions :labelStyle="{...labelStyle, 'width': '120px'}" v-if="(EnrollStudent.AuditStatus == 3 || EnrollStudent.LastAuditStatus == 3) && EnrollStudent.AuditRejectReason">
                    <el-descriptions-item label="审核不通过原因">
                        <el-input type="textarea" :rows="4" :value="EnrollStudent.AuditRejectReason" style="width: 500px" readonly></el-input>
                    </el-descriptions-item>
                </el-descriptions>
                <el-row type="flex" justify="space-between" style="margin-top: 15px">
                    <el-col v-if="EnrollStudent.AuditStatus == 1">
                        <el-button type="success" @click="audit(1)">审核通过</el-button>
                        <el-button type="danger" @click="audit(2)">审核不通过</el-button>
                    </el-col>
                    <el-col v-else-if="EnrollStudent.AuditStatus == 2">
                        <el-button type="danger" @click="audit(2)">改为审核不通过</el-button>
                    </el-col>
                    <el-col v-else-if="EnrollStudent.AuditStatus == 3">
                        <el-button type="success" @click="audit(1)">改为审核通过</el-button>
                    </el-col>
                    <el-col v-else></el-col>
                    <div>
                        <el-button @click="close()">关闭</el-button>
                    </div>
                </el-row>
            </div>
        </el-dialog>
        <el-image ref="imagePreview" class="image_preview"
            src="~@/assets/images/logo.png" 
            :preview-src-list="imagePreviews">
        </el-image>
    </div>
</template>
<script>
import utils from '@/utils';
import identityType from '@/utils/identityType.js';
export default {
    name: 'studentDialog',
    data(){
        return {
            visible: false,
            labelStyle: {'width':'80px','flex': 'none','display': 'flex','justify-content': 'end','padding-right':'12px','margin-right':'0','box-sizing':'border-box'},
            detail: {},
            imgChecked: false,
            ids: [],
            imagePreviews: [],
            abnormalDate: utils.abnormalDate
        }
    },
    computed: {
        pageText(){
            if(this.TrainClass.ClassType == 1){
                return {
                    trainType: '裁判员',
                    levelName: '裁判员证书',
                    workName: '执裁经历',
                    workTitle: '执裁经历：<span style="color: #409EFF;font-size: 0.7em">(请勾选审核通过的执裁经历)</span>'
                }
            }else{
                return {
                    trainType: '教练员',
                    levelName: '教练员证书',
                    workName: '执教经历',
                    workTitle: '执教经历：<span style="color: #409EFF;font-size: 0.7em">(请勾选审核通过的执教经历)</span>'
                }
            }
        },
        EnrollFieldsOption(){
            return this.detail.EnrollFieldsOption || {};
        },
        EnrollStudent(){
            return this.detail.EnrollStudent || {};
        },
        TrainClass(){
            return this.detail.TrainClass || {};
        },
        ClassSetting(){
            return this.detail.ClassSetting || {};
        },
        ExtraDataDefines(){
            return this.ClassSetting.ExtraDataDefines || [];
        },
        TrainOrder(){
            return this.detail.TrainOrder || {};
        },
        EnrollOperateUser(){
            return this.detail.EnrollOperateUser || {};
        },
        EnrollStudentUser(){
            return this.detail.EnrollStudentUser || {};
        },
        RefereeInfo(){
            return this.detail.RefereeInfo || {};
        },
        RefereeExperiences(){
            return this.detail.RefereeExperiences || [];
        },
        CoachInfo(){
            return this.detail.CoachInfo || {};
        },
        CoachExperiences(){
            return this.detail.CoachExperiences || [];
        },
        enrollTimeText(){
            return utils.dateFormat(this.EnrollStudent.EnrollTime, 'yyyy-MM-dd HH:mm');
        },
        createTimeText(){
            return utils.dateFormat(this.TrainOrder.CreateTime, 'yyyy-MM-dd HH:mm');
        },
        portrait_photo(){
            let default_img = this.EnrollStudentUser.Gender == 2 ? require('@/assets/imgs/detail_portrait_default_women@3x.png') : require('@/assets/imgs/detail_portrait_default_men@3x.png')
            return this.EnrollStudentUser.PortraitPhoto || default_img;
        },
        birthdayText(){
            return utils.dateFormat(this.EnrollStudentUser.Birthday, 'yyyy年MM月dd日');
        },
        identityText(){
            return identityType.getInfo(this.EnrollStudentUser.IdentityType, 'name');
        },
        addressText(){
            let {
                Region,
                Address
            } = (this.EnrollStudentUser.Address || {});
            let RegionName = (Region || {}).RegionName;
            return `${RegionName || ''}<br/>${Address || ''}`
        },
        occupationInfo(){
            return this.TrainClass.ClassType == 1 ? this.RefereeInfo : this.CoachInfo;
        },
        levelCertAuditTimeText(){
            return utils.dateFormat(this.occupationInfo.AuditTime, 'yyyy-MM-dd') + ' 审核通过';
        },
        currentLevelTimeText(){
            return utils.abnormalDate.get(this.occupationInfo.CurrentLevelTime);
        },
        workList(){
            return this.TrainClass.ClassType == 1 ? this.RefereeExperiences : this.CoachExperiences;
        },
        submitAuditTimeText(){
            return utils.dateFormat(this.EnrollStudent.SubmitTime, 'yyyy-MM-dd HH:mm');
        },
        auditTimeText(){
            return utils.dateFormat(this.EnrollStudent.AuditTime, 'yyyy-MM-dd HH:mm');
        },
        clothingSizesText(){
            if(this.EnrollFieldsOption.clothing_sizes){
                let {
                    height,
                    weight,
                    bust,
                    waist
                } = this.EnrollStudentUser.ClothingSizes;
                return `身高${height}厘米 / 体重${weight}公斤 ${bust ? `/<br/>胸围${bust}厘米` : ''}${waist ? ` / 腰围${waist}厘米` : ''}`
            }
            return '';
        }
    },
    methods: {
        dateFormat(date){
            return utils.dateFormat(date, 'yyyy-MM-dd HH:mm');
        },
        show(enrollId){
            this.visible = true;
            this.getDetailData(enrollId);
        },
        close(){
            this.visible = false;
        },
        getDetailData(enrollId){
            let loading = this.$loading({
                target: this.$refs.dialog.$el,
                text: '数据读取中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.8)'
            })
            this.$api.GetTrainEnrollStudentAuditDetail({
                "EnrollId": enrollId
            }).then(data=>{
                loading.close();
                if(data.Code == 0){
                    this.detail = data;
                }
            }).catch(()=>{
                loading.close();
            })
        },
        handleSelectionChange(ids){
            this.ids = ids.map(item=>item.Id);
        },
        audit(type){
            if(type == 1){
                this.$api.AuditTrainEnrollStudentOk({
                    "EnrollId": this.EnrollStudent.EnrollId,
                    "SubmitTime": this.EnrollStudent.SubmitTime,
                    "PortraitPhotoAuditOk": this.imgChecked,
                    "ExperienceIds": this.ids
                }).then(data=>{
                    if([0, 8265].includes(data.Code)){
                        this.$message.success(data.Code == 0 ? '操作成功' : data.Message);
                        this.getDetailData(this.EnrollStudent.EnrollId);
                        this.$emit('statusUpdate', this.EnrollStudent.EnrollId);
                    }
                })
            }else{
                this.$prompt('审核不通过的原因：', '审核不通过', {
                    inputType:'textarea',
                    inputValidator:(value)=>{
                        if(!value){
                            return '请输入审核不通过的原因'
                        }
                    }
                }).then(({ value }) => {
                    this.$api.AuditTrainEnrollStudentReject({
                        "EnrollId": this.EnrollStudent.EnrollId,
                        "AuditRejectReason": value,
                    }).then(data=>{
                        if(data.Code == 0){
                            this.$message.success('操作成功');
                            this.getDetailData(this.EnrollStudent.EnrollId);
                            this.$emit('statusUpdate', this.EnrollStudent.EnrollId);
                        }
                    })
                })
            }
        },
        lookImg(imgs){
            if(imgs.length){
                this.imagePreviews = imgs;
                this.$refs.imagePreview.showViewer = true;
            }
        }
    }
}
</script>
<style scoped>
    @import "~@/assets/css/clear-el.css";
    .student_dialog ::v-deep .el-dialog{
        width: 1200px;
        height: 80vh;
        margin-top: 10vh;
    }
    .student_dialog ::v-deep .el-dialog .el-dialog__body{
        height: calc(100% - 64px);
        overflow-y: auto;
    }
    .student_img{
        width: 180px;
        display: inline-block;
        vertical-align: top;
        margin-right: 20px;
    }
    .student_img img{
        width: 100%;
    }
    .student_img + .el-descriptions{
        width: calc(100% - 200px);
        display: inline-block;
    }
    .levelCert_auditTime{
        border: 1px solid #36b37e;
        color: #36b37e;
        padding: 3px 5px;
        border-radius: 4px;
    }
    .el-table{
        border: 1px solid #EBEEF5;
    }
    .el-table ::v-deep th{
        border-bottom-color: #EBEEF5 !important;
    }
    .operate_img{
        width: 120px;
    }
</style>