<template>
    <div class="editContest view">
        <span class="backBtn" @click="$router.back()"> &lt; 返回</span>
        <span v-show="pageTit" v-text="'当前：主页轮播图管理 > ' + pageTit"></span>
        <div class="infosBox">
            <h2>主页轮播图</h2>
            <div class="basicInfo">
                <div class="group">
                    <label><i class="needFlag">*</i>轮播图名称：</label>
                    <input type="text" placeholder="请输入轮播图名称" v-model="appname" maxlength="20" class="input" style="width:52rem;">
                </div>
                <div class="group">
                    <div class="group" style="display: inline-block" v-if="this.appCode == 89">
                        <label><i class="needFlag">*</i>竖版图片：</label>
                        <div class="uploadPic clearfix">
                            <div class="pic fl">
                                <img class="img-change" style="height:100px;width:auto;" :src="picImgAPP.url||require('@/assets/imgs/joinImg2.png')" alt="">
                                <input class="file" type="file" accept="image/*" id="img1" @change="chooseFile($event,1)">
                            </div>
                            <div class="picText fl">
                                <div>
                                    <p>支持jpg/png格式图片，长宽比例：375×488，大小不超过 2MB</p>
                                    <p>建议大小 1MB，分辨率：1080×1405 或 1440×1874</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="group" style="display: inline-block">
                        <label><i class="needFlag">*</i>横版图片：</label>
                        <div class="uploadPic clearfix">
                            <div class="pic fl">
                                <img class="img-change" style="height:100px;width:auto;" :src="picImgWX.url||require('@/assets/imgs/joinImg2.png')" alt="">
                                <input class="file" type="file" accept="image/*" id="img1" @change="chooseFile($event,2)">
                            </div>
                            <div class="picText fl">
                                <div>
                                    <p>支持jpg/png格式图片，长宽比例：335×188，大小不超过 1MB</p>
                                    <p>建议大小 0.5MB，分辨率：900×505 或 1080×606</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="hint" v-if="this.appCode == 89"><span>注意：</span>横版图片用于小程序和APP新版（1.5.0及以后版本），所以请一定上传。竖版图片用于APP旧版（1.5.0以前版本，不含1.5.0），如果需要在APP旧版上显示请上传，否则不用上传。</p>
                </div>
                <div class="group">
                    <label>跳转URL：</label>
                    <input type="text" placeholder="请输入跳转URL" v-model="url" @blur="lookOrigin" maxlength="225" class="input" style="width:52rem;">
                    <p class="hint" v-if="error.stats!=0" style="color: rgb(247, 16, 16);">{{error.errorstring}}</p>
                    <p class="hint"><span>注意：</span>如果不输入跳转URL，图片仍将展示，但点击不会有任何反应</p>
                </div>
                <div class="group">
                    <label><i class="needFlag">*</i>登录状态：</label>
                    <select v-model="login">
                        <option value="-1">请选择</option>
                        <option value="0">不需要登录</option>
                        <option value="1">个人用户登录</option>
                        <option value="2">机构用户登录</option>
                    </select>
                    <p class="hint"><span>注意：</span>小程序尚不支持[机构用户登录]，请勿选择此项</p>
                </div>
                <div class="group">
                    <label><i class="needFlag">*</i>播放序号：</label>
                    <input type="number" v-model="number1" placeholder="请输入播放序号" maxlength="18" class="input" style="width:20rem;">
                    <p class="hint"><span>注意：</span>任意数值，越小越靠前</p>
                </div>
                <div class="group">
                    <label><i class="needFlag">*</i>上架时间：</label>
                    <date-picker v-model="begin" type="datetime" value-type="format" format="YYYY-MM-DD HH:mm" placeholder="上架时间"></date-picker>
                </div>
                <div class="group">
                    <label><i class="needFlag">*</i>下架时间：</label>
                    <date-picker v-model="end" type="datetime" value-type="format" format="YYYY-MM-DD HH:mm" placeholder="下架时间"></date-picker>
                </div>
            </div>
        </div>
        <!-- 创建赛事or 查看赛事详情并且是未发布or审核未通过状态可以显示以下按钮 -->
        <div class="handleBox">
            <button @click="save(1)">保存</button>
        </div>
        <TipHandle
            :txt="tip"
            :btnTxt="btnTxt"
            @maskHandle="maskHandle($event)"
            />
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/zh-cn'
import TipHandle from '@/components/TipHandle.vue'
import OSS from 'ali-oss'
import { nanoid } from "nanoid"
// import { toQueryString } from '@/assets/js/util'
import{
   GetAppCarouselPicStoreOption,
   AddAppCarouselPic,
   GetOneAppCarouselPicAndUploadOption,
   UpdateAppCarouselPic
} from '@/newApi'
//import Infos from '../../components/Infos.vue'

export default {
    name: 'CreateAPPSlideShow',
    components: {
        DatePicker,
        TipHandle
    },
    props: {
    },
    data() {
        return {
            appCode: +this.$route.params.appCode,
            id: +(this.$route.query.id || 0),
            begin: null,
            end: null,
            picImgAPP:{
                url:""
            },
            picImgWX:{
                url:""
            },
            appname:"",
            url:"",
            number1:"",
            tip: '',
            btnTxt: [],
            pageTit: '',
            uploadOption:{},
            nextId:0,
            login: '-1',
            time:'',
            error:{
                stats:0,
                errorstring:""
            }
        }
    },
    mounted(){
        if(this.id==0){
            this.pageTit="添加主页轮播图"
            this.getAppstereopticon()
            this.datetime()
        }else{
            this.pageTit="修改主页轮播图"
            this.appUploadOption()
            this.datetime()
        }
    },
    watch:{
    },
    methods: {
        handleTips(txt, btnTxt){
            this.tip = txt
            this.btnTxt = btnTxt
        },
        maskHandle(btn){
            if(btn == 0) {
                // 提交成功,返回上一页
                this.$router.back();
            }
            this.tip = ''
        },
        getAppstereopticon(){
            GetAppCarouselPicStoreOption({
            }).then(data=>{
                this.uploadOption = data.UploadOption;
                this.nextId = data.NextId;
            })
        },
        chooseFile(event,type) {
            let files = event.target.files, file;
            if (files && files.length > 0) {
                file = files[0];
                if(!file) return; 
                var acceptArr =  ['image/jpeg','image/png','image/bmp'];
                if(acceptArr.indexOf(file.type) == -1){
                    alert('不支持此文件类型');
                    return;
                }
                if (type == 1 && file.size > 2*1024*1024) {
                    alert('图片大小不能超过 2MB，请压缩后上传');
                    return;
                }else if (type == 2 && file.size > 1*1024*1024) {
                    alert('图片大小不能超过 1MB，请压缩后上传');
                    return;
                }
                this.ossUpload({
                    fileData: file,
                    type
                })
            }
        },
        datetime(){
            this.time=new Date().getTime();
        },
        dateFormat(date,fmt){
            return(function(fmt){
            var o = {
                "M+":this.getMonth()+1,
                "d+": this.getDate(),
                "H+":this.getHours(),
                "m+":this.getMinutes(),
                "s+":this.getSeconds(),
                "q+":Math.floor((this.getMonth()+3)/3),
                "S":this.getMilliseconds()
            };
            fmt=fmt||"yyyy-MM-dd";
            if(/(y+)/.test(fmt))
                fmt=fmt.replace(RegExp.$1,(this.getFullYear()+"").substr(4-RegExp.$1.length));
            for(var k in o)
            if(new RegExp("("+ k+")").test(fmt))
                fmt=fmt.replace(RegExp.$1,(RegExp.$1.length==1)?(o[k]):(("00"+o[k]).substr((""+o[k]).length)));
                return fmt;
            }).call(date,fmt);
        },
        ossUpload({fileData, type}){
            var _this = this;
            const options = this.uploadOption;
            const client = new OSS({
                // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
                region: options.Region,
                // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                accessKeyId: options.AccessKeyId,
                accessKeySecret: options.AccessKeySecret,
                // 从STS服务获取的安全令牌（SecurityToken）。
                stsToken: options.SecurityToken,
                // 填写Bucket名称。
                bucket: options.Bucket
            });
            const headers = {
                'Cache-Control': 'public',
                'Content-Disposition':encodeURIComponent(fileData.name),
            }
            let nameID = options.ObjectNamePrefix + nanoid();
            client.put(nameID, fileData, {headers}).then(function (r1) {
                if(type == 1){
                    _this.picImgAPP.url = r1.url;
                }else{
                    _this.picImgWX.url = r1.url;
                }
                console.log('put success:'+r1);
            }).catch(function (err) {
                if(type == 1){
                    _this.picImgAPP.url = '';
                }else{
                    _this.picImgWX.url = '';
                }
                console.log('error: '+JSON.stringify(err));
            });
        },
        lookOrigin(){
			var Expression="^(http|https)://";
			var objExp=new RegExp(Expression);
			if(objExp.test(this.url))
			{
				this.error.stats=0
			}
            else if(this.url){
                this.error.stats=1,
                this.error.errorstring="跳转的链接开头非http或https"
            }
		},
        appUploadOption(){
            GetOneAppCarouselPicAndUploadOption({
                "Id": this.id
            }).then(data=>{
                this.appname=data.CarouselPic.Name;
                this.picImgAPP.url=data.CarouselPic.PicUrl;
                this.picImgWX.url=data.CarouselPic.MiniappPicUrl;
                this.url=data.CarouselPic.JumpUrl;
                this.login=data.CarouselPic.NeedLogin;
                this.number1=data.CarouselPic.OrderNo;
                this.begin=this.dateFormat(new Date(data.CarouselPic.PublishBegintime),"yyyy-MM-dd HH:mm");
                this.end= this.dateFormat(new Date(data.CarouselPic.PublishEndtime),"yyyy-MM-dd HH:mm");
                this.uploadOption=data.UploadOption
            })
        },
        save(){
            if(!this.appname) {
                this.handleTips('请填写轮播图名称!', ['关闭'])
                return;
            }	
            if(!this.picImgAPP.url && !this.picImgWX.url) {
                this.handleTips('请上传轮播图图片!', ['关闭'])
                return;
            }if(this.login == '-1') {
                this.handleTips('请选择登录状态!', ['关闭'])
                return;
            }
            if(!this.number1) {
                this.handleTips('请填写播放顺序!', ['关闭'])
                return;
            }
            if(!this.begin) {
                this.handleTips('请填写上架时间!', ['关闭'])
                return;
            }	
            if(!this.end) {
                this.handleTips('请填写下架时间!', ['关闭'])
                return;
            }
            console.log(this.time+"  "+this.begin+"  "+this.end)
            if(Date.parse(this.time)>Date.parse(this.begin)){
                this.handleTips('上架时间要晚于当前时间!', ['关闭'])
                return;
            }
            if(Date.parse(this.end)<Date.parse(this.begin)){
                this.handleTips('下架时间要晚于上架时间!', ['关闭'])
                return;
            }
            if(this.id==0)
            {
                AddAppCarouselPic({
                    "AppCode": this.appCode,
                    "Id": this.nextId,
                    "Name": this.appname,
                    "PicUrl": this.picImgAPP.url,
                    "MiniappPicUrl": this.picImgWX.url,
                    "JumpUrl":this.url,
                    "NeedLogin":this.login,
                    "PublishBegintime":this.dateFormat(new Date(this.begin),"yyyy-MM-ddTHH:mm"),
                    "PublishEndtime":this.dateFormat(new Date(this.end),"yyyy-MM-ddTHH:mm"),
                    "OrderNo": this.number1
                }).then(data=>{
                    if(data.Code==0){
                        this.handleTips('提交成功', ['返回列表'])
                    }
                })
            }else{
                UpdateAppCarouselPic({
                    "Id": this.id,
                    "Name": this.appname,
                    "PicUrl": this.picImgAPP.url,
                    "MiniappPicUrl": this.picImgWX.url,
                    "JumpUrl":this.url,
                    "NeedLogin":this.login,
                    "PublishBegintime":this.dateFormat(new Date(this.begin),"yyyy-MM-ddTHH:mm"),
                    "PublishEndtime":this.dateFormat(new Date(this.end),"yyyy-MM-ddTHH:mm"),
                    "OrderNo": this.number1
                }).then(data=>{
                    if(data.Code==0){
                        this.handleTips('提交成功', ['返回列表'])
                    }
                })
            }
            
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    @import '~@/assets/css/form.css';
    .img-change{
        position: static !important;
    }
    .isChecked{
        background: brown;
        color: cornsilk;
    }
    .hint{
        line-height: 1.5em;
        padding-left: 120px;
        padding-right: 100px;
        margin-bottom: 10px;
    }
    .hint span{
        color: red;
        margin-left: -3em;
    }
    .picText p{
        margin-bottom: 8px;
    }
</style>
