<template>
    <div class="view">
        <div>
            <!-- <router-link to="/infos/news/editNews/0" class="btn">+创建新闻</router-link> -->
            <!-- <StatusBar
                :tit="'类型'"
                :currStatus="currStatus"
                :json_status="json_status"
                @statusHandle="statusHandle($event)"
                /> -->
            <StatusBar
                :tit="'状态'"
                :currStatus="currType"
                :json_status="typeStatus"
                @statusHandle="typeHandle($event)"
                />
        </div>
        <div class="tab">
            <div class="tab_th clearfix">
                <div class="fl td_1">创建时间</div>
                <div class="fl td_3">关联赛事</div>
                <!-- <div class="fl td_1">新闻类型</div> -->
                <div class="fl td_3">标题</div>
                <div class="fl td_1">副标题</div>
                <div class="fl td_1">编辑者</div>
                <div class="fl td_1">状态</div>
                <div class="fl td_1">操作</div>
            </div>
            <div class="box_tr" v-if="dataAll.length == 0">
                <p class="noData" v-text="listLoadTip"></p>
            </div>
            <div class="box_tr" v-else>
                <div class="tab_tr clearfix" v-for="(item, i) in dataAll" :key="i">
                    <div class="fl td_1">
                        <span v-text="item.create_time"></span>
                    </div>
                    <div class="fl td_3">
                        <span :title="splitStr(item.league_name, 25).t" v-text="splitStr(item.league_name, 25).s || '-'"></span>
                    </div>
                    <!-- <div class="fl td_1">
                        <span v-text="json_status[item.datum_type]"></span>
                    </div> -->
                    <div class="fl td_3">
                        <span :title="splitStr(item.title, 25).t" v-text="splitStr(item.title, 25).s || '-'"></span>
                    </div>
                    <div class="fl td_1">
                        <span :title="splitStr(item.sub_title, 9).t" v-text="splitStr(item.sub_title, 9).s || '-'"></span>
                    </div>
                    <div class="fl td_1">
                        <span :title="splitStr(item.author, 9).t" v-text="splitStr(item.author, 9).s || '-'"></span>
                    </div>
                    <div class="fl td_1">
                        <span v-text="auditStatus[item.if_audit] || '-'"></span>
                    </div>
                    <div class="fl td_1">
                        <span>
                            <router-link :to="'/infos/news/editNews/' + item.datum_id" v-text="item.if_audit == 0 ? '处理' : '查看'"></router-link>
                            <a href="javascript:;" @click="delNews(item.datum_id, item.title)">删除</a>
                        </span>
                    </div>
                </div>
            </div>
            <PageHandle 
                :page="page"
                :totalPage="totalPage"
                :totalCount="totalCount"
                @pageTo="pageTo($event)" 
                />
        </div>
        <TipHandle 
            :txt="tip"
            :btnTxt="btnTxt"
            @maskHandle="maskHandle($event)" 
            />
    </div>
</template>

<script>
import StatusBar from '@/components/StatusBar.vue'
import PageHandle from '@/components/PageHandle.vue'
import TipHandle from '@/components/TipHandle.vue'
import { toQueryString, splitStr } from '@/assets/js/util'

export default {
    name: 'News',
    props: {
    },
    components: {
        StatusBar,
        PageHandle,
        TipHandle
    },
    data() {
        return {
            token: this.$cookies.get('token'),
            page: 1,
            count: 20,
            totalPage: 1,
            totalCount: 1,
            tip: '',
            btnTxt: [],
            currStatus: 0,
            currType: 0,
            json_status: {
                0: '全部',
                1: '活动新闻',
                2: '资讯新闻',
                3: '图片新闻',
                4: '视频新闻'
            },
            typeStatus: {
                0: '全部',
                1: '待审核',
                2: '已审核'
            },
            auditStatus: {
                0: '待审核',
                1: '审核通过',
                2: '审核未通过'
            },
            dataAll: [],
            listLoadTip: '',
            getListFlag: true,
            delNewsId: null,
            delFlag: true,
            splitStr
        }
    },
    mounted(){
       this.getList()
    },
    watch: {  
    },
    methods: {
        handleTips(txt, btnTxt){
            this.tip = txt
            this.btnTxt = btnTxt
        },
        maskHandle(btn){
            if(btn == 0){
                // 确认删除新闻
                this.confirmDelNews()
            }
            this.tip = ''
        },
        statusHandle(status) {
            if(!this.getListFlag) {
                return
            }
            this.page = 1
            this.currStatus = status
            this.getList()
        },
        typeHandle(type) {
            if(!this.getListFlag) {
                return
            }
            this.page = 1
            this.currType = type
            this.getList()
        },
        pageTo(page) {
            if(!this.getListFlag) {
                return
            }
            if(page == '-' && this.page > 1) {
                this.page --
                this.getList()
            } else if(page == '+' && this.page < this.totalPage){
                this.page ++
                this.getList()
            } else if(page != this.page && typeof page == 'number') {
                if(page > this.totalPage || page < 1) {
                    this.handleTips('请输入1~' + this.totalPage + '的数', ['关闭'])
                    return
                }
                this.page = page
                this.getList()
            }
        },
        handleList(status, newsId) {
            let handleConfig = {
                //未审核
                1: [
                    {txt:'编辑',to: '/infos/news/editNews/' + newsId},
                    {txt:'审核',to: ''},
                    {txt:'删除',to: ''},
                ],
                //审核通过
                2: [
                    {txt:'编辑',to: '/infos/news/editNews/' + newsId},
                    {txt:'删除',to: ''},
                ],
                //审核未通过
                3: [
                    {txt:'编辑',to: '/infos/news/editNews/' + newsId},
                    {txt:'删除',to: ''},
                ],
            }
            return handleConfig[status]
        },
        getList() {
            if(this.getListFlag) {
                this.getListFlag = false
            } else {
                return
            }
            this.dataAll = []
            this.listLoadTip = '数据加载中...'
            this.$axios.post('CESAManage/Entrance',toQueryString({
                action: 'league_DatumList',
                user_token:this.token,
                datum_type: this.currStatus,
                page: this.page,
                count: this.count,
                main_league_id: '',
                type: this.currType
            })).then((res) => {
                this.getListFlag = true
                let data = res.data;
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                if(data.code == 1){
                    if(data.NewList.length == 0){
                        this.listLoadTip = '无数据'
                    }
                    this.dataAll = data.NewList
                    this.totalCount = data.totalCount
                    this.totalPage = Math.ceil(data.totalCount/this.count)
                } else {
                    this.listLoadTip = data.message
                }
            }).catch(() => {
                this.listLoadTip = '网络异常'
                this.getListFlag = true
            })
        },
        delNews(newsId, newsTit) {
            this.delNewsId = newsId
            this.handleTips('确认删除<br>新闻：'+ newsTit +'？', ['确认', '取消'])
        },
        confirmDelNews() {
            if(this.delFlag) {
                this.delFlag = false
            } else {
                return
            }
            this.$axios.post('CESAManage/Entrance',toQueryString({
                action: 'DeleteDatum',
                user_token:this.token,
                delDatumIds: this.delNewsId
            })).then((res) => {
                this.delFlag = true
                let data = res.data;
                if(data.code == -200) {
                    this.handleTips('登录失效，请刷新重新登录', ['刷新'])
                    return
                }
                if(data.code == 1) {
                    this.handleTips('删除成功', ['关闭'])
                    this.page = 1
                    this.getList()
                } else {
                    this.handleTips(data.message, ['关闭'])
                }
            }).catch(() => {
                this.delFlag = true
                this.handleTips('网络异常', ['关闭'])
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .tab{
        width: 100%;
        height: calc(100% - 3rem);
    }
    .tab .td_3{min-width: 16rem;}
</style>
