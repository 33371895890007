import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueCookies from 'vue-cookies'
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import { dateFormat } from './assets/js/util.js'
import Axios from 'axios'
import request from './newApi/request.js';
import * as newApi from './newApi';

import "@/utils/directives";

Vue.use(ElementUI);
Vue.use(VueCookies)

Vue.config.productionTip = false;

Date.prototype.format = function(fmt){
    return dateFormat(this, fmt);
}
// 7天后过期
// Vue.$cookies.config('7d', '/')
Vue.prototype.clearCookie = function() {
    Vue.$cookies.remove('token' , '/')
    Vue.$cookies.remove('userName' , '/')
    Vue.$cookies.remove('userType' , '/')
    Vue.$cookies.remove('mainLeagueId' , '/')
}
Vue.prototype.refresh = function() {
    window.location.reload()
}

Axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
Axios.defaults.withCredentials = true;
(async function(){
    let configData = {baseUrl:"/api/"};
    if(process.env.NODE_ENV == "production"){
        try{
            let res = await Axios.get('/config.json');
            if(res.status == 200) configData = res.data;
        }catch{
          configData = {baseUrl:"/"};
        }
    }
    Axios.defaults.baseURL = configData.baseUrl;
    request.defaults.baseURL = configData.baseUrl;
    Vue.prototype.$axios = Axios;
    Vue.prototype.$api = newApi;

    new Vue({
        router,
        render: h => h(App)
    }).$mount('#app')
})()
