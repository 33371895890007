import {post} from './request';

//获取字典项列表
export function GetDictionaryItemList(params){
  return post('/NewApi/Common/GetDictionaryItemList',params)
}
//获取裁判级别字典
export function GetRefereeLevelDictionary(params){
  return post('/NewApi/Common/GetRefereeLevelDictionary',params)
}
//获取教练级别字典
export function GetCoachLevelDictionary(params){
  return post('/NewApi/Common/GetCoachLevelDictionary',params)
}
//获取省
export function GetProvinceList(params){
  return post('/NewApi/Common/GetProvinceList',params)
}
//获取市
export function GetCityList(params){
  return post('/NewApi/Common/GetCityList',params)
}
//获取区县
export function GetAreaList(params){
  return post('/NewApi/Common/GetAreaList',params)
}
//获取机构列表（分页）
export function GetCompanyPageList(params){
  return post('/NewApi/Common/GetCompanyPageList',params)
}
//获取已有裁判级别运动项目列表
export function GetRefereeLevelFlattedEventsList(params){
  return post('/NewApi/Common/GetRefereeLevelFlattedEventsList',params)
}
//获取已有教练级别运动项目列表
export function GetCoachLevelFlattedEventsList(params){
  return post('/NewApi/Common/GetCoachLevelFlattedEventsList',params)
}
//登录
export function LoginByPassword(params){
  return post('/NewApi/Account/LoginByPassword',params)
}
//获取扁平化运动项目列表
export function GetFlattedEventsList(params){
  return post('/NewApi/LeaguePublic/GetFlattedEventsList',params)
}
//初审审核通过
export function ApproveInitialAuditAndCreateManager(params){
  return post('/NewApi/LeagueManager/ApproveInitialAuditAndCreateManager',params)
}
//初审审核不通过
export function RejectInitialAudit(params){
  return post('/NewApi/LeagueManager/RejectInitialAudit',params)
}
//初审资料
export function InitialAudit(params){
  return post('/NewApi/LeagueManager/InitialAudit',params)
}
//人工初审项目列表和oss信息
export function PrepareDirectApproveInitialAudit(params){
  return post('/NewApi/LeagueManager/PrepareDirectApproveInitialAudit',params)
}
//运动项目认证定义
export function GetEventsCertificateDefine(params){
  return post('/NewApi/LeaguePublic/GetEventsCertificateDefine',params)
}
//人工初审提交
export function DirectApproveInitialAudit(params){
  return post('/NewApi/LeagueManager/DirectApproveInitialAudit',params)
}
//复审资料
export function ReviewAudit(params){
  return post('/NewApi/LeagueManager/ReviewAudit',params)
}
//复审通过
export function ApproveReviewAudit(params){
  return post('/NewApi/LeagueManager/ApproveReviewAudit',params)
}
//复审不通过
export function RejectReviewAudit(params){
  return post('/NewApi/LeagueManager/RejectReviewAudit',params)
}
//人工复审信息（包含赛季初始化数据）
export function PrepareDirectApproveReviewAudit(params){
  return post('/NewApi/LeagueManager/PrepareDirectApproveReviewAudit',params)
}
//人工复审选择新增新赛季或历史赛季时获取的初始化数据
export function PrepareDirectApproveNewReviewAudit(params){
  return post('/NewApi/LeagueManager/PrepareDirectApproveNewReviewAudit',params)
}
//人工复审选择已有赛季获取赛季的相关数据
export function PrepareDirectApproveOldReviewAudit(params){
  return post('/NewApi/LeagueManager/PrepareDirectApproveOldReviewAudit',params)
}
//人工复审选择新增新赛季或历史赛季时提交数据
export function DirectApproveNewReviewAudit(params){
  return post('/NewApi/LeagueManager/DirectApproveNewReviewAudit',params)
}
//人工复审选择已有赛季提交数据
export function DirectApproveOldReviewAudit(params){
  return post('/NewApi/LeagueManager/DirectApproveOldReviewAudit',params)
}

//获取审核赛事列表
export function GetAuditLeagueList(params){
  return post('/NewApi/LeagueManager/GetAuditLeagueList',params)
}

//获取App轮播图图片的配置
export function GetAppCarouselPicStoreOption(params){
  return post('/NewApi/AppManager/GetAppCarouselPicStoreOption',params)
}

//添加App轮播图
export function AddAppCarouselPic(params){
  return post('/NewApi/AppManager/AddAppCarouselPic',params)
}

//获取App轮播图列表
export function QueryAppCarouselPic(params){
  return post('/NewApi/AppManager/QueryAppCarouselPic',params)
}
//根据id获取轮播图的信息
export function GetOneAppCarouselPicAndUploadOption(params){
  return post('/NewApi/AppManager/GetOneAppCarouselPicAndUploadOption',params)
}

//编辑轮播图信息
export function UpdateAppCarouselPic(params){
  return post('/NewApi/AppManager/UpdateAppCarouselPic',params)
}

//删除轮播图信息
export function DelAppCarouselPic(params){
  return post('/NewApi/AppManager/DelAppCarouselPic',params)
}

//强制下架
export function DownAppCarouselPic(params){
  return post('/NewApi/AppManager/DownAppCarouselPic',params)
}

//运动员等级申请 审核通过 
export function ApproveAthleteLevelCertificateApply(params){
  return post('/NewApi/AthleteLevel/ApproveAthleteLevelCertificateApply',params)
}

//运动员等级审核不通过
export function RejectAthleteLevelCertificateApply(params){
  return post('/NewApi/AthleteLevel/RejectAthleteLevelCertificateApply',params)
}
//获取子赛事详情
export function GetSubleagueDetail(params){
  return post('/NewApi/LeagueManager/GetSubleagueDetail',params)
}

//获取大师分计算分页列表
export function GetMastersCountPageList(params){
  return post('/NewApi/LeagueManager/GetMastersCountPageList',params)
}
//获取大师分计算详情分页列表
export function GetMastersCountDetailPageList(params){
  return post('/NewApi/LeagueManager/GetMastersCountDetailPageList',params)
}
//获取获取大师分记录分页列表（变更）
export function GetMastersScoreRecordPageList(params){
  return post('/NewApi/LeagueManager/GetMastersScoreRecordPageList',params)
}
//大师分计算
export function CountMastersScore(params){
  return post('/NewApi/LeagueManager/CountMastersScore',params)
}
//获取子赛事排序列表
export function GetManageSubleagueSortPageList(params){
  return post('/NewApi/LeagueManager/GetManageSubleagueSortPageList',params)
}
//保存子赛事排序
export function SaveTopsSubleagueSort(params){
  return post('/NewApi/LeagueManager/SaveTopsSubleagueSort',params)
}
//恢复默认排序
export function RestoreTopsSubleagueSort(params){
  return post('/NewApi/LeagueManager/RestoreTopsSubleagueSort',params)
}
//获取个人账户上传配置
export function GetPersonalUserUploadOptions(params){
  return post('/NewApi/LeagueManager/GetPersonalUserUploadOptions',params)
}
//创建个人用户
export function CreateUserAccount(params){
  return post('/NewApi/LeagueManager/CreateUserAccount',params, {noErrorCode: [2212, 2214]})
}
//获取个人账户分页列表
export function GetUserAccountPageList(params){
  return post('/NewApi/LeagueManager/GetUserAccountPageList',params)
}
//获取个人账户详情
export function GetUserAccountDetail(params){
  return post('/NewApi/LeagueManager/GetUserAccountDetail',params)
}
//保存个人账户详情
export function SaveUserAccountDetail(params){
  return post('/NewApi/LeagueManager/SaveUserAccountDetail',params)
}
//修改个人账户手机号
export function AlterUserAccountPhone(params){
  return post('/NewApi/LeagueManager/AlterUserAccountPhone',params)
}
//清空个人账户手机号
export function ClearUserAccountPhone(params){
  return post('/NewApi/LeagueManager/ClearUserAccountPhone',params)
}
//根据手机号获取个人账户基本信息
export function GetUserAccountBaseInfoByPhone(params){
  return post('/NewApi/LeagueManager/GetUserAccountBaseInfoByPhone',params)
}
////////////// 首页 ////////////////////////
//获取后台管理首页混排列表
export function GetManageMainListEntryPageList(params){
  return post('/NewApi/LeagueManager/GetManageMainListEntryPageList',params)
}
//取消推荐新闻到首页
export function DelNewsMainListEntry(params){
  return post('/NewApi/LeagueManager/DelNewsMainListEntry',params)
}
//保存前20项排序结果
export function SaveTopsMainListEntrySort(params){
  return post('/NewApi/LeagueManager/SaveTopsMainListEntrySort',params)
}
//恢复前20项排序为按发布时间排序
export function RestoreTopsMainListEntrySort(params){
  return post('/NewApi/LeagueManager/RestoreTopsMainListEntrySort',params)
}
//获取主页列表缩略图上传配置
export function GetMainListThumbnailUploadOption(params){
  return post('/NewApi/LeagueManager/GetMainListThumbnailUploadOption',params)
}
//推荐新闻到首页
export function AddNewsToMainListEntry(params){
  return post('/NewApi/LeagueManager/AddNewsToMainListEntry',params)
}
///////////// 裁判 //////////////////
//获取裁判分页列表
export function GetUserRefereePageList(params){
  return post('/NewApi/LeagueManager/GetUserRefereePageList',params)
}
//创建裁判员
export function CreateReferee(params){
  return post('/NewApi/LeagueManager/CreateReferee',params, {noErrorCode: [8000, 8002, 2214]})
}
//注册裁判员
export function RegisReferee(params){
  return post('/NewApi/LeagueManager/RegisReferee',params, {noErrorCode: [8000]})
}
//获取裁判员详情
export function GetUserRefereeDetail(params){
  return post('/NewApi/LeagueManager/GetUserRefereeDetail',params)
}
//保存用户裁判基本信息
export function SaveUserRefereeBaseInfo(params){
  return post('/NewApi/LeagueManager/SaveUserRefereeBaseInfo',params)
}
//保存裁判执裁经历
export function SaveUserRefereeExperience(params){
  return post('/NewApi/LeagueManager/SaveUserRefereeExperience',params)
}
//删除裁判执裁经历
export function DelUserRefereeExperience(params){
  return post('/NewApi/LeagueManager/DelUserRefereeExperience',params)
}
//保存裁判培训经历
export function SaveUserRefereeTrainExperience(params){
  return post('/NewApi/LeagueManager/SaveUserRefereeTrainExperience',params)
}
//删除裁判培训经历
export function DelUserRefereeTrainExperience(params){
  return post('/NewApi/LeagueManager/DelUserRefereeTrainExperience',params)
}
//////////// 教练 //////////////
//获取教练分页列表
export function GetUserCoachPageList(params){
  return post('/NewApi/LeagueManager/GetUserCoachPageList',params)
}
//创建教练员
export function CreateCoach(params){
  return post('/NewApi/LeagueManager/CreateCoach',params, {noErrorCode: [8100, 8102, 2214]})
}
//注册教练员
export function RegisCoach(params){
  return post('/NewApi/LeagueManager/RegisCoach',params, {noErrorCode: [8100]})
}
//获取教练员详情
export function GetUserCoachDetail(params){
  return post('/NewApi/LeagueManager/GetUserCoachDetail',params)
}
//保存用户教练基本信息
export function SaveUserCoachBaseInfo(params){
  return post('/NewApi/LeagueManager/SaveUserCoachBaseInfo',params)
}
//保存教练执教经历
export function SaveUserCoachExperience(params){
  return post('/NewApi/LeagueManager/SaveUserCoachExperience',params)
}
//删除教练执教经历
export function DelUserCoachExperience(params){
  return post('/NewApi/LeagueManager/DelUserCoachExperience',params)
}
//保存教练培训经历
export function SaveUserCoachTrainExperience(params){
  return post('/NewApi/LeagueManager/SaveUserCoachTrainExperience',params)
}
//删除教练培训经历
export function DelUserCoachTrainExperience(params){
  return post('/NewApi/LeagueManager/DelUserCoachTrainExperience',params)
}
/////////// 培训 /////////////
//获取培训管理初始化信息
export function GetTrainManageInit(params){
  return post('/NewApi/LeagueManager/GetTrainManageInit',params)
}
//获取培训管理列表
export function GetTrainManageList(params){
  return post('/NewApi/LeagueManager/GetTrainManageList',params)
}
//获取培训详情
export function GetTrainDetail(params){
  return post('/NewApi/LeagueManager/GetTrainDetail',params)
}
//新建培训
export function CreateTrain(params){
  return post('/NewApi/LeagueManager/CreateTrain',params)
}
//修改培训
export function AlterTrain(params){
  return post('/NewApi/LeagueManager/AlterTrain',params)
}
//发布培训
export function PublishTrain(params){
  return post('/NewApi/LeagueManager/PublishTrain',params)
}
//获取培训报名管理初始化信息
export function GetTrainEnrollManageInit(params){
  return post('/NewApi/LeagueManager/GetTrainEnrollManageInit',params)
}
//获取培训报名管理列表
export function GetTrainEnrollManageList(params){
  return post('/NewApi/LeagueManager/GetTrainEnrollManageList',params)
}
//获取培训班级列表
export function GetTrainClasses(params){
  return post('/NewApi/LeagueManager/GetTrainClasses',params)
}
//获取报名设置
export function GetTrainEnrollSetting(params){
  return post('/NewApi/LeagueManager/GetTrainEnrollSetting',params)
}
//发布报名
export function PublishTrainEnroll(params){
  return post('/NewApi/LeagueManager/PublishTrainEnroll',params)
}
//取消报名
export function CancelTrainEnroll(params){
  return post('/NewApi/LeagueManager/CancelTrainEnroll',params)
}
//获取未创建报名的培训列表
export function GetTrainListWithoutCreateEnroll(params){
  return post('/NewApi/LeagueManager/GetTrainListWithoutCreateEnroll',params)
}
//创建培训报名
export function CreateTrainEnroll(params){
  return post('/NewApi/LeagueManager/CreateTrainEnroll',params)
}
//修改培训报名
export function AlterTrainEnroll(params){
  return post('/NewApi/LeagueManager/AlterTrainEnroll',params)
}
//获取培训订单统计
export function GetTrainOrderStatistics(params){
  return post('/NewApi/LeagueManager/GetTrainOrderStatistics',params)
}
//获取培训订单管理列表
export function GetTrainOrderManageList(params){
  return post('/NewApi/LeagueManager/GetTrainOrderManageList',params)
}
//确认培训订单已支付(转账的)
export function ConfirmTrainOrderPaid(params){
  return post('/NewApi/LeagueManager/ConfirmTrainOrderPaid',params)
}
//获取培训cms栏目
export function GetTrainSscmsChannelConfig(params){
  return post('/NewApi/LeagueManager/GetTrainSscmsChannelConfig',params)
}
//保存培训cms栏目
export function SaveTrainSscmsChannelConfig(params){
  return post('/NewApi/LeagueManager/SaveTrainSscmsChannelConfig',params)
}
// 获取培训学员审核列表
export function GetTrainEnrollStudentAuditList(params){
  return post('/NewApi/LeagueManager/GetTrainEnrollStudentAuditList',params)
}
// 获取一条培训学员审核列表信息
export function GetOneTrainEnrollStudentAuditListInfo(params){
  return post('/NewApi/LeagueManager/GetOneTrainEnrollStudentAuditListInfo',params)
}
// 获取培训报名学员审核详情
export function GetTrainEnrollStudentAuditDetail(params){
  return post('/NewApi/LeagueManager/GetTrainEnrollStudentAuditDetail',params)
}
// 培训报名学员审核通过
export function AuditTrainEnrollStudentOk(params){
  return post('/NewApi/LeagueManager/AuditTrainEnrollStudentOk',params, {noErrorCode: [8265]})
}
//培训报名学员审核不通过
export function AuditTrainEnrollStudentReject(params){
  return post('/NewApi/LeagueManager/AuditTrainEnrollStudentReject',params)
}

