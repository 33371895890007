<template>
    <div class="container">
        <el-page-header @back="$router.back()" content="新建教练员"></el-page-header>
        <div class="content">
            <el-form :model="form" ref="form" :rules="rules" size="mini" class="form" label-width="120px">
                <el-form-item label="运动项目：" prop="eventsId">
                    <el-select v-model="form.eventsId" @change="eventsChange">
                        <el-option v-for="(item,index) in eventsList" :value="item.Id" :label="item.Name" :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="当前等级：" prop="currentLevelNo">
                    <el-select v-model="form.currentLevelNo">
                        <el-option value="选择运动项目后显示" v-if="!form.eventsId && !coachLevelList.length" disabled>选择运动项目后显示</el-option>
                        <el-option v-for="(item,index) in coachLevelList" :value="item.LevelNo" :label="item.LevelName" :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="手机号：" prop="phone">
                    <el-input v-model="form.phone"></el-input>
                </el-form-item>
                <el-form-item label="姓名：" prop="name">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="身份证号码：" prop="identityNo">
                    <el-input v-model="form.identityNo"></el-input>
                </el-form-item>
                <el-row>
                    <el-button type="primary" style="margin-left: 120px" @click="create"> 创建 </el-button>
                </el-row>
            </el-form>
            <template v-if="occupationList.length">
                <el-descriptions title="手机号已被此用户占用："></el-descriptions>
            </template>
            <template v-if="existUserList.length">
                <el-descriptions title="您要创建的用户可能已经存在："></el-descriptions>
            </template>
            <template v-if="occupationList.length || existUserList.length">
                <el-table stripe style="width: 100%" :data="occupationList.length ? occupationList : existUserList" size="mini">
                    <el-table-column prop="UserInfo.UserId" label="用户ID" align="center"></el-table-column>
                    <el-table-column prop="UserInfo.Phone" label="手机号" align="center"></el-table-column>
                    <el-table-column prop="UserInfo.Name" label="姓名" align="center"></el-table-column>
                    <el-table-column label="身份证号码" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.UserInfo.IdentityType == 1">{{scope.row.UserInfo.IdentityNo}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="个人用户操作" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" v-href="`/account/UserDetail/${scope.row.UserInfo.UserId}`">查看/修改</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="User4Coach.Id" label="教练员ID" align="center"></el-table-column>
                    <el-table-column prop="User4Coach.CurrentLevelName" label="当前等级" align="center"></el-table-column>
                    <el-table-column label="教练员操作" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" @click="regis(scope.row)" v-if="!scope.row.User4Coach">注册为教练员</el-button>
                            <el-button type="text" v-href="`/refereeOrCoach/CoachDetail/${scope.row.User4Coach.Id}`" v-else>查看/修改</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
            <el-row style="margin-top: 20px" v-if="existUserList.length">
                <el-button type="primary" @click="continueCreate"> 都不是，继续创建 </el-button>
            </el-row>
        </div>
    </div>
</template>
<script>
import identityCard from '@/utils/identityCard.js';

export default {
    name: 'CreateCoach',
    data() {
        return {
            form:{
                eventsId: null,
                currentLevelNo: null,
                phone: "",
                name: "",
                identityNo: ""
            },
            oldForm: null,
            eventsList: [],
            coachLevelList: [],
            occupationList: [],
            existUserList: []
        };
    },
    created() {
        this.$api.GetCoachLevelFlattedEventsList().then(data=>{
            if(data.Code == 0){
                this.eventsList = data.EventsList || [];
            }
        })
    },
    computed:{
        rules(){
            return {
                eventsId: [{required: true, message: '请选择运动项目'}],
                currentLevelNo: [{required: true, message: '请选择当前等级'}],
                phone: [
                    {validator: (rule, value, callback)=>{
                        if(value && (value.length != 11 || value[0] !== '1') || isNaN(Number(value))){
                            callback(new Error('手机号格式不正确'));
                        }else{
                            callback();
                        }
                    }}
                ],
                name: [
                    {required: true, message: '请输入姓名'},
                    {min: 2, max: 50, message: '限制2～50个字符'}
                ],
                identityNo: [
                    {required: true, message: '请输入身份证号码'},
                    {validator: (rule, value, callback)=>{
                        let info = identityCard(value);
                        if(info){
                            callback();
                        }else{
                            callback(new Error('身份证号码格式有误'));
                        }
                    }}
                ]
            }
        }
    },
    methods: {
        eventsChange(){
            this.form.currentLevelNo = null;
            if(this.form.eventsId){
                this.getCoachLevel();
            }else{
                this.coachLevelList = [];
            }
        },
        getCoachLevel(){
            this.$api.GetCoachLevelDictionary({
                "EventsId": this.form.eventsId
            }).then(data=>{
                if(data.Code == 0){
                    this.coachLevelList = data.Levels || [];
                }
            })
        },
        create(){
            this.occupationList = [];
            this.existUserList = [];
            this.$refs.form.validate(valid=>{
                if(valid){
                    this.submit(this.form, false);
                }else{
                    this.$message.error('填写信息有误，请查看！');
                }
            })
        },
        continueCreate(){
            if(this.oldForm){
                this.submit(this.oldForm, true);
            }
        },
        submit(form, is){
            let loading = this.$loading({
                text: '创建中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.8)'
            })
            this.$api.CreateCoach({
                "EventsId": form.eventsId,
                "CurrentLevelNo": form.currentLevelNo,
                "Phone": form.phone,
                "Name": form.name,
                "IdentityNo": form.identityNo,
                "IsForceCreate": is
            }).then(data=>{
                loading.close();
                if(data.Code == 0){
                    this.$message.success('创建成功');
                    this.$router.replace('/refereeOrCoach/CoachDetail/' + data.CoachId);
                }else if(data.Code == 8100){
                    this.$router.replace('/refereeOrCoach/CoachDetail/' + data.CoachId);
                }else if(data.Code == 8102){
                    this.oldForm = {...this.form};
                    this.existUserList = data.ExistsCoachs || [];
                }else if(data.Code == 2214){
                    this.oldForm = {...this.form};
                    this.occupationList = data.ExistsCoachs || [];
                }
            }).catch(()=>{
                loading.close();
            })
        },
        regis(obj){
            let loading = this.$loading({
                text: '注册中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.8)'
            })
            let form = this.oldForm;
            this.$api.RegisCoach({
                "QueryUserId": obj.UserInfo.UserId,
                "EventsId": form.eventsId,
                "CurrentLevelNo": form.currentLevelNo
            }).then(data=>{
                loading.close();
                if(data.Code == 0){
                    this.$message.success('注册成功');
                    this.$router.replace('/refereeOrCoach/CoachDetail/' + data.CoachId);
                }else if(data.Code == 8100){
                    this.$router.replace('/refereeOrCoach/CoachDetail/' + data.CoachId);
                }
            }).catch(()=>{
                loading.close();
            })
        }
    },
};
</script>