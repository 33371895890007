<template>
    <div id="app" class="app">
        <login :sessionToken="sessionToken" @session="getSession"></login>
        <div class="allContent" v-if="sessionToken">
            <Header @session="getSession" @isNavIdx="getNavIdx" :initNav="initNav" />
            <div class="mainBody">
                <div class="leftSide">
                     <!-- @updateIdx="getSubnavIdx" -->
                    <LeftSide :subnav="subnav"/>
                </div>
                <div class="rightSide">
                    <router-view/>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Login from './components/Login'
import Header from './components/Header.vue'
import LeftSide from './components/LeftSide.vue'

export default {
    name: 'App',
    components: {
        Login,
        Header,
        LeftSide
    },
    created() {
        this.initHeadNav()
        console.log(this.$cookies.get('token'))
    },
    data(){
        return {
            sessionToken:!!this.$cookies.get('token'),
            initNav: 0,
            navIdx: -1,
            subnav: [],
            // subnavIdx: 0,
            subnavData: [
                [
                    {txt: '主页列表管理', src: '/infos/APPHomeList'},
                    {txt: '新闻管理', src: '/infos/news'},
                    {txt: '亿动体育轮播图', src: '/infos/APPslideshow/89'},
                    {txt: '掼蛋大师轮播图', src: '/infos/APPslideshow/71'}
                ], [
                    {txt: '赛事管理', src: '/match/contest'},
                    {txt: '赛事审核', src: '/match/pass'},
                    {txt: '认证评定', src: '/match/rating'},
                    {txt: '子赛事排序', src: '/match/MatchSort'}
                ], [
                    {txt: '积分管理', src: '/vipPoint/manage'},
                    {txt: '积分变更查询', src: '/vipPoint/search'},
                    {txt: '积分计算', src: '/vipPoint/count'}
                ], [
                    {txt: '运动员等级评定', src: '/grade/athleteRating'},
                    {txt: '证书查询', src: '/grade/certificate'}
                ], [
                    {txt: '大师分计算', src: '/masterScore/numericalIntegration'},
                    {txt: '大师分变更查询', src: '/masterScore/changeQuery'}
                ], [
                    {txt: '裁判员库', src: '/refereeOrCoach/RefereeList'},
                    {txt: '教练员库', src: '/refereeOrCoach/CoachList'},
                    {txt: '培训管理', src: '/refereeOrCoach/trainingManagement/List'},
                    {txt: '培训报名管理', src: '/refereeOrCoach/trainingEnroll/List'}
                ], [
                    {txt: '机构会员申请记录', src: '/account/vipApplyLists'},
                    {txt: '会员信息查询', src: '/account/MemberQuery'},
                    {txt: '账号管理', src: '/account/AccountManage'},
                    {txt: '个人用户管理', src: '/account/UserList'}
                ]
            ],
        }
    },
    watch: {
        sessionToken () {
            if(!this.$cookies.get('token')){
                this.sessionToken = false
                this.initHeadNav()
            }
        },
        navIdx:{
            handler(v){
                this.subnav = this.subnavData[v]
            }
        },
        $route () {
            this.initHeadNav();
        },
    },
    methods: {
        getSession (num) {
            console.log(num)
            this.sessionToken = num == 1
        },
        // getSubnavIdx(idx) {
        //     this.subnavIdx = idx
        // },
        getNavIdx(idx) {
            this.navIdx = idx
        },
        initHeadNav() {
            let arr = ['info', 'match', 'vipPoint', 'grade', 'masterScore', 'refereeOrCoach', 'account']
            for(let i in arr) {
                if(this.$route.path.indexOf(arr[i]) != -1) {
                    this.initNav = i
                    this.subnav = this.subnavData[i]
                    return
                }
            }
        }
    }
}
</script>

<style>
*{
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    font-family:'Helvetica', 'Tahoma', 'Arial', 'microsoft yahei', 'SimSun';
    font-size: 14px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 1200px;
    height: 100%;
    margin: 0 auto;
}
.mainBody{
    height: calc(100% - 4.4rem);
}
.allContent{
    width: 100%;
    height: 100%;
}
/*去掉input[type=number]默认的加减号*/
input[type='number'] {
    -moz-appearance:textfield;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
html,body{
    width: 100%;
    height: 100%;
    background: #101c35;
}
a{
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-user-select: none;
    -moz-user-focus: none;
    -moz-user-select: none;
}
.clearfix:after {
    clear:both;
    content:"";
    display:block;
    height:0;
    line-height:0;
    visibility:hidden;
}
[v-cloak] {
	display: none;
}
.box-body{
    width: 100%;
    height: calc(100% - 4.4rem);
}
.fl{
    float: left;
}
.fr{
    float: right;
}
.leftSide{
    width: 12rem;
    height: 100%;
    text-align: center;
    background: #fff;
    padding-top: 2rem;
    float: left;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.rightSide{
    width: 100%;
    height: 100%;
    background: #e9e9e9;
    padding: .4rem .4rem .4rem 13.2rem;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
</style>
